import cx from 'classnames';
import { Tooltip } from 'dodoc-design-system';
import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import AddIcon from './AddIcon';
import styles from './TableOptions.module.scss';

type AddShortcutProps = {
  type: 'row' | 'column';
  first: boolean;
  last: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  enabled: boolean;
  testId: string;
};

const AddShortcut = ({ first, last, type, onClick, enabled, testId }: AddShortcutProps) => {
  const intl = useIntl();

  return (
    <div
      className={cx(styles.shortcut, styles[type === 'column' ? 'top' : 'side'], {
        [styles.first]: first,
        [styles.last]: last,
        [styles.enabled]: enabled,
      })}
      data-testid={testId}
    >
      <div className={styles.hidden}>
        <div className={styles.icon} onClick={onClick} data-testid={`add-${type}-icon-shortcut`}>
          <Tooltip
            content={intl.formatMessage({
              id: type === 'column' ? 'ADD_COLUMN_HERE' : 'ADD_ROW_HERE',
            })}
            testId={`add-${type}-icon-shortcut-tooltip`}
          >
            <AddIcon />
          </Tooltip>
          <div className={styles.pau} />
        </div>
      </div>
      <div className={styles.dot} />
    </div>
  );
};

export default AddShortcut;

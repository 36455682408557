import { ReactNode, useRef, useState } from 'react';
import { CSSProperties } from 'react';
import { Tooltip } from 'dodoc-design-system';
import type { AvatarProps } from 'dodoc-design-system/build/types/Components/Avatar/Avatar';
import { usePublicProfile } from '_common/hooks';
import { SuiteAvatar } from '_common/suite/components';
import UserAvatar from '../UserAvatar/UserAvatar';
import UsernameLabel from '../Labels/UsernameLabel/UsernameLabel';
import styles from './UserCard.module.scss';

type UserCardProps = {
  userId: UserId;
  appendToName?: ReactNode;
  editor?: boolean;
  online?: boolean;
  titleStyle?: CSSProperties;
  color?: CSSProperties['color'];
  size?: AvatarProps['size'];
};

const UserCard = ({
  userId,
  appendToName = null,
  editor = false,
  size = 'medium',
  online = false,
  color,
  titleStyle,
}: UserCardProps) => {
  const [nameWrapped, setNameWrapped] = useState(false);
  const [emailWrapped, setEmailWrapped] = useState(false);
  const nameRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);

  const profile = usePublicProfile(userId);

  const showTooltip = () => {
    if (emailRef.current) {
      if (emailRef.current.scrollWidth > emailRef.current.clientWidth) {
        setEmailWrapped(true);
      } else {
        setEmailWrapped(false);
      }
    }
    if (nameRef.current) {
      if (nameRef.current.scrollWidth > nameRef.current.clientWidth) {
        setNameWrapped(true);
      } else {
        setNameWrapped(false);
      }
    }
  };


  return (
    <div
      className={`${styles.root} ${size && styles[size]} ${online && styles.online}`}
      data-testid={`${userId}-user-card`}
    >
      <div className={styles.avatar}>
        {editor ? (
          <SuiteAvatar userId={userId} size={size} online={online} />
        ) : (
          <UserAvatar userId={userId} size={size} online={online} />
        )}
      </div>
      <Tooltip
        placement="bottom"
        disabled={!nameWrapped && !emailWrapped}
        content={`${nameWrapped ? `${profile.name}\n` : ''}${
          emailWrapped ? `${profile.email}` : ''
        }`}
        testId={`user-card-${userId}-username-tooltip`}
      >
        <div
          className={`${styles.container} ${size && styles[size]}`}
          style={{ color }}
          onMouseEnter={showTooltip}
        >
          <div className={`${styles.name} ${styles.ellipsis}`} ref={nameRef}>
            <span style={{ fontWeight: 600, ...titleStyle }}>
              <UsernameLabel userId={userId} />
            </span>
            {appendToName}
          </div>

          {profile.email && (
            <div
              className={`${styles.username} ${styles.ellipsis}`}
              ref={emailRef}
              style={{ color }}
              data-testid={`${userId}-user-card-email`}
            >
              {profile.email}
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default UserCard;

import { cloneDeep } from 'lodash-es';
import { ParseMapper } from 'Editor/services/Parsers';
import { ELEMENTS } from 'Editor/services/consts';
import 'tippy.js/index.css';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { TableElement } from '../../TableViewBuilder';

import './TableCellElement.module.scss';

class DataCell extends HTMLTableCellElement {}

export class TableCellElement extends DataCell {
  Visualizer?: Editor.Visualizer.State;
  Data?: Editor.Data.API;

  private node?: HTMLElement;
  // protected tooltipElement?: HTMLElement;
  // protected tippyInstance: Instance | null = null;
  static EXTENDS = 'td';

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super();
    this.Visualizer = Visualizer;
    this.Data = Data;
  }

  get identifier() {
    return ELEMENTS.TableCellElement.IDENTIFIER;
  }

  connectedCallback() {
    this.dataset.gramm_editor = 'false';

    this.validateLegacyCellWidth();

    this._handleDataAttributes();
  }

  disconnectedCallback() {}

  static get observedAttributes() {
    return ['data-background-color', 'data-width'];
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'data-background-color':
          this._handleBackgroundColor();
          break;
        case 'data-width':
          this.handleCellWidth();
          break;
        default:
          break;
      }
    }
  }

  _handleDataAttributes() {
    if (this.dataset.backgroundColor) {
      this._handleBackgroundColor();
    }

    this.handleCellWidth();
  }

  _handleBackgroundColor() {
    const bg = this.dataset.backgroundColor;
    if (bg != null && bg !== 'null') {
      if (bg === 'rgba(0, 0, 0, 0)' || bg === 'transparent' || bg === 'false') {
        this.style.backgroundColor = 'rgba(0,0,0,0)';
      } else if (bg.includes('rgb') || bg.includes('#')) {
        this.style.backgroundColor = bg;
      } else {
        this.style.backgroundColor = `#${bg}`;
      }
    } else {
      this.style.backgroundColor = '';
    }
  }

  private validateLegacyCellWidth() {
    if (this.dataset.width == null) {
      const closesTable = EditorDOMUtils.closest(this, ELEMENTS.TableElement.TAG);

      if (closesTable instanceof TableElement && closesTable.dataset.cw) {
        const width = closesTable.dataset.cw.split(',')[this.cellIndex];
        this.dataset.width = `abs,${width}`;
      }
    }
  }

  private handleCellWidth() {
    if (this.dataset.width != null) {
      const [type, value] = this.dataset.width.split(',');

      switch (type as Editor.Data.Node.TableWidthTypes) {
        case 'pct':
          this.style.setProperty('--width', `${+value * 100}%`);
          break;
        case 'abs':
          this.style.setProperty('--width', `${value}pt`);
          break;
        case 'auto':
        case 'nil':
        default:
          this.style.setProperty('--width', 'auto');
          break;
      }
    }
  }

  get isSelected() {
    return this.dataset.selected === 'true';
  }

  get isMergedCell() {
    return this.colSpan > 1 || this.rowSpan > 1;
  }

  get isContainerElement() {
    return true;
  }

  get isEditable(): boolean {
    return true;
  }

  get isDeletable(): boolean {
    return true;
  }

  getCellInfo(): Editor.Elements.Table.CellInfo {
    const row = this.parentElement instanceof HTMLTableRowElement ? this.parentElement : null;

    return {
      id: this.id,
      isMergedCell: this.isMergedCell,
      rowSpan: this.rowSpan,
      colSpan: this.colSpan,
      cellIndex: this.cellIndex,
      sectionRowIndex: row ? row.sectionRowIndex : null,
    };
  }

  selectCell(rowPosition?: string, colPosition?: string) {
    this.dataset.selected = 'true';

    delete this.dataset.rowPosition;

    if (rowPosition != null) {
      this.dataset.rowPosition = rowPosition;
    }

    delete this.dataset.colPosition;

    if (colPosition != null) {
      this.dataset.colPosition = colPosition;
    }
  }

  deselectCell() {
    delete this.dataset.selected;
    delete this.dataset.rowPosition;
    delete this.dataset.colPosition;
  }

  getHeadCellId() {
    return this.getAttribute('head-id');
  }

  setNode(node: HTMLElement) {
    this.node = cloneDeep(node);
  }

  /**
   *
   * @deprecated
   */
  setVerticalAlignment(alignment?: string) {
    if (alignment != null) {
      this.dataset.verticalAlignment = alignment;
    }
  }
  /**
   * @deprecated
   */
  setBackgroundColor(color?: string) {
    if (color != null) {
      this.dataset.backgroundColor = color;
    }
  }

  /**
   * @param value - should be in 'pt'
   * @deprecated
   */
  setPadding(property: string, value?: string | number | null) {
    if (value != null) {
      switch (property) {
        case 'padding-top':
        case 'paddingTop':
          this.style.paddingTop = `${value}pt`;
          break;
        case 'padding-bottom':
        case 'paddingBottom':
          this.style.paddingBottom = `${value}pt`;
          break;
        case 'padding-left':
        case 'paddingLeft':
          this.style.paddingLeft = `${value}pt`;
          break;
        case 'padding-right':
        case 'paddingRight':
          this.style.paddingRight = `${value}pt`;
          break;
        default:
          break;
      }
    }
  }

  /**
   * @deprecated
   * @param property
   * @param value
   */
  setBorderProperty(property: string, value?: string | number | null) {
    if (value != null) {
      property = property.toLowerCase();

      if (property.includes('color')) {
        if (property.includes('top')) {
          this.style.borderTopColor = `${value}`;
        } else if (property.includes('bottom')) {
          this.style.borderBottomColor = `${value}`;
        } else if (property.includes('left')) {
          this.style.borderLeftColor = `${value}`;
        } else if (property.includes('right')) {
          this.style.borderRightColor = `${value}`;
        } else {
          this.style.borderTopColor = `${value}`;
          this.style.borderBottomColor = `${value}`;
          this.style.borderLeftColor = `${value}`;
          this.style.borderRightColor = `${value}`;
        }
      } else if (property.includes('width')) {
        let val = `${EditorDOMUtils.convertUnitTo(value, undefined, 'pt', 3)}pt`;
        if (property.includes('top')) {
          this.style.borderTopWidth = val;
        } else if (property.includes('bottom')) {
          this.style.borderBottomWidth = val;
        } else if (property.includes('left')) {
          this.style.borderLeftWidth = val;
        } else if (property.includes('right')) {
          this.style.borderRightWidth = val;
        } else {
          this.style.borderTopWidth = val;
          this.style.borderBottomWidth = val;
          this.style.borderLeftWidth = val;
          this.style.borderRightWidth = val;
        }
      } else if (property.includes('style')) {
        const style = ParseMapper.borderStyleMapper.render(value);
        if (property.includes('top')) {
          this.style.borderTopStyle = style;
        } else if (property.includes('bottom')) {
          this.style.borderBottomStyle = style;
        } else if (property.includes('left')) {
          this.style.borderLeftStyle = style;
        } else if (property.includes('right')) {
          this.style.borderRightStyle = style;
        } else {
          this.style.borderTopStyle = style;
          this.style.borderBottomStyle = style;
          this.style.borderLeftStyle = style;
          this.style.borderRightStyle = style;
        }
      } else {
        // generic property
        if (property.includes('top')) {
          this.style.borderTop = `${value}`;
        } else if (property.includes('bottom')) {
          this.style.borderBottom = `${value}`;
        } else if (property.includes('left')) {
          this.style.borderLeft = `${value}`;
        } else if (property.includes('right')) {
          this.style.borderRight = `${value}`;
        } else {
          this.style.border = `${value}`;
        }
      }
    }
  }

  /**
   * @param styles
   */
  setTableCellStyles(styles: { [index: string]: string } = {}) {
    const keys = Object.keys(styles);

    for (let i = 0; i < keys.length; i++) {
      const styleProp = keys[i];
      const value = styles[styleProp];

      if (styleProp.includes('border')) {
        this.setBorderProperty(styleProp, value);
      } else if (styleProp.includes('padding')) {
        this.setPadding(styleProp, value);
      } else if (styleProp.includes('background')) {
        this.setBackgroundColor(value);
      } else if (styleProp.includes('verticalAlignment')) {
        this.setVerticalAlignment(value);
      }
    }
  }
}

// register element
if (!window.customElements.get(ELEMENTS.TableCellElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.TableCellElement.IDENTIFIER, TableCellElement, {
    extends: TableCellElement.EXTENDS,
  });
}

import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';
import styles from './ImageElement.module.scss';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import EditorManager from 'Editor/services/EditorManager';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';

import { ELEMENTS } from 'Editor/services/consts';
import { EditorService, Logger } from '_common/services';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import ReduxInterface from 'Editor/services/ReduxInterface';
import { BaseViewElement } from '../../BaseViewBuilder';

const MAX_WIDTH = 663;

export class ImageElement extends BaseViewElement {
  private loaded: boolean;
  private _imageBase64?: string;
  private _imageWrap: Editor.Styles.ImageWrapProperty = 'inline';

  private pending: ((value?: unknown) => void)[];
  private image?: HTMLElement;
  private container?: HTMLElement;
  private icon?: HTMLElement;
  private description?: HTMLElement;

  private timeoutImageWrapping?: NodeJS.Timeout;

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super(Visualizer, Data);

    this.setImageSource = this.setImageSource.bind(this);
    this.fetchImage = this.fetchImage.bind(this);
    this.resizeElement = this.resizeElement.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.buildImage = this.buildImage.bind(this);

    this.loaded = false;
    this.pending = [];
  }

  static get observedAttributes() {
    return [
      // 'data-left-indentation',
      // 'data-right-indentation',
      // 'data-special-indent',
      // 'data-special-indent-value',
      'data-box-shadow',
      'data-w',
      'data-h',
      'data-wr',
      'data-sr',
      'data-ax',
      'data-ay',
      'data-ox',
      'data-oy',
      'data-rfx',
      'data-rfy',
      'data-bd',
      'data-es',
    ];
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      switch (attribute) {
        // case 'data-left-indentation':
        // case 'data-right-indentation':
        // case 'data-special-indent':
        // case 'data-special-indent-value':
        //   this.handleIndentation();
        //   break;
        case 'data-w':
          if (newValue != null) {
            this.style.width = `${EditorDOMUtils.convertUnitTo(newValue, 'pt', 'px', 0)}px`;
          } else {
            this.style.width = '';
          }

          this.setBoxShadow();
          this.handleImageCropping();
          break;
        case 'data-h':
          if (newValue != null) {
            this.style.height = `${EditorDOMUtils.convertUnitTo(newValue, 'pt', 'px', 0)}px`;
          } else {
            this.style.height = '';
          }

          this.setBoxShadow();
          this.handleImageCropping();
          break;
        case 'data-bd':
        case 'data-ax':
        case 'data-ay':
        case 'data-ox':
        case 'data-oy':
        case 'data-rfx':
        case 'data-rfy':
        case 'data-wr': {
          this.shceduleHandleImageWrapping();
          break;
        }
        case 'data-sr': {
          this.handleImageCropping();
          break;
        }
        case 'data-box-shadow':
          this.setBoxShadow();
          break;
        case 'data-es':
          this.fetchImage();
          break;
        default:
          break;
      }
    }
  }

  connectedCallback() {
    if (!this.preRendered) {
      this.preRender();
    }

    super.connectedCallback();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  static findStep(current: number, next: boolean) {
    const INDENTATION_MIN = StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MIN;
    const INDENTATION_MAX = StylesUtils.INDENTATION_LIMITS.LEFT.MANIPULATION_MAX;
    const INDENTATION_VALUE = StylesUtils.INDENTATION_LIMITS.INDENTATION_STEP;

    const value = next ? current + INDENTATION_VALUE : current;
    const rest: number = value % INDENTATION_VALUE;

    let result = current;

    if (Number(parseFloat(rest.toString()).toFixed(2)) === 0) {
      result = next ? current + INDENTATION_VALUE : current - INDENTATION_VALUE;
    } else {
      result = next ? value : value - rest;
    }

    if (result > 0) {
      return Math.min(INDENTATION_MAX, result);
    }
    return Math.max(INDENTATION_MIN, result);
  }

  get width(): number {
    return this.dataset.w ? +this.dataset.w : 0;
  }

  get height(): number {
    return this.dataset.h ? +this.dataset.h : 0;
  }

  get float(): Editor.Data.Node.ImageProperties['f'] {
    return this.dataset.f === 'true';
  }

  get behindDoc(): Editor.Data.Node.ImageProperties['bd'] {
    return this.dataset.bd === 'true';
  }

  get wrapping(): Editor.Data.Node.ImageProperties['wr'] {
    return this.dataset.wr != null ? JSON.parse(this.dataset.wr) : {};
  }

  get croppingRect(): Editor.Data.Node.ImageProperties['sr'] | null {
    return this.dataset.sr != null ? JSON.parse(this.dataset.sr) : null;
  }

  get alingX(): Editor.Data.Node.ImageProperties['ax'] | null {
    return this.dataset.ax != null
      ? (this.dataset.ax as Editor.Data.Node.ImageProperties['ax'])
      : null;
  }

  get alingY(): Editor.Data.Node.ImageProperties['ay'] | null {
    return this.dataset.ay != null
      ? (this.dataset.ay as Editor.Data.Node.ImageProperties['ay'])
      : null;
  }

  get offsetX(): Editor.Data.Node.ImageProperties['ox'] | null {
    if (this.dataset.ox != null) {
      return +this.dataset.ox;
    }
    return null;
  }

  get offsetY(): Editor.Data.Node.ImageProperties['oy'] | null {
    if (this.dataset.oy != null) {
      return +this.dataset.oy;
    }
    return null;
  }

  get relativeX(): Editor.Data.Node.ImageProperties['rfx'] | null {
    if (this.dataset.rfx != null) {
      return this.dataset.rfx as Editor.Data.Node.ImageProperties['rfx'];
    }
    return null;
  }

  get relativeY(): Editor.Data.Node.ImageProperties['rfy'] | null {
    if (this.dataset.rfy != null) {
      return this.dataset.rfy as Editor.Data.Node.ImageProperties['rfy'];
    }
    return null;
  }

  get sourceId(): Editor.Data.Node.ImageProperties['es'] | null {
    if (this.dataset.es != null) {
      return this.dataset.es;
    }
    return null;
  }

  get imageBase64() {
    return this._imageBase64;
  }

  get imageWrap() {
    return this._imageWrap;
  }

  awaitForImageLoad() {
    if (this.loaded) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      this.pending.push(resolve);
    });
  }

  triggerImageLoaded() {
    this.loaded = true;
    while (this.pending?.length) {
      this.pending.pop()?.();
    }
  }

  // private handleIndentation() {
  //   // WARN: OUTDATED CODE
  //   let indentType;
  //   let indentValue;
  //   const spInd = {
  //     v:
  //       this.dataset.specialIndentValue &&
  //       (!Number(this.dataset.specialIndentValue)
  //         ? `${Units.convert(
  //             this.dataset.specialIndentValue.replace(/\s/g, '').slice(0, -2),
  //             Units.TYPE.POINT,
  //             this.dataset.specialIndentValue.slice(-2),
  //           )}`
  //         : this.dataset.specialIndentValue),
  //     t: this.dataset.specialIndent,
  //   };
  //   const ind = {
  //     l:
  //       this.dataset.leftIndentation &&
  //       (!Number(this.dataset.leftIndentation)
  //         ? `${Units.convert(
  //             this.dataset.leftIndentation.replace(/\s/g, '').slice(0, -2),
  //             Units.TYPE.POINT,
  //             this.dataset.leftIndentation.slice(-2),
  //           )}`
  //         : this.dataset.leftIndentation),
  //     r:
  //       this.dataset.rightIndentation &&
  //       (!Number(this.dataset.rightIndentation)
  //         ? `${Units.convert(
  //             this.dataset.rightIndentation.replace(/\s/g, '').slice(0, -2),
  //             Units.TYPE.POINT,
  //             this.dataset.rightIndentation.slice(-2),
  //           )}`
  //         : this.dataset.rightIndentation),
  //   };
  //   if (spInd && spInd.t && spInd.v !== undefined) {
  //     indentType = spInd.t;
  //     indentValue = spInd.v;
  //   }
  //   if (ind && ind.r) {
  //     this.style.marginRight = `${ind.r}pt`;
  //   }
  //   if (indentType === INDENT_TYPE.NONE && indentValue) {
  //     this.style.paddingLeft = `${indentValue}pt`;
  //   }
  //   if (indentType === INDENT_TYPE.HANGING && indentValue) {
  //     this.style.textIndent = `-${indentValue}pt`;
  //     this.style.marginLeft = `${indentValue}pt`;
  //   }
  //   if (indentType === INDENT_TYPE.FIRST_LINE && indentValue) {
  //     this.style.textIndent = `${indentValue}pt`;
  //   }
  // }

  // private changeIndentation(indent: boolean) {
  //   const manager = EditorManager.getInstance();
  //   let current = 0;
  //   if (this.dataset.leftIndentation) {
  //     current = Number(this.dataset.leftIndentation);
  //   } else if (this.dataset.styleId && manager.hasStyleIndentation(this.dataset?.styleId)) {
  //     //TODO
  //     //CORRETO?
  //     const style = manager.hasStyleIndentation(this.dataset?.styleId);
  //     current = style?.l ?? 0;
  //   }
  //   const result = ImageElement.findStep(current, indent);
  //   this.dataset.leftIndentation = result.toString();
  // }

  // indent() {
  //   this.changeIndentation(true);
  // }

  // outdent() {
  //   this.changeIndentation(false);
  // }

  private setBoxShadow() {
    this.style.boxShadow = this.dataset.boxShadow ?? '';
  }

  preRender(pageWidth?: number, isAtNodeStart: boolean = false) {
    if (this.hasAttribute('localfile')) {
      this.setError();
    } else {
      this.image = this.querySelector('img') ?? undefined;
      if (!this.image) {
        this.image = this.buildImage();
        this.image.setAttribute('placeholder', 'true');

        this.appendChild(this.image);
        this.setBoxShadow();
      }

      this.fetchImage();
    }
    // this.handleIndentation();

    this.handleImageSize();

    this.handleImageCropping();

    this.handleImageWrapping(pageWidth, isAtNodeStart);

    super.preRender();
  }

  handleImageSize() {
    if (this.dataset.w != null) {
      this.style.width = `${EditorDOMUtils.convertUnitTo(this.dataset.w, 'pt', 'px', 0)}px`;
    }

    if (this.dataset.h != null) {
      this.style.height = `${EditorDOMUtils.convertUnitTo(this.dataset.h, 'pt', 'px', 0)}px`;
    }

    this.handleImageCropping();
  }

  handleImageCropping() {
    if (this.image) {
      // remove existing styles;
      this.image.style.removeProperty('marginLeft');
      this.image.style.removeProperty('marginTop');
      this.image.style.removeProperty('width');
      this.image.style.removeProperty('height');

      const crop = this.croppingRect;
      if (crop) {
        const l = crop.l || 0;
        const r = crop.r || 0;
        const t = crop.t || 0;
        const b = crop.b || 0;

        const restW = 1 - l - r;
        const uncroppedWidth = (this.width * 1) / restW;

        const restH = 1 - t - b;
        const uncroppedHeight = (this.height * 1) / restH;

        if (l) {
          this.image.style.marginLeft = `${-(l * uncroppedWidth)}pt`;
        }

        if (t) {
          this.image.style.marginTop = `${-(t * uncroppedHeight)}pt`;
        }

        if (this.width !== uncroppedWidth) {
          this.image.style.width = `${uncroppedWidth}pt`;
        }

        if (this.height !== uncroppedHeight) {
          this.image.style.height = `${uncroppedHeight}pt`;
        }
      }
    }
  }

  shceduleHandleImageWrapping() {
    if (this.timeoutImageWrapping) {
      clearTimeout(this.timeoutImageWrapping);
      delete this.timeoutImageWrapping;
    }

    this.timeoutImageWrapping = setTimeout(() => this.handleImageWrapping(), 0);
  }

  handleImageWrapping(pageWidth?: number, isAtNodeStart: boolean = false) {
    // remove existing styles;
    this.style.position = '';
    this.style.top = '';
    this.style.left = '';
    this.style.zIndex = '';
    this.style.float = '';
    this.style.marginLeft = '';
    this.style.marginBottom = '';
    this.style.marginRight = '';
    this.style.marginTop = '';
    this.style.display = '';

    if (this.float) {
      if (!pageWidth) {
        let level0 = EditorDOMUtils.findFirstLevelChildNode(
          EditorDOMUtils.getContentContainer(this),
          this,
        ) as HTMLElement;
        if (level0?.id) {
          pageWidth = this.Data?.sections.getPageWidthForBlockId(level0.id);
        }
      }

      let behindDoc = this.behindDoc;
      let wrapping = this.wrapping;

      switch (wrapping?.t) {
        case 'n': {
          // TODO validate relative offsets

          // position absolute
          this.style.position = 'absolute';
          if (this.offsetY != null && (this.relativeY === 'p' || this.relativeY === 'c')) {
            this.style.top = `${EditorDOMUtils.convertUnitTo(this.offsetY, 'pt', 'px', 0)}px`;
          }
          if (this.relativeX === 'p' || this.relativeX === 'c' || this.relativeX === 'm') {
            if (this.alingX != null) {
              let left = 0;
              switch (this.alingX) {
                case 'r':
                  if (pageWidth != null) {
                    left = pageWidth - this.width;
                  }
                  break;
                case 'c':
                  if (pageWidth != null) {
                    left = pageWidth / 2 - this.width / 2;
                  }
                  break;
                case 'l':
                default:
                  left = 0;
                  break;
              }
              this.style.left = `${EditorDOMUtils.convertUnitTo(left, 'pt', 'px', 0)}px`;
            } else if (this.offsetX != null) {
              this.style.left = `${EditorDOMUtils.convertUnitTo(this.offsetX, 'pt', 'px', 0)}px`;
            }
          }
          if (behindDoc) {
            // behind text
            this._imageWrap = 'behindText';
            this.style.zIndex = `${-1}`;
          } else {
            // in front of text
            this._imageWrap = 'inFrontText';
            this.style.zIndex = `${1}`;
          }
          break;
        }
        case 't':
        case 'ti':
        case 's': {
          // wrap text / float
          if (
            (this.offsetX != null && pageWidth != null && this.offsetX > pageWidth / 2) ||
            this.alingX === 'r'
          ) {
            // float right
            this._imageWrap = 'right';
            this.style.float = 'right';

            if (wrapping.ld) {
              this.style.marginLeft = `${EditorDOMUtils.convertUnitTo(
                wrapping.ld,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (wrapping.bd) {
              this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
                wrapping.bd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (
              this.offsetX &&
              pageWidth != null &&
              (this.relativeX === 'p' || this.relativeX === 'c')
            ) {
              const marginRight = pageWidth - this.width - this.offsetX;
              this.style.marginRight = `${EditorDOMUtils.convertUnitTo(
                marginRight,
                'pt',
                'px',
                0,
              )}px`;
            }
          } else {
            // float left
            this._imageWrap = 'left';
            this.style.float = 'left';

            if (wrapping.rd) {
              this.style.marginRight = `${EditorDOMUtils.convertUnitTo(
                wrapping.rd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (wrapping.bd) {
              this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
                wrapping.bd,
                'pt',
                'px',
                0,
              )}px`;
            }

            if (this.offsetX && (this.relativeX === 'p' || this.relativeX === 'c')) {
              this.style.marginLeft = `${EditorDOMUtils.convertUnitTo(
                this.offsetX,
                'pt',
                'px',
                0,
              )}px`;
            }
          }

          if (wrapping.td && !isAtNodeStart) {
            this.style.marginTop = `${EditorDOMUtils.convertUnitTo(wrapping.td, 'pt', 'px', 0)}px`;
          }
          break;
        }
        case 'tb': {
          // top and bottom
          this._imageWrap = 'topAndBottom';
          this.style.display = 'block';
          if (wrapping.td) {
            this.style.marginTop = `${EditorDOMUtils.convertUnitTo(wrapping.td, 'pt', 'px', 0)}px`;
          }

          if (wrapping.bd) {
            this.style.marginBottom = `${EditorDOMUtils.convertUnitTo(
              wrapping.bd,
              'pt',
              'px',
              0,
            )}px`;
          }

          if (this.alingX != null) {
            switch (this.alingX) {
              case 'r':
                this.style.marginLeft = 'auto';
                break;
              case 'l':
                this.style.marginLeft = '0px';
                break;
              case 'c':
              default:
                this.style.marginLeft = 'auto';
                this.style.marginRight = 'auto';
                break;
            }
          } else {
            this.style.marginLeft = 'auto';
            this.style.marginRight = 'auto';
          }

          break;
        }
      }
    } else {
      this._imageWrap = 'inline';
    }
  }

  resizeElement(width: number = 0, height: number = 0) {
    let w = EditorDOMUtils.convertUnitTo(width, 'px', 'pt', 3);
    let h = EditorDOMUtils.convertUnitTo(height, 'px', 'pt', 3);

    if (this.Visualizer?.editionManager && w != null && h != null) {
      this.Visualizer.editionManager.handleUpdateImageSize(this, h, w);
    }
  }

  get imageNode() {
    return this.image;
  }

  setImageSource(sourceId: string) {
    this.dataset.es = sourceId;
    this.fetchImage();
  }

  setImageDimensions(width: number, height: number) {
    const w = Math.min(MAX_WIDTH, width);
    const h = w === width ? height : (height * MAX_WIDTH) / width;

    this.dataset.w = `${w}`;
    this.dataset.h = `${h}`;

    this.handleImageSize();
  }

  private buildImage() {
    const image = DOMElementFactory.buildElement('img');
    image.setAttribute('parent_id', this.id);
    return image;
  }

  private imageLoaded(blob: Blob) {
    this.triggerImageLoaded();
    if (this.hasAttribute('localfile')) {
      this.removeAttribute('localfile');
      while (this.firstChild) {
        this.firstChild.remove();
      }
      this.image = undefined;
    }

    const url = URL.createObjectURL(blob);
    if (!this.image) {
      this.image = this.buildImage();
      this.image.setAttribute('src', url);
      this.appendChild(this.image);
    } else {
      this.image.removeAttribute('placeholder');
      this.image.setAttribute('src', url);
      this.image.setAttribute('parent_id', this.id);
    }

    this.image.onload = () => {
      EditorManager.getInstance().updatePasteOptions();
    };

    // get base64 data for eventual copy
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result != null) {
        this._imageBase64 = reader.result as string;
      }
    };
    reader.readAsDataURL(blob);
  }

  fetchImage() {
    this.loaded = false;
    const docId = this.Data?.document.getDocumentId();
    if (docId && this.sourceId) {
      new EditorService()
        .getImage(docId, this.sourceId, { responseType: 'blob' })
        .then((response) => {
          this.imageLoaded(response.data);
        })
        .catch((error) => {
          Logger.captureException(error);
        });
    }
  }

  private setError() {
    while (this.firstChild) {
      this.firstChild.remove();
    }

    this.removeAttribute('style');
    this.container = document.createElement('div');
    this.container.setAttribute('contenteditable', 'false');
    this.icon = document.createElement('div');
    this.description = document.createElement('div');

    this.container.classList.add(styles.container);
    this.description.classList.add(styles.description);

    this.container.appendChild(this.icon);
    this.container.appendChild(this.description);
    this.appendChild(document.createTextNode('\u200B'));
    this.appendChild(this.container);
    this.appendChild(document.createTextNode('\u200B'));
    const locale = ReduxInterface.getLocale();
    ReactDOM.unmountComponentAtNode(this.icon);
    ReactDOM.render(<Icon icon="Warning" size={32} />, this.icon);
    ReactDOM.unmountComponentAtNode(this.description);
    ReactDOM.render(
      <IntlProvider locale={locale}>
        <FormattedMessage id="ERROR_PASTING_LOCAL_IMAGE" />
      </IntlProvider>,
      this.description,
    );
  }
}

if (!window.customElements.get(ELEMENTS.ImageElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.ImageElement.IDENTIFIER, ImageElement);
}

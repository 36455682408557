import { ELEMENTS } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import { BaseViewElement } from '../../BaseViewBuilder';
import { toRoman } from 'utils';
import './NoteElement.module.scss';

export class NoteElement extends BaseViewElement {
  constructor() {
    super();

    this.clickListener = this.clickListener.bind(this);
  }

  static get observedAttributes() {
    return ['number', 'type'];
  }

  get type(): Notes.NoteType | null {
    const type = this.getAttribute('type');

    if (type === 'footnote' || type === 'endnote') {
      return type;
    }

    return null;
  }

  getContent() {
    const type = this.getAttribute('type');
    let number = this.getAttribute('number');
    if (number) {
      const numNumber = Number(number) + 1;
      number = numNumber ? numNumber.toString() : '';

      return type === 'footnote' ? number : toRoman(numNumber);
    }
    return '';
  }

  render() {
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }
    const content = this.getContent();
    this.appendChild(document.createTextNode(content));
  }

  clickListener(e: Event) {
    e.stopPropagation();
    const ref = this.getAttribute('element_reference');
    const type = this.getAttribute('type');

    if (ref != null && type != null) {
      EditorManager.getInstance().viewNoteCard(ref, type);
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
    this.addEventListener('click', this.clickListener);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', this.clickListener);
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.isConnected) {
      if (oldValue !== newValue) {
        this.render();
      }
    }
  }
}

if (!window.customElements.get(ELEMENTS.NoteElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.NoteElement.IDENTIFIER, NoteElement);
}

import { DodocParser } from './DodocParser';
import { FilesParser } from './FilesParser';
import { GenericParser } from './GenericParser';
import { OfficeParser } from './OfficeParser';
import { PlainTextParser } from './PlainTextParser';

export class ParserFactory {
  static DODOC: Editor.Clipboard.ParserKeys = 'dodoc/html';
  static HTML: Editor.Clipboard.ParserKeys = 'text/html';
  static PLAIN_TEXT: Editor.Clipboard.ParserKeys = 'text/plain';
  static FILES: Editor.Clipboard.ParserKeys = 'Files';

  static getParser(
    type: Editor.Clipboard.ParserKeys,
    dataTransfer: DataTransfer,
    dataManager: Editor.Data.API,
    stylesHandler: Editor.Styles.Handler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    switch (type) {
      case ParserFactory.DODOC:
        return new DodocParser(
          dataTransfer.getData(type),
          dataManager,
          stylesHandler,
          visualizerManager,
        );
      case ParserFactory.PLAIN_TEXT:
        return new PlainTextParser(
          dataTransfer.getData(type),
          dataManager,
          stylesHandler,
          visualizerManager,
        );
      case ParserFactory.FILES:
        return new FilesParser(
          Array.from(dataTransfer.items),
          dataManager,
          stylesHandler,
          visualizerManager,
        );
      case ParserFactory.HTML:
        return ParserFactory.getHtmlParser(
          dataTransfer.getData(type),
          dataManager,
          stylesHandler,
          visualizerManager,
        );
      default:
        throw new Error(`Invalid parser for type: ${type}`);
    }
  }

  static getHtmlParser(
    data: string,
    dataManager: Editor.Data.API,
    stylesHandler: Editor.Styles.Handler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    const regex =
      /(?:.*xmlns:([a-z])="urn:schemas-microsoft-com:office:([a-z]*)".*|xmlns:m="http:\/\/schemas\.microsoft\.com\/office\/2004\/12\/omml")+/gm;
    const matches = data.match(regex);
    if (
      matches &&
      matches.length > 0 &&
      (matches[0].includes('xmlns:o') || matches[0].includes('xmlns:m'))
    ) {
      return new OfficeParser(data, dataManager, stylesHandler, visualizerManager);
    }
    return new GenericParser(data, dataManager, stylesHandler, visualizerManager);
  }

  static getAvailableParsers() {
    return [ParserFactory.DODOC, ParserFactory.HTML, ParserFactory.PLAIN_TEXT, ParserFactory.FILES];
  }
}

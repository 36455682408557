 
import { Logger } from '_common/services';

import BaseController from '../BaseController';

export class ApprovalsController extends BaseController {
  private _approvalsEvents: {
    [index: string]: any;
  };

  private _selectionForApproval: string[] = [];

  constructor(Data: Editor.Data.State) {
    super(Data);

    this._approvalsEvents = {
      'NODES:PERMISSIONS:UPDATE': this.handleNodePermissionsUpdateEvent.bind(this),
      'FORCE:REMOTE:RELOAD': this.handleForceRemoteReloadEvent.bind(this),
    };
  }

  private handleNodePermissionsUpdateEvent(payload: string[]) {
    if (this._selectionForApproval.length) {
      if (payload.some((v: string) => this._selectionForApproval.includes(v))) {
        this.selectBlocksForApproval(this._selectionForApproval);
      }
    }
  }

  private handleForceRemoteReloadEvent() {
    if (this._selectionForApproval.length) {
      this.selectBlocksForApproval(this._selectionForApproval);
    }
  }

  start(): void {
    const eventKeys = Object.keys(this._approvalsEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      this.Data.transport.handleEvent(event, this._approvalsEvents[event]);
    }
  }

  stop(): void {}

  destroy(): void {
    const eventKeys = Object.keys(this._approvalsEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      this.Data.transport.removeEvent(event, this._approvalsEvents[event]);
    }
  }

  allSelectedForApproval(): boolean {
    return this.Data.structure?.getDocumentNodes().length === this._selectionForApproval.length;
  }

  isSelectedForApproval(blockId: string): boolean {
    return this._selectionForApproval.includes(blockId);
  }

  async selectBlocksForApproval(blocks: string[]): Promise<string[] | void> {
    if (blocks.length === 0) {
      return this.deselectAllBlocksForApproval();
    }
    this._selectionForApproval = blocks;
    try {
      await this.documentApprovalSummary(this._selectionForApproval);

      return this._selectionForApproval;
    } catch (error: any) {
      // TODO : LOADING STATE
      Logger.captureException(error);
      return this.deselectAllBlocksForApproval();
    }
  }

  documentApprovalSummary(
    blocks: string[] | undefined = this._selectionForApproval,
  ): Promise<Approval.AprovalPermissionSummary | undefined> {
    return new Promise((resolve, reject) => {
      if (blocks.length <= 0) {
        this.Data.events?.emit('LOAD_APPROVAL_SUMMARY', {
          selected: this._selectionForApproval,
          allSelected: this.allSelectedForApproval(),
          summary: {
            noPermissions: {},
            hasSuggestions: {},
            isApproved: {},
            isOpen: {},
          },
        });
        resolve({
          noPermissions: {},
          hasSuggestions: {},
          isApproved: {},
          isOpen: {},
        });
      } else {
        this.Data.transport.dispatchEvent(
          'DOCUMENT:APPROVAL:SUMMARY',
          {
            blocks,
          },
          (response) => {
            if (response.success) {
              this.Data.events?.emit('LOAD_APPROVAL_SUMMARY', {
                selected: this._selectionForApproval,
                allSelected: this.allSelectedForApproval(),
                summary: response.payload || {
                  noPermissions: {},
                  hasSuggestions: {},
                  isApproved: {},
                  isOpen: {},
                },
              });
              resolve(response.payload);
            } else {
              reject(response.error);
            }
          },
        );
      }
    });
  }

  async selectAllBlocksForApproval(): Promise<string[] | void> {
    if (this.Data.structure) {
      const blocks = await this.selectBlocksForApproval(this.Data.structure.getDocumentNodes());

      this.Data.events?.emit('SELECT_ALL_FOR_APPROVAL', blocks);

      return blocks;
    }
  }

  deselectAllBlocksForApproval(): void {
    this._selectionForApproval = [];
    this.Data.events?.emit('DESELECT_ALL_FOR_APPROVAL');
    this.Data.events?.emit('LOAD_APPROVAL_SUMMARY', {
      selected: this._selectionForApproval,
      allSelected: this.allSelectedForApproval(),
      summary: {
        noPermissions: {},
        hasSuggestions: {},
        isApproved: {},
        isOpen: {},
      },
    });
  }

  approveNodes(nodeIds: string[]): Promise<Realtime.Transport.RealtimeResponse<any>> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:APPROVE',
        {
          nodeIds,
        },
        (response) => {
          if (response.success) {
            resolve({
              success: true,
            });
            this.selectBlocksForApproval(nodeIds);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  reopenNodes(
    nodeIds: string[],
  ): Promise<Realtime.Transport.RealtimeResponse<{ needsReopening: any[] }>> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:REOPEN',
        {
          nodeIds,
        },
        (response) => {
          if (response.success) {
            resolve({
              success: true,
            });
            this.selectBlocksForApproval(nodeIds);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}

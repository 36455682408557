// source : http://www.alanwood.net/demos/wingdings-3.html
const values = {
  wingdings: {
    F020: '0020',
    F021: '1F589',
    F022: '2702',
    F023: '2701',
    F024: '1F453',
    F025: '1F56D',
    F026: '1F56E',
    F027: '1F56F',
    F028: '1F57F',
    F029: '2706',
    F02A: '1F582',
    F02B: '1F583',
    F02C: '1F4EA',
    F02D: '1F4EB',
    F02E: '1F4EC',
    F02F: '1F4ED',
    F030: '1F4C1',
    F031: '1F4C2',
    F032: '1F4C4',
    F033: '1F5CF',
    F034: '1F5D0',
    F035: '1F5C4',
    F036: '231B',
    F037: '1F5AE',
    F038: '1F5B0',
    F039: '1F5B2',
    F03A: '1F5B3',
    F03B: '1F5B4',
    F03C: '1F5AB',
    F03D: '1F5AC',
    F03E: '2707',
    F03F: '270D',
    F040: '1F58E',
    F041: '270C',
    F042: '1F44C',
    F043: '1F44D',
    F044: '1F44E',
    F045: '261C',
    F046: '261E',
    F047: '261D',
    F048: '261F',
    F049: '1F590',
    F04A: '263A',
    F04B: '1F610',
    F04C: '2639',
    F04D: '1F4A3',
    F04E: '2620',
    F04F: '1F3F3',
    F050: '1F3F1',
    F051: '2708',
    F052: '263C',
    F053: '1F4A7',
    F054: '2744',
    F055: '1F546',
    F056: '271E',
    F057: '1F548',
    F058: '2720',
    F059: '2721',
    F05A: '262A',
    F05B: '262F',
    F05C: '0950',
    F05D: '2638',
    F05E: '2648',
    F05F: '2649',
    F060: '264A',
    F061: '264B',
    F062: '264C',
    F063: '264D',
    F064: '264E',
    F065: '264F',
    F066: '2650',
    F067: '2651',
    F068: '2652',
    F069: '2653',
    F06A: '1F670',
    F06B: '1F675',
    F06C: '25CF',
    F06D: '1F53E',
    F06E: '25A0',
    F06F: '25A1',
    F070: '1F790',
    F071: '2751',
    F072: '2752',
    F073: '2927',
    F074: '29EB',
    F075: '25C6',
    F076: '2756',
    F077: '2B25',
    F078: '2327',
    F079: '2BB9',
    F07A: '2318',
    F07B: '1F3F5',
    F07C: '1F3F6',
    F07D: '1F676',
    F07E: '1F677',
    F080: '24EA',
    F081: '2460',
    F082: '2461',
    F083: '2462',
    F084: '2463',
    F085: '2464',
    F086: '2465',
    F087: '2466',
    F088: '2467',
    F089: '2468',
    F08A: '2469',
    F08B: '24FF',
    F08C: '2776',
    F08D: '2777',
    F08E: '2778',
    F08F: '2779',
    F090: '277A',
    F091: '277B',
    F092: '277C',
    F093: '277D',
    F094: '277E',
    F095: '277F',
    F096: '1F662',
    F097: '1F660',
    F098: '1F661',
    F099: '1F663',
    F09A: '1F65E',
    F09B: '1F65C',
    F09C: '1F65D',
    F09D: '1F65F',
    F09E: '00B7',
    F09F: '2022',
    F0A0: '25AA',
    F0A1: '26AA',
    F0A2: '1F786',
    F0A3: '1F788',
    F0A4: '25C9',
    F0A5: '25CE',
    F0A6: '1F53F',
    F0A7: '25AA',
    F0A8: '25FB',
    F0A9: '1F7C2',
    F0AA: '2726',
    F0AB: '2605',
    F0AC: '2736',
    F0AD: '2734',
    F0AE: '2739',
    F0AF: '2735',
    F0B0: '2BD0',
    F0B1: '2316',
    F0B2: '27E1',
    F0B3: '2311',
    F0B4: '2BD1',
    F0B5: '272A',
    F0B6: '2730',
    F0B7: '1F550',
    F0B8: '1F551',
    F0B9: '1F552',
    F0BA: '1F553',
    F0BB: '1F554',
    F0BC: '1F555',
    F0BD: '1F556',
    F0BE: '1F557',
    F0BF: '1F558',
    F0C0: '1F559',
    F0C1: '1F55A',
    F0C2: '1F55B',
    F0C3: '2BB0',
    F0C4: '2BB1',
    F0C5: '2BB2',
    F0C6: '2BB3',
    F0C7: '2BB4',
    F0C8: '2BB5',
    F0C9: '2BB6',
    F0CA: '2BB7',
    F0CB: '1F66A',
    F0CC: '1F66B',
    F0CD: '1F655',
    F0CE: '1F654',
    F0CF: '1F657',
    F0D0: '1F656',
    F0D1: '1F650',
    F0D2: '1F651',
    F0D3: '1F652',
    F0D4: '1F653',
    F0D5: '232B',
    F0D6: '2326',
    F0D7: '2B98',
    F0D8: '2B9A',
    F0D9: '2B99',
    F0DA: '2B9B',
    F0DB: '2B88',
    F0DC: '2B8A',
    F0DD: '2B89',
    F0DE: '2B8B',
    F0DF: '1F868',
    F0E0: '1F86A',
    F0E1: '1F869',
    F0E2: '1F86B',
    F0E3: '1F86C',
    F0E4: '1F86D',
    F0E5: '1F86F',
    F0E6: '1F86E',
    F0E7: '1F878',
    F0E8: '1F87A',
    F0E9: '1F879',
    F0EA: '1F87B',
    F0EB: '1F87C',
    F0EC: '1F87D',
    F0ED: '1F87F',
    F0EE: '1F87E',
    F0EF: '21E6',
    F0F0: '21E8',
    F0F1: '2B04',
    F0F2: '21F3',
    F0F3: '2B00',
    F0F4: '2B01',
    F0F5: '2B03',
    F0F6: '2B02',
    F0F7: '2BCD',
    F0F8: '203B',
    F0F9: '1F8AC',
    F0FA: '1F8AD',
    F0FB: '1F5F6',
    F0FC: '2714',
    F0FD: '1F5F7',
    F0FE: '1F5F9',
  },
  'wingdings 2': {
    F021: '1F58A',
    F022: '1F58B',
    F023: '1F58C',
    F024: '1F58D',
    F025: '2704',
    F026: '2700',
    F027: '1F57E',
    F028: '1F57D',
    F029: '1F5C5',
    F02A: '1F5C6',
    F02B: '1F5C7',
    F02C: '1F5C8',
    F02D: '1F5C9',
    F02E: '1F5CA',
    F02F: '1F5CB',
    F030: '1F5CC',
    F031: '1F5CD',
    F032: '1F4CB',
    F033: '1F5D1',
    F034: '1F5D4',
    F035: '1F5B5',
    F036: '1F5B6',
    F037: '1F5B7',
    F038: '1F5B8',
    F039: '1F5AD',
    F03A: '1F5AF',
    F03B: '1F5B1',
    F03C: '1F592',
    F03D: '1F593',
    F03E: '1F598',
    F03F: '1F599',
    F040: '1F59A',
    F041: '1F59B',
    F042: '1F448',
    F043: '1F449',
    F044: '1F59C',
    F045: '1F59D',
    F046: '1F59E',
    F047: '1F59F',
    F048: '1F5A0',
    F049: '1F5A1',
    F04A: '1F446',
    F04B: '1F447',
    F04C: '1F5A2',
    F04D: '1F5A3',
    F04E: '1F591',
    F04F: '1F5F4',
    F050: '2713',
    F051: '1F5F5',
    F052: '2611',
    F053: '2612',
    F054: '2612',
    F055: '2BBE',
    F056: '2BBF',
    F057: '29B8',
    F058: '29B8',
    F059: '1F671',
    F05A: '1F674',
    F05B: '1F672',
    F05C: '1F673',
    F05D: '203D',
    F05E: '1F679',
    F05F: '1F67A',
    F060: '1F67B',
    F061: '1F666',
    F062: '1F664',
    F063: '1F665',
    F064: '1F667',
    F065: '1F65A',
    F066: '1F658',
    F067: '1F659',
    F068: '1F65B',
    F069: '24EA',
    F06A: '2460',
    F06B: '2461',
    F06C: '2462',
    F06D: '2463',
    F06E: '2464',
    F06F: '2465',
    F070: '2466',
    F071: '2467',
    F072: '2468',
    F073: '2469',
    F074: '24FF',
    F075: '2776',
    F076: '2777',
    F077: '2778',
    F078: '2779',
    F079: '277A',
    F07A: '277B',
    F07B: '277C',
    F07C: '277D',
    F07D: '277E',
    F07E: '277F',
    F080: '2609',
    F081: '1F315',
    F082: '263D',
    F083: '263E',
    F084: '2E3F',
    F085: '271D',
    F086: '1F547',
    F087: '1F55C',
    F088: '1F55D',
    F089: '1F55E',
    F08A: '1F55F',
    F08B: '1F560',
    F08C: '1F561',
    F08D: '1F562',
    F08E: '1F563',
    F08F: '1F564',
    F090: '1F565',
    F091: '1F566',
    F092: '1F567',
    F093: '1F568',
    F094: '1F569',
    F095: '2022',
    F096: '25CF',
    F097: '26AB',
    F098: '2B24',
    F099: '1F785',
    F09A: '1F786',
    F09B: '1F787',
    F09C: '1F788',
    F09D: '1F78A',
    F09E: '29BF',
    F09F: '25FE',
    F0A0: '25A0',
    F0A1: '25FC',
    F0A2: '2B1B',
    F0A3: '2B1C',
    F0A4: '1F791',
    F0A5: '1F792',
    F0A6: '1F793',
    F0A7: '1F794',
    F0A8: '25A3',
    F0A9: '1F795',
    F0AA: '1F796',
    F0AB: '1F797',
    F0AC: '2B29',
    F0AD: '2B25',
    F0AE: '25C6',
    F0AF: '25C7',
    F0B0: '1F79A',
    F0B1: '25C8',
    F0B2: '1F79B',
    F0B3: '1F79C',
    F0B4: '1F79D',
    F0B5: '2B2A',
    F0B6: '2B27',
    F0B7: '29EB',
    F0B8: '25CA',
    F0B9: '1F7A0',
    F0BA: '25D6',
    F0BB: '25D7',
    F0BC: '2BCA',
    F0BD: '2BCB',
    F0BE: '25FC',
    F0BF: '2B25',
    F0C0: '2B1F',
    F0C1: '2BC2',
    F0C2: '2B23',
    F0C3: '2B22',
    F0C4: '2BC3',
    F0C5: '2BC4',
    F0C6: '1F7A1',
    F0C7: '1F7A2',
    F0C8: '1F7A3',
    F0C9: '1F7A4',
    F0CA: '1F7A5',
    F0CB: '1F7A6',
    F0CC: '1F7A7',
    F0CD: '1F7A8',
    F0CE: '1F7A8',
    F0CF: '1F7AA',
    F0D0: '1F7AB',
    F0D1: '1F7AC',
    F0D2: '1F7AD',
    F0D3: '1F7AE',
    F0D4: '1F7AF',
    F0D5: '1F7B0',
    F0D6: '1F7B1',
    F0D7: '1F7B2',
    F0D8: '1F7B3',
    F0D9: '1F7B4',
    F0DA: '1F7B5',
    F0DB: '1F7B6',
    F0DC: '1F7B7',
    F0DD: '1F7B8',
    F0DE: '1F7B9',
    F0DF: '1F7BA',
    F0E0: '1F7BB',
    F0E1: '1F7BC',
    F0E2: '1F7BD',
    F0E3: '1F7BE',
    F0E4: '1F7BF',
    F0E5: '1F7C0',
    F0E6: '1F7C2',
    F0E7: '1F7C4',
    F0E8: '2726',
    F0E9: '1F7C9',
    F0EA: '2605',
    F0EB: '2736',
    F0EC: '1F7CB',
    F0ED: '2737',
    F0EE: '1F7CF',
    F0EF: '1F7D2',
    F0F0: '2739',
    F0F1: '1F7C3',
    F0F2: '1F7C7',
    F0F3: '272F',
    F0F4: '1F7CD',
    F0F5: '1F7D4',
    F0F6: '2BCC',
    F0F7: '2BCD',
    F0F8: '203B',
    F0F9: '2042',
  },

  // source : http://www.alanwood.net/demos/wingdings-3.html
  'wingdings 3': {
    F020: '0020',
    F021: '2B60',
    F022: '2B62',
    F023: '2B61',
    F024: '2B63',
    F025: '2B66',
    F026: '2B67',
    F027: '2B69',
    F028: '2B68',
    F029: '2B70',
    F02A: '2B72',
    F02B: '2B71',
    F02C: '2B73',
    F02D: '2B76',
    F02E: '2B78',
    F02F: '2B7B',
    F030: '2B7D',
    F031: '2B64',
    F032: '2B65',
    F033: '2B6A',
    F034: '2B6C',
    F035: '2B6B',
    F036: '2B6D',
    F037: '2B4D',
    F038: '2BA0',
    F039: '2BA1',
    F03A: '2BA2',
    F03B: '2BA3',
    F03C: '2BA4',
    F03D: '2BA5',
    F03E: '2BA6',
    F03F: '2BA7',
    F040: '2B90',
    F041: '2B91',
    F042: '2B92',
    F043: '2B93',
    F044: '2B80',
    F045: '2B83',
    F046: '2B7E',
    F047: '2B7F',
    F048: '2B84',
    F049: '2B86',
    F04A: '2B85',
    F04B: '2B87',
    F04C: '2B8F',
    F04D: '2B8D',
    F04E: '2B8E',
    F04F: '2B8C',
    F050: '2B8e',
    F051: '2B6F',
    F052: '238B',
    F053: '2324',
    F054: '2303',
    F055: '2325',
    F056: '23B5',
    F057: '237D',
    F058: '21EA',
    F059: '2BB8',
    F05A: '1F8A0',
    F05B: '1F8A1',
    F05C: '1F8A2',
    F05D: '1F8A3',
    F05E: '1F8A4',
    F05F: '1F8A5',
    F060: '1F8A6',
    F061: '1F8A7',
    F062: '1F8A8',
    F063: '1F8A9',
    F064: '1F8AA',
    F065: '1F8AB',
    F066: '2190',
    F067: '2192',
    F068: '2191',
    F069: '2193',
    F06A: '2196',
    F06B: '2197',
    F06C: '2199',
    F06D: '2198',
    F06E: '1F858',
    F06F: '1F859',
    F070: '25B2',
    F071: '25BC',
    F072: '25B3',
    F073: '25BD',
    F074: '25C4',
    F075: '25BA',
    F076: '25C1',
    F077: '25B7',
    F078: '25E3',
    F079: '25E2',
    F07A: '25E4',
    F07B: '25E5',
    F07C: '1F780',
    F07D: '1F782',
    F07E: '1F781',
    F080: '1F783',
    F081: '25B2',
    F082: '25BC',
    F083: '25C0',
    F084: '25B6',
    F085: '2B9C',
    F086: '2B9E',
    F087: '2B9D',
    F088: '2B9F',
    F089: '1F810',
    F08A: '1F812',
    F08B: '1F811',
    F08C: '1F813',
    F08D: '1F814',
    F08E: '1F816',
    F08F: '1F815',
    F090: '1F817',
    F091: '1F818',
    F092: '1F81A',
    F093: '1F819',
    F094: '1F81B',
    F095: '1F81C',
    F096: '1F81E',
    F097: '1F81D',
    F098: '1F81F',
    F099: '1F800',
    F09A: '1F802',
    F09B: '1F801',
    F09C: '1F803',
    F09D: '1F804',
    F09E: '1F806',
    F09F: '1F805',
    F0A0: '1F807',
    F0A1: '1F808',
    F0A2: '1F80A',
    F0A3: '1F809',
    F0A4: '1F80B',
    F0A5: '1F820',
    F0A6: '1F822',
    F0A7: '1F824',
    F0A8: '1F826',
    F0A9: '1F828',
    F0AA: '1F82A',
    F0AB: '1F82C',
    F0AC: '1F89C',
    F0AD: '1F89D',
    F0AE: '1F89E',
    F0AF: '1F89F',
    F0B0: '1F82E',
    F0B1: '1F830',
    F0B2: '1F832',
    F0B3: '1F834',
    F0B4: '1F836',
    F0B5: '1F838',
    F0B6: '1F83A',
    F0B7: '1F839',
    F0B8: '1F83B',
    F0B9: '1F898',
    F0BA: '1F89A',
    F0BB: '1F899',
    F0BC: '1F89B',
    F0BD: '1F83C',
    F0BE: '1F83E',
    F0BF: '1F83D',
    F0C0: '1F83F',
    F0C1: '1F840',
    F0C2: '1F842',
    F0C3: '1F841',
    F0C4: '1F843',
    F0C5: '1F844',
    F0C6: '1F846',
    F0C7: '1F845',
    F0C8: '1F847',
    F0C9: '2BA8',
    F0CA: '2BA9',
    F0CB: '2BAA',
    F0CC: '2BAB',
    F0CD: '2BAC',
    F0CE: '2BAD',
    F0CF: '2BAE',
    F0D0: '2BAF',
    F0D1: '1F860',
    F0D2: '1F862',
    F0D3: '1F861',
    F0D4: '1F863',
    F0D5: '1F864',
    F0D6: '1F865',
    F0D7: '1F867',
    F0D8: '1F866',
    F0D9: '1F870',
    F0DA: '1F872',
    F0DB: '1F871',
    F0DC: '1F873',
    F0DD: '1F874',
    F0DE: '1F875',
    F0DF: '1F877',
    F0E0: '1F876',
    F0E1: '1F880',
    F0E2: '1F882',
    F0E3: '1F881',
    F0E4: '1F883',
    F0E5: '1F884',
    F0E6: '1F885',
    F0E7: '1F887',
    F0E8: '1F886',
    F0E9: '1F890',
    F0EA: '1F892',
    F0EB: '1F891',
    F0EC: '1F893',
    F0ED: '1F894',
    F0EE: '1F896',
    F0EF: '1F895',
    F0F0: '1F897',
  },

   
  // https://stackoverflow.com/questions/3346962/mapping-between-wingdings-symbol-characters-and-their-unicode-equivalents
  symbol: {
    F021: '0021',
    F022: '2200',
    F023: '0023',
    F024: '2203',
    F025: '0025',
    F026: '0026',
    F027: '220D',
    F028: '0028',
    F029: '0029',
    F02A: '2217',
    F02B: '002B',
    F02C: '002C',
    F02D: '2212',
    F02E: '002E',
    F02F: '002F',
    F030: '0030',
    F031: '0031',
    F032: '0032',
    F033: '0033',
    F034: '0034',
    F035: '0035',
    F036: '0036',
    F037: '0037',
    F038: '0038',
    F039: '0039',
    F03A: '003A',
    F03B: '003B',
    F03C: '003C',
    F03D: '003D',
    F03E: '003E',
    F03F: '003F',
    F040: '2245',
    F041: '0391',
    F042: '0392',
    F043: '03A7',
    F044: '0394',
    F045: '0395',
    F046: '03A6',
    F047: '0393',
    F048: '0397',
    F049: '0399',
    F04A: '03D1',
    F04B: '039A',
    F04C: '039B',
    F04D: '039C',
    F04E: '039D',
    F04F: '039F',
    F050: '03A0',
    F051: '0398',
    F052: '03A1',
    F053: '03A3',
    F054: '03A4',
    F055: '03A5',
    F056: '03C2',
    F057: '03A9',
    F058: '039E',
    F059: '03A8',
    F05A: '0396',
    F05B: '005B',
    F05C: '2234',
    F05D: '005D',
    F05E: '22A5',
    F05F: '005F',
    F060: '203E',
    F061: '03B1',
    F062: '03B2',
    F063: '03C7',
    F064: '03B4',
    F065: '03B5',
    F066: '03C6',
    F067: '03B3',
    F068: '03B7',
    F069: '03B9',
    F06A: '03D5',
    F06B: '03BA',
    F06C: '03BB',
    F06D: '03BC',
    F06E: '03BD',
    F06F: '03BF',
    F070: '03C0',
    F071: '03B8',
    F072: '03C1',
    F073: '03C3',
    F074: '03C4',
    F075: '03C5',
    F076: '03D6',
    F077: '03C9',
    F078: '03BE',
    F079: '03C8',
    F07A: '03B6',
    F07B: '007B',
    F07C: '007C',
    F07D: '007D',
    F07E: '223C',
    F0A0: '25AA',
    F0A1: '03D2',
    F0A2: '2032',
    F0A3: '2264',
    F0A4: '2044',
    F0A5: '221E',
    F0A6: '0192',
    F0A7: '2663',
    F0A8: '2666',
    F0A9: '2665',
    F0AA: '2660',
    F0AB: '2194',
    F0AC: '2190',
    F0AD: '2191',
    F0AE: '2192',
    F0AF: '2193',
    F0B0: '00B0',
    F0B1: '00B1',
    F0B2: '2033',
    F0B3: '2265',
    F0B4: '00D7',
    F0B5: '221D',
    F0B6: '2202',
    F0B7: '2022',
    F0B8: '00F7',
    F0B9: '2260',
    F0BA: '2261',
    F0BB: '2248',
    F0BC: '2026',
    F0BD: '23D0',
    F0BE: '23AF',
    F0BF: '21B5',
    F0C0: '2135',
    F0C1: '2111',
    F0C2: '211C',
    F0C3: '2118',
    F0C4: '2297',
    F0C5: '2295',
    F0C6: '2205',
    F0C7: '2229',
    F0C8: '222A',
    F0C9: '2283',
    F0CA: '2287',
    F0CB: '2284',
    F0CC: '2282',
    F0CD: '2286',
    F0CE: '2208',
    F0CF: '2209',
    F0D0: '2220',
    F0D1: '2207',
    F0D2: '00AE',
    F0D3: '00A9',
    F0D4: '2122',
    F0D5: '220F',
    F0D6: '221A',
    F0D7: '22C5',
    F0D8: '00AC',
    F0D9: '2227',
    F0DA: '2228',
    F0DB: '21D4',
    F0DC: '21D0',
    F0DD: '21D1',
    F0DE: '21D2',
    F0DF: '21D3',
    F0E0: '25CA',
    F0E1: '2329',
    F0E2: '00AE',
    F0E3: '00A9',
    F0E4: '2122',
    F0E5: '2211',
    F0E6: '239B',
    F0E7: '239C',
    F0E8: '239D',
    F0E9: '23A1',
    F0EA: '23A2',
    F0EB: '23A3',
    F0EC: '23A7',
    F0ED: '23A8',
    F0EE: '23A9',
    F0EF: '23AA',
    F0F0: '20AC',
    F0F1: '232A',
    F0F2: '222B',
    F0F3: '2320',
    F0F4: '23AE',
    F0F5: '2321',
    F0F6: '239E',
    F0F7: '239F',
    F0F8: '23A0',
    F0F9: '23A4',
    F0FA: '23A5',
    F0FB: '23A6',
    F0FC: '23AB',
    F0FD: '23AC',
    F0FE: '23AD',
  },
};

export default function SymboldsCodes(font, value) {
  return values[font] && values[font][value];
}

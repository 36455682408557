import { BlockViewModel } from 'Editor/services/VisualizerManager/ViewModels';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { DISPLAY_TYPES } from 'Editor/services/consts';

export class BaseViewElement extends HTMLElement {
  viewModel?: BlockViewModel;

  Visualizer?: Editor.Visualizer.State;
  Data?: Editor.Data.API;

  protected preRendered = false;

  constructor(Visualizer?: Editor.Visualizer.State, Data?: Editor.Data.API) {
    super();

    this.Visualizer = Visualizer;
    this.Data = Data;
  }

  get tag() {
    return this.tagName as Editor.Elements.ElementTagsType;
  }

  hasContent() {
    return !EditorDOMUtils.isEmptyElement(this);
  }

  connectedCallback() {
    if (!this.preRendered) {
      this.preRender();
    }
  }

  disconnectedCallback() {}

  preRender() {
    this.preRendered = true;
  }

  get displayType(): Editor.Elements.DisplayType | null {
    // return INLINE / BLOCK
    return null;
  }

  get isEditable(): boolean | null {
    return null;
  }

  get isDeletable(): boolean | null {
    return null;
  }

  get isSplitable(): boolean | null {
    return null;
  }

  get isContentWrapper(): boolean | null {
    return null;
  }

  get isSelectable(): boolean | null {
    return null;
  }
  // selection type?
  // normal, non-selectable, whole block ?

  get isInline() {
    return this.displayType === DISPLAY_TYPES.INLINE;
  }

  get isBlock() {
    return this.displayType === DISPLAY_TYPES.BLOCK;
  }

  get isContainerElement() {
    return false;
  }

  isParagraphMarker() {
    return false;
  }

  set vm(model: BlockViewModel | undefined) {
    if (model != null) {
      this.viewModel = model;
    }
  }

  get vm() {
    return this.viewModel;
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {}

  resizeElement(deltaX: number, deltaY: number) {}
}

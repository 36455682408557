import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { NodeUtils } from 'Editor/services/DataManager';
import { PlaceholderElement } from './PlaceholderElement/PlaceholderElement';

export class PlaceholderViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.PlaceholderElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // type
    t: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.type) {
          builder.addProperty('t', node.dataset.type);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties && json.properties.t) {
          node.dataset.type = json.properties.t;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.type;
      },
    },
    // xml ref
    xml: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.xml) {
          builder.addProperty('xml', node.dataset.xml);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties && json.properties.xml) {
          node.dataset.xml = json.properties.xml;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.xml;
      },
    },
    // editor source

    es: {
      parse: (node, builder) => {
        if (node.dataset.es != null) {
          builder.addProperty('es', node.dataset.es);
        }
      },
      render: (json, node) => {
        if (json.properties?.es != null) {
          node.dataset.es = json.properties.es;
        }
      },
      remove: (node) => {
        delete node.dataset.es;
      },
    },
    // width
    w: {
      parse: (node, builder) => {
        if (node.dataset.w != null) {
          builder.addProperty('w', node.dataset.w);
        }
      },
      render: (json, node) => {
        if (json.properties?.w != null) {
          node.dataset.w = `${json.properties.w}`;
        }
      },
      remove: (node) => {
        delete node.dataset.w;
      },
    },
    // height
    h: {
      parse: (node, builder) => {
        if (node.dataset.h != null) {
          builder.addProperty('h', node.dataset.h);
        }
      },
      render: (json, node) => {
        if (json.properties?.h != null) {
          node.dataset.h = `${json.properties.h}`;
        }
      },
      remove: (node) => {
        delete node.dataset.h;
      },
    },
    // allow overlap
    ao: {
      parse: (node, builder) => {
        if (node.dataset.ao != null) {
          builder.addProperty('ao', node.dataset.ao);
        }
      },
      render: (json, node) => {
        if (json.properties?.ao != null) {
          node.dataset.ao = `${json.properties.ao}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ao;
      },
    },
    // behind_doc
    bd: {
      parse: (node, builder) => {
        if (node.dataset.bd != null) {
          builder.addProperty('bd', node.dataset.bd);
        }
      },
      render: (json, node) => {
        if (json.properties?.bd != null) {
          node.dataset.bd = `${json.properties.bd}`;
        }
      },
      remove: (node) => {
        delete node.dataset.bd;
      },
    },
    // floating
    f: {
      parse: (node, builder) => {
        if (node.dataset.f != null) {
          builder.addProperty('f', node.dataset.f);
        }
      },
      render: (json, node) => {
        if (json.properties?.f != null) {
          node.dataset.f = `${json.properties.f}`;
        }
      },
      remove: (node) => {
        delete node.dataset.f;
      },
    },
    // locked
    l: {
      parse: (node, builder) => {
        if (node.dataset.l != null) {
          builder.addProperty('l', node.dataset.l);
        }
      },
      render: (json, node) => {
        if (json.properties?.l != null) {
          node.dataset.l = `${json.properties.l}`;
        }
      },
      remove: (node) => {
        delete node.dataset.l;
      },
    },
    // layout in cell
    lc: {
      parse: (node, builder) => {
        if (node.dataset.lc != null) {
          builder.addProperty('lc', node.dataset.lc);
        }
      },
      render: (json, node) => {
        if (json.properties?.lc != null) {
          node.dataset.lc = `${json.properties.lc}`;
        }
      },
      remove: (node) => {
        delete node.dataset.lc;
      },
    },
    // X offset,
    ox: {
      parse: (node, builder) => {
        if (node.dataset.ox != null) {
          builder.addProperty('ox', node.dataset.ox);
        }
      },
      render: (json, node) => {
        if (json.properties?.ox != null) {
          node.dataset.ox = `${json.properties.ox}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ox;
      },
    },
    // Y offset,
    oy: {
      parse: (node, builder) => {
        if (node.dataset.oy != null) {
          builder.addProperty('oy', node.dataset.oy);
        }
      },
      render: (json, node) => {
        if (json.properties?.oy != null) {
          node.dataset.oy = `${json.properties.oy}`;
        }
      },
      remove: (node) => {
        delete node.dataset.oy;
      },
    },
    // X relative from
    rfx: {
      parse: (node, builder) => {
        if (node.dataset.rfx != null) {
          builder.addProperty('rfx', node.dataset.rfx);
        }
      },
      render: (json, node) => {
        if (json.properties?.rfx != null) {
          node.dataset.rfx = `${json.properties.rfx}`;
        }
      },
      remove: (node) => {
        delete node.dataset.rfx;
      },
    },
    // Y relative from
    rfy: {
      parse: (node, builder) => {
        if (node.dataset.rfy != null) {
          builder.addProperty('rfy', node.dataset.rfy);
        }
      },
      render: (json, node) => {
        if (json.properties?.rfy != null) {
          node.dataset.rfy = `${json.properties.rfy}`;
        }
      },
      remove: (node) => {
        delete node.dataset.rfy;
      },
    },
    // wrapping
    wr: {
      parse: (node, builder) => {
        if (node.dataset.wr != null) {
          builder.addProperty('wr', JSON.parse(node.dataset.wr));
        }
      },
      render: (json, node) => {
        if (json.properties?.wr != null) {
          node.dataset.wr = JSON.stringify(json.properties.wr);
        }
      },
      remove: (node) => {
        delete node.dataset.wr;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return false;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const node = new PlaceholderElement(this.Visualizer, this.Data);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    let pageWidth;
    let isAtPageStart: boolean = false;
    if (model?.id) {
      pageWidth = this.Data?.sections.getPageWidthForBlockId(model.id);
      const data = model.selectedData();
      if (json.id && data) {
        isAtPageStart = NodeUtils.isPathAtContentStart(data, model.findPathToChild(json.id));
      }
    }

    node.preRender(pageWidth, isAtPageStart);

    return node;
  }
}

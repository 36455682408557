import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import { BaseBlockElement } from '../../BaseViewBuilder';

import './ReferencesSectionElement.module.scss';

export class ReferencesSectionElement extends BaseBlockElement {
  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return !this.isLocked;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return !this.isLocked;
  }

  get isSelectable() {
    return true;
  }

  get isContainerElement() {
    return true;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get selected() {
    return this.getAttribute('selected') === 'true';
  }

  set selected(value) {
    if (value === true) {
      this.setAttribute('selected', 'true');
    } else {
      this.removeAttribute('selected');
    }
  }

  select() {
    this.setAttribute('selected', 'true');
  }

  deselect() {
    this.removeAttribute('selected');
  }
}

if (!window.customElements.get(ELEMENTS.ReferencesSectionElement.IDENTIFIER)) {
  window.customElements.define(
    ELEMENTS.ReferencesSectionElement.IDENTIFIER,
    ReferencesSectionElement,
  );
}

import EditorManager from 'Editor/services/EditorManager';
import { PDFManager } from 'PDF/services';
import { PresentationManager } from 'Presentation/services/PresentationManager';
import { useModalsOpen } from 'App/ModalContext/ModalContext';

import { SaveVersionModal } from '_common/suite/modals';
import TaskWatchersModal from '_common/suite/modals/TaskWatchersModal';

/**
 * Modal provider for suite applications
 * All modals that are shared between two or more suite applications should be placed here
 * For example, the SaveVersionModal is used in Editor, PDF and Presentation
 * If a modal is only used in one application, it should be placed in the respective application ModalProvider
 */
const SuiteModalProvider = ({
  manager,
}: {
  manager: EditorManager | PDFManager | PresentationManager;
}) => {
  const openModal = useModalsOpen();

  return (
    <>
      {openModal.SaveVersionModal && (
        <SaveVersionModal
          saveVersion={manager.saveVersion}
          editDescription={
            manager instanceof EditorManager ? manager.editVersionDescription : undefined
          }
        />
      )}
      {openModal.TaskWatchersModal && <TaskWatchersModal />}
    </>
  );
};

export default SuiteModalProvider;

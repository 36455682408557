import { MouseEventHandler } from 'react';
import { Link } from 'dodoc-design-system';
import { RenderElementProps } from 'slate-react';

import { useDispatch } from '_common/hooks';

import InlineMention from './InlineMention/InlineMention';

import styles from './RichTextEditor.module.scss';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

type HandledElementsProps = RenderElementProps & {
  overwriteStyles?: Pick<RichTextEditor.RichFormat, 'align'>;
  testId: string;
};

const HandledElements = ({
  attributes,
  children,
  element,
  overwriteStyles,
  testId,
}: HandledElementsProps) => {
  const dispatch = useDispatch();

  const textAlign =
    element.type === 'paragraph' && element.align
      ? { textAlign: overwriteStyles?.align ?? element.align }
      : {};

  const handleLinkClicked: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (element.type !== 'link') {
      return;
    }

    e.stopPropagation();

    dispatch(
      openAndUpdateModal({
        modal: 'OpenHyperlinkModal',
        data: {
          url: element.href,
        },
      }),
    );
  };

  switch (element.type) {
    case 'mention':
      return (
        <InlineMention attributes={attributes} element={element} testId={`${testId}-mention`}>
          {children}
        </InlineMention>
      );
    case 'link':
      return (
        <Link
          {...attributes}
          //Disabled href because click is handled by a function instead
          // href={element.href.startsWith('http') ? element.href : `//${element.href}`}
          target="_blank"
          inline
          testId={`${testId}-link`}
          onClick={handleLinkClicked}
        >
          {children}
        </Link>
      );
    default:
      return (
        <p
          {...attributes}
          className={styles.paragraph}
          style={textAlign}
          data-testid={testId ? `${testId}-paragraph` : ''}
        >
          {children}
        </p>
      );
  }
};

export default HandledElements;

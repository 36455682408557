import { PresentationDocument } from '_common/services/Realtime';
import { BaseController } from '../BaseController';

export class DocumentController extends BaseController {
  doc?: PresentationDocument;

  async start(documentId: string) {
    this.doc = this.Data.models?.get(
      this.Data?.models.TYPE_NAME.DOCUMENT,
      documentId,
      {} as Realtime.Core.Document.Data,
    );
    this.doc?.on('UPDATED', (newObj) => {
      this.Data.events?.emit('DOCUMENT_UPDATED', newObj);
    });
    this.doc?.on(
      'UPDATE_OBJECT_STATUS',
      (
        id: Realtime.Core.Document.Data['id'],
        status: Realtime.Core.Document.Data['status'],
        statusInfo: Realtime.Core.Document.Data['statusInfo'],
      ) => {
        // this.Data.events?.emit(
        //   'UPDATE_OBJECT_STATUS',
        //   Object.assign({}, this.doc?.get(), { status, statusInfo }),
        //   status,
        //   statusInfo,
        // );
      },
    );
    await this.doc?.fetch();
  }

  stop(): void {}

  destroy(): void {
    if (this.doc) {
      // this.Data.models?.disposeModel(this.Data?.models.TYPE_NAME.DOCUMENT, this.doc.id);
      delete this.doc;
    }
  }
}

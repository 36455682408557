import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseBlockElement } from '../../BaseViewBuilder';
import styles from './LoaderElement.module.scss';

export class LoaderElement extends BaseBlockElement {
  private container?: HTMLElement;
  private wrapper?: HTMLElement;
  private info?: HTMLElement;

  get selectableContent() {
    return this.info || null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.innerHTML = '';

    // this.setAttribute('contentEditable', false);
    this.setAttribute('spellcheck', 'false');

    this.container = DOMElementFactory.buildElement('div');
    this.wrapper = DOMElementFactory.buildElement('span');
    this.info = DOMElementFactory.buildElement('span');

    this.container.setAttribute('class', styles.container);
    this.container.setAttribute('id', Date.now().toString());
    this.wrapper.setAttribute('class', styles.wrapper);
    this.wrapper.setAttribute('id', Date.now().toString());
    // this.wrapper.setAttribute('contentEditable', true);
    this.info.setAttribute('class', styles.info);
    this.info.setAttribute('id', Date.now().toString());
    this.info.textContent = 'Loading Data...';

    this.container.appendChild(this.wrapper);
    this.wrapper.appendChild(this.info);
    this.appendChild(this.container);
  }
}

// register element
if (!window.customElements.get(ELEMENTS.LoaderElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.LoaderElement.IDENTIFIER, LoaderElement);
}

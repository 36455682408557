import { CSSProperties } from 'react';
import { Saturation, Hue, Checkboard, EditableInput } from 'react-color/lib/components/common';
import { CustomPicker } from 'react-color';

import styles from './CustomColorPicker.module.scss';

type StylesProps = {
  input?: CSSProperties | undefined;
  label?: CSSProperties | undefined;
  wrap?: CSSProperties | undefined;
};

const hexStyles: StylesProps = {
  wrap: {
    width: '80px',
    height: '32px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    minWidth: '80px',
    width: '80px',
    minHeight: '32px',
    height: '32px',
    borderRadius: '3px',
    border: 'solid 1px #d5d5db',
    fontSize: '12px',
    color: '#585870',
    padding: '0.5rem',
  },
  label: {
    marginTop: '1rem',
    fontSize: '12px',
    color: '#828294',
  },
};

const rgbStyles: StylesProps = {
  wrap: {
    width: '40px',
    height: '32px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '1rem',
  },
  input: {
    minWidth: '40px',
    width: '40px',
    minHeight: '32px',
    height: '32px',
    borderRadius: '3px',
    border: 'solid 1px #d5d5db',
    fontSize: '12px',
    color: '#585870',
    padding: '0.5rem',
  },
  label: {
    marginTop: '1rem',
    fontSize: '12px',
    color: '#828294',
  },
};

const CustomPointer = () => <div className={styles.huePointer} />;

type HexColor = `#${string}`;

export type CustomColorPickerProps = {
  rgb: { r: number; g: number; b: number; a: number };
  hex: HexColor;
  onChange: (
    value: {
      hex?: HexColor;
      R?: string | undefined;
      B?: string | undefined;
      G?: string | undefined;
    },
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  hsl: { a: number; h: number; l: number; s: number };
  hsv: { a: number; h: number; l: number; s: number };
  color: string;
};

type dataProps = {
  hsl: {
    a?: number | undefined;
    h: number;
    l: number;
    s: number;
  };
  rgb: {
    a?: number | undefined;
    b: number;
    g: number;
    r: number;
  };
  HEX?: string;
  R?: string | undefined;
  B?: string | undefined;
  G?: string | undefined;
};

type functionsProps = {
  data: dataProps;
  event: React.ChangeEvent<HTMLInputElement>;
};

const CustomColorPicker = ({ rgb, hex, onChange, ...props }: CustomColorPickerProps) => {
  const handleOnChangeHEX = (data: functionsProps['data'], event: functionsProps['event']) => {
    const newBackgroundColor: {
      [key: string]: string | undefined | { a: number; h: number; l: number; s: number };
    } = { ...props, hex };
    const key = Object.keys(data)[0].toLowerCase();
    newBackgroundColor[key] = data.HEX;
    onChange(newBackgroundColor, event);
  };

  const handleOnChangeRGB = (data: functionsProps['data'], event: functionsProps['event']) => {
    const newBackgroundColor: {
      [key: string]: number | undefined | { r: number; g: number; b: number; a: number };
    } = { ...rgb };
    const key = Object.keys(data)[0].toLowerCase();
    let value = parseInt(data.R || data.G || data.B || '', 10);

    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }

    newBackgroundColor[key] = value;
    onChange(newBackgroundColor, event);
  };

  const handleHueChange = (data: functionsProps['data'], event: functionsProps['event']) => {
    onChange(data, event);
  };

  const handleOnSaturationChange = (
    data: functionsProps['data'],
    event: functionsProps['event'],
  ) => {
    onChange(data, event);
  };

  const hueProps = { ...props, radius: '2px' };

  return (
    <div className={styles.customColorPicker}>
      <div className={styles.saturation}>
        <Saturation {...props} onChange={handleOnSaturationChange} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.material}>
          <div className={styles.hue}>
            <Hue
              pointer={CustomPointer}
              direction="horizontal"
              {...hueProps}
              onChange={handleHueChange}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <EditableInput onChange={handleOnChangeHEX} style={hexStyles} label="HEX" value={hex} />
            <div style={{ marginLeft: '2rem', display: 'flex', flexDirection: 'row' }}>
              <EditableInput
                onChange={handleOnChangeRGB}
                style={rgbStyles}
                label="R"
                value={rgb.r}
              />
              <EditableInput
                onChange={handleOnChangeRGB}
                style={rgbStyles}
                label="G"
                value={rgb.g}
              />
              <EditableInput
                onChange={handleOnChangeRGB}
                style={rgbStyles}
                label="B"
                value={rgb.b}
              />
            </div>
          </div>
        </div>

        <div className={styles.checkboard}>
          <Checkboard white={hex} grey={hex} />
        </div>
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);

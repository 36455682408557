 
import { Mixin } from 'mixwith';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class CompositionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       * @param {Node} originalAnchor,
       * @param {*} originalOffset,
       */
      handleCompositionOnCollapsedSelection(
        actionContext,
        event,
        baseNode,
        anchorNode,
        anchorOffset,
        originalAnchor,
        originalOffset,
      ) {
        if (event.data && event.data.length > 0) {
          if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
            // if baseNode is undefined try to fix selection
            if (EditorSelectionUtils.fixSelection()) {
              const selection = EditorSelectionUtils.getSelection();
              baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
              anchorNode = selection.anchorNode;
              anchorOffset = selection.anchorOffset;
            }
          }

          if (baseNode && anchorNode) {
            if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this._handleCompositionOnTextElement(
                actionContext,
                event,
                baseNode,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this._handleCompositionOnNonEditable(
                actionContext,
                event,
                baseNode,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this._handleCompositionOnFigure(
                actionContext,
                event,
                baseNode,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this._handleCompositionOnTable(
                actionContext,
                event,
                baseNode,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              // SELECTION IS INSIDE A BLOCK TRACKED ELEMENT
              this._handleCompositionOnContainerElement(
                actionContext,
                event,
                baseNode,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            }
          }
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      _handleCompositionOnTextElement(
        actionContext,
        event,
        baseNode,
        anchorNode,
        anchorOffset,
        originalAnchor,
        originalOffset,
      ) {
        // delete inserted data before any fix to selection or data insertion
        if (originalAnchor && originalOffset) {
          originalAnchor.deleteData(originalOffset - event.data.length, event.data.length);
          const selection = EditorSelectionUtils.getSelection();
          anchorNode = selection.anchorNode;
          anchorOffset = selection.anchorOffset;
        }

        // check non_editable_inline_elements
        EditorSelectionUtils.fixCollapsedTextSelection({ suggestionMode: true });

        anchorNode = EditorSelectionUtils.getSelection().anchorNode;
        const closest = EditorDOMUtils.closest(anchorNode, ELEMENTS.TrackInsertElement.TAG);
        if (
          closest &&
          this.isUserAuthor(closest) &&
          (this.isDeleteParagraphMarker(closest) || this.isAddParagraphMarker(closest))
        ) {
          const insertSuggestion = this.getTrackInsElement(actionContext.id);
          EditorDOMUtils.insertNodeBefore(closest.parentNode, insertSuggestion, closest);
          EditorSelectionUtils.setCaret(insertSuggestion, 'INSIDE_END');
        } else if (closest && this.isUserAuthor(closest)) {
          actionContext.addReferenceToRefresh(closest.getAttribute('element_reference'));
        } else if (
          EditorSelectionUtils.isSelectionAtStart(anchorNode) &&
          this.isNodeTrackedAction(anchorNode.previousSibling, ELEMENTS.TrackInsertElement.TAG)
        ) {
          EditorSelectionUtils.setCaret(anchorNode.previousSibling, 'INSIDE_END');
          actionContext.addReferenceToRefresh(
            anchorNode.previousSibling.getAttribute('element_reference'),
          );
        } else if (
          this.selectionManager.isSelectionAtEnd(anchorNode) &&
          this.isNodeTrackedAction(anchorNode.nextSibling, ELEMENTS.TrackInsertElement.TAG)
        ) {
          EditorSelectionUtils.setCaret(anchorNode.nextSibling, 'INSIDE_START');
          actionContext.addReferenceToRefresh(
            anchorNode.nextSibling.getAttribute('element_reference'),
          );
        } else {
          const insertSuggestion = this.getTrackInsElement(
            this.getProperSuggestionRef(actionContext),
          );

          this.insertInlineNode(actionContext, insertSuggestion);
          EditorSelectionUtils.setCaret(insertSuggestion, 'INSIDE_END');
        }

        // insert normal text element
        this.insertTextInline(actionContext, event.data);
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      _handleCompositionOnNonEditable() {
        // nothing to do here
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      _handleCompositionOnFigure(
        actionContext,
        event,
        baseNode,
        anchorNode,
        anchorOffset,
        originalAnchor,
        originalOffset,
      ) {
        const closest = EditorDOMUtils.closest(anchorNode, ['FIGCAPTION']);
        if (closest && !closest.hasAttribute('lock')) {
          this._handleCompositionOnTextElement(
            actionContext,
            event,
            baseNode,
            anchorNode,
            anchorOffset,
            originalAnchor,
            originalOffset,
          );
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      _handleCompositionOnTable(
        actionContext,
        event,
        baseNode,
        anchorNode,
        anchorOffset,
        originalAnchor,
        originalOffset,
      ) {
        const closest = EditorDOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            const tdLevel0Node = EditorDOMUtils.findFirstLevelChildNode(closest, anchorNode);
            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              this.handleCompositionOnCollapsedSelection(
                actionContext,
                event,
                tdLevel0Node,
                anchorNode,
                anchorOffset,
                originalAnchor,
                originalOffset,
              );
            }
          }
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {Event} event
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      _handleCompositionOnContainerElement(
        actionContext,
        event,
        baseNode,
        anchorNode,
        anchorOffset,
        originalAnchor,
        originalOffset,
      ) {
        if (baseNode === anchorNode) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        } else if (baseNode.tagName === ELEMENTS.TrackDeleteElement.TAG) {
          if (baseNode.nextSibling) {
            EditorSelectionUtils.setCaret(baseNode.nextSibling, 'INSIDE_START');
          }
          return;
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleDefaultOnCollapsedSelection(
            actionContext,
            event,
            subLevel0Node,
            anchorNode,
            anchorOffset,
            originalAnchor,
            originalOffset,
          );
        }
      }
    },
);

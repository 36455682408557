import {
  BaseViewBuilder,
  TableElement,
} from 'Editor/services/VisualizerManager/Views/ViewBuilders';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { NodeUtils } from 'Editor/services/DataManager/models';

export class TableStyles {
  private editorContext: Editor.Context;
  private stylesContext: Editor.Styles.Context;

  constructor(editorContext: Editor.Context, stylesContext: Editor.Styles.Context) {
    this.editorContext = editorContext;
    this.stylesContext = stylesContext;
  }

  private getPropertiesFromCell = (
    tableData: Editor.Data.Node.Data,
    cellData: Editor.Data.Node.Data,
  ) => {
    const data: Editor.Styles.TableProperties = {};

    const background = BaseViewBuilder.colorMapper.render(
      cellData.properties?.bg || tableData.properties?.bg || 'false',
    );
    data.background = background;

    data.verticalAlignment = cellData.properties?.va || tableData.properties?.cva || 't';

    data.textAlignment = cellData.properties?.a || tableData.properties?.ca || 'l';

    let paddingData: Editor.Data.Node.Padding | undefined = undefined;
    if (cellData.properties?.p) {
      paddingData = cellData.properties.p;
    } else if (tableData.properties?.cp) {
      paddingData = tableData.properties.cp;
    }

    if (paddingData) {
      const padding: Editor.Styles.TableProperties['padding'] = {};

      if (paddingData.t) {
        padding.top = {
          value: +paddingData.t,
          incoherent: false,
        };
      }

      if (paddingData.b) {
        padding.bottom = {
          value: +paddingData.b,
          incoherent: false,
        };
      }

      if (paddingData.l) {
        padding.left = {
          value: +paddingData.l,
          incoherent: false,
        };
      }

      if (paddingData.r) {
        padding.right = {
          value: +paddingData.r,
          incoherent: false,
        };
      }

      data.padding = padding;
    }

    let borderData: Editor.Data.Node.Borders | undefined = undefined;
    if (cellData.properties?.b) {
      borderData = cellData.properties.b;
    } else if (tableData.properties?.cb) {
      borderData = tableData.properties.cb;
    }

    if (borderData) {
      const border: Editor.Styles.TableProperties['border'] = {};

      const color = borderData.t?.c || borderData.b?.c || borderData.r?.c || borderData.l?.c;
      border.color = {
        value: BaseViewBuilder.colorMapper.render(color) || '',
        incoherent: !(
          borderData.t?.c !== undefined &&
          borderData.t.c === borderData.r?.c &&
          borderData.t.c === borderData.b?.c &&
          borderData.t.c === borderData.l?.c
        ),
      };

      border.style = {
        value: borderData.t?.s || borderData.b?.s || borderData.r?.s || borderData.l?.s || 'n',
        incoherent: !(
          borderData.t?.s !== undefined &&
          borderData.t.s === borderData.r?.s &&
          borderData.t.s === borderData.b?.s &&
          borderData.t.s === borderData.l?.s
        ),
      };

      border.width = {
        value: borderData.t?.w || borderData.b?.w || borderData.r?.w || borderData.l?.w || 0,
        incoherent: !(
          borderData.t?.w !== undefined &&
          borderData.t.w === borderData.r?.w &&
          borderData.t.w === borderData.b?.w &&
          borderData.t.w === borderData.l?.w
        ),
      };

      data.border = border;
    }

    return data;
  };

  getSelectedTableProperties(): Editor.Styles.TablePropertiesData | null {
    this.editorContext.navigationManager?.scrollIntoSelection();
    const elements = EditorSelectionUtils.getSelectedTableElements();

    // No table was found
    if (!elements) {
      return null;
    }

    const { selectedTable, selectedRows, rowsIndex, columnsIndex, selectedCells } = elements;

    if (selectedTable instanceof TableElement) {
      const pageNode = EditorDOMUtils.getContentContainer(selectedTable);

      let level0Node: Node | null = selectedTable;
      if (level0Node.parentNode !== pageNode) {
        level0Node = EditorDOMUtils.findFirstLevelChildNode(pageNode, selectedTable);
      }

      if (EditorDOMElements.isSupportedBlockElement(level0Node)) {
        const nodeModel = this.editorContext.DataManager?.nodes.getNodeModelById(level0Node.id);

        if (nodeModel) {
          let tableData: Editor.Data.Node.Data = nodeModel.get();

          if (level0Node !== selectedTable) {
            tableData = nodeModel.getChildDataById(selectedTable.id);
          }

          const tbodyData: Editor.Data.Node.Data | undefined = tableData.childNodes?.find(
            (child) => child.type === ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE,
          );
          const rowsData = tbodyData?.childNodes;

          // Height is the sum of the rows' heights
          const rows = selectedTable.tBodies[0].rows;

          let pageWidth = this.editorContext.DataManager?.sections.getPageWidthForBlockId(
            level0Node.id,
          );

          const data: Editor.Styles.TablePropertiesData = {
            PAGE: {
              width: pageWidth,
            },
            TABLE: {},
            ROW: {
              fromStart: false,
            },
            COLUMN: {},
            CELL: {},
          };

          if (NodeUtils.isTableData(tableData)) {
            if (tableData.properties?.w) {
              data.TABLE.width = {
                value: {
                  t: tableData.properties.w.t,
                  v: tableData.properties.w.v,
                },
                incoherent: false,
              };
            }

            if (tableData.properties) {
              const padding: Editor.Styles.PaddingProp = {};

              if (tableData.properties?.cp?.t) {
                padding.top = {
                  value: +tableData.properties.cp.t,
                  incoherent: false,
                };
              }

              if (tableData.properties?.cp?.b) {
                padding.bottom = {
                  value: +tableData.properties.cp.b,
                  incoherent: false,
                };
              }

              if (tableData.properties?.cp?.l) {
                padding.left = {
                  value: +tableData.properties.cp.l,
                  incoherent: false,
                };
              }

              if (tableData.properties?.cp?.r) {
                padding.right = {
                  value: +tableData.properties.cp.r,
                  incoherent: false,
                };
              }

              data.TABLE.padding = padding;

              data.TABLE.alignment = tableData.properties.a || 'l';
              data.TABLE.textAlignment = tableData.properties.ca || 'l';
              data.TABLE.leftIndentation = tableData.properties.ind
                ? tableData.properties.ind.l
                : 0;
              data.TABLE.verticalAlignment = tableData.properties.cva || 't';
              data.TABLE.background =
                BaseViewBuilder.colorMapper.render(tableData.properties.bg) || '';

              if (tableData.properties.cb) {
                const cb = tableData.properties.cb;
                const tableBorder: Editor.Styles.TableProperties['border'] = {};

                const color = cb.t?.c || cb.b?.c || cb.r?.c || cb.l?.c;
                tableBorder.color = {
                  value: BaseViewBuilder.colorMapper.render(color) || '',
                  incoherent: !(
                    cb.t?.c !== undefined &&
                    cb.t.c === cb.r?.c &&
                    cb.t.c === cb.b?.c &&
                    cb.t.c === cb.l?.c
                  ),
                };

                tableBorder.style = {
                  value: cb.t?.s || cb.b?.s || cb.r?.s || cb.l?.s || 'n',
                  incoherent: !(
                    cb.t?.s !== undefined &&
                    cb.t.s === cb.r?.s &&
                    cb.t.s === cb.b?.s &&
                    cb.t.s === cb.l?.s
                  ),
                };

                tableBorder.width = {
                  value: cb.t?.w || cb.b?.w || cb.r?.w || cb.l?.w || 0,
                  incoherent: !(
                    cb.t?.w !== undefined &&
                    cb.t.w === cb.r?.w &&
                    cb.t.w === cb.b?.w &&
                    cb.t.w === cb.l?.w
                  ),
                };
                data.TABLE.border = tableBorder;
              }

              data.TABLE.autoResize = tableData.properties.ar || false;
            }

            if (selectedRows[0].id === rows[0].id) {
              data.ROW.fromStart = true;
            }

            const columnsIds: string[] = [];
            if (rowsData) {
              let columnWidth:
                | Editor.Styles.IncoherentProperty<Editor.Data.Node.TableWidth>
                | undefined;
              let rowHeight:
                | Editor.Styles.IncoherentProperty<Editor.Data.Node.RowHeight>
                | undefined
                | null;
              let headerRows: string[] = [];

              for (let i = 0; i < rowsData.length; i++) {
                const rowData = rowsData[i];

                if (rowsIndex.includes(i)) {
                  if (rowData.properties?.hr === true && rowData.id) {
                    headerRows.push(rowData.id);
                  }

                  if (rowData.properties?.rh != null) {
                    if (rowHeight == null) {
                      if (isNaN(rowData.properties?.rh)) {
                        rowHeight = {
                          value: {
                            t: rowData.properties?.rh.t,
                            v: rowData.properties?.rh.v,
                          },
                          incoherent: false,
                        };
                      } else {
                        rowHeight = {
                          value: {
                            t: 'abs',
                            v: rowData.properties?.rh,
                          },
                          incoherent: false,
                        };
                      }
                    } else if (
                      rowHeight?.value.t !== rowData.properties.rh.t ||
                      rowHeight?.value.v !== rowData.properties.rh.v
                    ) {
                      rowHeight.incoherent = true;
                    }
                  } else {
                    if (rowHeight == null) {
                      rowHeight = {
                        value: {
                          t: 'auto',
                          v: 0,
                        },
                        incoherent: false,
                      };
                    } else {
                      rowHeight.incoherent = true;
                    }
                  }
                }

                if (rowData.childNodes) {
                  for (let j = 0; j < rowData.childNodes.length; j++) {
                    if (columnsIndex.includes(j)) {
                      const cellData = rowData?.childNodes?.[j];

                      if (cellData && cellData.properties?.w) {
                        if (columnWidth === undefined) {
                          columnWidth = {
                            value: { t: cellData.properties.w.t, v: cellData.properties.w.v },
                            incoherent: false,
                          };
                        } else if (
                          columnWidth !== null &&
                          columnWidth.value.t !== cellData.properties.w.t &&
                          columnWidth.value.v !== cellData.properties.w.v
                        ) {
                          if (
                            (columnWidth.value.t !== 'abs' && cellData.properties.w.t === 'abs') ||
                            (columnWidth.value.t === cellData.properties.w.t &&
                              columnWidth.value.v < cellData.properties.w.v)
                          ) {
                            columnWidth.value.t = cellData.properties.w.t;
                            columnWidth.value.v = cellData.properties.w.v;
                          }

                          columnWidth.incoherent = true;
                        }
                      } else {
                        if (columnWidth === undefined) {
                          columnWidth = {
                            value: { t: 'auto', v: 0 },
                            incoherent: false,
                          };
                        }
                      }

                      if (cellData?.id) {
                        columnsIds.push(cellData.id);
                      }
                    }
                  }
                }
              }
              if (headerRows.length === 0) {
                data.ROW.headerRow = {
                  value: false,
                  incoherent: false,
                };
              } else if (headerRows.length === selectedRows.length) {
                data.ROW.headerRow = {
                  value: true,
                  incoherent: false,
                };
              } else if (headerRows.length < selectedRows.length) {
                data.ROW.headerRow = {
                  value: false,
                  incoherent: true,
                };
              }

              if (rowHeight != null) {
                data.ROW.height = rowHeight;
                data.CELL.height = rowHeight;
              }

              if (columnWidth) {
                data.COLUMN.width = columnWidth;
                data.CELL.width = columnWidth;
              }
            }

            const cellDataPath = nodeModel.findPathToChild(selectedCells[0].id);

            const cellProperties = this.getPropertiesFromCell(
              tableData,
              nodeModel.get(cellDataPath),
            );

            data.ROW = { ...data.ROW, ...cellProperties };
            data.COLUMN = { ...data.COLUMN, ...cellProperties };
            data.CELL = { ...data.CELL, ...cellProperties };
          }
          return data;
        }
      }
    }

    return null;
  }

  deselectTableCells(e: KeyboardEvent, checkSelection = true) {
    if (!e?.ctrlKey && !e?.metaKey) {
      const tables = Array.prototype.slice.call(
        this.editorContext.documentContainer?.querySelectorAll(`${ELEMENTS.TableElement.TAG}`),
      );
      for (let i = 0; i < tables.length; i += 1) {
        tables[i].deselectAllCells();
      }

      if (checkSelection) {
        // update selection status
        this.editorContext.visualizerManager?.selection.debounceSelectionChanged();
      }
    }
  }
}

import type { TagDescription } from '@reduxjs/toolkit/dist/query';
import { setBadRequestState, setForbiddenState, setNotFoundState } from 'App/redux/appSlice';

import api from '_common/services/api/api';

type RoleState = {
  order: {
    type: Uuid;
    field: Uuid;
  };
  list: ObjectList;
  dict: Record<Role['id'], Role>;
  role: Uuid;
};

type RoleList = Pick<RoleState, 'order'> & {
  roles: Role[];
};

type APIError = {
  error: { data: any; status: number };
};

const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRolesList: builder.query<Pick<RoleState, 'order' | 'list' | 'dict'>, void>({
      query: () => ({
        url: `/object/role/list`,
      }),
      transformResponse: (responseData: RoleList) => {
        const roles = responseData.roles.reduce(
          (previous: Pick<RoleState, 'list' | 'dict'>, current: Role) => {
            previous.list.push(current.id);
            previous.dict[current.id] = current;
            return previous;
          },
          {
            list: [],
            dict: {},
          },
        );

        return { order: responseData.order, ...roles };
      },
      providesTags: () => {
        return ['Role'];
      },
    }),
    getRole: builder.query<Role, Role['id']>({
      query: (id) => ({
        url: `/object/role/get/${id}`,
        errorsExpected: [400, 404, 403],
      }),
      providesTags: (result) => {
        const tags: TagDescription<'Role'>[] = [];
        if (result) {
          tags.push({ type: 'Role', id: result.id });
        }
        return tags;
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const typedError = (error as APIError).error;
          if (typedError?.status === 400) {
            dispatch(setBadRequestState());
          } else if (typedError?.status === 403) {
            dispatch(setForbiddenState());
          } else if (typedError?.status === 404) {
            dispatch(setNotFoundState());
          }
        }
      },
    }),
    createRole: builder.mutation<void, Pick<Role, 'name' | 'description'>>({
      query: ({ name, description }) => {
        return {
          url: `/object/role/create`,
          method: 'POST',
          body: { name, description },
        };
      },
      invalidatesTags: () => ['Role'],
    }),
    deleteRole: builder.mutation<void, Role['id']>({
      query: (id) => {
        return {
          url: `/object/role/${id}/delete`,
          method: 'POST',
          body: {},
        };
      },
      invalidatesTags: () => ['Role'],

      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          rolesApi.util.updateQueryData('getRolesList', undefined, (draft) => {
            //Optimistic save
            draft.list.filter((roleId) => roleId !== id);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResult.undo();
        }
      },
    }),
    renameRole: builder.mutation<Role, Pick<Role, 'id' | 'name'>>({
      query: ({ id, name }) => {
        return {
          url: `/object/role/${id}/edit`,
          method: 'POST',
          body: { name },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Role', id: arg.id }, 'Role'],

      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResultRole = dispatch(
          rolesApi.util.updateQueryData('getRole', id, (draft) => {
            //Optimistic save
            Object.assign(draft, patch);
          }),
        );
        const patchResultRolesList = dispatch(
          rolesApi.util.updateQueryData('getRolesList', undefined, (draft) => {
            //Optimistic save
            draft.dict[id].name = patch.name;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResultRole.undo();
          patchResultRolesList.undo();
        }
      },
    }),
    editRoleDescription: builder.mutation<Role, Pick<Role, 'id' | 'description'>>({
      query: ({ id, description }) => {
        return {
          url: `/object/role/${id}/edit`,
          method: 'POST',
          body: { description },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Role', id: arg.id }, 'Role'],

      /** Optimistic save mechanism
       *  Source: https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
       */
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResultRole = dispatch(
          rolesApi.util.updateQueryData('getRole', id, (draft) => {
            //Optimistic save
            Object.assign(draft, patch);
          }),
        );
        const patchResultRolesList = dispatch(
          rolesApi.util.updateQueryData('getRolesList', undefined, (draft) => {
            //Optimistic save
            draft.dict[id].description = patch.description;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          //Due to request fail, undo optimistic save
          patchResultRole.undo();
          patchResultRolesList.undo();
        }
      },
    }),
    addRolePermission: builder.mutation<
      Role,
      Pick<Role, 'id'> & { permissions: Role['granted'][number] }
    >({
      query: ({ id, permissions }) => {
        return {
          url: `/object/role/${id}/add`,
          method: 'POST',
          body: { permissions },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Role', id: arg.id }, 'Role'],
    }),
    removeRolePermission: builder.mutation<
      Role,
      Pick<Role, 'id'> & { permissions: Role['granted'][number] }
    >({
      query: ({ id, permissions }) => {
        return {
          url: `/object/role/${id}/remove`,
          method: 'POST',
          body: { permissions },
        };
      },
      invalidatesTags: (_, __, arg) => [{ type: 'Role', id: arg.id }, 'Role'],
    }),
  }),
});

// Export queries and mutations
export const {
  useGetRolesListQuery,
  useGetRoleQuery,
  useRenameRoleMutation,
  useEditRoleDescriptionMutation,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useAddRolePermissionMutation,
  useRemoveRolePermissionMutation,
} = rolesApi;

// Select data from where RTK Query stores the data from the list endpoint
export const selectRolesList = rolesApi.endpoints.getRolesList.select();

export default rolesApi;

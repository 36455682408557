 
 
import { DocumentModel } from '_common/services/Realtime';
import BaseController from '../BaseController';

export class DocumentController extends BaseController {
  doc?: DocumentModel;

  constructor(Data: Editor.Data.State) {
    super(Data);

    this.handleEventUpdated = this.handleEventUpdated.bind(this);
    this.handleEventTrackingStateUpdated = this.handleEventTrackingStateUpdated.bind(this);
    this.handleEventUpdateObjectStatus = this.handleEventUpdateObjectStatus.bind(this);
  }

  start(documentId: string, data: Realtime.Core.Document.Data): void {
    this.doc = this.Data.models?.get(this.Data?.models.TYPE_NAME.DOCUMENT, documentId, data);

    this.doc?.on('UPDATED', this.handleEventUpdated);
    this.doc?.on('DOCUMENT_TRAKING_STATE_UPDATED', this.handleEventTrackingStateUpdated);
    this.doc?.on('UPDATE_OBJECT_STATUS', this.handleEventUpdateObjectStatus);

    this.handleEventUpdated();
    this.handleEventTrackingStateUpdated();
  }

  stop(): void {}

  destroy(): void {
    if (this.doc) {
      this.Data.models?.disposeModel(this.Data?.models.TYPE_NAME.DOCUMENT, this.doc.id);
      delete this.doc;
    }
  }

  generateContent(amount: number, options?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'APPEND:TEST:CONTENT',
        {
          amount: amount,
          options,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  private handleEventUpdated() {
    const docData = this.doc?.get();
    if (docData) {
      this.Data.events?.emit('DOCUMENT_UPDATED', docData);
    }
  }

  private handleEventTrackingStateUpdated() {
    this.Data.events?.emit('UPDATE_TRACK_LOCK_STATUS');
  }

  private handleEventUpdateObjectStatus(
    id: Realtime.Core.Document.Data['id'],
    status: Realtime.Core.Document.Data['status'],
    statusInfo: Realtime.Core.Document.Data['statusInfo'],
  ) {
    this.Data.events?.emit('UPDATE_OBJECT_STATUS', id, status, statusInfo);
  }

  getDocumentModel() {
    return this.doc;
  }

  getDocumentData() {
    return this.doc?.get();
  }

  getDocumentTracking(): any | null {
    return this.doc?.get()?.tracking;
  }

  getDocumentId(): string | undefined {
    return this.doc?.get()?._id;
  }

  getDocumentLanguage() {
    return this.doc?.language;
  }

  async setDocumentLanguage(language: string): Promise<void> {
    return this.doc?.setDocumentLanguage(language);
  }
}

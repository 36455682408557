import { useMemo } from 'react';
import cx from 'classnames';
import { useTableOptionsContext } from './TableOptionsContext';
import styles from './TableOptions.module.scss';
import { useSelector } from '_common/hooks';

type SelectionOverlayProps = {
  selection: [
    Editor.Elements.Table.Point,
    Editor.Elements.Table.Point,
    Editor.Elements.Table.Point,
    Editor.Elements.Table.Point,
  ][];
  width: number;
  height: number;
};

const SelectionOverlay = ({ selection, width, height }: SelectionOverlayProps) => {
  const { deleting, deletingTable } = useTableOptionsContext();
  const zoom = useSelector((state) => state.editor.status.zoom);
  const paths = useMemo(() => {
    let d = selection.map(
      ([p1, p2, p3, p4]) =>
        `M ${p1.x / zoom} ${p1.y / zoom}
         L ${p2.x / zoom} ${p2.y / zoom}
         L ${p3.x / zoom} ${p3.y / zoom}
         L ${p4.x / zoom} ${p4.y / zoom}
         Z`,
    );
    return d;
  }, [selection]);

  if (selection.length > 0 || deletingTable) {
    return (
      <svg
        className={cx(styles.cells, { [styles.deleting]: deleting || deletingTable })}
        version="1.1"
        width={`${width}px`}
        height={`${height}px`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {deletingTable ? (
          <rect width="100%" height="100%" />
        ) : (
          paths.map((d) => <path key={d} d={d} />)
        )}
      </svg>
    );
  }
  return null;
};

export default SelectionOverlay;

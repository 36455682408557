import { useEffect, useState } from 'react';
import { Input } from 'dodoc-design-system';
import { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';

export type MeasureInputProps = {
  valueSuffix?: string;
  avoidEmpty?: boolean;
  min?: number;
  max?: number;
  onChange: (newValue: string) => void;
};

const MeasureField = ({
  valueSuffix,
  avoidEmpty,
  min,
  max,
  onChange,
  ...inputProps
}: Omit<InputProps, 'type' | 'onChange' | 'min' | 'max'> & MeasureInputProps) => {
  //Only display 'valueSuffix' when input isn't focused
  const [focused, setFocused] = useState(false);
  const [lastValidValue, setLastValidValue] = useState<string | null>(null);
  const [invalidValue, setInvalidValue] = useState<string | null>(null);

  useEffect(() => {
    if (!focused) {
      if (avoidEmpty && invalidValue !== null && lastValidValue) {
        onChange(lastValidValue);
      }

      setInvalidValue(null);
    }
  }, [focused]);

  const handleChange: InputProps['onChange'] = (e) => {
    if (
      (avoidEmpty && e.target.value === '') ||
      (min !== undefined && +e.target.value < min) ||
      (max !== undefined && +e.target.value > max)
    ) {
      //Write invalid values temporarily
      setInvalidValue(e.target.value);
    } else {
      setInvalidValue(null);
      setLastValidValue(e.target.value);
      onChange(e.target.value);
    }
  };

  return (
    <Input
      {...inputProps}
      type="number"
      value={
        !focused && valueSuffix && inputProps.value != null
          ? `${inputProps.value} ${valueSuffix}`
          : (invalidValue ?? inputProps.value)
      }
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={handleChange}
      allowDecimal
    />
  );
};

export default MeasureField;

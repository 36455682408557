import { ProgressBar, Icon } from 'dodoc-design-system';
import { IconSizes } from 'dodoc-design-system/build/types/Components/Icon/Icon';

import { FormattedMessage } from 'react-intl';

import styles from './Loading.module.scss';

type LoadingProps = Omit<IconSizes, 'size'> &
  Partial<Pick<IconSizes, 'size'>> & {
    message: TranslationMessage;
  };

const Loading = ({ message, size = 96, ...iconProps }: LoadingProps) => {
  return (
    <div className={styles.loading}>
      {iconProps.icon && (
        <span className={styles.icon}>
          <Icon size={size} {...iconProps} />
        </span>
      )}

      <ProgressBar
        color="primaryLight"
        size="medium"
        testId="import-new-version-importing-progressBar"
      />

      <span className={styles.message}>
        <FormattedMessage id={message.id} values={message.values} />
      </span>
    </div>
  );
};

export default Loading;

import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import { EditorSelectionUtils, JsonRange } from '../_Common/Selection';
import { EditorDOMUtils } from '../_Common/DOM';

class SpellCheck {
  private editorContext: Editor.Context;
  constructor(editorContext: Editor.Context) {
    this.editorContext = editorContext;
  }

  destroy() {}

  /**
   * find next error ocurrence
   */
  findError(forward = true, fromStart = false): Promise<Editor.SpellCheck.Occurrence> {
    return new Promise(async (resolve, reject) => {
      let data = null;
      if (this.editorContext?.navigationManager?.isMarkerRendered(undefined, false)) {
        this.editorContext.visualizerManager?.selection.stopSelectionTracker();
        try {
          const startRange = EditorSelectionUtils.getRange();
          if (startRange) {
            let startJsonRange = JsonRange.buildFromDOMRange(startRange);

            if (forward) {
              data = await this.editorContext?.DataManager?.spellcheck.nextSpellcheckHit(
                null,
                startJsonRange.end,
                fromStart,
              );

              if (!data) {
                const nodes = this.editorContext?.DataManager?.structure.getDocumentNodes();
                if (nodes) {
                  await this.editorContext?.navigationManager?.renderDocumentAtId(nodes[0]);
                }
                const childNodes = EditorDOMUtils.getContentContainer()?.childNodes;

                if (childNodes) {
                  startRange.setCaret(childNodes[0] ?? null, 'INSIDE_START', 0);
                  this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

                  startJsonRange = JsonRange.buildFromDOMRange(startRange);

                  data = await this.editorContext?.DataManager?.spellcheck.nextSpellcheckHit(
                    null,
                    startJsonRange.end,
                    fromStart,
                  );
                }
              }
            } else {
              data = await this.editorContext?.DataManager?.spellcheck.previousSpellcheckHit(
                null,
                startJsonRange.start,
              );

              if (!data) {
                const nodes = this.editorContext?.DataManager?.structure.getDocumentNodes();
                if (nodes) {
                  await this.editorContext?.navigationManager?.renderDocumentAtId(
                    nodes[nodes.length - 1],
                  );
                }
                const childNodes = EditorDOMUtils.getContentContainer()?.childNodes;

                if (childNodes) {
                  startRange.setCaret(childNodes?.[childNodes?.length - 1], 'INSIDE_END');
                  this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

                  startJsonRange = JsonRange.buildFromDOMRange(startRange);

                  data = await this.editorContext?.DataManager?.spellcheck.previousSpellcheckHit(
                    null,
                    startJsonRange.start,
                  );
                }
              }
            }

            if (data) {
              const resultJsonRange = new JsonRange(data.location.start, data.location.end);

              await this.editorContext?.navigationManager?.renderDocumentAtId(
                resultJsonRange.start.b,
              );

              const resultRange = resultJsonRange.serializeToDOMRange();
              EditorSelectionUtils.applyRangeToSelection(resultRange);

              data.rangeData = resultJsonRange.serializeToRangeData();
              data.blockId = resultJsonRange.start.b;
            }
          }
        } catch (error) {
          Logger.captureException(error);
          reject(error);
        } finally {
          this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
        }
      }
      resolve(data);
    });
  }

  async runSpellCheckFromStart() {
    const nodes = this.editorContext?.DataManager?.structure.getDocumentNodes();
    if (nodes) {
      await this.editorContext?.navigationManager?.renderDocumentAtId(nodes[0]);
    }

    EditorSelectionUtils.setCaret(
      //@ts-expect-error
      EditorDOMUtils.getContentContainer()?.childNodes[0] ?? null,
      'INSIDE_START',
      0,
    );
    this.editorContext.visualizerManager?.selection.triggerSelectionChanged();

    return this.findError(true, true);
  }

  restoreSelectionToError(
    rangeData: Editor.SpellCheck.Occurrence['rangeData'],
    wordToRestore: string,
  ) {
    if (this.editorContext?.navigationManager?.scrollIntoSelection([rangeData], false)) {
      const range = EditorSelectionUtils.getRange();
      if (range && range.toString() === wordToRestore) {
        return true;
      }

      EditorSelectionUtils.collapseToStart();
    }

    return false;
  }

  recheckError(rangeData: Editor.SpellCheck.Occurrence['rangeData'], wordToRestore: string) {
    if (this.restoreSelectionToError(rangeData, wordToRestore)) {
      return this.findError();
    }

    return Promise.reject();
  }

  /**
   * replace marker selectiond by suggestion
   * @param {Editor.SpellCheck.Occurrence} occurence
   * @param {String} suggestionToReplace
   */
  async replaceWord(occurence: Editor.SpellCheck.Occurrence, suggestionToReplace: string) {
    this.editorContext.visualizerManager?.selection.stopSelectionTracker();
    try {
      await this.editorContext.DataManager?.spellcheck?.changeOccurence(
        occurence,
        suggestionToReplace,
        ReduxInterface.isEditorTrackingStateOn(),
      );
      const resultJsonRange = new JsonRange(occurence.location.start);

      await this.editorContext?.navigationManager?.renderDocumentAtId(resultJsonRange.start.b);

      const resultRange = resultJsonRange.serializeToDOMRange();
      EditorSelectionUtils.applyRangeToSelection(resultRange);
      this.editorContext.selectionManager?.modifySelection(
        EditorSelectionUtils.SELECTION_CHANGE_TYPE.expand,
        EditorSelectionUtils.SELECTION_DIRECTION.forward,
        EditorSelectionUtils.SELECTION_GRANULARITY.word,
      );
    } catch (error) {
      Logger.captureException(error);
      throw error;
    } finally {
      this.editorContext.visualizerManager?.selection.debounceStartSelectionTracker();
    }
  }

  endSpellcheckCycle() {
    this.editorContext.DataManager?.spellcheck.endSpellcheckCycle();
  }

  ignoreErrorOnce() {
    return this.findError();
  }

  async ignoreAllOccurrences(term: string, lang: string) {
    return this.editorContext.DataManager?.spellcheck.ignoreAllOccurences(term, lang);
  }

  async addWordToDictionary(term: string, lang: string) {
    return this.editorContext.DataManager?.spellcheck.addWordToDictionary(term, lang);
  }

  async changeAllOcurrences(wordToReplace: string, suggestionToReplace: string) {
    return this.editorContext.DataManager?.spellcheck.changeAllOccurences(
      wordToReplace,
      suggestionToReplace,
      ReduxInterface.isEditorTrackingStateOn(),
    );
  }
}

export default SpellCheck;

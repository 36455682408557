 
 
import { DataObject } from '_common/services/Realtime';
import { TableOfContents } from '../../models';
import BaseController from '../BaseController';

type FieldsDataType = {
  date?: Date;
  title: string;
};

class FieldsDataModel extends DataObject<
  FieldsDataType,
  {
    READY: () => void;
    LOADED: (data: FieldsDataType | null) => void;
    CREATED: () => void;
    UPDATED: (data: FieldsDataType | null) => void;
    UPDATED_PROP: <T extends keyof FieldsDataType>(property: T, data: FieldsDataType[T]) => void;
  }
> {
  protected data: FieldsDataType;

  constructor(id: string) {
    super(id);
    this.data = {
      title: 'No title found',
    };
  }

  setProperty<T extends keyof FieldsDataType>(property: T, data: FieldsDataType[T]) {
    this.data[property] = data;
    this.emit('UPDATED_PROP', property, this.data[property]);
  }

  getProperty<T extends keyof FieldsDataType>(property: T): FieldsDataType[T] {
    return this.data[property];
  }
}

export class FieldsController extends BaseController {
  private data: FieldsDataModel;
  private toc?: TableOfContents;
  constructor(Data: Editor.Data.State) {
    super(Data);
    this.data = new FieldsDataModel('fields');
  }

  start(documentId: string): void {
    this.toc = this.Data.models?.get(this.Data?.models.TYPE_NAME.TOC, `TOC${documentId}`);
    this.data.setProperty('date', new Date());
    this.data?.setProperty('title', 'No title found');
    this.toc?.on('LOADED', this.refreshDocumentTitle.bind(this));
    this.toc?.on('UPDATED', this.refreshDocumentTitle.bind(this));
  }

  private refreshDocumentTitle() {
    this.Data.tableOfContents
      ?.getDocumentMainTitle(false)
      ?.then((result?: TableOfContents.TOCElementType) => {
        if (result && result.content) {
          this.data?.setProperty('title', result.content);
        }
      });
  }

  get<T extends keyof FieldsDataType>(property: T): FieldsDataType[T] {
    return this.data.getProperty(property);
  }

  stop(): void {}

  destroy(): void {
    this.data.destroy();
  }
}

import { FormattedMessage } from 'react-intl';
import { useLoginSetupQuery } from '_common/services/api/authority';
import styles from './Version.module.scss';

export const Version = ({ hero, white }: { hero: boolean; white: boolean }) => {
  const { data } = useLoginSetupQuery();
  if (data?.dodoc_version) {
    return (
      <span
        className={`${styles.root} ${hero ? styles.hero : undefined} ${
          white ? styles.white : undefined
        }`}
      >
        <FormattedMessage id="global.version" values={{ version: data.dodoc_version }} />
        <span style={{ margin: '0 1rem' }}>•</span>
      </span>
    );
  }
  return null;
};

export default Version;

import { ReduxInterface } from 'Editor/services';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { StylesHandler } from 'Editor/services/Styles';
import { ELEMENTS } from 'Editor/services/consts';
import { Parser } from './Parser';

export class FilesParser extends Parser {
  constructor(
    data: DataTransferItem[],
    dataManager: Editor.Data.API,
    stylesHandler: StylesHandler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    super([], dataManager, stylesHandler, visualizerManager);

    this.data = [];

    data.forEach((item: DataTransferItem) => {
      if (item.kind === 'file' && item.type.indexOf('image') === 0) {
        if (this.data instanceof Array) {
          const file = item.getAsFile();
          if (file) {
            this.data.push(file);
          }
        }
      }
    });
  }

  async parse() {
    this.container = document.createElement('div');
    try {
      const uploads: Promise<any>[] = [];
      if (this.data instanceof Array) {
        this.data.forEach((file: File) => {
          // TODO call visualizer view factory
          const paragraph = DOMElementFactory.createNewParagraphElement();
          const image = DOMElementFactory.buildElement(ELEMENTS.ImageElement.TAG);
          paragraph.appendChild(image);

          if (this.container) {
            this.container.appendChild(paragraph);
            const id = Parser.prepareImageForUpload(image);
            const upload = this.uploadPastedImage(id, file);
            if (upload) {
              uploads.push(upload);
            }
          }
        });
        await Promise.all(uploads);
        this.isValid = true;
      }
    } catch (error) {
      this.isValid = false;
    } finally {
      ReduxInterface.stopEditorLoading();
    }
  }
}

import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, usePopper, Tooltip, Toggle, Button } from 'dodoc-design-system';

import { navigateToEditor, navigateToPDF, navigateToPresentation } from 'router/history';
import { useDispatch, useSelector } from '_common/hooks';
import {
  cancelCheckOut,
  downloadFile,
  isChangeStatusEnabled,
  setAppLoading,
} from 'App/redux/appSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { updateMiniExplorer } from '_common/components/MiniExplorer/miniExplorerSlice';
import selectAvailableActions, { ActionsToShow } from './ActionsSelector';
import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import {
  useGetCurrentUserQuery,
  selectUserCurrentTenant,
  useGetLinksQuery,
  useGetTenantSettingsQuery,
} from '_common/services/api/authority';
import ActionItem from './ActionsItem';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { setObjectPreview } from '../ObjectPreview/ObjectPreviewSlice';
import { openModal } from 'App/ModalContext/utils';
import { notify } from '_common/components/ToastSystem';
import { useDownloadPresentationMutation, useExportPresentationMutation } from 'Presentation/api';
import { useJobs } from '../JobsContext/JobsContext';
import downloadFileFromBlob from '_common/utils/downloadFileFromBlob';
import { getValidZipMime } from 'utils';

const CONVERT_FILE_TO_OPTIONS = {
  'application/pdf': 'doPDF',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doDOC',
  'application/vnd.ms-powerpoint': 'doPPT',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'doPPT',
};

type ActionsToggleProps = {
  selected: ObjectId[];
  current: Objekt;
  disabled?: boolean;
  identity: Table.Identity;
  optionsToShow?: ActionsToShow;
};

const OFFICE_ICONS: Record<string, IconTypes['32']> = {
  Word: 'Word',
  Excel: 'Excel',
  PowerPoint: 'PowerPoint',
};

const ActionsToggle = ({
  selected,
  current,
  disabled,
  identity,
  optionsToShow = {
    rename: true,
    remove: true,
    download: true,
    downloadOriginalFile: true,
    move: true,
    copy: true,
    checkOut: true,
    update: true,
    checkIn: true,
    cancelCheckOut: true,
    copyLink: true,
    changeStatus: true,
    exportDocumentTo: true,
    convertTo: true,
    openElement: true,
    //These were not included in original defaultProps, so set them as false
    exportVeeva: false,
    exportDesktop: false,
    preview: false,
    wopiView: false,
    wopiEdit: false,
  },
}: ActionsToggleProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { addJob } = useJobs();
  const { data: statuses } = useGetElementStatusListQuery();
  const { data: tenantSettings } = useGetTenantSettingsQuery();
  const [exportPresentation] = useExportPresentationMutation();
  const [downloadPresentation] = useDownloadPresentationMutation();
  const statusChangeErrorId = useSelector((state) =>
    isChangeStatusEnabled(state, selected.length === 1 ? selected[0] : ''),
  );
  const appData = useSelector((state) => state.app.data);

  const userId = useSelector((state) => state.auth.userId);
  const actions = useSelector((state) =>
    selectAvailableActions(state, { optionsToShow, selected, current }),
  );
  const selectedObjects = useMemo(() => selected.map((id) => appData[id]), [selected, appData]);

  const isDisabled = disabled || Object.typedKeys(actions).every((elem) => !actions[elem]);

  const { data: currentUser } = useGetCurrentUserQuery();
  const currentTenant = useSelector(selectUserCurrentTenant);

  const { data: links } = useGetLinksQuery(
    { userId: currentUser?.profile.id ?? '', tenant: currentTenant?.name ?? '' },
    { skip: !currentUser?.other.tenants || !currentTenant },
  );

  const hasVeevaIntegration = useMemo(() => {
    return (
      links?.providers &&
      links.providers.length > 0 &&
      links.providers.find(
        (provider) =>
          provider.name.toLocaleLowerCase() === 'veeva' ||
          provider.name.toLocaleLowerCase() === 'jti',
      )
    );
  }, [links]);

  const openElementLabelId = useMemo(() => {
    let type: ObjectType | null = null;

    if (selectedObjects.length > 0) {
      if (
        selectedObjects.every((object, index) =>
          index === 0 ? true : object.type === selectedObjects[index - 1].type,
        )
      ) {
        type = selectedObjects[0].type;
      }
    }

    switch (type) {
      case 'document':
        return 'OPEN_IN_EDITOR';
      case 'dopdf':
      case 'presentation':
        return 'OPEN_IN_REVIEWER';
      default:
        return 'OPEN_DOCUMENT';
    }
  }, [selectedObjects]);

  const openElementTooltipId = useMemo(() => {
    const allowedElements = ['document', 'dopdf', 'presentation'];

    if (selectedObjects.length > 0) {
      if (
        !selectedObjects.every(
          (object) =>
            allowedElements.includes(object.type) &&
            object.status !== 'broken' &&
            object.status !== 'processing',
        )
      ) {
        return 'ONLY_VALID_ELEMENT_FORMATS_WILL_BE_OPENED';
      }
    }
  }, [selectedObjects]);

  const validZipMimeType = useMemo(() => getValidZipMime(selectedObjects[0]), [selectedObjects]);

  const isVeevaExportDisabled = () => {
    const status = statuses?.entities[selectedObjects[0]?.status];
    return !status || (status.id !== 'approved' && status.name !== 'Archived');
  };

  const handleOpenElement = () => {
    if (selectedObjects.length > 0) {
      selectedObjects.forEach((object) => {
        if (object.status !== 'processing' && object.status !== 'broken') {
          switch (object.type) {
            case 'document': {
              navigateToEditor(object.id);
              break;
            }
            case 'dopdf': {
              navigateToPDF(object.id);
              break;
            }
            case 'presentation': {
              navigateToPresentation(object.id);
              break;
            }
            default: {
              break;
            }
          }
        }
      });
    }
  };

  const handlePreview = () => {
    if (selectedObjects.length > 0) {
      dispatch(setObjectPreview({ id: selectedObjects[0].id }));
    }
  };

  const ACTIONS: ActionItem[] = [
    {
      id: 'preview',
      onClick: handlePreview,
      icon: 'ViewBlue',
      text: 'PREVIEW',
    },
    {
      id: 'openElement',
      onClick: handleOpenElement,
      icon: 'Open',
      text: openElementLabelId,
      tooltipMessageId: openElementTooltipId,
    },
    {
      id: 'exportDocumentTo',
      icon: 'Export',
      text: 'EXPORT_TO',
      subMenu: true,
      subActions: [
        selectedObjects[0]?.type !== 'file'
          ? {
              id: 'exportDesktop',
              text: 'DESKTOP',
              onClick: () => {
                const object = selectedObjects[0];
                switch (object.type) {
                  case 'presentation':
                    openModal({
                      modal: 'ExportModal',
                      data: {
                        objectName: object.name,
                        message: 'YOU_ARE_ABOUT_TO_EXPORT_DOPPT',
                        checkboxMessage: 'YES_INCLUDE_TASKS_AS_COMMENTS',
                        onExport: async (withTasks) => {
                          dispatch(
                            setAppLoading({
                              isOpen: true,
                              message: 'EXPORTING_DOCUMENT',
                            }),
                          );
                          const result = await exportPresentation({
                            object_id: object.id,
                            keep_tasks: withTasks,
                          }).unwrap();
                          addJob(result.id, async () => {
                            const blobData = await downloadPresentation({
                              object_id: object.id,
                            }).unwrap();

                            downloadFileFromBlob(blobData, `${object.name}.pptx`);

                            notify({
                              type: 'success',
                              title: 'DOPPT_EXPORTED',
                              message: 'DOPPT_WAS_EXPORTED_AND_DOWNLOADED_EXPORT_MANUALLY',
                              messageValues: {
                                name: `${object.name}.pptx`,
                              },
                              persist: true,
                              footerContent: (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      downloadFileFromBlob(blobData, `${object.name}.pptx`);
                                    }}
                                    testId="download-manually-button"
                                  >
                                    <FormattedMessage id="DOWNLOAD_MANUALLY" />
                                  </Button>
                                </div>
                              ),
                            });
                            dispatch(setAppLoading({ isOpen: false }));
                          });
                        },
                      },
                    });
                    break;
                  case 'dopdf':
                    dispatch(
                      openAndUpdateModal({
                        modal: 'ExportPDFModal',
                        data: {
                          id: selected,
                        },
                      }),
                    );
                    break;
                  default:
                    dispatch(
                      openAndUpdateModal({
                        modal: 'ExportDocumentModal',
                        data: {
                          exportType: 'simple',
                          exporting: false,
                          objectId: selected[0],
                        },
                      }),
                    );
                    break;
                }
              },
            }
          : undefined,
        hasVeevaIntegration
          ? {
              id: 'exportVeeva',
              text: 'Veeva',
              onClick: () => {
                dispatch(
                  openAndUpdateModal({
                    modal: 'PublishVeevaModal',
                    data: {
                      id: selected,
                      explorer: true,
                    },
                  }),
                );
              },
              disabled: isVeevaExportDisabled(),
              tooltipMessageId: 'DOCUMENT_WRONG_STATUS_EXPORT_VEEVA',
            }
          : undefined,
      ],
    },
    {
      id: 'downloadOriginalFile',
      onClick: () => {
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'DOWNLOAD_ORIGINAL_FILE',
              message:
                selectedObjects[0].type === 'document'
                  ? 'DOWNLOAD_ORIGINAL_DOCUMENT_FILE_CONFIRMATION'
                  : selectedObjects[0].type === 'dopdf'
                    ? 'DOWNLOAD_ORIGINAL_DOPDF_FILE_CONFIRMATION'
                    : 'DOWNLOAD_ORIGINAL_PRESENTATION_FILE_CONFIRMATION',
              confirmButtonTextId: 'DOWNLOAD_ORIGINAL_FILE',
              confirmButtonType: 'primary',
              cancelButtonTextId: 'global.cancel',
              actionCode: 'downloadOriginalFile',
              actionValue: {
                objectId: selected[0],
                filename: selectedObjects[0].name,
                objectType: selectedObjects[0].type,
              },
              headerType: 'information',
              cancelButtonShow: true,
            },
          }),
        );
      },
      icon: 'Download',
      text: 'DOWNLOAD_ORIGINAL_FILE',
    },
    {
      id: 'changeStatus',
      onClick: () => {
        dispatch(
          openAndUpdateModal({
            modal: 'ChangeElementStatusModal',
            data: {
              objectId: selected,
              objectType: selectedObjects[0].type,
            },
          }),
        );
      },
      icon: 'ChangeStatus',
      text: 'CHANGE_ELEMENT_STATUS',
      disabled: statusChangeErrorId.errorId !== undefined,
      tooltipMessageId: statusChangeErrorId.errorId,
    },
    {
      id: 'copy',
      onClick: () => {
        dispatch(updateMiniExplorer({ current, operatedObjIds: selected }));
        dispatch(
          openAndUpdateModal({
            modal: 'SaveAsModal',
            data: { current, objectIds: selected, explorer: true, identity },
          }),
        );
      },
      icon: 'CopyTo',
      text: 'storage.actionBar.actions.copyto',
    },
    {
      id: 'move',
      onClick: () => {
        dispatch(updateMiniExplorer({ current, operatedObjIds: selected }));
        dispatch(
          openAndUpdateModal({
            modal: 'MoveModal',
            data: {
              objectId: selected,
              currentId: current?.id,
              sources: selected,
              current,
              identity,
            },
          }),
        );
      },
      icon: 'MoveTo',
      text: 'storage.actionBar.actions.moveto',
    },
    {
      id: 'convertTo',
      onClick: () => {
        dispatch(
          openAndUpdateModal({
            modal: 'ConvertToModal',
            data: {
              objectIds: selected,
            },
          }),
        );
      },
      icon: 'Convert',
      text: 'CONVERT_FILE_TO',
      textValues: {
        newType:
          validZipMimeType || selectedObjects[0]?.mime?.type
            ? // @ts-expect-error types not correct here
              CONVERT_FILE_TO_OPTIONS[validZipMimeType || selectedObjects[0].mime.type]
            : '.ddc',
      },
    },
    {
      id: 'rename',
      onClick: () =>
        dispatch(openAndUpdateModal({ modal: 'RenameObjectModal', data: { objectId: selected } })),
      icon: 'Rename',
      text: 'storage.actionBar.actions.rename',
    },
    {
      id: 'remove',
      onClick: () =>
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'DELETE_ITEM_QUANTITY',
              titleValues: { total: selectedObjects.length },
              message: 'DELETING_THE_SELECTED_ITEM_QUANTITY_WILL_PERMANENTLY_REMOVE_CONFIRM',
              messageValues: { total: selectedObjects.length },
              confirmButtonTextId: 'global.delete',
              confirmButtonType: 'danger',
              cancelButtonTextId: 'global.cancel',
              actionCode: 'deleteObjects',
              actionValue: { objectList: selectedObjects, identity },
              headerType: 'error',
              cancelButtonShow: true,
            },
          }),
        ),
      icon: 'Delete',
      text: 'storage.actionBar.actions.delete',
    },
    {
      id: 'download',
      onClick: () => {
        selectedObjects.forEach((object) =>
          dispatch(
            downloadFile({ objectId: object.id, filename: object.name, objectType: object.type }),
          ),
        );
      },
      icon: 'Download',
      text: 'storage.actionBar.actions.download',
    },
    {
      id: 'checkOut',
      onClick: () =>
        dispatch(
          openAndUpdateModal({
            modal: 'ConfirmationModal',
            data: {
              title: 'storage.modals.checkOut.title',
              message: 'storage.modals.checkOut.message',
              confirmButtonTextId: 'storage.modals.checkOut.button',
              confirmButtonType: 'primary',
              cancelButtonTextId: 'global.cancel',
              actionCode: 'checkOut',
              actionValue: { objectId: selected[0] },
              headerType: 'information',
              cancelButtonShow: true,
            },
          }),
        ),
      icon: 'Checkout',
      text: 'storage.actionBar.actions.checkOut',
    },
    {
      id: 'update',
      onClick: () =>
        dispatch(
          openAndUpdateModal({
            modal: 'CheckInModal',
            data: { operation: 'update', objectId: selected[0] },
          }),
        ),
      icon: 'Update',
      text: 'storage.actionBar.actions.update',
    },
    {
      id: 'checkIn',
      onClick: () =>
        dispatch(
          openAndUpdateModal({
            modal: 'CheckInModal',
            data: { operation: 'checkIn', objectId: selected[0] },
          }),
        ),
      icon: 'CheckIn',
      text: 'storage.actionBar.actions.checkIn',
    },
    {
      id: 'cancelCheckOut',
      onClick: () => {
        dispatch(cancelCheckOut({ objectId: selected[0] }));
      },
      icon: 'CheckoutDelete',
      text: 'storage.actionBar.actions.cancelCheckOut',
    },
    {
      id: 'copyLink',
      onClick: () => {
        const url = `${window.location.origin}/file/${selectedObjects[0].id}`;
        const textarea = document.createElement('textarea');
        textarea.value = url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      },
      icon: 'ElementLinkActive',
      text: 'COPY_LINK',
    },
  ];

  const renderWOPIActions = () => {
    const ALLOWED_WOPI_ACTIONS = ['view', 'edit'];
    const wopi = (selectedObjects[0] as doDOC.File)?.wopi;
    if (wopi) {
      const object = selectedObjects[0];
      return wopi.actions
        .filter(({ name }) => ALLOWED_WOPI_ACTIONS.includes(name))
        .map(({ name, app }) => {
          const onActionClick = () => {
            const wopiAccess = localStorage.getItem(`${userId}-WOPI_ALLOW_ACCESS`);
            if (wopiAccess) {
              window.open(`/extensions/wopi/${object.id}/${app}/${name}`);
            } else {
              dispatch(
                openAndUpdateModal({
                  modal: 'WOPIRedirectionModal',
                  data: { app, action: name, id: object.id },
                }),
              );
            }
          };
          return (
            <ActionItem
              id={`${app}-${name}`}
              key={`${app}-${name}`}
              onClick={onActionClick}
              text={`WOPI_${name.toUpperCase()}`}
              textValues={{ application: app }}
              icon={OFFICE_ICONS[app]}
            />
          );
        });
    }
    return null;
  };
  const { isOpen, popperProps, referenceProps } = usePopper({
    placement: 'bottom-end',
    disabled: isDisabled,
  });

  const previewAction = ACTIONS.find((action) => action.id === 'preview');

  return (
    <>
      <Tooltip
        placement="bottom"
        content={intl.formatMessage({ id: 'global.moreActions' })}
        testId="more-option-tooltip"
      >
        <Toggle
          size="medium"
          variant="standard"
          icon="NavMoreOptions"
          disabled={isDisabled}
          margin="0 0 0 0.5rem"
          isToggled={isOpen}
          testId="more-option-toggle"
          {...referenceProps}
        />
      </Tooltip>
      <Dropdown
        {...popperProps}
        width={
          selectedObjects && selectedObjects[0]?.wopi && selectedObjects.length === 1
            ? '43.125rem'
            : '30rem'
        }
        testId="more-options-dropdown"
      >
        {actions.preview && previewAction && <ActionItem {...previewAction} />}
        {renderWOPIActions()}
        {ACTIONS.filter((action) => {
          if (action.id === 'preview') {
            return false;
          }
          if (
            action.id === 'convertTo' &&
            selectedObjects[0]?.type === 'file' &&
            ((selectedObjects[0]?.mime.type === 'application/pdf' &&
              !tenantSettings?.['product.pdf_reviewer']) ||
              (selectedObjects[0]?.mime.type ===
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' &&
                !tenantSettings?.['product.presentation_reviewer']))
          ) {
            return false;
          }
          if (
            action.id === 'openElement' &&
            ((selectedObjects[0]?.type === 'dopdf' && !tenantSettings?.['product.pdf_reviewer']) ||
              (selectedObjects[0]?.type === 'presentation' &&
                !tenantSettings?.['product.presentation_reviewer']))
          ) {
            return false;
          }
          if (
            action.id === 'exportDocumentTo' &&
            selectedObjects[0]?.type === 'file' &&
            !hasVeevaIntegration
          ) {
            return false;
          }
          return true;
        }).map((action) =>
          actions[action.id] ? <ActionItem key={action.id} {...action} /> : null,
        )}
      </Dropdown>
    </>
  );
};

export default ActionsToggle;

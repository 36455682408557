import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

export class ImageStyles {
  private editorContext: Editor.Context;
  private stylesContext: Editor.Styles.Context;

  constructor(editorContext: Editor.Context, stylesContext: Editor.Styles.Context) {
    this.editorContext = editorContext;
    this.stylesContext = stylesContext;
  }

  getSelectedImageProperties(): Editor.Styles.ImageProperties {
    const properties: Editor.Styles.ImageProperties = {};
    this.editorContext.navigationManager?.scrollIntoSelection();

    const range = EditorSelectionUtils.getRange();

    if (range) {
      const image = EditorDOMUtils.closest(range.commonAncestorContainer, [
        ELEMENTS.ImageElement.TAG,
      ]);

      if (EditorDOMElements.isImageElement(image)) {
        properties.imageWrap = {
          value: image.imageWrap,
        };

        const wrap = image.wrapping;

        properties.margins = {
          top: {
            value: wrap?.td != null ? wrap.td : 0,
          },
          bottom: {
            value: wrap?.bd != null ? wrap.bd : 0,
          },
          left: {
            value: wrap?.ld != null ? wrap.ld : 0,
          },
          right: {
            value: wrap?.rd != null ? wrap.rd : 0,
          },
        };
      }
    }

    return properties;
  }
}

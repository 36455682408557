import { ELEMENTS } from 'Editor/services/consts';

import './SectionElement.module.css';

export class SectionElement extends HTMLElement {
  static get observedAttributes() {
    return [];
  }

  connectedCallback() {}

  disconnectedCallback() {}

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    // Commented to respect no-empty rule
    // if (oldValue !== newValue) {
    // }
  }

  isEmpty() {
    return !this.childNodes.length;
  }

  get section(): string | null {
    return this.getAttribute('section');
  }

  set section(value: string | null) {
    if (value != null) {
      this.setAttribute('section', value);
    } else {
      this.removeAttribute('section');
    }
  }
}

if (!window.customElements.get(ELEMENTS.SectionElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.SectionElement.IDENTIFIER, SectionElement);
}

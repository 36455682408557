import './TrackInsertElement.module.scss';
import EditorManager from 'Editor/services/EditorManager';
import UserColors from 'Editor/services/UserColors/UserColors';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import ReduxInterface from 'Editor/services/ReduxInterface/ReduxInteface';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { BaseViewElement } from '../../BaseViewBuilder';
import { TrackDeleteElement } from '../../TrackDeleteViewBuilder';

const ATTRIBUTES = {
  AUTHOR: 'author',
  USER: 'user',
  SELECTED: 'selected',
  REPLACEWITH: 'replacewith',
  REPLACEWITHSIBLING: 'replacewithsibling',
  DISABLED: 'disabled',
};

export class TrackInsertElement extends BaseViewElement {
  protected setBackgroundTimeout?: NodeJS.Timeout;

  constructor() {
    super();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  static get observedAttributes() {
    return [
      ATTRIBUTES.AUTHOR,
      ATTRIBUTES.SELECTED,
      ATTRIBUTES.REPLACEWITH,
      ATTRIBUTES.REPLACEWITHSIBLING,
      ATTRIBUTES.DISABLED,
      ATTRIBUTES.USER,
    ];
  }

  get hidden() {
    return this.getAttribute(ATTRIBUTES.DISABLED) === 'true';
  }

  setHidden(value: boolean) {
    if (value) {
      this.setAttribute('disabled', 'true');
    } else {
      this.removeAttribute('disabled');
    }
    return this;
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.handleOnClick);

    if (!this.preRendered) {
      this.preRender();
    }
  }

  preRender() {
    this.handleAttributeReplace();

    this.setUserColor();

    // this needs to happen in rendering to apply background on tracked block elements
    this.setBackground();

    super.preRender();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click', this.handleOnClick);
    if (this.setBackgroundTimeout) {
      clearTimeout(this.setBackgroundTimeout);
    }
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.hidden && name !== ATTRIBUTES.DISABLED) {
      return;
    }
    if (document.contains(this) && oldValue !== newValue) {
      switch (name) {
        case ATTRIBUTES.DISABLED:
          this.handleChangeDisabled();
          break;
        case ATTRIBUTES.AUTHOR:
        case ATTRIBUTES.USER:
          this.setUserColor();
          break;

        case ATTRIBUTES.SELECTED:
          this.setBackground();
          break;

        case ATTRIBUTES.REPLACEWITH:
        case ATTRIBUTES.REPLACEWITHSIBLING:
          this.handleAttributeReplace();
          break;

        default:
          break;
      }
    }
  }

  get displayType() {
    // return INLINE / BLOCK
    const parentNode = this.parentNode as HTMLElement;
    return parentNode === EditorDOMUtils.getContentContainer(this) ||
      EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(parentNode?.tagName)
      ? DISPLAY_TYPES.BLOCK
      : DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return !this.isParagraphMarker();
  }

  get isDeletable() {
    return true;
  }

  get isContentWrapper() {
    return this.parentNode === EditorDOMUtils.getContentContainer(this);
  }

  get isSelectable() {
    return true;
  }

  get isContainerElement() {
    return this.isBlock;
  }

  get author() {
    return this.getAttribute('author') || this.getAttribute('user');
  }

  private handleChangeDisabled() {
    if (this.hidden) {
      const color = '#d5d5db';
      this.style.color = color;
      this.style.textDecorationColor = color;
      this.style.setProperty('--suggestionColor', color);
      this.style.boxShadow = `0 0 0 0`;
    } else {
      this.setBackground();
    }
  }

  private handleAttributeReplace() {
    if (this.isParagraphMarker()) {
      if (this.hasAttribute('contenteditable')) {
        this.removeAttribute('contanteditable');
      }

      // clean children
      let i;
      for (i = 0; i < this.childNodes.length; i++) {
        this.removeChild(this.childNodes[i]);
      }
    }
  }

  /**
   *
   * @param {Event} event
   */
  handleOnClick(event: Event) {
    event.stopPropagation();
    if (this.isParagraphMarker() && !this.selected) {
      EditorManager.getInstance().setSelectionInTrackedAction(this);
    }
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(val) {
    if (val) {
      this.setAttribute('selected', 'true');
    } else {
      this.removeAttribute('selected');
    }
  }

  get elementReference() {
    return this.getAttribute('element_reference');
  }

  selectElement() {
    if (!this.hidden) {
      this.selected = true;
    }
  }

  deselectElement() {
    this.selected = false;
  }

  setEditable(value: string) {
    this.setAttribute('contenteditable', value);
  }

  setUserColor() {
    const author = this.author;
    if (author) {
      const color = UserColors.getUserColor(author)?.base;
      if (color) {
        this.style.color = color;
        this.style.setProperty('--suggestionColor', color);
      }
    }
  }

  reRenderBackground() {
    this.setBackground();
  }

  setBackground() {
    if (this.setBackgroundTimeout) {
      clearTimeout(this.setBackgroundTimeout);
    }

    this.setBackgroundTimeout = setTimeout(() => {
      const isSelected = this.hasAttribute('selected') && this.getAttribute('selected') === 'true';
      if (this.author) {
        const colors = UserColors.getUserColor(this.author);
        const selectedColor = colors && colors.tint30;
        const unselectedColor = colors && `${colors.base}26`;

        const computedStyle = window.getComputedStyle(this);

        const lineHeight = parseInt(computedStyle.lineHeight, 10);

        this.setUserColor();

        const parentNode = this.parentNode as HTMLElement;

        if (
          parentNode === EditorDOMUtils.getContentContainer(this) ||
          EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(parentNode?.tagName)
        ) {
          const firstChild = this.firstChild as HTMLElement;
          if (firstChild) {
            if (firstChild.tagName === ELEMENTS.TableElement.TAG) {
              firstChild.style.boxShadow = `-1rem 0px 0px ${
                isSelected ? selectedColor : unselectedColor
              }, 1rem 0px 0px ${isSelected ? selectedColor : unselectedColor} ${
                isSelected
                  ? ', 0px 8px 8px rgba(0, 0, 0, 0.25), 16px 0px 8px -8px rgba(0, 0, 0, 0.25), -16px 0px 8px -8px rgba(0, 0, 0, 0.25)'
                  : ''
              }`;
              if (isSelected) {
                this.style.setProperty('--suggestionColor', 'initial');
              }
            } else if (firstChild.tagName === ELEMENTS.FigureElement.TAG) {
              const image = this.querySelector(ELEMENTS.ImageElement.TAG) as HTMLElement;
              if (image) {
                image.dataset.boxShadow = `-1rem 0px 0px ${
                  isSelected ? selectedColor : unselectedColor
                }, 1rem 0px 0px ${isSelected ? selectedColor : unselectedColor} ${
                  isSelected
                    ? ', 0px 8px 8px rgba(0, 0, 0, 0.25), 16px 0px 8px -8px rgba(0, 0, 0, 0.25), -16px 0px 8px -8px rgba(0, 0, 0, 0.25)'
                    : ''
                }`;

                if (isSelected) {
                  this.style.setProperty('--suggestionColor', 'initial');
                }
              }
            } else if (isSelected) {
              firstChild.style.boxShadow = `inset var(--contentWidth) 0px 0px ${selectedColor}, -1rem 0px 0px ${selectedColor}, 1rem 0px 0px ${selectedColor}, 0px 8px 8px rgba(0, 0, 0, 0.25)`;
              this.style.setProperty('--suggestionColor', 'initial');
            } else {
              firstChild.style.boxShadow = `inset var(--contentWidth) 0px 0px ${unselectedColor}, -1rem 0px 0px ${unselectedColor}, 1rem 0px 0px ${unselectedColor}`;
              this.style.setProperty('--suggestionColor', 'initial');
            }
          } else if (isSelected) {
            this.style.boxShadow = `inset var(--contentWidth) 0px 0px ${selectedColor}, -2rem 0px 0px ${selectedColor}, 2rem 0px 0px ${selectedColor}, 0px 8px 8px rgba(0, 0, 0, 0.25)`;
            this.style.setProperty('--suggestionColor', 'initial');
          } else {
            this.style.boxShadow = `0 0 0 0`;
          }
        } else if (isSelected) {
          const bounds = this.getClientRects();

          if (bounds.length === 0) {
            return;
          }

          const boxShadowLength = (lineHeight - bounds[0].height) / 2;
          const zoomValue = ReduxInterface.getZoomValue();
          const height = bounds[0].height / zoomValue;
          this.style.boxShadow = `inset 0px -${Math.min(
            height,
            lineHeight,
          )}px 0px ${selectedColor} ${
            boxShadowLength >= 0
              ? `, 0px ${Math.min(boxShadowLength, 5)}px 0px ${selectedColor}, 0px -${Math.min(
                  boxShadowLength,
                  5,
                )}px 0px 0px ${selectedColor}`
              : ''
          }, 0px 8px 8px -4px rgba(0, 0, 0, 0.25)`;

          const extractedStyles: any = StylesUtils.getStylesAppliedToNode(this, ['color']);
          this.style.color = extractedStyles.color;
          this.style.setProperty('--suggestionColor', 'initial');
        } else {
          this.style.boxShadow = '0 0 0 0';
        }
      }
    }, 50);
  }

  styleChilds(background: string) {
    for (let index = 0; index < this.children.length; index++) {
      (this.children[index] as HTMLElement).style.backgroundColor = `${background}`;
    }
  }

  isEqual(node: HTMLElement) {
    return (
      this.tagName === node.tagName &&
      this.getAttribute('element_reference') === node.getAttribute('element_reference')
    );
  }

  hasChildParagraphMarker(): boolean {
    if (
      this.lastChild instanceof TrackInsertElement ||
      this.lastChild instanceof TrackDeleteElement
    ) {
      return this.lastChild.isParagraphMarker() || this.lastChild.hasChildParagraphMarker();
    }
    return false;
  }

  isParagraphMarker() {
    return (
      this.hasAttribute(ATTRIBUTES.REPLACEWITH) || this.hasAttribute(ATTRIBUTES.REPLACEWITHSIBLING)
    );
  }

  isMergeable() {
    return !this.isParagraphMarker();
  }

  isEmpty() {
    if (this.textContent === '') {
      if (
        this.querySelectorAll([ELEMENTS.FigureElement.TAG, ELEMENTS.TableElement.TAG].join(','))
          .length
      ) {
        return false;
      }
      return true;
    }
    return false;
  }
}

// register element
if (!window.customElements.get(ELEMENTS.TrackInsertElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.TrackInsertElement.IDENTIFIER, TrackInsertElement);
}

import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { StylesHandler } from 'Editor/services/Styles';
import { Parser } from './Parser';

export class PlainTextParser extends Parser {
  constructor(
    data: string,
    dataManager: Editor.Data.API,
    stylesHandler: StylesHandler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    super(data, dataManager, stylesHandler, visualizerManager);
    this.data = data;
    this.openPasteOptions = false;
  }

  async parse() {
    this.html = document.createElement('html');
    this.container = document.createElement('div');

    this.debugMessage('PlainTextParser Parsing...', this.data);
    this.html.innerHTML = this.data as string;
    const body = this.html.querySelector('body');

    if (body) {
      const text = body.innerHTML;
      const createNewParagraph = (string: string) => {
        const p = DOMElementFactory.createNewParagraphElement();
        p.appendChild(document.createTextNode(string.trim()));
        if (this.container) {
          this.container.appendChild(p);
        }
      };
      if (text.includes('\n\r')) {
        text.split('\n\r').forEach(createNewParagraph);
      } else if (text.includes('\n')) {
        text.split('\n').forEach(createNewParagraph);
      } else {
        this.container.appendChild(document.createTextNode(text));
      }
      this.isValid = true;
      this.debugMessage('Parsed data', this.container);
    }
  }

  countLines(): number | null {
    return typeof this.data === 'string' ? (this.data.match(/\n/g) || '').length + 1 : null;
  }
}

import { useSelector } from '_common/hooks';

import TableOptionsView from './TableOptionsView';
import TableOptionsProvider from './TableOptionsContext';

type TableOptionsProps = {
  columnWidths: number[];
  rowHeights: number[];
  selection: {
    polygon: [
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
    ][];
    rows: number[];
    columns: number[];
    fullySelected: {
      row: number[];
      column: number[];
    };
  };
  offsets: Rect | null;
  insert: (index: number, selection: Editor.Elements.Table.Selection) => void;
  select: (index: number, selection: Editor.Elements.Table.Selection) => void;
  remove: (index: number[], selection: Editor.Elements.Table.Selection) => void;
  resize: (
    selection: Editor.Elements.Table.Selection,
    params: { [index: number | string]: { current: number; delta: number } },
  ) => void;
};

const TableOptions = ({
  columnWidths,
  rowHeights,
  selection,
  offsets,
  insert,
  select,
  remove,
  resize,
}: TableOptionsProps) => {
  const zoom = useSelector((state) => state.editor.status.zoom);

  const heights = rowHeights.map((h) => h / zoom);
  const widths = columnWidths.map((w) => w / zoom);

  return (
    <TableOptionsProvider>
      <TableOptionsView
        widths={widths}
        heights={heights}
        columnWidths={columnWidths}
        rowHeights={rowHeights}
        selection={selection}
        offsets={offsets}
        insert={insert}
        select={select}
        remove={remove}
        resize={resize}
      />
    </TableOptionsProvider>
  );
};

export default TableOptions;

import {
  ForwardedRef,
  MouseEventHandler,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import RichTextEditor, {
  RichTextEditorProps,
} from '_common/components/RichTextEditor/RichTextEditor';

import { useIsNodeClamped } from '_common/hooks';

import styles from './ViewRichTextEditor.module.scss';

type ViewRichTextEditorProps = Omit<
  RichTextEditorProps,
  | 'readOnly'
  | 'expanded'
  | 'onChange'
  | 'onFocus'
  | 'onSelectionChanged'
  | 'placeholder'
  | 'skipFocus'
  | 'singleLine'
  | 'mentionableUsers'
> & {
  dependencies: React.DependencyList;
  moreLabel?: TranslationMessage;
  lessLabel?: TranslationMessage;
};

const ViewRichTextEditor = forwardRef(
  (
    { dependencies, moreLabel, lessLabel, ...props }: ViewRichTextEditorProps,
    ref: ForwardedRef<RichTextEditor.RichTextEditorHandler>,
  ) => {
    const mainEditorRef = useRef<RichTextEditor.RichTextEditorHandler>(null);

    const current = ref
      ? typeof ref === 'function'
        ? ref(mainEditorRef.current)
        : ref?.current
      : mainEditorRef.current;

    const isClamped = useIsNodeClamped({
      ref: current?.editorRef,
      clamp: props.maxLines ?? 3,
      dependencies: [dependencies, current],
    });

    const [readMore, setReadMore] = useState(true);

    const more = useMemo<TranslationMessage>(() => {
      return moreLabel ?? { id: 'editor.comments.readMore' };
    }, [moreLabel]);

    const less = useMemo<TranslationMessage>(() => {
      return lessLabel ?? { id: 'editor.comments.readLess' };
    }, [lessLabel]);

    const handleToggleReadMore: MouseEventHandler<HTMLDivElement> = (e) => {
      /**
       * Stop propagation to avoid card selection
       * Card selection will invoke scrollIntoView and app might pause to load new content
       */
      e.stopPropagation();

      setReadMore(!readMore);
    };

    useLayoutEffect(() => {
      if (!isClamped) {
        setReadMore(false);
      }
    }, [isClamped]);

    return (
      <>
        <RichTextEditor {...props} readOnly expanded={readMore} ref={ref ?? mainEditorRef} />
        {isClamped && (
          <div className={styles.readMore} onClick={handleToggleReadMore}>
            {readMore ? <FormattedMessage {...less} /> : <FormattedMessage {...more} />}
          </div>
        )}
      </>
    );
  },
);

export default ViewRichTextEditor;

import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '../BaseViewBuilder';

export class RedactedViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: () => {},
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.RedactedElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // enclosed_element
    enclosed_element: this.GENERIC_ATTRIBUTE_MAPPER.enclosed_element,
    // section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return false;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const node = DOMElementFactory.buildElement(ELEMENTS.RedactedElement.TAG);

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}

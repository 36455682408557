 
 
import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import { NodeDataBuilder } from 'Editor/services/DataManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { notify } from '_common/components/ToastSystem';
import { EditorRange, EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class RemoveContentHandler extends superclass {
      _splitTableCells(range) {
        return range.getNodes([1], (node) => {
          return node.tagName === ELEMENTS.TableCellElement.TAG;
        });
      }

      _fixBoundaries(thisPage = this.page) {
        //* Fix Selection
        const selection = EditorSelectionUtils.getSelection();
        if (selection.focusNode === thisPage) {
          let focusOffset = selection.focusOffset;
          let caretPosition = 'INSIDE_START';

          if (focusOffset >= selection.focusNode.childNodes.length) {
            focusOffset -= 1;
            caretPosition = 'INSIDE_END';
          }
          this.selectionManager.setRangeEnd(
            selection.focusNode.childNodes[focusOffset],
            caretPosition,
          );
        }
        if (selection.anchorNode === thisPage) {
          const anchorOffset = selection.anchorOffset;
          const caretPosition = 'INSIDE_START';
          this.selectionManager.setRangeStart(
            selection.anchorNode.childNodes[anchorOffset],
            caretPosition,
          );
        }
      }

      _removeBlockNode(actionContext, blockNode, baseContainer) {
        if (actionContext != null && blockNode != null) {
          if (!baseContainer) {
            baseContainer =
              EditorDOMUtils.closestMultiBlockContainerElement(blockNode) ||
              EditorDOMUtils.getContentContainer(blockNode);
          }

          let block = blockNode;
          if (block?.parentNode !== baseContainer) {
            block = EditorDOMUtils.findFirstLevelChildNode(baseContainer, block);
          }

          if (
            block?.previousSibling.tagName === ELEMENTS.TableElement.TAG &&
            block?.nextSibling.tagName === ELEMENTS.TableElement.TAG
          ) {
            const pageNode = EditorDOMUtils.getContentContainer(blockNode);
            if (block?.parentNode === pageNode) {
              const data = NodeDataBuilder.buildParagraph({
                parent_id: pageNode.id,
              });
              this.inserBlockNodeOperation(actionContext, data, block, 'BEFORE');
            } else {
              const paragraph = DOMElementFactory.createNewParagraphElement();
              EditorDOMUtils.insertNodeBefore(pageNode, paragraph, block);
              actionContext.addChangeAddedNode(paragraph);
            }
          }

          this.removeBlockElementFromDOM(actionContext, block);
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       */
      removeSelectionContent(actionContext) {
        try {
          let range = EditorSelectionUtils.getRange();

          if (!range.collapsed) {
            // validate confirmation to delete captions
            if (!actionContext.confirmDeleteCaption) {
              const captions = EditorSelectionUtils.getElementsFromRange(
                range,
                [ELEMENTS.FieldElement.TAG],
                (node, isAncestor) => {
                  if (isAncestor) {
                    return (
                      node.isTypeCaption() &&
                      EditorSelectionUtils.isSelectionAtStart(node, 'start', range) &&
                      EditorSelectionUtils.isSelectionAtEnd(node, 'end', range)
                    );
                  }
                  return node.isTypeCaption();
                },
              );

              if (captions.length > 0) {
                ReduxInterface.openDeleteCaptionConfirmationModal();
                return false;
              }
            }

            let startMarkerLevel0;
            let endMarkerLevel0;

            EditorSelectionUtils.fixSelection(range);

            const closestContainer =
              EditorDOMUtils.closestMultiBlockContainerElement(range.commonAncestorContainer) ||
              EditorDOMUtils.getContentContainer(range.commonAncestorContainer);

            startMarkerLevel0 = EditorDOMUtils.findFirstLevelChildNode(
              closestContainer,
              range.startContainer,
            );
            endMarkerLevel0 = EditorDOMUtils.findFirstLevelChildNode(
              closestContainer,
              range.endContainer,
            );

            if (startMarkerLevel0 !== endMarkerLevel0) {
              //* Multi block deletion
              // this._fixBoundaries();
              return this.multiBlockRemotion(actionContext);
            }
            //* Single block deletion
            return this.singleBlockRemotion(actionContext, closestContainer, range, true);
          } else {
            return true;
          }
        } catch (error) {
          Logger.captureException(error);
        }

        return false;
      }

      /**
       * @param {ActionContext} actionContext
       */
      singleBlockRemotion(
        actionContext,
        baseContainer = this.page,
        range = EditorSelectionUtils.getRange(),
        setCaret = false,
      ) {
        let returnValue = false;
        if (EditorSelectionUtils.isCurrentSelectionDeletable(range)) {
          this._fixBoundaries(baseContainer);

          const blockNode = EditorDOMUtils.findFirstLevelChildNode(
            baseContainer,
            range.commonAncestorContainer,
          );
          const ancestorTracked = EditorDOMUtils.closest(blockNode, [
            ELEMENTS.TrackInsertElement.TAG,
            ELEMENTS.TrackDeleteElement.TAG,
          ]);

          let savedRange;

          try {
            let elementsToCheck = [
              ...EditorDOMElements.BLOCK_TEXT_ELEMENTS,
              ...EditorDOMElements.BLOCK_NON_EDITABLE_ELEMENTS,
              ELEMENTS.FigureElement.TAG,
              ELEMENTS.TableElement.TAG,
              ELEMENTS.ReferencesSectionElement.TAG,
              ELEMENTS.TableOfLabelsElement.TAG,
            ];
            let closestElement = EditorDOMUtils.closest(
              range.commonAncestorContainer,
              elementsToCheck,
            );

            // check double state elements
            while (closestElement && !closestElement.isBlock) {
              closestElement = EditorDOMUtils.closest(closestElement.parentNode, elementsToCheck);
            }

            if (
              closestElement &&
              EditorDOMElements.BLOCK_TEXT_ELEMENTS.includes(closestElement?.tagName)
            ) {
              // text elements

              savedRange = range.saveRange();

              range = EditorSelectionUtils.getRangeBasedOnSavedMarkers(savedRange);

              const fullExtractRangeList = EditorRange.getTrackInsertOnRange(range);
              let i;
              for (i = fullExtractRangeList.length - 1; i >= 0; i--) {
                if (
                  this.isUserAuthor(fullExtractRangeList[i].track) &&
                  fullExtractRangeList[i].track !== ancestorTracked
                ) {
                  const trackElement = fullExtractRangeList[i].track;
                  const trackRange = fullExtractRangeList[i].range;
                  const insertReference = trackElement.getAttribute('element_reference');

                  const startMarker = trackElement.querySelector(
                    `[id="${savedRange.startMarkerId}"]`,
                  );

                  const endMarker = trackElement.querySelector(`[id="${savedRange.endMarkerId}"]`);

                  actionContext.addReferenceToRefresh(insertReference);

                  // const closestCitationGroup = DOMUtils.closest(
                  //   trackRange.commonAncestorContainer,
                  //   ELEMENTS.CitationsGroupElement.TAG,
                  // );
                  // if (closestCitationGroup) {
                  //   // remove citation elements

                  //   if (
                  //     closestCitationGroup.getCitationsCount &&
                  //     closestCitationGroup.getCitationsCount() <= 1
                  //   ) {
                  //     // unwrap markers
                  //     if (startMarker) {
                  //       closestCitationGroup.parentNode.insertBefore(
                  //         startMarker,
                  //         closestCitationGroup,
                  //       );
                  //     }
                  //     if (endMarker) {
                  //       if (closestCitationGroup.nextSibling) {
                  //         closestCitationGroup.parentNode.insertBefore(
                  //           endMarker,
                  //           closestCitationGroup.nextSibling,
                  //         );
                  //       } else {
                  //         closestCitationGroup.parentNode.appendChild(endMarker);
                  //       }
                  //     }

                  //     closestCitationGroup.remove();
                  //   } else {
                  //     // unwrap markers
                  //     if (startMarker) {
                  //       trackElement.parentNode.insertBefore(startMarker, trackElement);
                  //     }
                  //     if (endMarker) {
                  //       if (trackElement.nextSibling) {
                  //         trackElement.parentNode.insertBefore(endMarker, trackElement.nextSibling);
                  //       } else {
                  //         trackElement.parentNode.appendChild(endMarker);
                  //       }
                  //     }

                  //     if (
                  //       trackElement.parentNode === closestCitationGroup &&
                  //       closestCitationGroup.removeCitationElement
                  //     ) {
                  //       closestCitationGroup.removeCitationElement(trackElement);
                  //     } else {
                  //       trackRange.deleteContents();
                  //     }
                  //   }
                  // } else {

                  // }

                  // unwrap markers
                  if (
                    startMarker &&
                    trackElement.contains(startMarker) &&
                    EditorDOMUtils.isAtStartOfNode(trackElement, startMarker) &&
                    trackElement.isEmpty()
                  ) {
                    trackElement.parentNode.insertBefore(startMarker, trackElement);
                  }

                  if (
                    endMarker &&
                    trackElement.contains(endMarker) &&
                    EditorDOMUtils.isAtEndOfNode(trackElement, endMarker) &&
                    trackElement.isEmpty()
                  ) {
                    if (trackElement.nextSibling) {
                      trackElement.parentNode.insertBefore(endMarker, trackElement.nextSibling);
                    } else {
                      trackElement.parentNode.appendChild(endMarker);
                    }
                  }

                  trackRange.deleteContents();

                  // remove element if its empty
                  if (trackElement.isEmpty()) {
                    trackElement.remove();
                  }
                }
              }

              // normalize text nodes to avoid invalid ranges
              closestElement.normalize();

              range = EditorSelectionUtils.getRangeBasedOnSavedMarkers(savedRange);

              // split non editables
              const elementsToSplit = [
                ...EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
                ...EditorDOMElements.INLINE_WRAP_ELEMENTS,
                ELEMENTS.TrackInsertElement.TAG,
              ];
              const splitedRanges = EditorRange.splitRangeByInlineElements(
                closestElement,
                range,
                elementsToSplit,
              );

              let j;
              for (j = splitedRanges.length - 1; j >= 0; j--) {
                const rangeElement = splitedRanges[j].element;
                let rangeToDelete = splitedRanges[j].range;

                // if (rangeElement.element?.tagName === ELEMENTS.CitationsGroupElement.TAG) {
                //   // CITATION ELEMENTS
                //   this.removeRangeFromCitations(actionContext, splitedRanges[j].range);
                // } else {
                if (
                  EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(rangeElement?.tagName) &&
                  !rangeElement?.isEditable
                ) {
                  const closestCitationGroup = EditorDOMUtils.closest(rangeElement, [
                    ELEMENTS.CitationsGroupElement.TAG,
                  ]);

                  rangeToDelete = EditorSelectionUtils.createNewRange();
                  if (closestCitationGroup) {
                    rangeToDelete.selectNode(closestCitationGroup);
                  } else {
                    rangeToDelete.selectNode(rangeElement);
                  }
                }

                const nodesToFilter = Array.from(rangeToDelete?.getNodes());

                const nodesValidator = (node) => node.tagName === ELEMENTS.TrackDeleteElement.TAG;

                if (
                  nodesToFilter.length > 0 &&
                  !nodesToFilter.every(nodesValidator) &&
                  !EditorDOMUtils.closest(rangeToDelete?.commonAncestorContainer, [
                    ELEMENTS.TrackDeleteElement.TAG,
                  ])
                ) {
                  this.addDeleteSuggestionOnRange(actionContext, rangeToDelete, savedRange);
                }
                // }
              }

              if (closestElement.checkEmptyContent) {
                closestElement.checkEmptyContent();
              }

              range.restoreRange(savedRange);
              if (setCaret) {
                EditorSelectionUtils.applyRangeToSelection(range);
              }

              if (savedRange) {
                EditorRange.removeLostMarkers(savedRange);
                savedRange = null;
              }

              actionContext.addChangeUpdatedNode(closestElement);

              if (setCaret) {
                this.setCursorPosition(
                  actionContext.getAllSuggestionsRefs(),
                  actionContext.collapseDirection,
                  range,
                );
              }
            } else if (
              closestElement &&
              [
                ELEMENTS.FigureElement.TAG,
                ELEMENTS.TableElement.TAG,
                ELEMENTS.ReferencesSectionElement.TAG,
                ELEMENTS.TableOfLabelsElement.TAG,
              ].includes(closestElement?.tagName) &&
              baseContainer.contains(closestElement)
            ) {
              // Figures
              let expandRange = EditorSelectionUtils.createNewRange();
              expandRange.setStartBefore(closestElement);
              expandRange.setEndAfter(closestElement);

              if (expandRange.getNodes().length > 0) {
                if (ancestorTracked && this.isUserAuthor(ancestorTracked)) {
                  actionContext.addReferenceToRefresh(
                    ancestorTracked.getAttribute('element_reference'),
                  );
                  this._removeBlockNode(actionContext, blockNode, baseContainer);
                } else {
                  this.addDeleteSuggestionOnRange(actionContext, expandRange);

                  if (setCaret) {
                    this.setCursorPosition(
                      actionContext.getAllSuggestionsRefs(),
                      actionContext.collapseDirection,
                      range,
                    );
                  }
                }
              }
            } else if (
              closestElement &&
              EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS.includes(closestElement?.tagName) &&
              !EditorDOMUtils.closest(
                range.commonAncestorContainer,
                EditorDOMUtils.EDITABLE_LEVEL0_ELEMENTS,
              )
            ) {
              // non editable elements

              if (blockNode) {
                if (blockNode.tagName === ELEMENTS.TrackInsertElement.TAG) {
                  // track insert element
                  if (this.isUserAuthor(blockNode)) {
                    actionContext.addReferenceToRefresh(
                      blockNode.getAttribute('element_reference'),
                    );
                    this._removeBlockNode(actionContext, blockNode, baseContainer);
                  } else {
                    let expandRange = EditorSelectionUtils.createNewRange();
                    expandRange.setStartBefore(closestElement);
                    expandRange.setEndAfter(closestElement);

                    this.addDeleteSuggestionOnRange(actionContext, expandRange);
                  }
                } else {
                  let expandRange = EditorSelectionUtils.createNewRange();
                  expandRange.setStartBefore(closestElement);
                  expandRange.setEndAfter(closestElement);

                  this.addDeleteSuggestionOnRange(actionContext, expandRange);
                }
              }
            } else if (
              blockNode.tagName === ELEMENTS.TrackInsertElement.TAG &&
              EditorSelectionUtils.isSelectionAtStart(blockNode) &&
              EditorSelectionUtils.isSelectionAtEnd(blockNode)
            ) {
              // if is tracked block element and all content is selected
              if (this.isUserAuthor(blockNode)) {
                actionContext.addReferenceToRefresh(blockNode.getAttribute('element_reference'));
                this._removeBlockNode(actionContext, blockNode, baseContainer);
              } else {
                // tracked block was inserted by other user
                this.addDeleteSuggestionOnRange(actionContext, range);
              }
            }
            returnValue = true;
          } catch (error) {
            Logger.captureException(error);
          } finally {
            if (savedRange) {
              EditorRange.removeLostMarkers(savedRange);
              savedRange = null;
            }
          }
        } else {
          if (this.selectionManager.isCurrentSelectionApproved()) {
            notify({
              type: 'error',
              title: 'CANNOT_DELETE_CONTENT',
              message: 'UNAPPROVE_BEFORE_DELETE',
            });
          }
        }

        return returnValue;
      }

      _handleParagraphMarker(actionContext, containerElement, nodeToHandle, previousNode) {
        let lastChild = previousNode.lastChild;

        // check for spans and other markers
        while (
          lastChild &&
          EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS.includes(lastChild.tagName)
        ) {
          lastChild = lastChild.previousSibling;
        }

        if (!this.isParagraphMarker(lastChild)) {
          // check BR
          // remove BR elements
          const brElements = previousNode.querySelectorAll('BR');
          Array.from(brElements).forEach((br) => {
            br.remove();
          });

          // there is no paragraph marker
          const markParagraph = this.getParagraphMarker('track-del-element', '', {
            [EditorDOMElements.isNodeContainerElement(nodeToHandle.parentNode)
              ? 'replacewithsibling'
              : 'replacewith']: nodeToHandle.id,
          });
          EditorDOMUtils.insertNodeAfter(previousNode, markParagraph, lastChild);
          actionContext.addChangeUpdatedNode(previousNode);
          const suggRef = this.getProperSuggestionRef(actionContext, markParagraph);
          markParagraph.setAttribute('element_reference', suggRef);
        } else if (this.isAddParagraphMarker(lastChild)) {
          const addMarker = lastChild;
          // there is an add paragraph marker
          if (this.isUserAuthor(addMarker)) {
            // remove paragraph marker
            const idToRemove =
              addMarker.getAttribute('replacewith') || addMarker.getAttribute('replacewithsibling');

            actionContext.addReferenceToRefresh(addMarker.getAttribute('element_reference'));

            if (idToRemove === nodeToHandle.id) {
              // check if blocks can be joined
              if (
                previousNode.tagName === ELEMENTS.ParagraphElement.TAG &&
                nodeToHandle.tagName === ELEMENTS.ParagraphElement.TAG
              ) {
                this.joinParagraphContents(actionContext, previousNode, nodeToHandle);
              } else {
                this.removeBlockElementFromDOM(actionContext, nodeToHandle);
              }

              if (addMarker && EditorDOMUtils.parentContainsNode(previousNode, addMarker)) {
                addMarker.remove();
              }

              actionContext.addChangeUpdatedNode(previousNode);

              return true;
            }
          } else {
            // wrap paragraph marker in a delete
            const markParagraph = this.getParagraphMarker('track-del-element', '', {
              [EditorDOMElements.isNodeContainerElement(nodeToHandle.parentNode)
                ? 'replacewithsibling'
                : 'replacewith']: nodeToHandle.id,
            });
            EditorDOMUtils.insertNodeBefore(previousNode, markParagraph, addMarker);
            markParagraph.appendChild(addMarker);
            const suggRef = this.getProperSuggestionRef(actionContext, markParagraph);
            markParagraph.setAttribute('element_reference', suggRef);
            actionContext.addChangeUpdatedNode(previousNode);
          }
        }

        return false;
      }

      _getRangeToDelete(node, startNode, endNode, originalRange) {
        const newRange = EditorSelectionUtils.createNewRange();

        // text elements
        if (node === startNode && node === endNode) {
          newRange.setStart(originalRange.startContainer, originalRange.startOffset);
          newRange.setEnd(originalRange.endContainer, originalRange.endOffset);
        } else if (node === startNode) {
          newRange.setStart(originalRange.startContainer, originalRange.startOffset);

          let lastChild = node;
          while (lastChild.lastChild && !EditorDOMUtils.isNodeNonSelectable(lastChild.lastChild)) {
            lastChild = lastChild.lastChild;
          }

          if (lastChild) {
            const length =
              lastChild.nodeType === Node.ELEMENT_NODE
                ? lastChild.childNodes.length
                : lastChild.length;
            newRange.setEnd(lastChild, length);
          } else {
            newRange.setEnd(node, node.childNodes.length);
          }
        } else if (node === endNode) {
          let firstChild = node;
          while (
            firstChild.firstChild &&
            !EditorDOMUtils.isNodeNonSelectable(firstChild.firstChild)
          ) {
            firstChild = firstChild.firstChild;
          }

          newRange.setStart(firstChild, 0);

          if (node.contains(originalRange.endContainer)) {
            // check if original range was not deleted already
            const endContainerLength =
              originalRange.endContainer.nodeType === Node.ELEMENT_NODE
                ? originalRange.endContainer.childNodes.length
                : originalRange.endContainer.length;
            const endOffset =
              originalRange.endOffset <= endContainerLength
                ? originalRange.endOffset
                : endContainerLength;

            newRange.setEnd(originalRange.endContainer, endOffset);
          } else {
            const nodeLength =
              node.nodeType === Node.ELEMENT_NODE ? node.childNodes.length : node.length;
            newRange.setEnd(node, nodeLength);
          }
        } else {
          let firstChild = node;
          while (
            firstChild.firstChild &&
            !EditorDOMUtils.isNodeNonSelectable(firstChild.firstChild)
          ) {
            firstChild = firstChild.firstChild;
          }

          newRange.setStart(firstChild, 0);

          let lastChild = node;
          while (lastChild.lastChild && !EditorDOMUtils.isNodeNonSelectable(lastChild.lastChild)) {
            lastChild = lastChild.lastChild;
          }

          if (lastChild) {
            const length =
              lastChild.nodeType === Node.ELEMENT_NODE
                ? lastChild.childNodes.length
                : lastChild.length;
            newRange.setEnd(lastChild, length);
          } else {
            newRange.setEnd(node, node.childNodes.length);
          }
        }

        return newRange;
      }

      /**
       *
       * @param {ActionContext} actionContext
       */
      multiBlockRemotion(actionContext, thisPage = this.page) {
        let returnValue = false;

        let savedRange;

        try {
          //* mark selection

          let range = EditorSelectionUtils.getRange();

          savedRange = range.saveRange();

          range = EditorSelectionUtils.getRangeBasedOnSavedMarkers(savedRange);

          let rangeList = [];

          // check nodes inside tables
          if (
            EditorDOMUtils.closest(range.commonAncestorContainer, [
              ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG,
            ])
          ) {
            rangeList = EditorRange.splitRangeByTableCells(range);
          } else {
            rangeList.push(range.cloneRange());
          }

          let i;
          for (i = 0; i < rangeList.length; i++) {
            // get reference parent, a table cell or the page
            const referenceContainer =
              EditorDOMUtils.closestMultiBlockContainerElement(
                rangeList[i].commonAncestorContainer,
              ) || thisPage;

            let startBlock;
            if (referenceContainer === rangeList[i].startContainer) {
              const index =
                rangeList[i].startOffset < referenceContainer.childNodes.length
                  ? rangeList[i].startOffset
                  : rangeList[i].startOffset - 1;
              startBlock = referenceContainer.childNodes[index];
            } else {
              startBlock = EditorDOMUtils.findFirstLevelChildNode(
                referenceContainer,
                rangeList[i].startContainer,
              );
            }

            let endBlock;
            if (referenceContainer === rangeList[i].endContainer) {
              const index =
                rangeList[i].endOffset < referenceContainer.childNodes.length
                  ? rangeList[i].endOffset
                  : rangeList[i].endOffset - 1;
              endBlock = referenceContainer.childNodes[index];
            } else {
              endBlock = EditorDOMUtils.findFirstLevelChildNode(
                referenceContainer,
                rangeList[i].endContainer,
              );
            }

            // get track insert elements
            const fullExtractRangeList = EditorRange.getTrackInsertOnRangeV2(rangeList[i]);
            let j;
            for (j = fullExtractRangeList.length - 1; j >= 0; j--) {
              // remove track insert level 0
              if (
                this.isUserAuthor(fullExtractRangeList[j].track) &&
                fullExtractRangeList[j].track.parentNode === this.page
              ) {
                const insertReference =
                  fullExtractRangeList[j].track.getAttribute('element_reference');
                actionContext.addReferenceToRefresh(insertReference);

                // remove element if its empty
                this._removeBlockNode(actionContext, fullExtractRangeList[j].track);
              }
            }

            const rangesToDelete = EditorRange.splitRangeByBlocks(
              rangeList[i],
              [...EditorDOMElements.DELETABLE_LEVEL0_ELEMENTS],
              { includeCellSelection: false },
            );

            // handle blocks from start to end
            if (startBlock && endBlock && startBlock.parentNode === endBlock.parentNode) {
              let workingNode;
              let previousBlock;
              let deleteRange = EditorSelectionUtils.createNewRange();

              // for (
              //   workingNode = startBlock;
              //   workingNode &&
              //   workingNode !== endBlock.nextSibling &&
              //   DOMUtils.parentContainsNode(referenceContainer, endBlock);
              //   workingNode = workingNode.nextSibling
              // ) {
              for (let i = 0; i < rangesToDelete.length; i++) {
                workingNode = rangesToDelete[i].block;

                // iterate throw all block elements
                if (EditorDOMUtils.isClosestTextElementEditable(workingNode)) {
                  // text elements

                  deleteRange = rangesToDelete[i].range;

                  // this.addDeleteSuggestionOnRange(actionContext, deleteRange);
                  this.singleBlockRemotion(actionContext, referenceContainer, deleteRange);

                  if (previousBlock && previousBlock.isEditable) {
                    const merged = this._handleParagraphMarker(
                      actionContext,
                      referenceContainer,
                      workingNode,
                      previousBlock,
                    );
                    if (merged) {
                      if (workingNode === endBlock) {
                        endBlock = previousBlock;
                      }
                      workingNode = previousBlock;
                    }
                  }
                  previousBlock = workingNode;
                } else if (EditorDOMUtils.isBlockNodeDeletable(workingNode)) {
                  if (
                    workingNode.parentNode === ELEMENTS.TrackDeleteElement.TAG ||
                    workingNode.parentNode.tagName === ELEMENTS.TrackInsertElement.TAG
                  ) {
                    workingNode = workingNode.parentNode;
                  }

                  // non text elements
                  previousBlock = null;
                  deleteRange.selectNode(workingNode);
                  this.addDeleteSuggestionOnRange(actionContext, deleteRange);

                  // if (workingNode.parentNode !== startBlock.parentNode) {
                  //   workingNode = DOMUtils.findNodeLevel0(startBlock.parentNode, workingNode);
                  // }
                }
              }
            }
          }

          range.restoreRange(savedRange);

          EditorSelectionUtils.applyRangeToSelection(range);

          if (savedRange) {
            EditorRange.removeLostMarkers(savedRange);
            savedRange = null;
          }

          returnValue = true;
        } catch (e) {
          Logger.captureException(e);
          throw e;
        } finally {
          this.setCursorPosition([], actionContext.collapseDirection);
        }

        return returnValue;
      }
    },
);

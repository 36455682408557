import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { axiosResponseSuccessInterceptor, axiosResponseErrorInterceptor } from './interceptors';
import getConfig from 'dodoc.config';
import { LocalStorage, SessionStorage } from '_common/utils';

export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    config?: Request.Config;
    errorsExpected?: Request.StatusCode[];
    arrayFormat?: 'brackets' | 'indices' | 'path';
  } & (
    | {
        method: 'POST';
        body: AxiosRequestConfig['data'];
      }
    | {
        method?: 'GET';
        body?: undefined;
      }
  ),
  unknown,
  {
    data: any;
    status: number | undefined;
  },
  {}
>;

function createAxiosInstance(config: Request.Config, errorsExpected: number[]) {
  const instance = axios.create(config);
  instance.interceptors.response.use(
    axiosResponseSuccessInterceptor,
    axiosResponseErrorInterceptor(errorsExpected),
  );
  return instance;
}

export function get<T>({ url, config, errorsExpected }: Request.GetParams) {
  const instance = createAxiosInstance(config, errorsExpected);
  return instance.get<T>(url, config);
}

export function post<T>({ url, data, config, errorsExpected }: Request.PostParams) {
  const instance = createAxiosInstance(config, errorsExpected);
  return instance.post<T>(url, data, config);
}

const stringifyBody = (body: MyAny, arrayFormat: 'brackets' | 'indices' | 'path') => {
  //Custom path format, for example: options[0][fields][0]=a will be transformed to options.0.fields.0=a
  return arrayFormat === 'path'
    ? qs
        .stringify(body, { arrayFormat: 'indices' })
        .replaceAll('%5D%5B', '.')
        .replaceAll('%5B', '.')
        .replaceAll('%5D', '')
    : qs.stringify(body, { arrayFormat });
};

// Used as RTK Query's baseQuery
export function axiosBaseQuery({
  app,
  apiRoute,
}: {
  app: 'api' | 'authority' | 'rt_rest';
  apiRoute: string;
}): AxiosBaseQuery {
  return async ({
    url,
    method = 'GET',
    body,
    config,
    errorsExpected = [],
    arrayFormat = 'brackets',
  }) => {
    try {
      // headers
      const headers: Request.Config['headers'] = {
        'X-DoDOC-Tenant': LocalStorage.getTenant(),
        Accept: 'application/json, text/plain, */*',
        Authorization: `Token ${SessionStorage.getToken()}`,
      };
      const requestConfig = {
        baseURL: getConfig()[app],
        ...config,
        headers: {
          ...headers,
          ...config?.headers,
        },
      };

      const axios = createAxiosInstance(requestConfig, errorsExpected);
      const result = await axios({
        url: apiRoute + url,
        method,
        data: body instanceof FormData ? body : stringifyBody(body, arrayFormat),
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };
}

import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export class TableRowViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<HTMLElement, Editor.Data.Node.TableRowData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    HTMLElement,
    Editor.Data.Node.TableRowData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // vanish
    v: this.GENERIC_ATTRIBUTE_MAPPER.v,
    // heigth
    rh: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        const parsedHeight: Partial<Editor.Data.Node.RowHeight> = {};

        if (node.dataset.rh != null) {
          const width = node.dataset.rh.split(',');
          parsedHeight.t = width[0] as Editor.Data.Node.RowHeightTypes;
          parsedHeight.v = +width[1];
        } else if (node.style.height != null) {
          parsedHeight.t = 'abs';
          parsedHeight.v = EditorDOMUtils.convertUnitTo(node.style.height, undefined, 'pt', 3);
        }

        builder.addProperty('rh', parsedHeight);
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        const heigth = json.properties?.rh || json.properties?.h;
        if (heigth != null) {
          if (typeof heigth === 'object') {
            if (!heigth.t || !heigth.v) {
              node.style.height = 'auto';
            } else if (heigth.t != null) {
              node.dataset.rh = [heigth.t, heigth.v].join(',');

              if (heigth.t === 'auto') {
                node.style.height = 'auto';
              } else {
                node.style.height = `${EditorDOMUtils.convertUnitTo(heigth.v, 'pt', 'px', 3)}px`;
              }
            }
          } else {
            node.dataset.rh = ['abs', heigth].join(',');
            node.style.height = `${EditorDOMUtils.convertUnitTo(heigth, 'pt', 'px', 3)}px`;
          }
        }
      },
      remove: (node) => {
        node.style.removeProperty('height');
        delete node.dataset.rh;
      },
    },
    // rowCanSplit
    rcs: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.rcs != null) {
          builder.addProperty('rcs', node.dataset.rcs);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.rcs != null) {
          node.dataset.rcs = `${json.properties.rcs}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.rcs;
      },
    },
    hr: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.hr != null) {
          builder.addProperty('hr', node.dataset.hr);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.hr != null) {
          node.dataset.hr = `${json.properties.hr}`;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.hr;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.TableRowData | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.TableRowData) {
    const node = DOMElementFactory.buildElement(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    return node;
  }
}

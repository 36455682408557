import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, EmptyState, Switch } from 'dodoc-design-system';
import {
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps,
  ScrollParams,
} from 'react-virtualized';

import { useDispatch, useSelector } from '_common/hooks';
import {
  lazyNotifications,
  listNotifications,
  selectStartOfSections,
  resetOffset,
} from './NotificationsSlice';

import FilterPopover from '_common/components/Filters/Components/FilterPopover/FilterPopover';
import { EmptyFilteredState } from '_common/components';
import FilterDisplay from '_common/components/Filters/Components/FilterDisplay/FilterDisplay';
import Settings from '_common/components/Popovers/NotificationsPopover/Settings/Settings';

import DynamicHeight from './DynamicHeight';
import styles from './NotificationsPopover.module.scss';
import MarkAllButton from './MarkAllButton/MarkAllButton';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';

const DTI = {
  settings: 'notificationsPopover_settings',
};

type NotificationsPopoverProps = {
  closePopover: () => void;
  popperHeight?: number;
  object?: doDOC.SuiteObject;
};

const NotificationsPopover = ({
  closePopover,
  popperHeight,
  object,
}: NotificationsPopoverProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectFilterParams } = useFilterSelector();

  const hasNextPage = useSelector((state) => state.notifications.hasNextPage);
  const isNextPageLoading = useSelector((state) => state.notifications.isNextPageLoading);
  const notificationsList = useSelector((state) => state.notifications.list);
  const notificationsDict = useSelector((state) => state.notifications.dict);
  const startOfSections = useSelector(selectStartOfSections);
  const unreadNotificationsList = notificationsList.filter(
    (id) => notificationsDict[id].read === false,
  );

  const filterParams = useSelector((state) =>
    selectFilterParams(state, state.filters.notifications),
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const filtersRef = useRef<HTMLDivElement>(null);

  const [marginValue, setMarginValue] = useState('2rem');
  const [currentScroll, setCurrentScroll] = useState(0);
  const [onlyUnread, setOnlyUnread] = useState(false);

  useEffect(() => {
    const extendedFilterParams: Request.FilterParams = { filter_fields: [], filter_values: [] };

    if (object && !filterParams.filter_fields.includes('target')) {
      extendedFilterParams.filter_fields.push('target');
      extendedFilterParams.filter_values.push(object.id);
    }

    if (onlyUnread) {
      extendedFilterParams.filter_fields.push('read');
      extendedFilterParams.filter_values.push('false');
    }

    dispatch(resetOffset());
    dispatch(
      listNotifications({
        filterParams: {
          filter_fields: [...filterParams.filter_fields, ...extendedFilterParams.filter_fields],
          filter_values: [...filterParams.filter_values, ...extendedFilterParams.filter_values],
        },
      }),
    );
  }, [filterParams, onlyUnread, object]);

  const filterOptions = useMemo(() => {
    const isDDC = object?.type === 'document';
    const isPDF = object?.type === 'dopdf';
    const isPPT = object?.type === 'presentation';

    const options = [
      {
        value: 'obj_permissions',
        label: intl.formatMessage({ id: 'OBJ_PERMISSIONS' }),
        availableIn: ['document', 'dopdf', 'presentation'],
      },
      {
        value: 'comments',
        label: intl.formatMessage({ id: isDDC ? 'COMMENTS' : 'DOCUMENT_COMMENTS' }),
        availableIn: ['document'],
      },
      {
        value: 'suggestions',
        label: intl.formatMessage({ id: isDDC ? 'SUGGESTION' : 'DOCUMENT_SUGGESTIONS' }),
        availableIn: ['document'],
      },
      {
        value: 'tasks',
        label: intl.formatMessage({ id: isDDC ? 'TASKS' : 'DOCUMENT_TASKS' }),
        availableIn: ['document'],
      },
      {
        value: 'pdf_annotations',
        label: intl.formatMessage({ id: isPDF ? 'COMMENTS' : 'PDF_COMMENTS' }),
        availableIn: ['dopdf'],
      },
      {
        value: 'pdf_tasks',
        label: intl.formatMessage({ id: isPDF ? 'TASKS' : 'PDF_TASKS' }),
        availableIn: ['dopdf'],
      },
      {
        value: 'presentation_comments',
        label: intl.formatMessage({ id: isPPT ? 'COMMENTS' : 'PRESENTATION_COMMENTS' }),
        availableIn: ['presentation'],
      },
      {
        value: 'presentation_tasks',
        label: intl.formatMessage({ id: isPPT ? 'TASKS' : 'PRESENTATION_TASKS' }),
        availableIn: ['presentation'],
      },
    ];

    if (object) {
      return options.filter((filter) => filter.availableIn.includes(object.type));
    }

    return options;
  }, [object]);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 72,
  });

  const handleLoadMoreRows = () => {
    if (!isNextPageLoading) {
      return dispatch(lazyNotifications());
    }
  };

  const handleSectionTitle = (index: number) => {
    if (index === startOfSections?.startOfToday) {
      return <FormattedMessage id="today" />;
    } else if (index === startOfSections?.startOfYesterday) {
      return <FormattedMessage id="yesterday" />;
    }
    if (index === startOfSections?.startOfOlder) {
      return <FormattedMessage id="older" />;
    }
  };

  const handleScroll = (e: ScrollParams) => {
    setCurrentScroll(e.scrollTop);
  };

  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const sectionTitle = handleSectionTitle(index);

    const renderSection = () => {
      if (sectionTitle) {
        return (
          <div className={styles.section} data-testid="notification-section">
            <div className={styles.sectionLabel}>{sectionTitle}</div>
          </div>
        );
      }
    };

    if (import.meta.env.MODE === 'test') {
      return (
        <DynamicHeight
          key={key}
          measure={() => null}
          registerChild={() => null}
          renderSection={renderSection}
          notificationId={notificationsList[index]}
          style={style}
          closePopover={closePopover}
        />
      );
    }

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {({ measure, registerChild }) => (
          <DynamicHeight
            measure={measure}
            registerChild={registerChild}
            renderSection={renderSection}
            notificationId={notificationsList[index]}
            style={style}
            closePopover={closePopover}
          />
        )}
      </CellMeasurer>
    );
  };

  const renderEmptyView = () => {
    if (filterParams.filter_fields.length > 0) {
      return <EmptyFilteredState margin="8rem 10rem" />;
    } else {
      return (
        <div className={styles.emptyState} data-testid="empty-state-list-notifications">
          <EmptyState
            size="large"
            title={intl.formatMessage({ id: 'NO_NOTIFICATIONS' })}
            testId="empty-state-list-notifications"
          >
            {intl.formatMessage({ id: 'NO_NOTIFICATIONS_TO_READ' })}
          </EmptyState>
        </div>
      );
    }
  };

  useMemo(() => {
    setTimeout(() => {
      const scrollHeight = containerRef.current?.children[0]?.children[0]?.scrollHeight ?? 0;
      const clientHeight = containerRef.current?.children[0]?.children[0]?.clientHeight ?? 0;

      if (scrollHeight > clientHeight) {
        setMarginValue('4rem');
      } else {
        setMarginValue('2rem');
      }
    }, 0);
  }, [notificationsList.length]);

  const handleToggleOnlyUnread = () => {
    setOnlyUnread((prevState) => !prevState);
  };

  const getFiltersHeight = () => {
    const el = filtersRef.current;

    if (el) {
      const elStyles = window.getComputedStyle(el);

      return Math.ceil(
        parseFloat(elStyles.height) +
          parseFloat(elStyles.marginTop) +
          parseFloat(elStyles.marginBottom),
      );
    }
    return 0;
  };

  return (
    <div
      className={styles.root}
      style={{ height: popperHeight !== undefined ? popperHeight : 640 }}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <FormattedMessage id="NOTIFICATIONS" />
        </div>
        <div className={styles.actions}>
          <MarkAllButton enabled={unreadNotificationsList.length > 0} />
          <div className={styles.actionsButtons}>
            <FilterPopover
              identity="notifications"
              popperSettings={{ placement: 'bottom-end' }}
              toggleVariant="link"
              notificationCategory={{ options: filterOptions }}
            />
            <Settings testId={DTI.settings} closePopover={closePopover} />
          </div>
        </div>
      </div>
      <Divider margin="0 0 2rem 0" />
      <div className={styles.listHeader} ref={filtersRef}>
        <FilterDisplay identity="notifications" direction="column" margin="0rem 2rem 2rem" />
        <div style={{ marginRight: marginValue }}>
          <Switch
            size="medium"
            active={onlyUnread}
            onChange={handleToggleOnlyUnread}
            labelPlacement="left"
            testId="switch-only-show-unread-notifications"
            margin={'0 0 0.5rem 0'}
          >
            {intl.formatMessage({ id: 'ONLY_SHOW_UNREAD_NOTIFICATIONS' })}
          </Switch>
        </div>
      </div>

      <div ref={containerRef} className={styles.list}>
        {notificationsList.length === 0 ? (
          renderEmptyView()
        ) : import.meta.env.MODE === 'test' ? (
          // @ts-expect-error
          notificationsList.map((_, index) => rowRenderer({ index, key: `not-${index}` }))
        ) : (
          <InfiniteLoader
            isRowLoaded={({ index }) => {
              return notificationsList.length - 1 === index && hasNextPage
                ? false
                : index <= notificationsList.length;
            }}
            //@ts-expect-error loadMoreRows has some non Promise returns
            loadMoreRows={handleLoadMoreRows}
            rowCount={notificationsList.length}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={registerChild}
                deferredMeasurementCache={cache}
                width={480}
                height={
                  popperHeight !== undefined
                    ? popperHeight - 72 - getFiltersHeight() //72 is the height of the header (56) + bottom margin (16)
                    : 640 - 72 - getFiltersHeight() //640 is the height of the popper
                }
                overscanRowCount={10}
                rowCount={notificationsList.length}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                onRowsRendered={onRowsRendered}
                onScroll={handleScroll}
                scrollTop={currentScroll}
              />
            )}
          </InfiniteLoader>
        )}
      </div>
    </div>
  );
};

export default NotificationsPopover;

export class PathUtils {
  static isPathEqual(path1: Editor.Selection.Path, path2: Editor.Selection.Path) {
    // check path
    if (path1.length === path2.length) {
      for (let i = 0; i < path1.length; i++) {
         
        if (path1[i] != path2[i]) {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  static isValidSelectionPath(
    path: Realtime.Core.RealtimePath | Realtime.Core.AbstractPath,
  ): path is Editor.Selection.Path {
    for (let i = 0; i < path.length; i++) {
      let key = path[i];
      if (typeof key === 'string' && (key === 'childNodes' || key === 'content' || !isNaN(+key))) {
        continue;
      } else if (typeof key === 'number') {
        continue;
      } else {
        return false;
      }
    }

    return true;
  }

  static getCommonAncestorPath(
    path1: Realtime.Core.RealtimePath,
    path2: Realtime.Core.RealtimePath,
  ): Editor.Selection.Path {
    let commonPath: Editor.Selection.Path = [];

    let i = 0;
    let lastKey: 'childNodes' | 'content' | null = null;
     
    while (path1[i] != null && path1[i] == path2[i]) {
      let key = path1[i];
      if (key === 'childNodes') {
        lastKey = key;
      } else if (lastKey != null && !isNaN(+key)) {
        commonPath.push(lastKey);
        commonPath.push(+key);

        lastKey = null;
      }

      i++;
    }

    return commonPath;
  }

  static isChildPath(parentPath: Editor.Selection.Path, childPath: Editor.Selection.Path) {
    let includes: boolean = false;

    if (parentPath.length === 0 && childPath.length !== 0) {
      return true;
    }

    if (parentPath.length > 0 && childPath.length > 0) {
      for (let i = 0; i < parentPath.length; i++) {
        if (parentPath[i] != null && childPath[i] != null) {
          // '==' intended
           
          if (parentPath[i] == childPath[i]) {
            includes = true;
          } else {
            includes = false;
            break;
          }
        } else {
          includes = false;
          break;
        }
      }
    }

    return includes;
  }

  static comparePath(path1: Editor.Selection.Path, path2: Editor.Selection.Path): number {
    if (path1?.length >= 0 && path2?.length >= 0) {
      let length: number = 0;
      if (path1.length >= path2.length) {
        length = path1.length;
      } else {
        length = path2.length;
      }

      let previousIteration: string | null = null;

      for (let i = 0; i < length; i++) {
        if (path1[i] != null && path2[i] != null) {
          if (
            (path1[i] === 'childNodes' && path2[i] === 'childNodes') ||
            (path1[i] === 'content' && path2[i] === 'content')
          ) {
            previousIteration = path1[i] as string;
          } else if (previousIteration != null) {
            const index1 = +path1[i];
            const index2 = +path2[i];

            if (index1 < index2) {
              // path1 before path2
              return -1;
            } else if (index1 > index2) {
              // path1 after path2
              return 1;
            }

            previousIteration = null;
          } else {
            throw new Error('Invalid path to compare!');
          }
        } else if (path1[i] == null && path2[i] == null) {
          // paths should be equal
          return 0;
        } else if (path1[i] == null) {
          // path1 before path2
          return -1;
        } else if (path2[i] == null) {
          // path1 after path2
          return 1;
        }
      }
    } else {
      throw new Error('Invalid path to compare!');
    }

    return 0;
  }

  static containsPath(
    start: Editor.Selection.Path,
    end: Editor.Selection.Path,
    path: Editor.Selection.Path,
  ) {
    let cmpStart = PathUtils.comparePath(path, start);
    let cmpEnd = PathUtils.comparePath(path, end);
    if (cmpStart >= 0 && cmpEnd <= 0) {
      return true;
    }
    return false;
  }

  static transformPath(
    basePath: Editor.Selection.Path | null,
    transformPath: Editor.Selection.Path | null,
    action: 'ADD' | 'SUBTRACT' = 'SUBTRACT',
  ): Editor.Selection.Path | null {
    let resultPath: Editor.Selection.Path | null = [];

    if (basePath && basePath?.length >= 0 && transformPath && transformPath.length >= 0) {
      if (transformPath.length === 0) {
        resultPath = basePath;
      } else {
        let previousIteration: string | null = null;
        let preivousP1Index: number | null = null;
        let keepTransforming = true;

        const length =
          basePath.length > transformPath.length ? basePath.length : transformPath.length;

        for (let i = 0; i < length; i++) {
          const p1 = basePath[i];
          const p2 = transformPath[i];

          if (p1 != null && p2 != null) {
            if (
              (p1 === 'childNodes' && p2 === 'childNodes') ||
              (p1 === 'content' && p2 === 'content')
            ) {
              previousIteration = p1 as string;
              resultPath.push(p1);
            } else if (previousIteration != null) {
              if (keepTransforming) {
                let index = -1;
                if (action === 'SUBTRACT') {
                  index = +p1 - +p2;
                  if (index > 0) {
                    keepTransforming = false;
                  }
                } else {
                  index = +p1 + +p2;
                  if (+p1 > 0) {
                    keepTransforming = false;
                  }
                }

                previousIteration = null;
                if (index >= 0) {
                  preivousP1Index = +p1;
                  resultPath.push(index);
                } else {
                  // invalid transform
                  resultPath = null;
                  break;
                }
              } else {
                resultPath.push(p1);
              }
            } else {
              resultPath.push(p1);
            }
          } else if (p1 != null && p2 == null) {
            resultPath.push(p1);
            previousIteration = null;
          } else if (p1 == null && p2 != null && preivousP1Index === 0) {
            resultPath.push(p2);
            previousIteration = null;
          }
        }
      }
    }

    return resultPath;
  }
}

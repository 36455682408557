import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, Tooltip } from 'dodoc-design-system';

import { ReduxInterface } from 'Editor/services';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import styles from './AuthorsElement.module.scss';
import { BaseBlockElement } from '../../BaseViewBuilder';

const AuthorsElementContent = () => {
  const intl = useIntl();

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'CONTENT_CANNOT_BE_EDITED' })}
      testId="authors-cannot-be-edited-tooltip"
    >
      <div className={styles.contentWrapper}>
        <div className={styles.iconWrapper}>
          <Icon icon="PlaceholderTableOfContents" size={32} />
        </div>
        <div className={styles.info}>
          <FormattedMessage id={'EDITOR_DOCUMENT_AUTHORS'} />
        </div>
      </div>
    </Tooltip>
  );
};

export class AuthorsElement extends BaseBlockElement {
  private container?: HTMLElement;

  get selectableContent() {
    if (this.container) {
      return this.container.childNodes[0]?.childNodes[1];
    }
    return null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.buildElement();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || [];
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  buildElement() {
    this.innerHTML = '';

    this.setAttribute('spellcheck', 'false');

    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);

    this.appendChild(this.container);

    const locale = ReduxInterface.getLocale();

    ReactDOM.unmountComponentAtNode(this.container);
    ReactDOM.render(
      <IntlProvider locale={locale}>
        <AuthorsElementContent />
      </IntlProvider>,
      this.container,
    );
  }
}

if (!window.customElements.get(ELEMENTS.AuthorsElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.AuthorsElement.IDENTIFIER, AuthorsElement);
}

import { setAppVersion } from 'Auth/redux/localStorageSlice';
import { resetAppState, setAppLoading } from 'App/redux/appSlice';
import getConfig from 'dodoc.config';
import { Middleware, isAction } from '@reduxjs/toolkit';
import { RootState } from '.';

/**
 * Store Middleware function to validate app version
 */
export const validateAppVersion: Middleware<{}, RootState> = (store) => (next) => (action) => {
  if (
    isAction(action) &&
    action.type === setAppLoading.type /* && import.meta.env.MODE === 'production' */
  ) {
    const localVersion = store.getState().localStorage.version;
    if (localVersion) {
      if (localVersion !== getConfig().version) {
        store.dispatch(resetAppState());
        store.dispatch(setAppVersion(getConfig().version));
      }
    } else {
      store.dispatch(setAppVersion(getConfig().version));
    }
  }

  return next(action);
};

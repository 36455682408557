//import NumberingUtils from 'Editor/services/_Common/NumberingUtils';
import { ELEMENTS } from 'Editor/services/consts';

type PositionValues = 'above' | 'below';
type NumberingFormatValues =
  | 'b'
  | 'd'
  | 'ad'
  | 'lr'
  | 'ur'
  | 'la'
  | 'ua'
  | 'on'
  | 'ow'
  | '1lz'
  | '2lz'
  | '3lz'
  | '4lz'
  | 'specialChars'
  | 'n';

export type ListData = {
  style: string;
  start: number;
  n: string[];
  so: any;
};

export type ListLevelDefinition = {
  st: number;
  et?: string;
  nf: NumberingFormatValues;
  tA: string;
  tB: string;
  ff?: string;
  ind: {
    l?: number;
    r?: number;
  };
  sp_ind: {
    t?: string;
    v?: number;
  };
  tabs?: Editor.Data.TabStop[];
  fnw?: 'not' | 'spc' | 'tab';
  paragraph_style?: any;
  rl?: string[];
  t?: string;
};

export type ListDefinitionType = {
  [index: string]: ListLevelDefinition;
};

export type ListStyleData = {
  ldef: ListDefinitionType;
};

export type DocumentStyleProperties = Partial<{
  fontfamily: string;
  fontsize: number;
  color: string | boolean;
  bg: any;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  a: Editor.Data.Node.Alignment;
  lh: number;
  lr: string;
  sb: number;
  sa: number;
  asa: boolean;
  asb: boolean;
  v: boolean;
  ind: Editor.Data.Node.Indentation;
  sp_ind: Editor.Data.Node.SpecialIndentation;
  lst: {
    lId: string;
    lLv: number | string;
    lStId: string;
  };
  wc: boolean;
  kn: boolean;
  kl: boolean;
  cts: boolean;
  pbb: boolean;
  otl: number | undefined;
  tabs: Editor.Data.TabStop[];

  [index: string]: unknown;
}>;

export type DocumentStyleData = {
  n: string;
  id: string;
  a: string;
  cs?: any;
  b: boolean;
  e: string;
  p: DocumentStyleProperties;
  t?: string;
};

export const DEFAULT_CAPTION = {
  nf: 'd',
  p: 'below',
  ls: ELEMENTS.ParagraphElement.BASE_STYLES.FIGURE_CAPTION,
};

export type CaptionData = {
  nf: NumberingFormatValues;
  ls: string;
  p: PositionValues;
  s?: string;
  c?: string;
};

export type StructureData = {
  _id?: string;
  parent_id: string;
  childNodes: Array<string>;
  blkProps: any;
  st: {
    [index: string]: DocumentStyleData;
  };
  l_st: {
    [index: string]: ListStyleData;
  };
  lists: {
    [index: string]: ListData;
  };
  cpt: {
    [label: string]: CaptionData;
  };
  extra?: {
    [index: string]: any;
    ts: number;
  };
  t?: string;
  tsk_sq?: any;
};

export const StructureKeys = Object.freeze({
  ID: '_id',
  PARENT_ID: 'parent_id',
  STYLES: 'st',
  LISTS: 'lists',
  LIST_STYLES: 'l_st',
  CHILDNODES: 'childNodes',
  BLOCK_PROPERTIES: 'blkProps',
  CAPTIONS: 'cpt',
  EXTRA: 'extra',
  TEMPLATE: 't',
  TASK_SEQUENCE: 'tsk_sq',
});

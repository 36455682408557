import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';
import { validateAppVersion } from './storeUtils';
import api from '_common/services/api/api';
import authority from '_common/services/api/authority';
import realtime from '_common/services/api/realtime';
import { injectStoreForLocalStorage } from '_common/utils/LocalStorage';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // stateTransformer: state => {
  //   // Transform the state to remove sensitive information
  //   const transformedState = {
  //     ...state,
  //     auth: {
  //       ...state.auth,
  //       publicProfiles: null,
  //       user: {
  //         ...state.auth.user,
  //         profile: null,
  //       },
  //     },
  //   };
  //   return transformedState;
  // },
  // configureScopeWithState: (scope, state) => {
  //   // Set tag to filter users.
  //   scope.setTag('user', state.auth.userId);
  //   scope.setTag('username', state.auth.user.profile.username);
  // },
});

type ReduxStoreMiddlewares = {
  serializableCheck: boolean | { ignoredActions: string[] };
  immutableCheck: boolean;
};

export function getStore(
  preloadedState: PreloadedState | undefined = undefined,
  middlewares: ReduxStoreMiddlewares = { serializableCheck: false, immutableCheck: false },
) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: import.meta.env.MODE !== 'test' && middlewares.serializableCheck,
        immutableCheck: import.meta.env.MODE !== 'test' && middlewares.immutableCheck, // Enable this when testing immutability
      })
        .concat(validateAppVersion)
        .concat(api.middleware)
        .concat(authority.middleware)
        .concat(realtime.middleware),
    enhancers: (getDefaultEnhancers) =>
      /**
       * https://github.com/reduxjs/redux-toolkit/issues/3973
       * https://redux-toolkit.js.org/api/autoBatchEnhancer#autobatchenhancer-1
       * Redux toolkit v2 uses autoBatch by default, but this causes problems with some tests.
       * With autoBatch more awaits and waitFor have to be added to the tests.
       * Gonna leave it off in test enviroment for now.
       */
      getDefaultEnhancers({ autoBatch: import.meta.env.MODE !== 'test' }).concat(
        sentryReduxEnhancer,
      ),
  });
}

const store = getStore();

setupListeners(store.dispatch);
injectStoreForLocalStorage(store);

export type AppDispatch = typeof store.dispatch;
export type Store = typeof store;
export type PreloadedState = Parameters<typeof rootReducer>[0];

export default store;

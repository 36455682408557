import { Mixin } from 'mixwith';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';

export default Mixin(
  (superclass) =>
    class extends superclass {
       
      parseListOfTablesNode(representation) {
        const builder = new ElementNodeBuilder();
        builder.addElementType(ELEMENTS.ListOfTablesElement.ELEMENT_TYPE);
        if (representation.attributes.parent_id) {
          builder.addParentId(representation.attributes.parent_id.nodeValue);
        }
        if (representation.attributes.id) {
          builder.addId(representation.attributes.id.nodeValue);
        }
        return builder.getNode();
      }
    },
);

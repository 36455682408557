import { BaseTypedEmitter } from '_common/services/Realtime';
import { uniq } from 'lodash-es';
import { ListData, Structure, Template, Numbering } from '../../models';
import List from './List';

type ListsDictionay = {
  [index: string]: ListData;
};

export type MergedListsData = {
  [index: string]: List;
};

export default class ListsManager extends BaseTypedEmitter<{
  LOAD_LISTS: (data: MergedListsData) => void;
  LOAD_LIST: (id: string, data: List) => void;
  LIST_UPDATE: (id: string, data: List) => void;
  LIST_REMOVE: (id: string) => void;
  LIST_UPDATE_STYLE: (data: any) => void;
  LIST_UPDATE_ELEMENTS: (data: any) => void;
}> {
  private template?: Template;
  private structure?: Structure;
  private numbering?: Numbering;
  private templateLists: ListsDictionay = {};
  private documentLists: ListsDictionay = {};
  private mergedLists: MergedListsData = {};

  constructor(templateObject?: Template, StructureObject?: Structure, numberingModel?: Numbering) {
    super();
    this.template = templateObject;
    this.structure = StructureObject;
    this.numbering = numberingModel;
    this.setupHandlers();
  }

  private setupHandlers() {
    this.template?.on('LOADED', this.onTemplateLoad.bind(this));
    this.structure?.on('LOADED', this.onStructureLoad.bind(this));
    this.structure?.on('UPDATED', this.onStructureUpdated.bind(this));
    this.numbering?.on('LOADED', this.onNumberingLoad.bind(this));
    this.numbering?.on('UPDATED', this.onNumberingUpdated.bind(this));
  }

  private onTemplateLoad(data: any) {
    this.loadTemplateLists(this.template?.lists);
  }

  private onStructureLoad(data: any) {
    this.loadDocumentLists(this.structure?.get(['lists']) as unknown as ListsDictionay);
  }

  private onStructureUpdated(data: any, ops: any) {
    let element: any;
    for (let index = 0; ops && index < (ops as Array<Realtime.Core.RealtimeOps>).length; index++) {
      element = ops[index];
      if (element.p[0] === 'lists') {
        if (element.p.length === 2) {
          if (element.oi) {
            this.appendDocumentList(element.p[1], element.oi);
          }
          if (element.od) {
            this.removeDocumentList(element.p[1]);
          }
        } else if (element.p.length > 2) {
          this.updateDocumentList(
            element.p[1],
            this.structure?.get(['lists', element.p[1]]) as unknown as ListData,
            element,
          );
        } else {
          this.loadDocumentLists(this.structure?.get(['lists']) as unknown as ListsDictionay);
        }
      }
    }
  }

  private onNumberingLoad(data: any) {
    this.joinLists();
    this.loadLists();
  }

  private onNumberingUpdated(data: any, ops: any) {
    const length = ops.length;
    let op;
    let path;
    const lists = new Set();
    const blocks = new Set<string>();
    let newNumbering;
    let oldNumbering;
    for (let i = 0; i < length; i++) {
      op = ops[i];
      path = op.p;
      if (path[0] === 'gN') {
        blocks.add(path[1]);
        newNumbering = op.oi;
        oldNumbering = op.od;
        if (newNumbering) {
          lists.add(newNumbering.listId);
        }
        if (oldNumbering) {
          lists.add(oldNumbering.listId);
        }
      }
    }
    const listIds = Array.from(lists) as string[];
    for (let index = 0; index < listIds.length; index++) {
      this.updateListIndexing(listIds[index]);
    }
    this.emit('LIST_UPDATE_ELEMENTS', Array.from(blocks));
  }

  private joinLists() {
    const newLists = uniq([...Object.keys(this.templateLists), ...Object.keys(this.documentLists)]);
    const listStylesIds = uniq([...newLists, ...Object.keys(this.mergedLists)]);
    let listId;
    for (let index = 0; index < listStylesIds.length; index++) {
      listId = listStylesIds[index];
      if (newLists.includes(listId)) {
        if (!this.mergedLists[listId]) {
          this.mergedLists[listId] = new List(
            listId,
            this.templateLists[listId],
            this.documentLists[listId],
            this.numbering?.getListIndexing(listId),
          );
          this.emit('LOAD_LIST', listId, this.mergedLists[listId]);
        } else {
          this.mergedLists[listId]
            .loadDocumentList(this.documentLists[listId])
            .loadTemplateList(this.templateLists[listId])
            .updateListIndexing(this.numbering?.getListIndexing(listId));
        }
      }
      if (!newLists.includes(listId) && this.mergedLists[listId]) {
        delete this.mergedLists[listId];
        this.emit('LIST_REMOVE', listId);
      }
    }
    return newLists;
  }

  private loadLists() {
    this.emit('LOAD_LISTS', this.mergedLists);
  }

  private loadDocumentLists(documentLists: ListsDictionay) {
    this.documentLists = documentLists;
    this.joinLists();
    this.loadLists();
  }

  private loadTemplateLists(templateLists: ListsDictionay) {
    this.templateLists = templateLists;
    this.joinLists();
    this.loadLists();
  }

  private appendDocumentList(listId: string, list: ListData) {
    this.documentLists[listId] = list;
    if (!this.mergedLists[listId]) {
      this.mergedLists[listId] = new List(
        listId,
        this.templateLists[listId],
        this.documentLists[listId],
        this.numbering?.getListIndexing(listId),
      );
      this.emit('LOAD_LIST', listId, this.mergedLists[listId]);
    } else {
      this.mergedLists[listId].loadDocumentList(this.documentLists[listId]);
      this.emit('LIST_UPDATE', listId, this.mergedLists[listId]);
    }
  }

  private removeDocumentList(listId: string) {
    delete this.documentLists[listId];
    if (!this.templateLists[listId]) {
      delete this.mergedLists[listId];
      this.emit('LIST_REMOVE', listId);
    } else {
      this.mergedLists[listId].loadDocumentList();
      this.emit('LIST_UPDATE', listId, this.mergedLists[listId]);
    }
  }

  private updateDocumentList(listId: string, data: ListData, operation?: any) {
    this.documentLists[listId] = data;
    if (!this.mergedLists[listId]) {
      this.mergedLists[listId] = new List(
        listId,
        this.templateLists[listId],
        this.documentLists[listId],
        this.numbering?.getListIndexing(listId),
      );
      this.emit('LOAD_LIST', listId, this.mergedLists[listId]);
      return;
    }
    this.mergedLists[listId].loadDocumentList(data);
    if (operation.p.includes('style')) {
      this.emit('LIST_UPDATE_STYLE', {
        listId: operation.p[1],
        old: operation.od,
        update: operation.oi,
      });
    } else if (operation.p.includes('n')) {
      // this.emit('LIST_UPDATE_ELEMENTS', {
      //   listId: operation.p[1],
      //   removed: operation.ld ? [operation.ld] : [],
      //   inserted: operation.li ? [operation.li] : [],
      // });
    } else {
      this.emit('LIST_UPDATE', operation.p[1], this.mergedLists[listId]);
    }
  }

  private updateListIndexing(listId: string) {
    if (this.mergedLists[listId]) {
      this.mergedLists[listId].updateListIndexing(this.numbering?.getListIndexing(listId));
      this.emit('LIST_UPDATE', listId, this.mergedLists[listId]);
    }
  }

  bindToTemplate(templateObject?: Template) {
    if (templateObject) {
      this.template = templateObject;
      this.template.on('LOADED', this.onTemplateLoad.bind(this));
    }
    return this;
  }

  bindToStructure(structureObject?: Structure) {
    if (structureObject) {
      this.structure = structureObject;
      this.structure.on('LOADED', this.onStructureLoad.bind(this));
      this.structure.on('UPDATED', this.onStructureUpdated.bind(this));
    }
    return this;
  }

  bindToNumbering(numbering?: Numbering) {
    if (numbering) {
      this.numbering = numbering;
      this.numbering.on('LOADED', this.onNumberingLoad.bind(this));
      this.numbering.on('UPDATED', this.onNumberingUpdated.bind(this));
    }
    return this;
  }

  start() {
    this.templateLists = this.template?.lists || {};
    this.documentLists = (this.structure?.get(['lists']) || {}) as unknown as ListsDictionay;
    this.joinLists();
    this.loadLists();
  }

  updatedListStyle(listStyleId: string) {
    const listStylesIds = Object.keys(this.mergedLists);
    let listId;
    for (let index = 0; index < listStylesIds.length; index++) {
      listId = listStylesIds[index];
      if (this.mergedLists[listId] && this.mergedLists[listId].style === listStyleId) {
        this.mergedLists[listId].emit('UPDATED', this.mergedLists[listId].data);
        this.emit('LIST_UPDATE_STYLE', {
          listId,
          old: listStyleId,
          update: listStyleId,
        });
      }
    }
  }

  isListFromTemplate(listId: string) {
    return !this.documentLists[listId] && !!this.templateLists[listId];
  }

  isListInDocument(listId: string) {
    return !!this.documentLists[listId];
  }

  list(listId: string): List | undefined {
    return this.mergedLists[listId];
  }

  lists() {
    return this.mergedLists;
  }

  listsIds() {
    return Object.keys(this.mergedLists);
  }

  destroy(): void {
    super.destroy();
  }
}

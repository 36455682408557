 
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

const EMPTY_SUMMARY_VALUE = {
  WORDS: 0,
  CHARACTERS_NO_SPACES: 0,
  CHARACTERS_WITH_SPACES: 0,
  PARAGRAPHS: 0,
  LINES: 0,
  REFERENCES: 0,
  FIGURES: 0,
  TABLES: 0,
  EQUATIONS: 0,
  SMART_OBJECTS: 0,
  OUTLINE_LVL_ONE: 0,
};

export class Proofing extends RealtimeObject<Editor.Data.Proofing.ProofingData> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'proofing_limited');
  }

  static get EMPTY_SUMMARY() {
    return EMPTY_SUMMARY_VALUE;
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    logger.trace('Proofing handleBatchOperations ' + source, ops);
    this.emit('UPDATED', this.get(), ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  getWordCount(): number {
    const wordCount: number = this.get(['summary', 'WORDS']);
    if (wordCount > 0) {
      return wordCount;
    }
    return 0;
  }

  getAllElementsCount(): Editor.Data.Proofing.FullSummary {
    const elemnts: Editor.Data.Proofing.FullSummary = this.get(['summary']);

    if (elemnts) {
      return elemnts;
    }
    return Proofing.EMPTY_SUMMARY;
  }
}

import ReactDOM from 'react-dom';
import { Icon } from 'dodoc-design-system';

import { ELEMENTS } from 'Editor/services/consts';
import { DISPLAY_TYPES } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseBlockElement } from '../../BaseViewBuilder';
import styles from './RedactedElement.module.scss';

export class RedactedElement extends BaseBlockElement {
  private icon: any;
  private container?: HTMLElement;
  private wrapper?: HTMLElement;
  private info?: HTMLElement;
  private content?: HTMLElement;

  static onClickListener(e: Event) {
    e.stopPropagation();
  }

  static onMouseUpListener(e: Event) {
    e.stopPropagation();
  }

  constructor() {
    super();

    this.addEventListener('click', RedactedElement.onClickListener);
    this.addEventListener('mouseup', RedactedElement.onMouseUpListener);
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return false;
  }

  get isSplitable() {
    return false;
  }

  get isContentWrapper() {
    return true;
  }

  get isSelectable() {
    return true;
  }

  setIcon() {
    ReactDOM.unmountComponentAtNode(this.icon);
    ReactDOM.render(<Icon disabled icon="PasswordHide" size={24} />, this.icon);
  }

  get selectableContent() {
    return this.content?.childNodes[0] || null;
  }

  connectedCallback() {
    super.connectedCallback();

    // this.setAttribute('contentEditable', false);
    this.setAttribute('spellcheck', 'false');

    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);
    this.container.setAttribute('id', Date.now().toString());

    this.icon = DOMElementFactory.buildElement('div');
    this.icon.setAttribute('class', styles.icon);
    this.icon.setAttribute('id', Date.now());

    this.container.appendChild(this.icon);

    this.wrapper = DOMElementFactory.buildElement('span');
    this.wrapper.setAttribute('class', styles.wrapper);
    this.wrapper.setAttribute('id', Date.now().toString());
    this.wrapper.setAttribute('contentEditable', 'true');

    this.info = DOMElementFactory.buildElement('span');
    this.info.setAttribute('class', styles.info);
    this.info.setAttribute('id', Date.now().toString());
    this.info.textContent = "You don't have permissions to view this content.";

    this.wrapper.appendChild(this.info);

    this.container.appendChild(this.wrapper);
    this.appendChild(this.container);

    this.setIcon();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('click', RedactedElement.onClickListener);
    this.removeEventListener('mouseup', RedactedElement.onMouseUpListener);
  }
}

if (!window.customElements.get(ELEMENTS.RedactedElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.RedactedElement.IDENTIFIER, RedactedElement);
}

import BaseController from '../BaseController';
import {
  CitationsLibrary,
  DocumentCitations,
  NodeModel,
  Structure,
  Template,
  Numbering,
  Cmapps,
  Section,
  Comment,
  Task,
  Notes,
  Suggestion,
  Selection,
  TableOfContents,
  Proofing,
} from '../../models';
import { IndexableElementTypeName, TypeName, Types, TYPE_NAME } from './Models.types';
import { Doc } from 'sharedb';
import { ModelIndexer } from './ModelIndexer';
import { CommentList } from '../Comments/CommentList';
import { TaskList } from '../Tasks/TaskList';
import { SuggestionList } from '../Suggestion/SuggestionList';
import { NotesList } from '../Notes/NotesList';
import {
  DocumentModel,
  PresenceChannel,
  RealtimeObject,
  UndoManager,
} from '_common/services/Realtime';

type ModelsState = {
  [index in TypeName]: {
    [x: string]: Types[index];
  };
};

type ModelsControllerArgType = Pick<Editor.Data.State, 'transport' | 'context'>;

export class ModelsController extends BaseController {
  protected models: ModelsState;
  undoManager?: Realtime.Core.UndoManager;
  constructor(Data: ModelsControllerArgType) {
    super(Data);
    this.models = {
      [TYPE_NAME.DOCUMENT]: {},
      [TYPE_NAME.STRUCTURE]: {},
      [TYPE_NAME.CITATIONS_LIBRARY]: {},
      [TYPE_NAME.DOCUMENT_CITATIONS]: {},
      [TYPE_NAME.TEMPLATE]: {},
      [TYPE_NAME.NODE]: {},
      [TYPE_NAME.NUMBERING]: {},
      [TYPE_NAME.CMAPPS]: {},
      [TYPE_NAME.SECTION]: {},
      [TYPE_NAME.COMMENT]: {},
      [TYPE_NAME.TASK]: {},
      [TYPE_NAME.SUGGESTION]: {},
      [TYPE_NAME.NOTES]: {},
      [TYPE_NAME.SELECTION]: {},
      [TYPE_NAME.TOC]: {},
      [TYPE_NAME.PROOFING]: {},
    };
    this.undoManager = new UndoManager({
      compose: {
        shouldCompose: true,
        interval: 600,
      },
    });
  }

  start(): void {}

  stop(): void {}

  destroy(): void {
    // dispose models
    const modelKeys = Object.keys(this.models);
    for (let i = 0; i < modelKeys.length; i++) {
      const modelType: TypeName = modelKeys[i] as TypeName;

      const idKeys = Object.keys(this.models[modelType]);
      for (let j = 0; j < idKeys.length; j++) {
        const id: string = idKeys[j];

        this.models[modelType][id]?.dispose();
        delete this.models[modelType][id];
      }
    }
  }

  get TYPE_NAME() {
    return TYPE_NAME;
  }

  private fetchModel<T extends TypeName, R extends Types[T]>(
    type: T,
    id: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let model;
    switch (type) {
      case TYPE_NAME.DOCUMENT:
        model = new DocumentModel(
          this.Data.transport,
          id as string,
          args[0] as Realtime.Core.Document.Data,
        ) as R;
        model.fetch();
        break;
      case TYPE_NAME.SELECTION:
        model = new Selection(this.Data.transport, id as string);
        break;
      case TYPE_NAME.STRUCTURE:
        model = new Structure(this.Data.transport, id, this.undoManager);
        break;
      case TYPE_NAME.CITATIONS_LIBRARY:
        model = new CitationsLibrary(this.Data.transport, id);
        break;
      case TYPE_NAME.DOCUMENT_CITATIONS:
        model = new DocumentCitations(this.Data.transport, id);
        break;
      case TYPE_NAME.TEMPLATE:
        model = new Template(this.Data.transport, id as string);
        model.fetch();
        break;
      case TYPE_NAME.NODE:
        model = new NodeModel(this.Data.transport, id, this.undoManager);
        break;
      case TYPE_NAME.NUMBERING:
        model = new Numbering(this.Data.transport, id);
        break;
      case TYPE_NAME.CMAPPS:
        model = new Cmapps(this.Data.transport, id);
        break;
      case TYPE_NAME.SECTION:
        model = new Section(this.Data.transport, id);
        break;
      case TYPE_NAME.COMMENT:
        model = new Comment(this.Data.transport, id);
        break;
      case TYPE_NAME.TASK:
        model = new Task(this.Data.transport, id);
        break;
      case TYPE_NAME.SUGGESTION:
        model = new Suggestion(this.Data.transport, id, this.undoManager);
        break;
      case TYPE_NAME.NOTES:
        model = new Notes(this.Data.transport, id);
        break;
      case TYPE_NAME.TOC:
        model = new TableOfContents(this.Data.transport, id);
        break;
      case TYPE_NAME.PROOFING:
        model = new Proofing(this.Data.transport, id);
        break;
      default:
        throw new Error(`Unsupported model type : ${type}`);
    }
    if (this.Data.context?.version) {
      if (model instanceof NodeModel || model instanceof Structure) {
        model.setVersion(this.Data.context.version);
      }
    }
    if (model instanceof RealtimeObject || model instanceof PresenceChannel) {
      model.subscribe();
    }
    return model as R;
  }

  get<T extends TypeName, R extends Types[T]>(
    type: T,
    id?: Realtime.Core.RealtimeObjectId,
    ...args: unknown[]
  ): R {
    let _id: string = '';
    if (id == null) {
      throw new Error('Invalid id value: ' + id);
    }
    if (typeof id === 'string') {
      _id = id;
    } else if (!(id instanceof String) && (id as Doc).id) {
      _id = (id as Doc).id;
    }
    if (!this.models[type][_id]) {
      // @ts-expect-error
      this.models[type][_id] = this.fetchModel(type, id, ...args);
    }
    return this.models[type][_id] as R;
  }

  disposeModel<T extends TypeName>(type: T, id: string | undefined) {
    if (id != null && this.models[type][id]) {
      this.models[type][id].dispose();
      delete this.models[type][id];
    }
  }

  setModelsVersion(version: ApiSchemas['VersionsSchema'] | null) {
    const processing = [];
    const nodeIds = Object.keys(this.models.NODE);
    for (let index = 0; index < nodeIds.length; index++) {
      processing.push(this.models.NODE[nodeIds[index]].setVersion(version));
    }
    const structureIds = Object.keys(this.models.STRUCTURE);
    for (let index = 0; index < structureIds.length; index++) {
      processing.push(this.models.STRUCTURE[structureIds[index]].setVersion(version));
    }
    const sectionIds = Object.keys(this.models.SECTION);
    for (let index = 0; index < sectionIds.length; index++) {
      processing.push(this.models.SECTION[sectionIds[index]].setVersion(version));
    }
    const numberingIds = Object.keys(this.models.NUMBERING);
    for (let index = 0; index < numberingIds.length; index++) {
      processing.push(this.models.NUMBERING[numberingIds[index]].setVersion(version));
    }
    const cmappsIds = Object.keys(this.models.CMAPPS);
    for (let index = 0; index < cmappsIds.length; index++) {
      processing.push(this.models.CMAPPS[cmappsIds[index]].setVersion(version));
    }
    const proofingIds = Object.keys(this.models.PROOFING);
    for (let index = 0; index < proofingIds.length; index++) {
      processing.push(this.models.PROOFING[proofingIds[index]].setVersion(version));
    }

    return Promise.all(processing);
  }

  getIndexer<T extends IndexableElementTypeName>(type: T) {
    switch (type) {
      case TYPE_NAME.COMMENT:
        return new CommentList(this.Data.transport, this) as ModelIndexer<'NODE'>;
      case TYPE_NAME.TASK:
        return new TaskList(this.Data.transport, this) as ModelIndexer<'NODE'>;
      case TYPE_NAME.SUGGESTION:
        return new SuggestionList(this.Data.transport, this) as ModelIndexer<'NODE'>;
      case TYPE_NAME.NOTES:
        return new NotesList(this.Data.transport, this) as ModelIndexer<'NODE'>;
      default:
        return new ModelIndexer<T>(this.Data.transport, this, type);
    }
  }
}

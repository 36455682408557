 
 
import { Theme } from '../../models/Theme';
import { BaseController } from '../BaseController';

export class ThemesController extends BaseController {
  private theme?: Theme;

  async start(documentId: string) {
    const structure = this.Data.models?.get('STRUCTURE', `PS${documentId}`);
    await structure?.awaitForEvent('LOADED');
    const themeId = structure?.get(['theme']);
    if (themeId) {
      this.theme = this.Data.models?.get('THEME', structure?.get(['theme']));
      await this.theme?.awaitForEvent('LOADED');
    }
  }

  stop(): void {}

  destroy(): void {
    //
  }
}

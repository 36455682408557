import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '../BaseViewBuilder';

export class CitationGroupViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<Editor.Elements.CitationsGroupElement>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<Editor.Elements.CitationsGroupElement> =
    {
      // id
      id: this.GENERIC_ATTRIBUTE_MAPPER.id,
      // parent id
      parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
      // element_type
      element_type: {
        parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
        render: (json: Editor.Data.Node.Data, node: Editor.Elements.CitationsGroupElement) => {
          node.setAttribute('element_type', ELEMENTS.CitationsGroupElement.ELEMENT_TYPE);
        },
        remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
      },
    };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(
      ELEMENTS.CitationsGroupElement.TAG,
    ) as Editor.Elements.CitationsGroupElement;

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}

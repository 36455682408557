import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { BaseOperation } from '../BaseOperation';
import { NodeUtils } from 'Editor/services/DataManager';

export class RemoveBlockOperation extends BaseOperation<Editor.Data.Structure.Model> {
  private elementId: string;
  private editionContext: Editor.Edition.Context;
  private options: Editor.Edition.RemoveBlockOptions;

  constructor(
    editionContext: Editor.Edition.Context,
    model: Editor.Data.Structure.Model,
    elementId: string,
    options: Editor.Edition.RemoveBlockOptions = {},
  ) {
    super(model);

    this.elementId = elementId;
    this.editionContext = editionContext;
    this.options = {
      blockSelectionFix: 'PREV',
      ...options,
    };

    this.build();
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const structureData = this.model.selectedData();

    if (!structureData) {
      return this;
    }

    const structureNodes = structureData.childNodes;

    const elementIndex = structureNodes.indexOf(this.elementId);

    if (elementIndex != null && elementIndex >= 0) {
      // remove the node from the structure
      this.ops.push(RealtimeOpsBuilder.listDelete(this.elementId, ['childNodes', elementIndex]));
      // check if we have block properties, something might have gone wrong
      if (Object.prototype.hasOwnProperty.call(structureData.blkProps, this.elementId)) {
        const props = structureData.blkProps[this.elementId];
        this.ops.push({
          od: props,
          p: ['blkProps', this.elementId],
        });
        // we might need to override the style list definition with an invalid inline
        // to remove a block from an outline. This check has to stay here
        if (props.lst && props.lst.lId && structureData.lists[props.lst.lId]) {
          const listPosition = structureData.lists[props.lst.lId].n.indexOf(this.elementId);
          if (listPosition >= 0) {
            if (structureData.lists[props.lst.lId].n.length === 1) {
              this.ops.push({
                p: ['lists', props.lst.lId],
                od: structureData.lists[props.lst.lId],
              });
            } else {
              this.ops.push({
                p: ['lists', props.lst.lId, 'n', listPosition],
                ld: this.elementId,
              });
            }
          }
        }
      }
    }

    this.adjustPostOpPosition();

    return this;
  }

  adjustPostOpPosition(): void {
    // get the previous nodeModel
    const previousModel = this.editionContext.DataManager?.nodes.getPreviousModelById(
      this.elementId,
    );

    if (this.options.blockSelectionFix === 'PREV' && previousModel) {
      const previousData = previousModel.selectedData();

      const index = previousData?.childNodes?.length || 0;

      this.posOpPosition = {
        b: previousModel.id,
        p: ['childNodes', index],
      };

      // TODO: evaluate if it makes sense to have path adjustment for PREV option like we do for NEXT option
    } else {
      // get the next nodeModel
      const nextModel = this.editionContext.DataManager?.nodes.getNextModelById(this.elementId);

      if (nextModel) {
        let nextData = nextModel.selectedData();

        let path: Editor.Selection.Path = [];

        if (NodeUtils.isTrackedData(nextData) && nextData.childNodes?.[0]) {
          nextData = nextData.childNodes[0];
          path.push('childNodes', 0);
        }

        if (NodeUtils.isTableData(nextData)) {
          path.push(
            'childNodes',
            0,
            'childNodes',
            0,
            'childNodes',
            0,
            'childNodes',
            0,
            'childNodes',
            0,
          );
        } else if (NodeUtils.isMultiBlockContainerData(nextData)) {
          path.push('childNodes', 0, 'childNodes', 0);
        } else {
          path.push('childNodes', 0);
        }

        this.posOpPosition = {
          b: nextModel.id,
          p: path,
        };
      }
    }
  }
}

import React from 'react';
import { updateIntl } from 'react-intl-redux';
import getConfig from 'dodoc.config';
import deStrings from 'locales/de/translation.json';
import enStrings from 'locales/en/translation.json';
// import ptStrings from 'locales/pt/translation.json';
import esStrings from 'locales/es/translation.json';
// import noStrings from 'locales/no/translation.json';
import frStrings from 'locales/fr/translation.json';
import zhStrings from 'locales/zh-CN/translation.json';
import jaStrings from 'locales/ja/translation.json';
import { useSelector, useDispatch } from '_common/hooks/redux';
import styles from './LanguageMenu.module.scss';

const LANGUAGE_STRINGS = {
  en: enStrings,
  // pt: ptStrings,
  es: esStrings,
  // no: noStrings,
  fr: frStrings,
  de: deStrings,
  'zh-CN': zhStrings,
  ja: jaStrings,
};

const LanguageMenu = ({ hero = false, white = false }) => {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.intl.locale);
  const { languages } = getConfig();
  const renderItem = (code: Language['code'], name: Language['name'], index: number) => (
    <React.Fragment key={code}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const messages = LANGUAGE_STRINGS[code] || enStrings;
          dispatch(updateIntl({ locale: code, messages }));
        }}
        className={`${locale === code ? styles.active : undefined}`}
        data-testid={`${code}lang_login`}
      >
        {name}
      </span>
      {index !== languages.length - 1 && <span style={{ margin: '1rem' }}>•</span>}
    </React.Fragment>
  );
  return (
    <div
      className={`${styles.root} ${hero ? styles.hero : undefined} ${
        white ? styles.white : undefined
      }`}
    >
      {languages.map((lang, index) => renderItem(lang.code, lang.name, index))}
    </div>
  );
};

export default LanguageMenu;

import jsondiff from 'json0-ot-diff';
import { findIndex } from 'lodash-es';
import { getValidNodes } from '../DOMUtilities/DOMNormalizer/DOMNormalizer';
import { Logger } from '_common/services';

function getPlatformId(element) {
  if (element.hasAttribute('enclosed_element')) {
    return element.getAttribute('enclosed_element');
  }
  return element.id;
}

function fastFindIndex(array, target) {
  const len = array.length;
  // eslint-disable-next-line no-var
  for (var index = 0; index < len; index++) {
    if (array[index] === target) {
      return index;
    }
  }
  return -1;
}

function mapOrder(array, order, key) {
  array.sort((a, b) => {
    let A;
    let B;
    if (key) {
      A = a[key];
      B = b[key];
    } else {
      A = a;
      B = b;
    }

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });
  return array;
}

export default class DiffManager {
  static getNodeIdentifier(node) {
    if (node && node.hasAttribute('enclosed_element')) {
      return node.getAttribute('enclosed_element');
    }
    return node.id;
  }

  constructor(editorContext) {
    this.editorContext = editorContext;
  }

  diffThis(htmlDocument, addedNodes, removedNodes, changedNodes) {
    if (!this.editorContext.DataManager) {
      return null;
    }

    const parser = this.editorContext.documentParser;

    const result = {
      patch: {
        updatedOps: {},
        added: [],
        removed: [],
      },
    };
    let length;
    const docNodes = this.editorContext.DataManager.structure.getDocumentNodes();
    const htmlNodes = getValidNodes(htmlDocument).map((node) =>
      DiffManager.getNodeIdentifier(node),
    );
    let index;
    let referenceId;
    removedNodes = mapOrder(removedNodes, docNodes);
    length = removedNodes.length;

    for (let i = 0; i < length; i++) {
      const removingNode = this.editorContext.DataManager.nodes.getNodeForParser(removedNodes[i]);
      if (removingNode) {
        index = docNodes.indexOf(removedNodes[i]);
        if (index + 1 < docNodes.length) {
          referenceId = docNodes[index + 1];
        } else {
          referenceId = null;
        }

        result.patch.removed.push({
          node: removingNode,
          referenceId,
        });
      } else {
        Logger.captureMessage('Tried to remove an unexisting node');
      }
    }
    addedNodes = mapOrder(addedNodes, htmlNodes);
    length = addedNodes.length;
    let nodeId;
    let offset;

    for (let i = 0; i < length; i++) {
      nodeId = addedNodes[i];
      index = fastFindIndex(htmlNodes, nodeId);
      const addingNode = parser.parse(htmlDocument.childNodes.item(index));
      if (addingNode) {
        offset = 1;
        referenceId = null;
        while (index + offset < htmlNodes.length) {
          if (!removedNodes.includes(htmlNodes[index + offset])) {
            referenceId = htmlNodes[index + offset];
            break;
          }
          offset += 1;
        }

        result.patch.added.push({
          node: addingNode,
          referenceId,
        });
      } else {
        Logger.captureException('Tried to add an unexisting node');
      }
    }

    result.patch.updatedOps = this.diffChangedNodes(htmlDocument, changedNodes);

    result.patch.length =
      result.patch.removed.length +
      result.patch.added.length +
      Object.keys(result.patch.updatedOps).length;

    return result;
  }

  diffChangedNodes(htmlDocument, changedNodes) {
    const parser = this.editorContext.documentParser;

    const nextChildren = Array.from(htmlDocument.childNodes).map((node) => getPlatformId(node));
    const diffList = {};

    for (let index = 0; index < changedNodes.length; index++) {
      let duplicatedDiff = false;

      let blockNodeId = changedNodes[index];
      let childNodeId;
      if (blockNodeId.includes(':')) {
        [blockNodeId, childNodeId] = blockNodeId.split(':');

        if (blockNodeId === childNodeId) {
          childNodeId = null;
        }

        // avoid operation duplication
        if (changedNodes.includes(blockNodeId)) {
          duplicatedDiff = true;
        }
      }

      if (!duplicatedDiff) {
        const index = findIndex(nextChildren, (obj) => obj === blockNodeId);

        let node;
        if (childNodeId != null && childNodeId !== '') {
          node = htmlDocument.childNodes.item(index)?.querySelector(`[id="${childNodeId}"]`);
        } else {
          node = htmlDocument.childNodes.item(index);
        }

        const newNode = parser.parse(node);

        if (newNode != null && !newNode.ignore) {
          const nodeModel = this.editorContext.DataManager.nodes.getNodeModelById(blockNodeId);

          if (newNode && nodeModel) {
            let path = [];
            if (newNode.id !== nodeModel.get('id') && newNode.id === childNodeId) {
              path = nodeModel.findPath('id', childNodeId);
              // remove path id
              path.pop();
            }

            const oldNodeData = nodeModel.getChildDataByPath(path);

            if (newNode.id === oldNodeData.id) {
              const nodeDiff = jsondiff(oldNodeData, newNode);
              if (nodeDiff.length > 0) {
                for (let i = 0; i < nodeDiff.length; i++) {
                  const element = nodeDiff[i];
                  if (element.p && !element.p.includes('lock')) {
                    if (diffList[blockNodeId] == null) {
                      diffList[blockNodeId] = [];
                    }

                    element.p = [...path, ...element.p];
                    diffList[blockNodeId].push(element);
                  }
                }
              }
            }
          } else {
            global.logger.warn('Node Model not found ', blockNodeId);
          }
        }
      }
    }
    return diffList;
  }
}

import { ErrorBoundary } from 'dodoc-design-system';
import { ErrorBoundaryProps } from 'dodoc-design-system/build/types/Components/ErrorBoundary/ErrorBoundary';
import { useIntl } from 'react-intl';

type IntlErrorBoundaryProps = {
  disabled?: boolean;
  collapsed?: boolean;
} & ErrorBoundaryProps;

const IntlErrorBoundary = ({
  disabled,
  collapsed,
  children,
  label,
  tooltipPlacement,
  ...fallbackProps
}: IntlErrorBoundaryProps) => {
  const intl = useIntl();

  if (disabled || import.meta.env.MODE === 'test') {
    return <>{children}</>;
  }
  return (
    <ErrorBoundary
      {...fallbackProps}
      tooltipContent={
        collapsed
          ? (label ?? intl.formatMessage({ id: 'COULD_NOT_RETRIEVE_INFORMATION' }))
          : undefined
      }
      tooltipPlacement={collapsed ? tooltipPlacement : undefined}
      label={
        collapsed
          ? undefined
          : (label ?? intl.formatMessage({ id: 'COULD_NOT_RETRIEVE_INFORMATION' }))
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default IntlErrorBoundary;

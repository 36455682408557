import { Doc } from 'sharedb';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { NodeModel, Structure } from '../../models';
import { IndexerDeltaType, ModelIndexer } from '../Models/ModelIndexer';
import { Notes } from '../../models/Notes/Notes';
import { uniq } from 'lodash-es';

export class NoteModel extends BaseTypedEmitter<{
  UPDATED: (id: Notes.NoteDataId, data: Notes.NoteData, serial: number) => void;
}> {
  id: Notes.NoteDataId;
  data: Notes.NoteData;
  serial: number;

  constructor(id: Notes.NoteDataId, data: Notes.NoteData, serial: number) {
    super();
    this.id = id;
    this.data = data;
    this.serial = serial;
  }

  get author() {
    return this.data.author;
  }

  get content() {
    return this.data.content;
  }

  get type() {
    return this.data.type;
  }

  updateData(data: Notes.NoteData) {
    this.data = {
      ...this.data,
      ...data,
    };
    this.emit('UPDATED', this.id, this.data, this.serial);
  }

  updateSerial(serial: number) {
    if (this.serial !== serial) {
      this.serial = serial;
      this.emit('UPDATED', this.id, this.data, this.serial);
    }
  }

  update(data: Notes.NoteData, serial: number) {
    this.data = {
      ...this.data,
      ...data,
    };
    this.serial = serial;
    this.emit('UPDATED', this.id, this.data, this.serial);
  }
}

type NotesLocationsData = { [id: Notes.NoteDataId]: Notes.NoteLocation };

export class NotesList extends ModelIndexer<'NODE'> {
  notesModel?: Notes;
  protected footnotesIndex: Notes.NoteDataId[] = [];
  protected endnotesIndex: Notes.NoteDataId[] = [];
  protected notes: { [id: Notes.NoteDataId]: NoteModel } = {};
  protected notesLocations: NotesLocationsData = {};
  protected documentId?: string;
  protected structure?: Structure;

  constructor(transport: Transport, models: Editor.Data.Models.Controller) {
    super(transport, models, 'NODE');
    this.handleStructureLoaded = this.handleStructureLoaded.bind(this);
    this.handleStructureUpdated = this.handleStructureUpdated.bind(this);
    this.handleNodeNotesChanged = this.handleNodeNotesChanged.bind(this);
    this.handleModelLoaded = this.handleModelLoaded.bind(this);
    this.handleModelUpdated = this.handleModelUpdated.bind(this);
  }

  get footnotesList() {
    return this.footnotesIndex;
  }

  get endnotesList() {
    return this.endnotesIndex;
  }

  get data() {
    return this.notes;
  }

  get locations() {
    return this.notesLocations;
  }

  start(documentId: string) {
    this.documentId = documentId;
    this.notesModel = this.models.get('NOTES', `NTS${documentId}`);
    this.notesModel?.on('LOADED', this.handleModelLoaded);
    this.notesModel?.on('UPDATED', this.handleModelUpdated);
    this.startStructure();
  }

  private noteCreateOrUpdate(noteId: Notes.NoteDataId, data?: Notes.NoteData, serial?: number) {
    let _data = data || this.notesModel?.get(['nts', noteId]);
    let _serial = serial !== undefined ? serial : this.footnotesIndex.indexOf(noteId);
    if (!this.notes[noteId]) {
      this.notes[noteId] = new NoteModel(noteId, _data, _serial);
    } else {
      this.notes[noteId].update(_data, _serial);
    }
    return this.notes[noteId];
  }

  private handleModelLoaded() {
    // this.startStructure();
    let notesIds = Object.keys(this.notesModel?.get(['nts']));
    for (let index = 0; index < notesIds.length; index++) {
      this.noteCreateOrUpdate(notesIds[index]);
    }
  }

  private handleModelUpdated(
    data: Notes.NotesModelData | null,
    ops: Realtime.Core.RealtimeOps,
    source: any,
  ) {
    for (let index = 0; index < ops.length; index++) {
      if (ops[index].p.length >= 2) {
        const noteId: Notes.NoteDataId = ops[index].p[1] as Notes.NoteDataId;
        if (data?.nts[noteId]) {
          this.noteCreateOrUpdate(noteId, data.nts[noteId]);
        }
      }
    }
  }

  private startStructure() {
    this.structure = this.models.get(this.models.TYPE_NAME.STRUCTURE, `DS${this.documentId}`);
    this.structure.on('LOADED', this.handleStructureLoaded);
    this.structure.on('CHILDREN_UPDATE', this.handleStructureUpdated);
    if (this.structure.loaded) {
      this.handleStructureLoaded();
    }
  }

  private handleStructureLoaded() {
    this.qs = {
      id: { $in: this.structure?.childNodes },
      parent_id: this.documentId,
      $and: [
        {
          refs: { $exists: true },
        },
        {
          $or: [
            {
              'refs.fnt': { $not: { $size: 0 }, $exists: true },
            },
            {
              'refs.ent': { $not: { $size: 0 }, $exists: true },
            },
          ],
        },
      ],
    };
    !this.version && super.start(this.qs);
  }

  private handleStructureUpdated() {
    this.qs = {
      id: { $in: this.structure?.childNodes },
      parent_id: this.documentId,
      $and: [
        {
          refs: { $exists: true },
        },
        {
          $or: [
            {
              'refs.fnt': { $not: { $size: 0 }, $exists: true },
            },
            {
              'refs.ent': { $not: { $size: 0 }, $exists: true },
            },
          ],
        },
      ],
    };
    !this.version && super.start(this.qs);
  }

  private handleNodeNotesChanged() {
    !this.version && this.reIndex();
  }

  handleQueryReady() {
    this.ready = true;
    this.emit('LOADED', this.results);
  }

  handleQueryInsertedElements(docs: Doc[], index: number) {
    let newDocs: NodeModel[] = docs.map((doc) => {
      return this.models.get(this.typeName, doc);
    });
    this.results.splice(index, 0, ...newDocs);
    for (let index = 0; index < newDocs.length; index++) {
      const newNode = newDocs[index];
      newNode.on('LOADED', this.handleNodeNotesChanged);
      newNode.on('NOTES_CHANGED', this.handleNodeNotesChanged);
    }
    !this.version && this.reIndex();
    this.emit('INSERTED', newDocs);
  }

  handleQueryRemovedElements(docs: Doc[], index: number) {
    let oldDocs: NodeModel[] = this.results.splice(index, docs.length);
    for (let index = 0; index < oldDocs.length; index++) {
      const oldNode = oldDocs[index];
      oldNode.off('LOADED', this.handleNodeNotesChanged);
      oldNode.off('NOTES_CHANGED', this.handleNodeNotesChanged);
    }
    this.reIndex();
    this.emit('REMOVED', oldDocs);
  }

  handleQueryElementsChanged() {}

  protected adjustToNewIndex(
    fntIndex: string[],
    entIndex: string[],
    newLocations: NotesLocationsData,
  ) {
    let oldFntIndex = this.version?.fnt || this.footnotesIndex;
    let oldEntIndex = this.version?.ent || this.endnotesIndex;
    const fntDiff = NotesList.diffInOut(oldFntIndex, fntIndex, true);
    const entDiff = NotesList.diffInOut(oldEntIndex, entIndex, true);
    this.footnotesIndex = fntIndex;
    this.endnotesIndex = entIndex;
    this.notesLocations = newLocations;
    let notesDelta: IndexerDeltaType<{
      footNotes: Notes.NoteDataId[];
      endnotes: Notes.NoteDataId[];
    }> = {
      in: { footNotes: [], endnotes: [] },
      out: { footNotes: [], endnotes: [] },
      changedOrder: false,
    };
    if (fntDiff.in.length > 0 || fntDiff.out.length > 0 || fntDiff.changedOrder) {
      notesDelta.in.footNotes = fntDiff.in.length > 0 ? fntDiff.in : [];
      notesDelta.out.footNotes = fntDiff.out.length > 0 ? fntDiff.out : [];
      notesDelta.changedOrder = true;
      for (let index = 0; index < fntDiff.in.length; index++) {
        this.noteCreateOrUpdate(fntDiff.in[index]);
      }
      for (let index = 0; index < fntDiff.out.length; index++) {
        delete this.notes[fntDiff.out[index]];
      }
    }
    if (entDiff.in.length > 0 || entDiff.out.length > 0 || entDiff.changedOrder) {
      notesDelta.in.endnotes = entDiff.in.length > 0 ? entDiff.in : [];
      notesDelta.out.endnotes = entDiff.out.length > 0 ? entDiff.out : [];
      notesDelta.changedOrder = true;
      for (let index = 0; index < entDiff.in.length; index++) {
        this.noteCreateOrUpdate(entDiff.in[index]);
      }
      for (let index = 0; index < entDiff.out.length; index++) {
        delete this.notes[entDiff.out[index]];
      }
    }
    for (let index = 0; index < this.footnotesIndex.length; index++) {
      this.notes[this.footnotesIndex[index]].updateSerial(index);
    }
    for (let index = 0; index < this.endnotesIndex.length; index++) {
      this.notes[this.endnotesIndex[index]].updateSerial(index);
    }
    return notesDelta;
  }

  protected reIndex() {
    let tempFntIndex: Notes.NoteDataId[] = [];
    let tempEntIndex: Notes.NoteDataId[] = [];
    let newNotesLocations: NotesLocationsData = {};
    for (let index = 0, length = this.results.length; index < length; index++) {
      tempFntIndex.push(...this.results[index].footNotes);
      tempEntIndex.push(...this.results[index].endNotes);
      const newLocs = this.results[index].getNotesLocations();
      const locsKeys = Object.keys(newLocs);
      for (let index = 0; index < locsKeys.length; index++) {
        newNotesLocations[locsKeys[index]] = newLocs[locsKeys[index]];
      }
    }
    tempFntIndex = uniq(tempFntIndex);
    tempEntIndex = uniq(tempEntIndex);
    let notesDelta: IndexerDeltaType<{
      footNotes: Notes.NoteDataId[];
      endnotes: Notes.NoteDataId[];
    }> = this.adjustToNewIndex(tempFntIndex, tempEntIndex, newNotesLocations);
    !this.version && this.emit('CHANGED_DELTA', notesDelta);
  }

  note(id: Notes.NoteDataId) {
    return this.notes[id];
  }

  getFootnoteSerial(footnoteId: Notes.NoteDataId): number {
    return this.notes[footnoteId]?.serial;
  }

  getEndnoteSerial(endnoteId: Notes.NoteDataId): number {
    return this.notes[endnoteId]?.serial;
  }

  getNoteLocation(noteId: Notes.NoteDataId): Notes.NoteLocation {
    return this.version?.locations?.[noteId] || this.locations[noteId];
  }

  async addNote(
    noteId: Notes.NoteDataId | null,
    type: Notes.NoteType,
    content: string,
    author: string,
  ) {
    return this.notesModel?.addNote(noteId, type, content, author);
  }

  async editNote(noteId: Notes.NoteDataId, content?: string) {
    return this.notesModel?.editNote(noteId, content);
  }

  placement() {
    return this.notesModel?.placement();
  }

  async setPlacement(placement: Notes.NotePlacement) {
    return this.notesModel?.setPlacement(placement);
  }

  setVersionData(
    version: ApiSchemas['VersionsSchema'] | null,
    data?: {
      fnt: string[];
      ent: string[];
      locations: {};
    },
  ) {
    //
    let fntIndex: string[];
    let entIndex: string[];
    if (version) {
      this.version = {
        version,
        fnt: data?.fnt || [],
        ent: data?.fnt || [],
        locations: data?.locations || {},
      };
      fntIndex = this.version.fnt;
      entIndex = this.version.ent;
    } else {
      this.version = null;
      fntIndex = this.footnotesIndex;
      entIndex = this.endnotesIndex;
    }
    let notesDelta: IndexerDeltaType<{
      footNotes: Notes.NoteDataId[];
      endnotes: Notes.NoteDataId[];
    }> = this.adjustToNewIndex(fntIndex, entIndex, this.notesLocations);
    this.emit('CHANGED_DELTA', notesDelta);
  }

  refresh() {
    this.handleStructureLoaded();
  }
}

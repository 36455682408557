import { createEntityAdapter, createSelector, EntityState } from '@reduxjs/toolkit';
import type { TagDescription } from '@reduxjs/toolkit/dist/query';
import api from './api';

export type EditElementStatusProps = {
  id: ElementStatus['id'];
  status: Pick<
    ElementStatus,
    'name' | 'color' | 'visible' | 'allow_move' | 'allow_delete' | 'confirm_input'
  > & { allow_edit?: boolean } & { position?: number };
};

const statusAdapter = createEntityAdapter<ElementStatus>({
  sortComparer: (a, b) => {
    if (a.position === null) {
      return 1;
    }
    if (b.position === null) {
      return 1;
    }
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }

    return 0;
  },
});

const initialState = statusAdapter.getInitialState();

export const elementStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getElementStatusList: builder.query<EntityState<ElementStatus, ElementStatus['id']>, void>({
      query: () => ({
        url: `/object/status/list`,
      }),
      transformResponse: (responseData: ElementStatus[]) => {
        return statusAdapter.setAll(initialState, responseData);
      },
      providesTags: (result, error, arg) => {
        // Not sure if this TagDescription<T> is the most correct one
        const tags: TagDescription<'ElementStatus'>[] = ['ElementStatus'];
        if (result) {
          result.ids.forEach((id) => {
            tags.push({ type: 'ElementStatus', id });
          });
        }
        return tags;
      },
    }),
    createElementStatus: builder.mutation<ElementStatus, EditElementStatusProps['status']>({
      query: (status) => ({
        url: '/object/status/create',
        method: 'POST',
        body: status,
      }),
      invalidatesTags: ['ElementStatus'],
    }),
    deleteElementStatus: builder.mutation<
      undefined,
      Pick<ElementStatus, 'id'> & { status: ElementStatus['id'] }
    >({
      query: ({ id, status }) => ({
        url: `/object/status/${id}/delete`,
        method: 'POST',
        body: { status },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ElementStatus', id: arg.id }],
    }),
    editElementStatus: builder.mutation<undefined, EditElementStatusProps>({
      query: ({ id, status }) => ({
        url: `/object/status/${id}/edit`,
        method: 'POST',
        body: status,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'ElementStatus', id: arg.id }],
    }),
  }),
});

// Export queries
export const {
  useGetElementStatusListQuery,
  useCreateElementStatusMutation,
  useDeleteElementStatusMutation,
  useEditElementStatusMutation,
} = elementStatusApi;

// Select data from where RTK Query stores the data from the list endpoint
export const selectStatusList = elementStatusApi.endpoints.getElementStatusList.select();

// Create memoized selector from previous select
const selectStatusData = createSelector(
  selectStatusList,
  (statusListResult) => statusListResult.data,
);

// Use the selector to provide the path for the Entity Adapter selectors
export const { selectAll: selectElementStatusList, selectById: selectElementStatusById } =
  statusAdapter.getSelectors((state: RootState) => selectStatusData(state) ?? initialState);

// Use the Entity Adaptor selectors to build custom selectors
export const selectOrderedElementStatusList = createSelector(selectElementStatusList, (list) =>
  list.filter((status) => status.position !== null && !status.deleted),
);

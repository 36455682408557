import {
  createContext,
  DependencyList,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useEnvisionTheme, useSelector } from '_common/hooks';
import { useParams } from 'react-router';
import LoadingPage from '_common/components/Loading/LoadingPage/LoadingPage';
import { ErrorScreen } from '_common/components/ErrorBoundary/ErrorBoundary';
import { PresentationManager } from './services/PresentationManager';
import { SessionStorage } from '_common/utils';
import { setPresentationId } from './PresentationSlice';
import { closeModal } from 'App/ModalContext/utils';
import { openConnectionErrorModal, openDisconnectedModal } from './utils';

const PresentationContext = createContext<PresentationManager | undefined>(undefined);

const PresentationManagerProvider = ({ children }: { children: ReactNode }) => {
  useEnvisionTheme();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const userId = useSelector((state) => state.auth.userId);
  const token = SessionStorage.getToken();
  const [managerState, setManagerState] = useState<Presentation.Status>('NOT_READY');
  const [error, setError] = useState<any | null>(null);
  const [initializing, setInitializing] = useState(true);
  // const [loading, setLoading] = useState(true);

  const presentationManager = useRef<PresentationManager>();

  const statusChanged = useCallback((error: Error, status: Presentation.Status) => {
    setManagerState(status);
    if (error) {
      setInitializing(false);
      switch (error.message) {
        case 'SOI_CONNECT_ERROR':
          openConnectionErrorModal();
          break;
        case 'DISCONNECTED':
          openDisconnectedModal();
          break;
        default:
          setError(error);
          break;
      }
    } else {
      setError(null);
      if (status === 'READY') {
        closeModal('ConfirmationModal');
        setInitializing(false);
      } else if (status === 'DESTROYED' || status === 'DESTROYING') {
        setInitializing(false);
      }
    }
  }, []);

  const loading = useCallback((loading: boolean, reason?: string) => {
    console.log('loading...');
    // setLoading(loading);
  }, []);

  useEffect(() => {
    if (id && userId && token) {
      dispatch(setPresentationId(id));
      if (!presentationManager.current) {
        presentationManager.current = PresentationManager.getInstance();
      }
      if (presentationManager.current && !presentationManager.current.isInitialized()) {
        presentationManager.current.on('STATUS_CHANGED', statusChanged);
        presentationManager.current.on('LOADING', loading);
        presentationManager.current.connect(id, {
          id: userId,
          token,
        });
      }
    }

    return () => {
      dispatch(setPresentationId(null));
      if (presentationManager.current) {
        presentationManager.current.destroy();
      }
    };
  }, [id, userId, token]);

  return (
    <PresentationContext.Provider value={presentationManager.current}>
      {error && <ErrorScreen />}
      {!error && managerState === 'READY' && children}
      {initializing && <LoadingPage />}
    </PresentationContext.Provider>
  );

  // return <PresentationContext.Provider value={undefined}>{children}</PresentationContext.Provider>;
};

export const usePresentationManager = () => {
  const context = useContext(PresentationContext);
  if (context === undefined) {
    throw new Error('usePresentationManager can only be used in a PresentationManagerProvider');
  }
  return context;
};

export function usePresentationDataEvents<
  E extends Presentation.Data.DataEventsEventName,
  H extends Presentation.Data.DataEvents[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = usePresentationManager();
  useEffect(() => {
    manager?.getData()?.events.on(type, listener);
    return () => {
      manager?.getData()?.events.off(type, listener);
    };
  }, [type, listener, ...deps]);
}

export function usePresentationEvents<
  E extends keyof Presentation.Events,
  H extends Presentation.Events[E],
>(type: E, listener: H, deps: DependencyList = []) {
  const manager = usePresentationManager();
  useEffect(() => {
    manager?.on(type, listener);
    return () => {
      manager?.off(type, listener);
    };
  }, [type, listener, ...deps]);
}

export default PresentationManagerProvider;

 
 
import { uniq } from 'lodash-es';
import { Logger } from '_common/services';

import BaseController from '../BaseController';
import { DocumentModel } from '_common/services/Realtime';

export class PermissionsController extends BaseController {
  private _permissionsEvents: {
    [index: string]: any;
  };
  private _selectionForPermissions: string[] = [];
  private _doc?: DocumentModel;

  constructor(Data: Editor.Data.State) {
    super(Data);

    this._permissionsEvents = {
      'NODES:PERMISSIONS:UPDATE': this.handleNodePermissionsUpdateEvent.bind(this),
      'FORCE:REMOTE:RELOAD': this.handleForceRemoteReloadEvent.bind(this),
    };
  }

  private handleNodePermissionsUpdateEvent(payload: string[]) {
    if (this._selectionForPermissions.length) {
      if (payload.some((v) => this._selectionForPermissions.includes(v))) {
        this.selectBlocksForPermissions(this._selectionForPermissions);
      }
    }
  }

  private handleForceRemoteReloadEvent() {
    if (this._selectionForPermissions.length) {
      this.selectBlocksForPermissions(this._selectionForPermissions);
    }
  }

  start(documentId: string): void {
    const eventKeys = Object.keys(this._permissionsEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      this.Data.transport.handleEvent(event, this._permissionsEvents[event]);
    }
    this._doc = this.Data.models?.get(this.Data?.models.TYPE_NAME.DOCUMENT, documentId);
  }

  stop(): void {}

  destroy(): void {
    const eventKeys = Object.keys(this._permissionsEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      this.Data.transport.removeEvent(event, this._permissionsEvents[event]);
    }
  }

  allSelectedForPermissions(): boolean {
    return this.Data.structure?.getDocumentNodes().length === this._selectionForPermissions.length;
  }

  isSelectedForPermissions(blockId: string): boolean {
    return this._selectionForPermissions.includes(blockId);
  }

  async selectBlocksForPermissions(blocks: string[]): Promise<string[] | void> {
    if (!blocks || blocks.length === 0) {
      return this.deselectAllBlocksForPermissions();
    }
    this._selectionForPermissions = blocks;
    try {
      const summary = await this.documentPermissionsSummary(this._selectionForPermissions);
      this.Data.events?.emit('LOAD_PERMISSIONS_SUMMARY', {
        selected: this._selectionForPermissions,
        allSelected: this.allSelectedForPermissions(),
        summary: summary || {
          owners: {},
          users: {},
          byUser: {},
        },
      });
      return this._selectionForPermissions;
    } catch (error: any) {
      // TODO : LOADING STATE
      Logger.captureException(error);
      return this.deselectAllBlocksForPermissions();
    }
  }

  async selectAllBlocksForPermissions(): Promise<string[] | void> {
    if (this.Data.structure) {
      const blocks = await this.selectBlocksForPermissions(this.Data.structure.getDocumentNodes());

      this.Data.events?.emit('SELECT_ALL_FOR_PERMISSIONS', blocks || []);

      return blocks;
    }
  }

  deselectAllBlocksForPermissions(): void {
    this._selectionForPermissions = [];
    this.Data.events?.emit('LOAD_PERMISSIONS_SUMMARY', {
      selected: this._selectionForPermissions,
      allSelected: this.allSelectedForPermissions(),
      summary: {
        owners: {},
        users: {},
        byUser: {},
      },
    });
    this.Data.events?.emit('DESELECT_ALL_FOR_PERMISSIONS');
  }

  async usersWithBlockPermissions(nodeId: string) {
    const docPermissions = await this.documentPermissionsSummary([nodeId]);
    const node = this.Data.models?.get('NODE', nodeId);
    const nodePermissions = node?.get(['permissions']);
    if (docPermissions) {
      return uniq([
        ...Object.keys(docPermissions.owners),
        ...Object.keys(docPermissions.users).filter((userId: string) => {
          if (nodePermissions && nodePermissions.users && nodePermissions.users[userId]) {
            return nodePermissions.users[userId].includes('access');
          }
          return docPermissions.users[userId] && docPermissions.users[userId].access;
        }),
      ]);
    }
    return [];
  }

  canUserPerform(
    nodeId: string,
    action: Permissions.ObjectPermissionsType | Permissions.BlockPermissionsType,
  ) {
    if (
      this._doc?.user_permissions.includes('owner') ||
      this._doc?.user_permissions.includes('admin')
    ) {
      return true;
    }
    const node = this.Data.models?.get('NODE', nodeId);
    const permissions = node?.get(['permissions']);
    const userId = this.Data.users?.loggedUserId;
    if (userId) {
      if (!permissions || !permissions.users || !permissions.users[userId]) {
        // TODO : validate user permissions
        return this._doc?.user_permissions.includes(action);
      }
      return permissions.users[userId].includes(action);
    }
    return false;
  }

  documentPermissionsSummary(
    blocks: string[],
  ): Promise<Permissions.BlockPermissionSummary | undefined> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'DOCUMENT:PERMISSIONS:SUMMARY',
        {
          blocks,
        },
        (response) => {
          if (response.success) {
            resolve(response.payload);
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  addUserForPermissions(nodeIds: string[], userId: string, permissions: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:PERMISSION:ADD:USER',
        {
          nodeIds,
          userId,
          permissions,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  removeUserForPermission(nodeIds: string[], userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:PERMISSION:REMOVE:USER',
        {
          nodeIds,
          userId,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  addPermissionForUser(nodeIds: string[], userId: string, permission: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:PERMISSION:ADD:PERMISSION',
        {
          nodeIds,
          userId,
          permission,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }

  removePermissionForUser(nodeIds: string[], userId: string, permission: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.Data.transport.dispatchEvent(
        'NODE:PERMISSION:REMOVE:PERMISSION',
        {
          nodeIds,
          userId,
          permission,
        },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        },
      );
    });
  }
}

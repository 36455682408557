import { createSelector } from '@reduxjs/toolkit';
import { FILE_TYPE_FROM_MIME } from '_common/consts';
import { selectElementStatusList } from '_common/services/api/elementStatusApi';
import { getValidZipMime } from 'utils';

export type Action =
  | 'rename'
  | 'remove'
  | 'download'
  | 'downloadOriginalFile'
  | 'move'
  | 'copy'
  | 'checkOut'
  | 'update'
  | 'checkIn'
  | 'cancelCheckOut'
  | 'copyLink'
  | 'changeStatus'
  | 'exportDocumentTo'
  | 'exportVeeva'
  | 'exportDesktop'
  | 'convertTo'
  | 'openElement'
  | 'preview'
  | 'wopiView'
  | 'wopiEdit';

export type ActionsToShow = { [key in Action]: boolean };

const getData = (state: RootState) => state.app.data;
const getTriggerPage = (state: RootState) => state.search.triggerPage;
const getCurrentUserId = (state: RootState) => state.auth.userId;
const getSelected = (_: RootState, { selected }: { selected: ObjectId[] }) => selected;
const getSelectedData = (state: RootState, selected: ObjectId[]) =>
  selected.filter((id: ObjectId) => state.app.data[id]).map((id) => state.app.data[id]);
const getCurrentData = (state: RootState, { current }: { current?: { id: ObjectId } }) =>
  !current?.id ? null : state.app.data[current.id];
const getOptionsToShow = (_: RootState, { optionsToShow }: { optionsToShow: ActionsToShow }) =>
  optionsToShow;
const getCurrentFolder = (state: RootState) => state.storage.current;

/**
 *  User can rename if:
 *    -> only one object is selected
 *    -> it isn't a personal space
 *    -> status allows to edit
 *    -> if a file and currently checked out
 *      . must be the user who set as checked out
 *    -> it is inside any object except personal space and nothing is selected
 */

const rename = createSelector(
  [
    (state: RootState) => state,
    getOptionsToShow,
    getSelected,
    getCurrentUserId,
    selectElementStatusList,
  ],
  (state, show, selected, userId, statuses) => {
    if (!show || !show.rename) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selected.length !== 1) {
      return false;
    }
    const status = statuses.find(({ id }) => selectedData[0].status === id);

    if (
      status?.allow_edit !== true ||
      selectedData[0].status === 'broken' ||
      selectedData[0].status === 'processing'
    ) {
      return false;
    }
    if (
      ALLOWED_PERMISSIONS.some((permission) =>
        selectedData[0].user_permissions.includes(permission),
      )
    ) {
      if (selectedData[0].status === 'checked_out') {
        // ! TODO: Review if allow admin or owner to rename
        return (
          selectedData[0].lifecycle.checkouts[selectedData[0].lifecycle.checkouts.length - 1]
            .creator === userId
        );
      }
      return true;
    }
    return false;
  },
);

/**
 *  User can delete if:
 *    -> there is at least one object selected
 *    -> it has owner or delete permissons
 *    -> status is not checked_out and allows to edit
 */
const remove = createSelector(
  [
    (state: RootState) => state,
    getOptionsToShow,
    getSelected,
    selectElementStatusList,
    getTriggerPage,
  ],
  (state, show, selected, statuses, page) => {
    const selectedData = getSelectedData(state, selected);

    if (page === 'spaces') {
      const conditionsToEnableShare = ['admin', 'owner'];

      return (
        !selectedData[0]?.personal ||
        !conditionsToEnableShare.some(
          (permission) => selectedData[0] && selectedData[0].user_permissions.includes(permission),
        )
      );
    }

    if (page === 'shared') {
      return false;
    }
    if (!show || !show.remove) {
      return false;
    }
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'delete'];
    if (selectedData.length < 1) {
      return false;
    }
    return selectedData.every((object) => {
      const status = statuses.find(({ id }) => object.status === id);
      if (object.status === 'checked_out' || status?.allow_delete !== true) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can download if:
 *    -> at least 1 file is selected
 *    -> only files can be selected
 *    -> they must not be in a broken state
 */
const download = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.download) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
    if (selectedData.length < 1) {
      return false;
    }
    return selectedData.every((object) => {
      if (object.type !== 'file' || object.status === 'broken') {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can download original file if:
 *    -> only 1 .ddc document or .pdf file is selected
 *    -> only .ddc documents or .pdf files can be selected
 *    -> document must have source
 *    -> must not be in broken state
 *    -> must have access or admin/owner permission
 */
const downloadOriginalFile = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.downloadOriginalFile) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
    if (selectedData.length !== 1) {
      return false;
    }

    return selectedData.every((object) => {
      if (
        (object.type !== 'document' && object.type !== 'dopdf' && object.type !== 'presentation') ||
        object.has_source === false ||
        object.status === 'broken'
      ) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can move the selected items if:
 *    -> isAdmin
 *    -> has owner creator or edit permissions
 *    -> parent isn't approved
 */
const move = createSelector(
  [
    (state: RootState) => state,
    getOptionsToShow,
    getSelected,
    getCurrentData,
    getTriggerPage,
    selectElementStatusList,
  ],
  (state, show, selected, current, page, statuses) => {
    if (!page?.includes('storage')) {
      return false;
    }
    if (!show || !show.move) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selectedData.length < 1) {
      return false;
    }
    if (!current || current.status === 'approved') {
      return false;
    }
    return selectedData.every((object) => {
      const status = statuses.find(({ id }) => object.status === id);
      if (
        object.status === 'checked_out' ||
        object.status === 'processing' ||
        object.status === 'broken' ||
        status?.allow_move !== true
      ) {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => object.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can copy the selected items if:
 *    -> isAdmin
 *    -> has owner creator or edit permissions????
 */
const copy = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected, getTriggerPage],
  (state, show, selected, page) => {
    if (!page?.includes('storage')) {
      return false;
    }
    if (!show || !show.copy) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'access'];
    if (selectedData.length < 1) {
      return false;
    }
    return selectedData.every((node) => {
      if (node.status === 'broken' || node.status === 'processing') {
        return false;
      }
      return ALLOWED_PERMISSIONS.some((permission) => node.user_permissions.includes(permission));
    });
  },
);

/**
 *  User can enter check out a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions
 *    -> the file status allows to edit
 */
const checkOut = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected, selectElementStatusList],
  (state, show, selected, statuses) => {
    if (!show || !show.checkOut) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selectedData.length !== 1 || selectedData[0].status === 'broken') {
      return false;
    }
    const status = statuses.find(({ id }) => selectedData[0].status === id);
    return (
      selectedData[0].status !== 'checked_out' &&
      selectedData[0].type === 'file' &&
      status?.allow_edit === true &&
      ALLOWED_PERMISSIONS.some((permission) =>
        selectedData[0].user_permissions.includes(permission),
      )
    );
  },
);

/**
 *  User can update a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions
 *    -> the file status allows to edit
 */
const update = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected, selectElementStatusList],
  (state, show, selected, statuses) => {
    if (!show || !show.update) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);
    const ALLOWED_PERMISSIONS = ['admin', 'owner', 'edit'];
    if (selectedData.length !== 1 || selectedData[0].status === 'broken') {
      return false;
    }
    const status = statuses.find(({ id }) => selectedData[0].status === id);
    return (
      selectedData[0].type === 'file' &&
      status?.allow_edit === true &&
      ALLOWED_PERMISSIONS.some((permission) =>
        selectedData[0].user_permissions.includes(permission),
      )
    );
  },
);

/**
 *  User can check in a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions????
 *    -> the file is CHECKED OUT??
 */
const checkIn = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected, getCurrentUserId],
  (state, show, selected, userId) => {
    if (!show || !show.checkIn) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }
    if (
      selectedData[0].type !== 'file' ||
      selectedData[0].status !== 'checked_out' ||
      selectedData[0].status === 'broken'
    ) {
      return false;
    }
    if (
      selectedData[0].user_permissions.includes('admin') ||
      selectedData[0].user_permissions.includes('owner')
    ) {
      return true;
    }
    if (
      selectedData[0].user_permissions.includes('edit') &&
      selectedData[0].lifecycle &&
      selectedData[0].lifecycle.checkouts &&
      selectedData[0].lifecycle.checkouts[selectedData[0].lifecycle.checkouts.length - 1]
        .creator === userId
    ) {
      return true;
    }
    return false;
  },
);

/**
 *  User can cancel a check out a file if:
 *    -> the object is of type file
 *    -> it is the creator or has owner or edit permissions????
 *    -> the file is CHECKED OUT??
 */
const cancelCheckOut = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected, getCurrentUserId],
  (state, show, selected, userId) => {
    if (!show || !show.cancelCheckOut) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }
    if (
      selectedData[0].type !== 'file' ||
      selectedData[0].status !== 'checked_out' ||
      selectedData[0].status === 'broken'
    ) {
      return false;
    }
    if (
      selectedData[0].user_permissions.includes('admin') ||
      selectedData[0].user_permissions.includes('owner')
    ) {
      return true;
    }
    if (
      selectedData[0].user_permissions.includes('edit') &&
      selectedData[0].lifecycle &&
      selectedData[0].lifecycle.checkouts &&
      selectedData[0].lifecycle.checkouts[selectedData[0].lifecycle.checkouts.length - 1]
        .creator === userId
    ) {
      return true;
    }

    return false;
  },
);

const copyLink = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.copyLink) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }
    if (selectedData[0].type !== 'file' || selectedData[0].status === 'broken') {
      return false;
    }

    return true;
  },
);

const changeStatus = createSelector(
  [
    (state: RootState) => state,
    getOptionsToShow,
    getSelected,
    selectElementStatusList,
    getCurrentFolder,
    getData,
  ],
  (state, show, selected, statuses, currentId, data) => {
    if (!currentId) {
      return false;
    }

    const currentObj = data[currentId];
    // Can't change status of element with an edit = false status
    if (currentObj?.status) {
      const currentFolderStatus = statuses.find(({ id }) => currentObj.status === id);
      if (!currentFolderStatus?.allow_edit) {
        return false;
      }
    }
    if (!show || !show.changeStatus) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    const ALLOWED_PERMISSIONS = ['admin', 'owner'];
    if (selectedData.length !== 1) {
      return false;
    }
    const status = statuses.find(({ id }) => selectedData[0].status === id);
    if (status?.allow_change !== true) {
      return false;
    }
    return ALLOWED_PERMISSIONS.some((permission) =>
      selectedData[0].user_permissions.includes(permission),
    );
  },
);

const exportDocumentTo = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.exportDocumentTo) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);
    const ALLOWED_PERMISSIONS = ['admin', 'owner'];
    const allowedElements: ObjectType[] = ['document', 'dopdf', 'presentation', 'file'];

    if (!allowedElements.includes(selectedData[0]?.type)) {
      return false;
    }

    if (selectedData.length !== 1) {
      return false;
    }
    return ALLOWED_PERMISSIONS.some((permission) =>
      selectedData[0].user_permissions.includes(permission),
    );
  },
);

const convertTo = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.convertTo) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);
    const ALLOWED_PERMISSIONS = ['admin', 'owner'];
    if (selectedData.length === 0) {
      return false;
    }

    const firstSelected = selectedData[0];
    const mimeType = firstSelected?.mime?.type;

    const isValidZip = getValidZipMime(firstSelected);

    return (
      // @ts-expect-error type mismatch here
      (FILE_TYPE_FROM_MIME[mimeType] || isValidZip) &&
      selectedData.every((obj) => {
        return (
          obj.status !== 'broken' &&
          obj.type === 'file' &&
          obj.mime?.type === firstSelected.mime?.type &&
          ALLOWED_PERMISSIONS.some((permission) => obj.user_permissions.includes(permission))
        );
      })
    );
  },
);

const openElement = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || !show.openElement) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }

    const allowedElements = ['document', 'dopdf', 'presentation'];

    return selectedData.some((obj) => {
      return (
        obj.status !== 'broken' && obj.status !== 'processing' && allowedElements.includes(obj.type)
      );
    });
  },
);

const preview = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    if (!show || show.preview) {
      return false;
    }

    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }

    const object = selectedData[0];
    if (object.type !== 'file') {
      return false;
    }

    const zipMime = getValidZipMime(object);

    switch (zipMime ?? object.mime.type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
      case 'application/pdf':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return true;
    }

    return false;
  },
);

const wopiView = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }
    const file = selectedData[0] as doDOC.File;
    const wopiActions = file.wopi?.actions;
    if (wopiActions) {
      return wopiActions.some((action) => action.name === 'view');
    }
    return false;
  },
);

const wopiEdit = createSelector(
  [(state: RootState) => state, getOptionsToShow, getSelected],
  (state, show, selected) => {
    const selectedData = getSelectedData(state, selected);

    if (selectedData.length !== 1) {
      return false;
    }
    const file = selectedData[0] as doDOC.File;
    const wopiActions = file.wopi?.actions;
    if (wopiActions) {
      return wopiActions.some((action) => action.name === 'edit');
    }
    return false;
  },
);

const selectAvailableActions = createSelector(
  [
    rename,
    remove,
    download,
    downloadOriginalFile,
    move,
    copy,
    checkOut,
    update,
    checkIn,
    cancelCheckOut,
    copyLink,
    changeStatus,
    exportDocumentTo,
    convertTo,
    openElement,
    preview,
    wopiView,
    wopiEdit,
  ],
  (
    rename,
    remove,
    download,
    downloadOriginalFile,
    move,
    copy,
    checkOut,
    update,
    checkIn,
    cancelCheckOut,
    copyLink,
    changeStatus,
    exportDocumentTo,
    convertTo,
    openElement,
    preview,
    wopiView,
    wopiEdit,
  ) =>
    ({
      rename,
      remove,
      download,
      downloadOriginalFile,
      move,
      copy,
      checkOut,
      update,
      checkIn,
      cancelCheckOut,
      copyLink,
      changeStatus,
      exportDocumentTo,
      convertTo,
      openElement,
      preview,
      wopiView,
      wopiEdit,
    }) as ActionsToShow,
);

export default selectAvailableActions;

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

export type SidebarSliceState = {
  view:
    | 'APPROVE_CONTENT'
    | 'CONTENT_PERMISSIONS'
    | 'REVIEW'
    | 'REFERENCES'
    | 'NOTES'
    | 'CROSS_REFERENCES'
    | 'NAVIGATION'
    | 'VERSIONS'
    | 'SPELL_CHECK'
    | 'TASKS'
    | 'DOCUMENT_STYLES'
    | 'FIND_AND_REPLACE'
    | null;
  tabs: {
    review: 'comments' | 'changes';
    citations: 'library' | 'document';
    notes: 'footnotes' | 'endnotes';
  };
};

const SLICE_NAME = 'EditorSidebar';
const initialState: SidebarSliceState = {
  view: null,
  tabs: {
    review: 'comments',
    citations: 'library',
    notes: 'footnotes',
  },
};

// #region AsyncThunks
// #endregion

// #region Selectors
// #endregion

// #region Slice
type SidebarPanelTab<
  V extends keyof SidebarSliceState['tabs'],
  T extends SidebarSliceState['tabs'][V],
> = { view: V; tab: T };
const SidebarSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSidebarView(state, action: PayloadAction<SidebarSliceState['view']>) {
      state.view = action.payload;
    },
    setSidebarPanelTab<
      V extends keyof SidebarSliceState['tabs'],
      T extends SidebarSliceState['tabs'][V],
    >(state: SidebarSliceState, action: PayloadAction<SidebarPanelTab<V, T>>) {
      const { view, tab } = action.payload;
      state.tabs[view] = tab;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default SidebarSlice.reducer;
// #endregion

// #region Actions
export const { setSidebarView, setSidebarPanelTab } = SidebarSlice.actions;
// #endregion

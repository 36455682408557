/**
 * Extend object a with the properties of object b.
 * If there's a conflict, object b takes precedence.
 *
 * @param {object} a
 * @param {object} b
 */
export const extend = (a, b) => {
   
  for (const i in b) {
    a[i] = b[i];
  }
  return a;
};

import { BaseViewBuilder } from '../BaseViewBuilder';
import { TextElement } from './TextElement/TextElement';

export class TextViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<TextElement>
{
  get attributeMapper(): Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<TextElement> {
    return {};
  }

  build(json: Editor.Data.Node.Data): TextElement {
    return new TextElement(json.content || '');
  }

  shouldRenderChildren() {
    return false;
  }
}

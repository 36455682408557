import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { SelectOption } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { notify } from '_common/components/ToastSystem';
import { EditorService, Logger } from '_common/services';
import { paths } from '_types/api';

import { openAndUpdateModal, updateModal } from '_common/modals/ModalsSlice';
import { getDocumentObject } from './EditorStatusSlice';
import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

export type CitationsSliceState = {
  citations: { [key in Citation['id']]: Citation };
  order: string[];
  selected?: string;
  styleList: SelectOption[];
  currentStyleId?: string;
};

const SLICE_NAME = 'CITATIONS';
const initialState: CitationsSliceState = {
  citations: {},
  order: [],
  selected: undefined,
  styleList: [],
  currentStyleId: undefined,
};

function getDecodedURI(str: string | undefined): string {
  if (str) {
    try {
      return decodeURI(str);
    } catch {
      return str;
    }
  }
  return '';
}

// #region AsyncThunks
export const searchCitations = createAsyncThunk(
  `${SLICE_NAME}/searchCitations`,
  async (
    { documentId, query, source }: { documentId: string; query: string; source: string },
    { dispatch },
  ) => {
    const parsedQuery =
      source === 'scopus' ? `AUTH("${query}") OR TITLE("${query}") OR DOI("${query}")` : query;
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: {
          search: {
            list: [],
            dict: {},
            loading: true,
            total: 0,
          },
        },
      }),
    );

    try {
      const response = await new EditorService({ errorsExpected: [400] }).searchCitations(
        documentId,
        parsedQuery,
        source,
      );

      const { citations, total } = response.data;
      const search = citations.reduce(
        (citations, citation, index) => {
          const id = `${index + 1}`;
          citations.list.push(id);
          try {
            citations.dict[id] = {
              ...citation,
              source_title: getDecodedURI(citation.source_title),
              title: getDecodedURI(citation.title),
            };
          } catch (error) {
            Logger.captureException(error);
          }
          return citations;
        },
        { list: [], dict: {}, loading: false, total } as {
          list: Citation['id'][];
          dict: { [key in Citation['id']]: ApiSchemas['CitationSchema'] };
          loading: boolean;
          total: number;
        },
      );
      dispatch(updateModal({ modal: 'CitationsModal', data: { search } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR_SCREEN_HEADER',
          });
        } else {
          Logger.captureException(error);
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              data: { search: { list: [], dict: {}, loading: false, total: 0 } },
            }),
          );
        }
      }
    }
  },
);

export const importCitations = createAsyncThunk(
  `${SLICE_NAME}/importCitations`,
  async (
    params: paths['/api/object/document/citation/import']['post']['requestBody']['content']['multipart/form-data'],
    { dispatch },
  ) => {
    dispatch(
      updateModal({
        modal: 'CitationsModal',
        data: { import: { list: [], dict: {}, loading: true, dropzone: false } },
      }),
    );

    try {
      const response = await new EditorService({ errorsExpected: [400] }).importCitations(params);
      const citations = {
        list: [],
        dict: {},
        loading: false,
        dropzone: false,
      };
      //@ts-expect-error
      response.data.forEach((citation, index) => {
        if (!citation.id) {
          citation.id = citation.uid || `${index + 1}`;
        }

        //@ts-expect-error
        citations.dict[citation.id] = citation;
        //@ts-expect-error
        citations.list.push(citation.id);
      });
      dispatch(updateModal({ modal: 'CitationsModal', data: { import: citations } }));
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              data: { import: { list: [], dict: {}, loading: false, dropzone: false } },
            }),
          );
          notify({
            type: 'error',
            title: 'global.error',
            message: 'ERROR_SCREEN_HEADER',
          });
        } else {
          Logger.captureException(error);
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              data: { import: { list: [], dict: {}, loading: false, dropzone: true } },
            }),
          );
        }
      }
    }
  },
);

export const getScopusCitationAuthors = createAsyncThunk<
  void,
  { documentId: string; citationId: string; scopusId: string },
  { state: RootState }
>(
  `${SLICE_NAME}/getScopusCitationAuthors`,
  async ({ documentId, citationId, scopusId }, { getState, dispatch }) => {
    try {
      const response = await new EditorService().getScopusCitationAuthors(documentId, scopusId);
      const data = getState().modals.CitationsModal.search;

      const newDict = {
        ...data.dict,
        //@ts-expect-error API's getScopusCitationAuthors endpoint has wrong type
        [citationId]: { ...data.dict[citationId], author: response.data.join(' and ') },
      };
      delete newDict[citationId].scopus_id;
      dispatch(
        updateModal({
          modal: 'CitationsModal',
          data: { search: { ...data, dict: newDict, loading: false } },
        }),
      );
    } catch (error) {
      Logger.captureException(error);
    }
  },
);

export const exportCitations = createAsyncThunk(
  `${SLICE_NAME}/exportCitations`,
  async (
    {
      documentId,
      params,
    }: {
      documentId: ObjectId;
      params: paths['/api/object/document/{document_id}/citation/export']['post']['requestBody']['content']['multipart/form-data'];
    },
    { dispatch },
  ) => {
    try {
      const payload = await new EditorService({ errorsExpected: [400] }).exportCitations(
        documentId,
        params,
      );

      if (!params.target) {
        const url = window.URL.createObjectURL(payload.data);
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', params.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      notify({
        type: 'success',
        title: 'REFERENCE_LIBRARY_EXPORTED',
        message: 'REFERENCE_LIBRARY_EXPORTED_MESSAGE',
      });
    } catch (error) {
      if (EditorService.isAxiosError(error)) {
        if (error?.response?.status === 400) {
          dispatch(
            openAndUpdateModal({
              modal: 'ConfirmationModal',
              data: {
                title: 'UPDATE_REFERENCE_LIBRARY',
                message: 'UPDATE_REFERENCE_LIBRARY_MESSAGE',
                confirmButtonTextId: 'EXPORT_REFERENCES',
                confirmButtonType: 'primary',
                cancelButtonTextId: 'GO_BACK',
                actionCode: 'exportCitations',
                actionValue: { documentId, params },
                headerType: 'error',
                cancelButtonShow: true,
                width: '75rem',
              },
            }),
          );
        }
      }
    }
  },
);
// #endregion

// #region Selectors
export const selectDocumentCitations = createSelector([getDocumentObject], (document) => {
  let citations: {
    list: Citation['id'][];
    dict: { [key in Citation['id']]: Citation };
  } = {
    dict: {},
    list: [],
  };
  if (document && document.citations && Array.isArray(document.citations)) {
    citations = document.citations.reduce((citations, citation) => {
      citations.dict[citation.id] = citation;
      citations.list.push(citation.id);
      return citations;
    }, citations);
  }
  return citations;
});
// #endregion

// #region Slice
const CitationsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    citationsLoaded: (
      state,
      action: PayloadAction<Pick<CitationsSliceState, 'citations' | 'order'>>,
    ) => {
      const { citations, order } = action.payload;

      state.citations = citations;
      state.order = order;
    },
    setSelectedCitation: (
      state,
      action: PayloadAction<Required<CitationsSliceState['selected']>>,
    ) => {
      state.selected = action.payload;
    },
    referenceStylesLoaded: (
      state,
      action: PayloadAction<Pick<CitationsSliceState, 'styleList'>>,
    ) => {
      state.styleList = action.payload.styleList;
    },
    setReferenceStyleId: (
      state,
      action: PayloadAction<{ referenceStyleId: Required<CitationsSliceState['currentStyleId']> }>,
    ) => {
      state.currentStyleId = action.payload.referenceStyleId;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export default CitationsSlice.reducer;
// #endregion

// #region Actions
export const { citationsLoaded, setSelectedCitation, referenceStylesLoaded, setReferenceStyleId } =
  CitationsSlice.actions;
// #endregion

import { v4 as uuid } from 'uuid';
import { intersection } from 'lodash-es';
import { ELEMENTS } from 'Editor/services/consts';
import { PathUtils } from 'Editor/services/_Common/Selection';

type Descendants = {
  [index in Editor.Data.Node.DataTypes]?: Editor.Data.Node.DataTypes[];
};

const INDENTATION_LIMITS = {
  INDENTATION_STEP: 36,
  LEFT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  RIGHT: {
    MANIPULATION_MIN: -1440,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: -72,
    RENDER_MAX: 216,
  },
  SPECIAL_INDENT: {
    MANIPULATION_MIN: 0,
    MANIPULATION_MAX: 1440,
    RENDER_MIN: 0,
    RENDER_MAX: 216,
  },
} as const;

export class NodeUtils {
  // units in points (pt)
  static get INDENTATION_LIMITS() {
    return INDENTATION_LIMITS;
  }

  static NON_CONTENT_ELEMENTS: Editor.Data.Node.DataTypes[] = ['citations-group', 'tracked-delete'];
  static NON_CONTENT_ELEMENTS_WITH_PROPERTIES: any = { 'tracked-insert': ['replacewith'] };

  // ----------------------------------------------------------------
  //                    Inline Elements
  // ----------------------------------------------------------------
  static INLINE_NON_EDITABLE_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.CitationsGroupElement.ELEMENT_TYPE,
    // 'CITATION-ELEMENT',
    ELEMENTS.NoteElement.ELEMENT_TYPE,
    ELEMENTS.SymbolElement.ELEMENT_TYPE,
    ELEMENTS.EquationElement.ELEMENT_TYPE,
    ELEMENTS.InvalidElement.ELEMENT_TYPE,
    ELEMENTS.PlaceholderElement.ELEMENT_TYPE,
    ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
    ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
    ELEMENTS.TabElement.ELEMENT_TYPE,
    ELEMENTS.ImageElement.ELEMENT_TYPE,
    // 'FIELD-ELEMENT',
  ];

  static INLINE_WRAP_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.CommentElement.ELEMENT_TYPE,
    ELEMENTS.TemporaryComment.ELEMENT_TYPE,
    ELEMENTS.HyperlinkElement.ELEMENT_TYPE,
    ELEMENTS.FieldElement.ELEMENT_TYPE,
  ];

  static INLINE_TEXT_TYPES: Editor.Data.Node.DataTypes[] = [ELEMENTS.FormatElement.ELEMENT_TYPE];

  static INLINE_TYPES: Editor.Data.Node.DataTypes[] = [
    ...NodeUtils.INLINE_TEXT_TYPES,
    ...NodeUtils.INLINE_WRAP_TYPES,
    ...NodeUtils.INLINE_NON_EDITABLE_TYPES,
    ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
  ];

  static INLINE_LAST_CHILD_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
    ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
  ];

  static INLINE_NON_STYLABLE_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.CitationsGroupElement.ELEMENT_TYPE,
    ELEMENTS.NoteElement.ELEMENT_TYPE,
    ELEMENTS.EquationElement.ELEMENT_TYPE,
    ELEMENTS.InvalidElement.ELEMENT_TYPE,
    // ELEMENTS.FieldElement.ELEMENT_TYPE,
    ELEMENTS.PlaceholderElement.ELEMENT_TYPE,
    ELEMENTS.PageBreakElement.ELEMENT_TYPE,
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE,
    ELEMENTS.ColumnBreakElement.ELEMENT_TYPE,
    ELEMENTS.TabElement.ELEMENT_TYPE,
    ELEMENTS.ImageElement.ELEMENT_TYPE,
  ];

  // ----------------------------------------------------------------
  //                    Block Elements
  // ----------------------------------------------------------------
  static BLOCK_INVALID_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.InvalidElement.ELEMENT_TYPE,
    ELEMENTS.LoaderElement.ELEMENT_TYPE,
  ];

  static BLOCK_TEXT_TYPES: Editor.Data.Node.DataTypes[] = [ELEMENTS.ParagraphElement.ELEMENT_TYPE];

  static BLOCK_NON_TEXT_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.TableElement.ELEMENT_TYPE,
    ELEMENTS.FigureElement.ELEMENT_TYPE,
    ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
    ELEMENTS.TableOfLabelsElement.ELEMENT_TYPE,
    ELEMENTS.TableOfContentsElement.ELEMENT_TYPE,
    ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE,
    ELEMENTS.ListOfTablesElement.ELEMENT_TYPE,
    ELEMENTS.KeywordsElement.ELEMENT_TYPE,
    ELEMENTS.AuthorsElement.ELEMENT_TYPE,
  ];

  static BLOCK_EDITABLE_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.ParagraphElement.ELEMENT_TYPE,
    ELEMENTS.TableElement.ELEMENT_TYPE,
    ELEMENTS.FigureElement.ELEMENT_TYPE,
    ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
    ELEMENTS.TableOfLabelsElement.ELEMENT_TYPE,
    ELEMENTS.TableOfContentsElement.ELEMENT_TYPE,
  ];

  static BLOCK_NON_EDITABLE_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.ListOfFiguresElement.ELEMENT_TYPE,
    ELEMENTS.ListOfTablesElement.ELEMENT_TYPE,
    ELEMENTS.KeywordsElement.ELEMENT_TYPE,
    ELEMENTS.AuthorsElement.ELEMENT_TYPE,
  ];

  static BLOCK_DELETABLE_TYPES: Editor.Data.Node.DataTypes[] = [
    ...NodeUtils.BLOCK_EDITABLE_TYPES,
    ...NodeUtils.BLOCK_NON_EDITABLE_TYPES,
  ];

  static BLOCK_TYPES: Editor.Data.Node.DataTypes[] = [...NodeUtils.BLOCK_DELETABLE_TYPES];

  static MULTI_BLOCK_CONTAINER_TYPES: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.ReferencesSectionElement.ELEMENT_TYPE,
    ELEMENTS.TableCellElement.ELEMENT_TYPE,
    ELEMENTS.TableOfLabelsElement.ELEMENT_TYPE,
    ELEMENTS.TableOfContentsElement.ELEMENT_TYPE,
  ];

  static BLOCK_CONTAINER_TYPES: Editor.Data.Node.DataTypes[] = [
    ...NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
    // ELEMENTS.TrackInsertElement.ELEMENT_TYPE, // this types should not be here
    // ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
    ELEMENTS.TableElement.ELEMENT_TYPE,
    ELEMENTS.TableBodyElement.ELEMENT_TYPE,
    ELEMENTS.TableRowElement.ELEMENT_TYPE,
  ];

  static BLOCK_SPLITABLE_TYPES: Editor.Data.Node.DataTypes[] = [...NodeUtils.BLOCK_TEXT_TYPES];

  static DOUBLE_TYPE_ELEMENTS: Editor.Data.Node.DataTypes[] = [
    ELEMENTS.TrackInsertElement.ELEMENT_TYPE,
    ELEMENTS.TrackDeleteElement.ELEMENT_TYPE,
    ELEMENTS.PageBreakElement.ELEMENT_TYPE, // legacy
    ELEMENTS.SectionBreakElement.ELEMENT_TYPE, // legacy
  ];

  static ALLOWED_DESCENDANTS: Descendants = {
    rs: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete'],
    toc: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete'],
    tol: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete'],
    tbl: ['tblb'] /* 'tblh' */,
    // tblh: ['tblr'],
    tblb: ['tblr'],
    tblr: ['tblc'],
    tblc: ['p', 'tbl', 'figure', 'tracked-insert', 'tracked-delete'],
    figure: ['img', 'image-element'],
    p: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
    ],
    format: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'temp-comment': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    comment: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    f: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'citations-group': ['citation', 'tracked-insert', 'tracked-delete', 'format', 'text'],
    citation: ['text'],
    // 'cross-reference': [
    //   'link',
    //   'comment',
    //   'temp-comment',
    //   'tracked-insert',
    //   'tracked-delete',
    //   'format',
    //   'citations-group',
    //   'note',
    //   'symbol',
    //   'cross-reference',
    //   'equation',
    //   'f',
    //   'ph',
    //   'img',
    //   'tab',
    //   'text',
    // ],
    link: [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'tab',
      'text',
    ],
    'tracked-insert': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
      'tbl',
      'figure',
      'k',
      'a',
      'toc',
      'tof',
      'tot',
      'tol',
    ],
    'tracked-delete': [
      'link',
      'comment',
      'temp-comment',
      'tracked-insert',
      'tracked-delete',
      'format',
      'citations-group',
      'note',
      'symbol',
      'equation',
      'f',
      'ph',
      'img',
      'pb',
      'sb',
      'cb',
      'tab',
      'text',
      'tbl',
      'figure',
      'k',
      'a',
      'toc',
      'tof',
      'tot',
      'tol',
    ],
  };

  static RESTRICTED_DESCENDANTS: Descendants = {
    tbl: ['pb', 'sb', 'cb'],
  };

  static isRestrictedUnder(
    blockType: Editor.Data.Node.DataTypes,
    elementType: Editor.Data.Node.DataTypes,
  ): boolean {
    return !!(
      NodeUtils.RESTRICTED_DESCENDANTS[blockType] &&
      NodeUtils.RESTRICTED_DESCENDANTS[blockType]?.includes(elementType)
    );
  }

  static isAllowedUnder(
    parentType: Editor.Data.Node.DataTypes,
    elementType: Editor.Data.Node.DataTypes,
  ): boolean {
    return !!(
      NodeUtils.ALLOWED_DESCENDANTS[parentType] &&
      NodeUtils.ALLOWED_DESCENDANTS[parentType]?.includes(elementType)
    );
  }

  static isBlockInsertionAllowed(
    baseData: Editor.Data.Node.Data,
    pathToInsert: Editor.Selection.Path,
    newNodeData: Editor.Data.Node.Data,
  ) {
    const closestContainer = NodeUtils.closestOfTypeByPath(
      baseData,
      pathToInsert,
      NodeUtils.MULTI_BLOCK_CONTAINER_TYPES,
    );

    if (closestContainer) {
      return NodeUtils.isAllowedUnder(closestContainer.data.type, newNodeData.type);
    }

    return NodeUtils.isBlockTypeData(newNodeData);
  }

  private static getProperParentForReferenceNode(element: Editor.Data.Node.Data, ref: string) {
    const queue: { last: Editor.Data.Node.Data; node: Editor.Data.Node.Data }[] = [
      {
        last: element,
        node: element,
      },
    ];
    let result = element;
    let index;

    while ((index = queue.shift())) {
      const { node, last } = index;
      if (node.id === ref) {
        result = last;
        break;
      }
      if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(node.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type] &&
          intersection(
            Object.keys(node.properties || {}),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[node.type],
          ).length > 0
        ) &&
        node.childNodes
      ) {
        let properLast = last;
        if (node.type === 'p') {
          properLast = node;
        }

        const childreen = node.childNodes.map((value: Editor.Data.Node.Data) => ({
          last: properLast,
          node: value,
        }));

        queue.unshift(...childreen);
      }
    }
    return result;
  }

  private static getNodeContents(node: Editor.Data.Node.Data) {
    const queue = [node];
    let result = '';
    while (queue.length) {
      const element = queue.shift();
      if (element) {
        if (element.type === 'text') {
          result += element.content;
        } else if (
          !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
          !(
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
            intersection(
              Object.keys(element.properties || {}),
              NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
            ).length > 0
          ) &&
          element.childNodes
        ) {
          queue.unshift(...element.childNodes);
        }
      }
    }
    return result;
  }

  static getContent(element: Editor.Data.Node.Data, ref: string | null = null) {
    if (element.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      if (ref) {
        return NodeUtils.getNodeContents(NodeUtils.getProperParentForReferenceNode(element, ref));
      }
      return NodeUtils.getNodeContents(element);
    }
    if (element.type === 'p') {
      return NodeUtils.getNodeContents(element);
    }
    return null;
  }

  static getNodeContentsAfterField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        result = '';
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  static getNodeContentsBeforeField(node: Editor.Data.Node.Data, fieldId: string | undefined) {
    let result = '';
    if (!fieldId) {
      return result;
    }
    let queue = [JSON.parse(JSON.stringify(node))];
    if (node.type === ELEMENTS.TableElement.ELEMENT_TYPE) {
      queue = [NodeUtils.getProperParentForReferenceNode(node, fieldId)];
    }
    while (queue.length) {
      const element = queue.shift();
      if (element.type === 'f' && element.id === fieldId) {
        queue = [...(element.childNodes || [])];
      } else if (element.type === 'text') {
        result += element.content;
      } else if (
        !NodeUtils.NON_CONTENT_ELEMENTS.includes(element.type) &&
        !(
          NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type] &&
          intersection(
            Object.keys(element.properties),
            NodeUtils.NON_CONTENT_ELEMENTS_WITH_PROPERTIES[element.type],
          ).length > 0
        ) &&
        element.childNodes
      ) {
        queue.unshift(...element.childNodes);
      }
    }
    return result;
  }

  static closestOfTypeByPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[],
  ): Editor.Data.Node.DataPathInfo | null {
    let result: Editor.Data.Node.DataPathInfo | null = null;

    let types: Editor.Data.Node.DataTypes[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    let auxData = baseData;
    let lastKey: string | number | null = null;

    if (types.includes(auxData.type)) {
      result = {
        data: auxData,
        path: [],
      };
    }

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(auxData) && auxData.childNodes) {
          auxData = auxData.childNodes[+key];

          if (result?.data !== auxData && types.includes(auxData?.type)) {
            result = {
              data: auxData,
              path: path.slice(0, i + 1),
            };
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return result;
  }

  static closestSiblingAncestorOfType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[],
    searchThroughTypes: Editor.Data.Node.DataTypes[] = [],
  ) {
    let result: Editor.Data.Node.DataPathInfo | null = null;

    let types: Editor.Data.Node.DataTypes[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    result = NodeUtils.closestOfTypeByPath(baseData, path, types);

    if (!result) {
      // check previous and next
      const childData = NodeUtils.getChildDataByPath(baseData, path);
      const pathKey = path[path.length - 2];
      const pathOffset = Number(path[path.length - 1]);

      if (NodeUtils.isTextData(childData) && pathKey === 'content' && !isNaN(pathOffset)) {
        if (pathOffset === 0) {
          result = NodeUtils.getPreviousAncestor(baseData, path);

          while (
            result &&
            searchThroughTypes.includes(result.data.type) &&
            result.data.childNodes?.length
          ) {
            result = {
              data: result.data.childNodes[result.data.childNodes.length - 1],
              path: [...result.path, 'childNodes', result.data.childNodes.length - 1],
            };
          }
        } else if (pathOffset === childData.content.length) {
          result = NodeUtils.getNextAncestor(baseData, path);

          while (
            result &&
            searchThroughTypes.includes(result.data.type) &&
            result.data.childNodes?.length
          ) {
            result = {
              data: result.data.childNodes[0],
              path: [...result.path, 'childNodes', 0],
            };
          }
        }
      } else {
        const previous = NodeUtils.getPreviousAncestor(baseData, path);
        if (previous) {
          result = previous;
          while (
            result &&
            searchThroughTypes.includes(result.data.type) &&
            result.data.childNodes?.length
          ) {
            result = {
              data: result.data.childNodes[result.data.childNodes.length - 1],
              path: [...result.path, 'childNodes', result.data.childNodes.length - 1],
            };
          }
        } else if (childData) {
          result = {
            data: childData,
            path: path,
          };

          while (
            result &&
            searchThroughTypes.includes(result.data.type) &&
            result.data.childNodes?.length
          ) {
            result = {
              data: result.data.childNodes[0],
              path: [...result.path, 'childNodes', 0],
            };
          }
        }
      }
    }

    if (result && types.includes(result.data.type)) {
      return result;
    }

    return null;
  }

  static closestPreviousAncestorOfType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[],
  ) {
    let result: Editor.Data.Node.DataPathInfo | null = null;

    let types: Editor.Data.Node.DataTypes[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    result = NodeUtils.closestOfTypeByPath(baseData, path, types);

    if (!result || PathUtils.isPathEqual(path, result.path)) {
      // check previous
      const childData = NodeUtils.getChildDataByPath(baseData, path);
      const pathKey = path[path.length - 2];
      const pathOffset = Number(path[path.length - 1]);

      if (NodeUtils.isTextData(childData) && pathKey === 'content' && !isNaN(pathOffset)) {
        if (pathOffset === 0) {
          result = NodeUtils.getPreviousAncestor(baseData, path);
        }
      } else {
        result = NodeUtils.getPreviousAncestor(baseData, path);
      }
    }

    if (result && types.includes(result.data.type)) {
      return result;
    }

    return null;
  }

  static closestNextAncestorOfType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[],
  ) {
    let result: Editor.Data.Node.DataPathInfo | null = null;

    let types: Editor.Data.Node.DataTypes[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    result = NodeUtils.closestOfTypeByPath(baseData, path, types);

    if (!result) {
      // check next
      const childData = NodeUtils.getChildDataByPath(baseData, path);
      const pathKey = path[path.length - 2];
      const pathOffset = Number(path[path.length - 1]);

      if (NodeUtils.isTextData(childData) && pathKey === 'content' && !isNaN(pathOffset)) {
        if (pathOffset === childData.content.length) {
          result = NodeUtils.getNextAncestor(baseData, path);
        }
      } else if (childData) {
        result = {
          data: childData,
          path: path,
        };
      }
    }

    if (result && types.includes(result.data.type)) {
      return result;
    }

    return null;
  }

  static firstOfTypeByPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[],
  ): Editor.Data.Node.DataPathInfo | null {
    let types: Editor.Data.Node.DataTypes[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    let auxData = baseData;
    let lastKey: string | number | null = null;

    if (types.includes(auxData.type)) {
      return {
        data: auxData,
        path: [],
      };
    }

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(auxData) && auxData.childNodes) {
          auxData = auxData.childNodes[+key];

          if (types.includes(auxData?.type)) {
            return {
              data: auxData,
              path: path.slice(0, i + 1),
            };
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return null;
  }

  static getChildDataByPath(
    baseData: Editor.Data.Node.Data,
    path: Realtime.Core.RealtimePath,
  ): Editor.Data.Node.Data | undefined {
    let data = baseData;
    let lastKey: string | number | null = null;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (lastKey === 'childNodes' && !isNaN(+key)) {
        if (lastKey === 'childNodes' && NodeUtils.isElementData(data) && data.childNodes) {
          const child = data.childNodes[+key];
          if (child) {
            data = child;
          } else {
            return data;
          }
        }
      }

      if (key === 'childNodes') {
        lastKey = key;
      } else {
        lastKey = null;
      }
    }

    return data;
  }

  static getParentChildInfoByPath(baseData: Editor.Data.Node.Data, path: Editor.Selection.Path) {
    let parentPath: Editor.Selection.Path;
    let parentIndex: number = NaN;
    let childPath: Editor.Selection.Path;
    let childIndex: number = NaN;
    let contentIndex: number = NaN;

    if (path.length > 0) {
      if (path.includes('content')) {
        contentIndex = Number(path[path.length - 1]);
        childPath = path.slice(0, path.length - 2);
        childIndex = Number(childPath[childPath.length - 1]);
        parentPath = path.slice(0, path.length - 4);
        parentIndex = Number(parentPath[parentPath.length - 1]);
      } else {
        childPath = path.slice(0, path.length);
        childIndex = Number(childPath[childPath.length - 1]);
        parentPath = path.slice(0, path.length - 2);
        parentIndex = Number(parentPath[parentPath.length - 1]);
      }
    } else {
      parentPath = [];
      childPath = [];
    }

    let parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
    let childData = NodeUtils.getChildDataByPath(baseData, childPath);

    if (parentData) {
      return {
        parentData,
        parentPath,
        parentIndex,
        childData,
        childPath,
        childIndex,
        contentIndex,
      };
    }

    return null;
  }

  static getContentFromData(
    data?: Editor.Data.Node.Data | null,
    excludingChildTypes: Editor.Data.Node.DataTypes[] = [],
  ) {
    if (!data) {
      return '';
    }

    let content = data.content || '';

    if (data.childNodes) {
      const queue = [...data.childNodes];
      while (queue.length) {
        const child = queue.shift();

        if (child && !excludingChildTypes?.includes(child.type)) {
          if (child?.content) {
            content += child?.content;
          } else if (child?.childNodes) {
            queue.unshift(...child.childNodes);
          }
        }
      }
    }

    return content;
  }

  static querySelectorInData(
    data: Editor.Data.Node.Data,
    type: Editor.Data.Node.DataTypes | Editor.Data.Node.DataTypes[] = [],
    properties?: Editor.Data.Node.GenericProperties,
    options: { onlyFirstChild?: boolean } = {},
  ): Editor.Data.Node.DataPathInfo[] {
    let types: Editor.Data.Node.DataTypes[] = [];
    let elements: Editor.Data.Node.DataPathInfo[] = [];

    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }

    const queue: Editor.Data.Node.DataPathInfo[] = [
      {
        data,
        path: [],
      },
    ];

    while (queue.length) {
      const child = queue.shift();

      if (child) {
        const data = child.data;
        const path = child.path;

        let match = false;
        if (types.includes(data.type)) {
          match = true;
          // check properties
          if (properties) {
            const propKeys = Object.keys(properties);
            for (let p = 0; p < propKeys.length; p++) {
              const prop = propKeys[p];
              if (
                !child.data.properties?.[prop] ||
                child.data.properties?.[prop] !== properties[prop]
              ) {
                match = false;
                break;
              }
            }
          }

          if (match) {
            elements.push(child);
          }
        }

        if (data.childNodes && ((match && !options.onlyFirstChild) || !match)) {
          for (let i = data.childNodes.length - 1; i >= 0; i--) {
            queue.unshift({ data: data.childNodes[i], path: [...path, 'childNodes', i] });
          }
        }
      }
    }

    return elements;
  }

  static getElementDataById(
    data: Editor.Data.Node.Data,
    id: string,
  ): Editor.Data.Node.DataPathInfo | undefined {
    const queue: Editor.Data.Node.DataPathInfo[] = [
      {
        data,
        path: [],
      },
    ];

    while (queue.length) {
      const child = queue.shift();

      if (child) {
        const data = child.data;
        const path = child.path;

        if (data.id === id) {
          return child;
        }

        if (data.childNodes) {
          for (let i = data.childNodes.length - 1; i >= 0; i--) {
            queue.unshift({ data: data.childNodes[i], path: [...path, 'childNodes', i] });
          }
        }
      }
    }

    return;
  }

  static getPreviousAncestor(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    opts: { onlyAtContentStart?: boolean } = {},
  ): Editor.Data.Node.DataPathInfo | null {
    let options = {
      onlyAtContentStart: true,
      ...opts,
    };

    let parentPath;
    let childOffset;
    let contentOffset = NaN;
    if (path.includes('content')) {
      parentPath = path.slice(0, path.length - 4);
      childOffset = Number(path[path.length - 3]);
      contentOffset = Number(path[path.length - 1]);
    } else {
      parentPath = path.slice(0, path.length - 2);
      childOffset = Number(path[path.length - 1]);
    }
    let parentData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
      baseData,
      parentPath,
    );

    if (options.onlyAtContentStart && parentData?.childNodes) {
      let currentChild = parentData.childNodes[childOffset];
      if (NodeUtils.isTextData(currentChild) && !isNaN(contentOffset) && contentOffset !== 0) {
        return null;
      }
    }

    while (parentData && !isNaN(childOffset)) {
      if (parentData.childNodes?.[childOffset - 1]) {
        return {
          data: parentData.childNodes?.[childOffset - 1],
          path: [...parentPath, 'childNodes', childOffset - 1],
        };
      }

      if (parentPath.length > 0) {
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
      }
    }

    return null;
  }

  static getNextAncestor(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    opts: { onlyAtContentEnd?: boolean } = {},
  ): Editor.Data.Node.DataPathInfo | null {
    let options = {
      onlyAtContentEnd: true,
      ...opts,
    };

    let parentPath;
    let childOffset;
    let contentOffset = NaN;
    if (path.includes('content')) {
      parentPath = path.slice(0, path.length - 4);
      childOffset = Number(path[path.length - 3]);
      contentOffset = Number(path[path.length - 1]);
    } else {
      parentPath = path.slice(0, path.length - 2);
      childOffset = Number(path[path.length - 1]);
    }
    let parentData: Editor.Data.Node.Data | undefined = NodeUtils.getChildDataByPath(
      baseData,
      parentPath,
    );

    if (options.onlyAtContentEnd && parentData?.childNodes) {
      let currentChild = parentData.childNodes[childOffset];
      if (
        NodeUtils.isTextData(currentChild) &&
        !isNaN(contentOffset) &&
        contentOffset !== currentChild.content.length
      ) {
        return null;
      }
    }

    while (parentData && !isNaN(childOffset)) {
      if (parentData.childNodes?.[childOffset + 1]) {
        return {
          data: parentData.childNodes?.[childOffset + 1],
          path: [...parentPath, 'childNodes', childOffset + 1],
        };
      }

      if (parentPath.length > 0) {
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
      }
    }

    return null;
  }

  static getPreviousSibling(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Data.Node.DataPathInfo | null {
    const parentChildInfo = NodeUtils.getParentChildInfoByPath(baseData, path);

    if (parentChildInfo && !isNaN(parentChildInfo.childIndex) && parentChildInfo.childIndex > 0) {
      let previousOffset = parentChildInfo.childIndex - 1;
      let previousPath: Editor.Selection.Path = [
        ...parentChildInfo.parentPath,
        'childNodes',
        previousOffset,
      ];
      let previousData = parentChildInfo.parentData.childNodes?.[previousOffset];
      if (previousData) {
        return {
          data: previousData,
          path: previousPath,
        };
      }
    }

    return null;
  }

  static getNextSibling(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Data.Node.DataPathInfo | null {
    const parentChildInfo = NodeUtils.getParentChildInfoByPath(baseData, path);

    if (parentChildInfo) {
      let parentNodesLength = parentChildInfo.parentData.childNodes?.length || 0;

      if (
        !isNaN(parentChildInfo.childIndex) &&
        parentChildInfo.childIndex < parentNodesLength - 1
      ) {
        let nextOffset = parentChildInfo.childIndex + 1;
        let nextPath: Editor.Selection.Path = [
          ...parentChildInfo.parentPath,
          'childNodes',
          nextOffset,
        ];
        let nextData = parentChildInfo.parentData.childNodes?.[nextOffset];
        if (nextData) {
          return {
            data: nextData,
            path: nextPath,
          };
        }
      }
    }

    return null;
  }

  static expandStartPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Selection.Path | null {
    let parentPath = [...path];
    let childKey;
    let childOffset;
    let parentData: Editor.Data.Node.Data | undefined;

    while (!parentData) {
      // find closest parent in data
      childKey = parentPath[parentPath.length - 2];
      childOffset = Number(parentPath[parentPath.length - 1]);
      parentPath = parentPath.slice(0, parentPath.length - 2);

      parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
    }

    while (parentData && childOffset != null && childKey != null && !isNaN(childOffset)) {
      if (childOffset !== 0) {
        break;
      }

      if (parentPath.length >= 2) {
        childKey = parentPath[parentPath.length - 2];
        childOffset = Number(parentPath[parentPath.length - 1]);
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
        break;
      }
    }

    if (childOffset != null && childKey != null && !isNaN(childOffset)) {
      parentPath.push(childKey);
      parentPath.push(childOffset);
    }

    return parentPath;
  }

  static expandEndPath(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
  ): Editor.Selection.Path | null {
    let parentPath: Editor.Selection.Path = [...path];
    let childKey;
    let childOffset;
    let parentData: Editor.Data.Node.Data | undefined;

    while (!parentData) {
      // find closest parent in data
      childKey = parentPath[parentPath.length - 2];
      childOffset = Number(parentPath[parentPath.length - 1]);
      parentPath = parentPath.slice(0, parentPath.length - 2);

      parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
    }

    while (parentData && childOffset != null && childKey != null && !isNaN(childOffset)) {
      if (
        (NodeUtils.isTextData(parentData) && childOffset < parentData.content.length) ||
        (parentData.childNodes &&
          parentData.childNodes.length > 0 &&
          childOffset < parentData.childNodes.length)
      ) {
        break;
      }

      if (parentPath.length >= 2) {
        childKey = parentPath[parentPath.length - 2];
        childOffset = Number(parentPath[parentPath.length - 1]) + 1;
        parentPath = parentPath.slice(0, parentPath.length - 2);
        parentData = NodeUtils.getChildDataByPath(baseData, parentPath);
      } else {
        parentData = undefined;
        break;
      }
    }

    if (childOffset != null && childKey != null && !isNaN(childOffset)) {
      parentPath.push(childKey);
      parentPath.push(childOffset);
    }

    return parentPath;
  }

  static isPathAtContentStart(baseData: Editor.Data.Node.Data, path: Realtime.Core.RealtimePath) {
    let data: Editor.Data.Node.Data = baseData;
    let lastKey: string | number | null = null;

    if (path.length === 0) {
      return true; // WARN: Most of the cases this is intended to be true, not sure if validation should be here
    }

    let isAtStart = false;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (data) {
        if (lastKey === 'childNodes' && !isNaN(+key) && data.childNodes) {
          const index = +key;
          if (index === 0) {
            if (i === path.length - 1) {
              isAtStart = true;
            }
          } else if (data.childNodes.length) {
            // validate nodes before
            for (let j = 0; j < index; j++) {
              const child = data.childNodes[j];
              if (
                NodeUtils.isBlockContainerData(child) ||
                NodeUtils.isNonEditableInlineData(child)
              ) {
                return false;
              } else {
                const content = NodeUtils.getContentFromData(child);
                if (content.length === 0) {
                  isAtStart = true;
                } else {
                  return false;
                }
              }
            }
          }
          // drill down to children
          data = data.childNodes[index];
        } else if (lastKey === 'content' && !isNaN(+key)) {
          const index = +key;
          if (index === 0) {
            isAtStart = true;
          } else {
            return false;
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }
    }

    return isAtStart;
  }

  static isPathAtContentEnd(baseData: Editor.Data.Node.Data, path: Realtime.Core.RealtimePath) {
    let data: Editor.Data.Node.Data = baseData;
    let lastKey: string | number | null = null;

    let isAtEnd = false;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (data) {
        if (lastKey === 'childNodes' && !isNaN(+key) && data.childNodes) {
          const index = +key;
          if (index >= data.childNodes.length) {
            if (
              NodeUtils.isNonEditableInlineData(data) &&
              index === 0 &&
              data.childNodes.length === 0
            ) {
              return false;
            }
            return true;
          } else if (index !== 0 && index === data.childNodes.length - 1) {
            data = data.childNodes[index];
            const content = NodeUtils.getContentFromData(data);
            if (!NodeUtils.isNonEditableInlineData(data) && content.length === 0) {
              isAtEnd = true;
            }
          } else if (data.childNodes.length) {
            // validate nodes after

            let checkIndex;
            if (i === path.length - 1) {
              // path does not drill down inside
              // check self and after
              checkIndex = index;
            } else {
              // path drills down inside to child nodes or content
              // check only after
              checkIndex = index + 1;
            }

            for (let j = data.childNodes.length - 1; j >= checkIndex; j--) {
              const child = data.childNodes[j];
              if (
                (NodeUtils.isBlockContainerData(child) ||
                  NodeUtils.isNonEditableInlineData(child)) &&
                !NodeUtils.isParagraphMarker(child)
              ) {
                return false;
              } else {
                const content = NodeUtils.getContentFromData(child);
                if (content.length === 0) {
                  isAtEnd = true;
                } else {
                  return false;
                }
              }
            }
            // drill down to children
            data = data.childNodes[index];
          }
        } else if (lastKey === 'content' && !isNaN(+key) && data.content) {
          const index = +key;
          if (index === data.content.length && data.content.length !== 0) {
            isAtEnd = true;
          } else {
            return false;
          }
        }

        if (key === 'childNodes' || key === 'content') {
          lastKey = key;
        } else {
          lastKey = null;
        }
      }
    }

    return isAtEnd;
  }

  static isEmptyData(data?: Editor.Data.Node.Data | null) {
    if (data == null || !data.childNodes) {
      return false;
    }

    if (data.childNodes.length === 0) {
      return true;
    }

    const content = NodeUtils.getContentFromData(data);
    if (content.length) {
      return false;
    }

    const queue = [...data.childNodes];
    while (queue.length) {
      const child = queue.shift();

      if (child) {
        if (NodeUtils.isNonEditableInlineData(child) || NodeUtils.isImageData(child)) {
          return false;
        }

        if (child.childNodes) {
          queue.unshift(...child.childNodes);
        }
      }
    }

    return true;
  }

  static checkPropertyValueExists(
    elementData: Editor.Data.Node.Data,
    prop: string | number,
    value: any,
  ) {
    if (elementData.properties?.[prop] === value) {
      return true;
    }

    if (elementData.childNodes) {
      for (let i = 0; elementData.childNodes.length > i; i++) {
        if (this.checkPropertyValueExists(elementData.childNodes[i], prop, value)) {
          return true;
        }
      }
    }
    return false;
  }

  static findPreviousOrNextAncestorByType(
    baseData: Editor.Data.Node.Data,
    path: Editor.Selection.Path,
    type: string,
  ): Editor.Data.Node.Data | null {
    const previous = NodeUtils.getPreviousAncestor(baseData, path);
    const next = NodeUtils.getNextAncestor(baseData, path);

    let closest = null;
    if (previous?.data.type === type) {
      closest = previous.data;
    }

    if (next?.data.type === type) {
      closest = next.data;
    }

    const data = NodeUtils.getChildDataByPath(baseData, path);
    const isText = NodeUtils.isTextData(data);

    const startOffset = Number(path[path.length - 1]);

    if (
      isText &&
      ((startOffset !== 0 && startOffset !== data.content.length) ||
        (data.content.length === 0 && !data.childNodes))
    ) {
      return null;
    }

    return closest;
  }

  static cloneData(
    baseData: Editor.Data.Node.Data,
    startPath: Editor.Selection.Path,
    endPath: Editor.Selection.Path,
    allowEmptyTypes: Editor.Data.Node.DataTypes[] = [],
  ): Editor.Data.Node.Data[] {
    let clonedData: Editor.Data.Node.Data[] = [];

    if (PathUtils.isPathEqual(startPath, endPath)) {
      return clonedData;
    }

    let commonAncestorPath: Editor.Selection.Path = PathUtils.getCommonAncestorPath(
      startPath,
      endPath,
    );
    let commonAncestorData: Editor.Data.Node.Data | undefined;
    if (commonAncestorPath.length) {
      commonAncestorData = NodeUtils.getChildDataByPath(baseData, commonAncestorPath);
    } else {
      commonAncestorData = baseData;
    }

    if (commonAncestorData) {
      let adjustedStartPath = startPath.slice(commonAncestorPath.length);
      let adjustedEndPath = endPath.slice(commonAncestorPath.length);

      if (!adjustedStartPath.length) {
        if (NodeUtils.isTextData(commonAncestorData)) {
          adjustedStartPath = ['content', 0];
        } else {
          adjustedStartPath = ['childNodes', 0];
        }
      }

      if (!adjustedEndPath.length) {
        if (NodeUtils.isTextData(commonAncestorData)) {
          adjustedEndPath = ['content', 0];
        } else if (commonAncestorData.childNodes?.length) {
          adjustedEndPath = ['childNodes', 0];
        }
      }

      if (PathUtils.isPathEqual(adjustedStartPath, adjustedEndPath)) {
        // adjusted paths are collapsed
        return clonedData;
      }

      let startKey = adjustedStartPath[0];
      let startOffset = Number(adjustedStartPath[1]);
      let remainingStart = adjustedStartPath.slice(2, adjustedStartPath.length);

      let endKey = adjustedEndPath[0];
      let endOffset = Number(adjustedEndPath[1]);
      let remainingEnd = adjustedEndPath.slice(2, adjustedEndPath.length);

      if (!isNaN(startOffset) && !isNaN(endOffset)) {
        if (startKey === 'childNodes' && endKey === 'childNodes') {
          const childNodes = commonAncestorData.childNodes;

          if (childNodes) {
            const length = remainingEnd.length ? endOffset + 1 : endOffset;
            for (let i = startOffset; i < length; i++) {
              if (childNodes[i]) {
                const child: Editor.Data.Node.Data = JSON.parse(JSON.stringify(childNodes[i]));
                if (
                  (i === startOffset && remainingStart.length) ||
                  (i === length - 1 && remainingEnd.length)
                ) {
                  // start node and end node
                  if (NodeUtils.isTextData(child)) {
                    let startContent = 0;
                    if (remainingStart.includes('content') && i === startOffset) {
                      startContent = Number(remainingStart[remainingStart.indexOf('content') + 1]);
                    }

                    let endContent = child.content.length;
                    if (remainingEnd.includes('content') && i === length - 1) {
                      endContent = Number(remainingEnd[remainingEnd.indexOf('content') + 1]);
                    }

                    if (!isNaN(startContent) && !isNaN(endContent)) {
                      child.content = child.content.slice(startContent, endContent);
                      if (child.content.length > 0 || allowEmptyTypes.includes('text')) {
                        clonedData.push(child);
                      }
                    }
                  } else {
                    if (remainingStart.length === 0) {
                      remainingStart = ['childNodes', 0];
                    }

                    if (remainingEnd.length === 0) {
                      remainingEnd = ['childNodes', child.childNodes?.length || 0];
                    }

                    if (i === startOffset && i === length - 1) {
                      child.childNodes = NodeUtils.cloneData(
                        child,
                        remainingStart,
                        remainingEnd,
                        allowEmptyTypes,
                      );
                    } else if (i === startOffset) {
                      child.childNodes = NodeUtils.cloneData(
                        child,
                        remainingStart,
                        ['childNodes', child.childNodes?.length || 0],
                        allowEmptyTypes,
                      );
                    } else if (i === length - 1) {
                      child.childNodes = NodeUtils.cloneData(
                        child,
                        ['childNodes', 0],
                        remainingEnd,
                        allowEmptyTypes,
                      );
                    } else {
                      child.childNodes = NodeUtils.cloneData(
                        child,
                        ['childNodes', 0],
                        ['childNodes', child.childNodes?.length || 0],
                        allowEmptyTypes,
                      );
                    }
                    // WARN: CAREFULL WITH EMPTY NON-EDITABLES (PAGE-BREAK)
                    if (NodeUtils.isTextData(child) && child.content.length > 0) {
                      clonedData.push(child);
                    } else if (
                      NodeUtils.isNonEditableInlineData(child) ||
                      NodeUtils.isParagraphMarker(child)
                    ) {
                      clonedData.push(child);
                    } else if (NodeUtils.isElementData(child) && child.childNodes.length > 0) {
                      clonedData.push(child);
                    } else if (allowEmptyTypes.includes(child.type)) {
                      clonedData.push(child);
                    }
                  }
                } else {
                  // middle nodes
                  if (NodeUtils.isTextData(child) && child.content.length) {
                    clonedData.push(child);
                  } else if (
                    NodeUtils.isNonEditableInlineData(child) ||
                    NodeUtils.isParagraphMarker(child)
                  ) {
                    clonedData.push(child);
                  } else if (NodeUtils.isElementData(child) && child.childNodes?.length) {
                    clonedData.push(child);
                  } else if (allowEmptyTypes.includes(child.type)) {
                    clonedData.push(child);
                  }
                }
              }
            }
          }
        } else if (
          startKey === 'content' &&
          endKey === 'content' &&
          NodeUtils.isTextData(commonAncestorData)
        ) {
          const child: Editor.Data.Node.Data = JSON.parse(JSON.stringify(commonAncestorData));

          child.content = commonAncestorData.content.slice(startOffset, endOffset);
          if (child.content.length > 0) {
            clonedData.push(child);
          }
        }
      }
    }

    return clonedData;
  }

  static isTableCellHidden(element: Editor.Data.Node.Data) {
    return !!element?.properties?.['head-id'] && !element?.properties?.d !== false;
  }

  static isTableCellEmpty(cell: Editor.Data.Node.Data) {
    return (
      !cell.childNodes?.length ||
      (!NodeUtils.querySelectorInData(cell, 'img').length && !NodeUtils.getContentFromData(cell))
    );
  }

  static getCurrentAndParentIndexByPath(path: Editor.Selection.Path | Realtime.Core.AbstractPath) {
    const pathLength = path.length - 1;
    let currentIndex: number | null = null;
    let parentIndex: number | null = null;

    for (let i = pathLength; i > -1; i--) {
      let pathElement = path[i];
      const num = parseFloat(pathElement as string);

      if (!isNaN(num)) {
        if (currentIndex === null) {
          currentIndex = num;
        } else {
          parentIndex = num;
          break;
        }
      }
    }

    return {
      currentIndex,
      parentIndex,
    };
  }

  static canBlocksBeMerged(b1: Editor.Data.Node.Data, b2: Editor.Data.Node.Data) {
    return (
      NodeUtils.isBlockSplitableTypeData(b1) &&
      NodeUtils.isBlockSplitableTypeData(b2) &&
      b1.type === b2.type
    );
  }

  static generateRandomNodeId() {
    return `ddc${uuid()}`;
  }

  static generateUUID() {
    return uuid();
  }

  // ----------------------------------------------------------
  //               Position Iterators Utils
  // ----------------------------------------------------------
  static getNavigationPositionFromPath(
    navigationData: Editor.Data.Node.NavigationData[],
    path: Editor.Selection.Path,
  ): Editor.Data.Node.NavigationPosition {
    const position: Editor.Data.Node.NavigationPosition = {
      iterator: -1,
      info: -1,
      content: -1,
      globalOffset: -1,
    };

    if (navigationData.length === 1 && navigationData[0].content === null) {
      position.iterator = 0;
      position.info = 0;

      let pathOffset = Number(path[path.length - 1]);

      if (path[path.length - 2] === 'childNodes') {
        position.content = navigationData[0].info[0].contentOffsets.start + pathOffset;
        position.globalOffset = navigationData[0].info[0].globalOffsets.start + pathOffset;
      } else {
        position.content = navigationData[0].info[0].contentOffsets.start;
        position.globalOffset = navigationData[0].info[0].globalOffsets.start;
      }
    } else {
      for (let i = 0; i < navigationData.length; i++) {
        const navData = navigationData[i];
        for (let j = 0; j < navData.info.length; j++) {
          const info = navData.info[j];

          if (info.path.length !== 0 && PathUtils.isChildPath(info.path, path)) {
            position.iterator = i;
            position.info = j;

            let pathOffset = Number(path[path.length - 1]);

            if (path[path.length - 2] === 'content') {
              position.content = info.contentOffsets.start + pathOffset;
              position.globalOffset = info.globalOffsets.start + pathOffset;
            } else {
              position.content = info.contentOffsets.start;
              position.globalOffset = info.globalOffsets.start;
            }

            break;
          } else if (path.length !== 0 && PathUtils.isChildPath(path, info.path)) {
            position.iterator = i;
            position.info = j;
            position.content = info.contentOffsets.start;
            position.globalOffset = info.globalOffsets.start;
            break;
          } else if (PathUtils.comparePath(info.path, path) < 0 && j === navData.info.length - 1) {
            // condition needed when selection is at the end of the block
            position.iterator = i;
            position.info = j;
            position.content = info.contentOffsets.end;
            position.globalOffset = info.globalOffsets.end;
          }
        }
      }
    }

    return position;
  }

  static getNavigationDataInfoFromOffset(
    navigationData: Editor.Data.Node.NavigationData[],
    position: Editor.Data.Node.NavigationPosition,
  ) {
    let navData: Editor.Data.Node.NavigationData | undefined;
    let info: Editor.Data.Node.NavigationInfo | undefined;

    for (let n = 0; n < navigationData.length; n++) {
      navData = navigationData[n];
      if (navData) {
        for (let i = 0; i < navData.info.length; i++) {
          info = navData.info[i];
          if (info) {
            if (
              info.globalOffsets.start <= position.globalOffset &&
              info.globalOffsets.end >= position.globalOffset
            ) {
              return { navData, info };
            }
          }
        }
      }
    }
    return {};
  }

  static getPathFromNavigationPosition(
    navigationData: Editor.Data.Node.NavigationData[],
    position: Editor.Data.Node.NavigationPosition,
  ): Editor.Selection.Path | undefined {
    let path: Editor.Selection.Path | undefined;

    let navData: Editor.Data.Node.NavigationData | undefined;
    let info: Editor.Data.Node.NavigationInfo | undefined;

    if (position.globalOffset >= 0) {
      let data = NodeUtils.getNavigationDataInfoFromOffset(navigationData, position);
      navData = data.navData;
      info = data.info;

      if (navData && info) {
        if (navData.content == null) {
          // empty elements

          if (info.globalOffsets.end === position.globalOffset) {
            path = [...info.path];
            let pathOffset = Number(path[path.length - 1]);
            if (!isNaN(pathOffset)) {
              path[path.length - 1] = pathOffset + 1;
            }
          } else {
            path = [...info.path];
          }
        } else {
          if (
            info.globalOffsets.start <= position.globalOffset &&
            info.globalOffsets.end >= position.globalOffset
          ) {
            let contentOffset = position.globalOffset - info.globalOffsets.start;
            if (info.data.type === 'text') {
              path = [...info.path, 'content', contentOffset];
            } else {
              path = [...info.path, 'childNodes', contentOffset];
            }
          } else {
            path = [...info.path];
          }
        }
      }
    } else {
      navData = navigationData[position.iterator];
      info = navData?.info[position.info];

      if (navData && info) {
        if (navData.content == null) {
          // empty elements

          if (position.content === 1) {
            path = [...info.path];
            let pathOffset = Number(path[path.length - 1]);
            if (!isNaN(pathOffset)) {
              path[path.length - 1] = pathOffset + 1;
            }
          } else {
            path = [...info.path];
          }
        } else {
          if (
            position.content >= info.contentOffsets.start &&
            position.content <= info.contentOffsets.end
          ) {
            if (info.data.type === 'text') {
              path = [...info.path, 'content', position.content];
            } else {
              path = [...info.path, 'childNodes', position.content];
            }
          } else {
            path = [...info.path];
          }
        }
      }
    }

    return path;
  }

  // ----------------------------------------------------------
  //                 #region data type validations
  // ----------------------------------------------------------
  static isSupportedType(type: string): type is Editor.Data.Node.DataTypes {
    //@ts-expect-error
    return this.BLOCK_TYPES.includes(type) || this.INLINE_TYPES.includes(type);
  }

  static isInlineDataType(
    type: Editor.Data.Node.DataTypes,
  ): type is Editor.Data.Node.InlineDataTypes {
    return this.INLINE_TYPES.includes(type);
  }

  static isBlockDataType(
    type: Editor.Data.Node.DataTypes,
  ): type is Editor.Data.Node.BlockDataTypes {
    return this.BLOCK_TYPES.includes(type);
  }

  static isParagraphData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.ParagraphData {
    return data?.type === 'p';
  }

  static isTableData(data?: any): data is Editor.Data.Node.TableData {
    return NodeUtils.isElementData(data) && data?.type === 'tbl';
  }

  static isImageData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.ImageData {
    return data?.type === 'img' || data?.type === 'image-element';
  }

  static isTableRowData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableRowData {
    return data?.type === 'tblr';
  }

  static isTableCellData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableCellData {
    return data?.type === 'tblc';
  }

  static isTextData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.TextData {
    return data?.type === 'text';
  }

  static isTrackedData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TrackInsertData | Editor.Data.Node.TrackDeleteData {
    return data?.type === 'tracked-insert' || data?.type === 'tracked-delete';
  }

  static isBlockTrackedData(data?: Editor.Data.Node.Data | null) {
    return NodeUtils.isTrackedData(data) && NodeUtils.isBlockTypeData(data.childNodes?.[0]);
  }

  static isTrackInsertData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TrackInsertData {
    return data?.type === 'tracked-insert';
  }

  static isTrackDeleteData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TrackDeleteData {
    return data?.type === 'tracked-delete';
  }

  static isFigureData(data?: any): data is Editor.Data.Node.FigureData {
    return NodeUtils.isElementData(data) && data?.type === 'figure';
  }

  static isCommentData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.CommentData {
    return data?.type === 'comment' || data?.type === 'temp-comment';
  }

  static isCitationsGroupData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.CitationsGroupData {
    return data?.type === 'citations-group';
  }

  static isCitationData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.CitationData {
    return data?.type === 'citation';
  }

  static isFieldData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.FieldData {
    return data?.type === 'f';
  }

  static isFormatData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.FormatData {
    return data?.type === 'format';
  }

  static isFieldCaptionData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.FieldData {
    return NodeUtils.isFieldData(data) && data.properties.t === 'cpt';
  }

  static isTableOfContentsData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableOfContentsData {
    return data?.type === 'toc';
  }

  static isTableOfLabelsData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableOfLabelsData {
    return data?.type === 'tol';
  }

  static isLegacyTableOfFiguresData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableOfLabelsData {
    return data?.type === 'tof';
  }

  static isLegacyTableOfTablesData(
    data?: Editor.Data.Node.Data | null,
  ): data is Editor.Data.Node.TableOfLabelsData {
    return data?.type === 'tot';
  }

  static isNoteData(data?: Editor.Data.Node.Data | null): data is Editor.Data.Node.NoteData {
    return data?.type === 'note';
  }

  static isParagraphMarker(data?: Editor.Data.Node.Data | null) {
    return (
      NodeUtils.isTrackedData(data) &&
      (!!data.properties?.replacewith || !!data.properties?.replacewithsibling)
    );
  }

  static isElementData(data?: any): data is Editor.Data.Node.Data {
    return !!data?.type && !!data?.childNodes;
  }

  static isLastChildElementData(data?: any): data is Editor.Data.Node.Data {
    return (
      NodeUtils.isElementData(data) &&
      (NodeUtils.INLINE_LAST_CHILD_TYPES.includes(data.type) || NodeUtils.isParagraphMarker(data))
    );
  }

  static isSupportedInlineData(data?: any): data is Editor.Data.Node.Data {
    return (
      NodeUtils.isElementData(data) &&
      (NodeUtils.INLINE_TYPES.includes(data.type) || data.type === 'tblc')
    );
  }

  static isNonEditableInlineData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.INLINE_NON_EDITABLE_TYPES.includes(data.type);
  }

  static isNonStylableInlindeData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.INLINE_NON_STYLABLE_TYPES.includes(data.type);
  }

  static isInlineTextData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.INLINE_TEXT_TYPES.includes(data.type);
  }

  static isInlineData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.INLINE_TYPES.includes(data.type);
  }

  static isBlockTextData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_TEXT_TYPES.includes(data.type);
  }

  static isBlockEditableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_EDITABLE_TYPES.includes(data.type);
  }

  static isBlockTypeData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_TYPES.includes(data.type);
  }

  static isDoubleTypeData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.DOUBLE_TYPE_ELEMENTS.includes(data.type);
  }

  static isBlockSplitableTypeData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_SPLITABLE_TYPES.includes(data.type);
  }

  static isBlockDeletableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_DELETABLE_TYPES.includes(data.type);
  }

  static isBlockNonEditableData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_NON_EDITABLE_TYPES.includes(data.type);
  }

  static isBlockNonTextData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_NON_TEXT_TYPES.includes(data.type);
  }

  static isBlockContainerData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.BLOCK_CONTAINER_TYPES.includes(data.type);
  }

  static isMultiBlockContainerData(data?: Editor.Data.Node.Data | null) {
    return !!data && NodeUtils.MULTI_BLOCK_CONTAINER_TYPES.includes(data.type);
  }
  //#endregion
}

type GhostUserKey = 'davidBean';
export const GHOST_USERS: { [x in GhostUserKey]: UserPublicProfile } = {
  davidBean: {
    id: 'user-david-bean',
    email: 'dbean@dodoc.com',
    first_name: 'David',
    last_name: 'Bean',
    username: 'dbean',
    is_superuser: false,
    has_avatar: true,
  },
};

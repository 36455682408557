import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from '_common/hooks';
import { Select } from 'dodoc-design-system';
import { SelectProps } from 'dodoc-design-system/build/types/Components/Selects/Select';

import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';
import { selectUserIsAdmin } from '_common/services/api/authority';
import { addTag, removeTag } from 'App/redux/appSlice';
import {
  useGetTagsListQuery,
  useCreateTagMutation,
} from 'Settings/pages/TenantSettingsPage/Tags/TagsApi';

import Tags from './Tags/Tags';
import styles from './TagSystem.module.scss';

type TagSystemProps = {
  objectId: ObjectId;
  testId: string;
  placeholder: string;
} & Pick<SelectProps, 'menuPlacement' | 'size' | 'width'>;

const TagSystem = ({
  objectId,
  testId,
  placeholder,
  menuPlacement,
  size = 'medium',
  width = '100%',
}: TagSystemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const object = useSelector((state: RootState) => state.app.data[objectId]);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  const [mutCreateTag] = useCreateTagMutation();
  const { status } = useGetElementStatusListQuery(undefined, {
    selectFromResult: ({ data }) => ({ status: data?.entities[object.status] }),
  });

  const { data: tags, isLoading } = useGetTagsListQuery();

  const handleAddTag: SelectProps['onChange'] = (option) => {
    dispatch(addTag({ objectId: object.id, objectType: object.type, tag: option.value }));
  };

  const handleRemoveTag = (value: Tag) => {
    dispatch(removeTag({ objectId: object.id, objectType: object.type, tag: value }));
  };

  const handleCreateTag = (tag: Tag) => {
    mutCreateTag(tag);
    if (!object.tags.includes(tag)) {
      handleAddTag({
        value: tag,
        label: tag,
      });
    }
  };

  const checkPermissions = () => {
    return (
      object.user_permissions.includes('owner') ||
      object.user_permissions.includes('edit') ||
      object.user_permissions.includes('admin')
    );
  };

  return (
    <div className={styles.root} data-testid={`${testId}-tags-container`}>
      {checkPermissions() && (
        <div className={styles.inputContainer}>
          <Select
            testId={testId}
            disabled={status && !status?.['allow_edit']}
            showOptionTooltip
            creatable={userIsAdmin}
            size={size}
            width={width}
            value={null}
            onChange={handleAddTag}
            options={
              object.tags &&
              tags?.list
                .slice()
                .sort()
                .filter((tag) => !object.tags.includes(tag))
                .map((tag) => ({ value: tag, label: tag }))
            }
            placeholder={placeholder}
            noOptionsMessage={({ inputValue }) => {
              if (tags?.list.length === 0) {
                return intl.formatMessage({ id: 'TENANT_WITHOUT_TAGS' });
              }
              if (tags?.list.length === object.tags.length) {
                return intl.formatMessage({ id: 'ALL_TAGS_IN_USE' });
              }
              return intl.formatMessage({ id: 'NO_TAGS_FOUNDED' }, { tag: inputValue });
            }}
            onCreateOption={handleCreateTag}
            escapeClearsValue
            isValidNewOption={(input) => input.length > 0}
            isLoading={isLoading}
            menuPlacement={menuPlacement}
          />
        </div>
      )}

      {object.tags && (
        <Tags
          testId={testId}
          tags={object.tags}
          canEdit={checkPermissions()}
          removeTag={status?.allow_edit && checkPermissions() && handleRemoveTag}
        />
      )}
    </div>
  );
};

export default TagSystem;

import cx from 'classnames';
import { MouseEvent as ReactMouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from '_common/hooks';
import styles from './ResizableWidget.module.scss';

type ResizeState = {
  anchor: Corner | Side;
  originalX: number;
  originalY: number;
  currentX: number;
  currentY: number;
  keepAspectRatio: boolean;
} | null;

type Corner = 'nw' | 'ne' | 'se' | 'sw';
type Side = 'n' | 'e' | 's' | 'w';

type ResizableWidgetProps = {
  rect: Rect;
  corners?: {
    [Property in Corner]?: boolean;
  };
  sides?: {
    [Property in Side]?: boolean;
  };
  resize: (width: number, height: number) => void;
  keepAspectRatioByDefault: boolean;
  max?: { width?: number; height?: number };
};

const ResizableWidgetView = ({
  rect,
  corners,
  sides,
  resize,
  keepAspectRatioByDefault,
  max,
}: ResizableWidgetProps) => {
  const zoom = useSelector((state) => state.editor.status.zoom);
  const ref = useRef<HTMLDivElement | null>(null);
  const [resizeState, setResizeState] = useState<ResizeState>(null);

  useEffect(() => {
    if (resizeState) {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      return () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [resizeState]);

  const style = useMemo(() => {
    let width = rect.width;
    let height = rect.height;
    let top = 0;
    let left = 0;
    let deltaX = 0;
    let deltaY = 0;
    if (resizeState) {
      const [a, b] = resizeState.anchor.split('');
      let maxWidth: number | null = null;
      let maxHeight: number | null = null;

      if (max) {
        if (max.width) {
          maxWidth = Math.max(max.width - width, 0);
        }
        if (max.height) {
          maxHeight = Math.max(max.height - height, 0);
        }
      }

      if (a === 'n') {
        const delta = resizeState.originalY - resizeState.currentY;
        deltaY = Math.max(Math.min(maxHeight ?? delta, delta), -height + 50); // random number, decide what is best and put it in a const
        height += deltaY;
        top -= deltaY / zoom;
      }
      if (a === 'e') {
        const delta = resizeState.currentX - resizeState.originalX;
        deltaX = Math.max(Math.min(maxWidth ?? delta, delta), -width + 50);
        width += deltaX;
      }
      if (a === 's') {
        const delta = resizeState.currentY - resizeState.originalY;
        deltaY = Math.max(Math.min(maxHeight ?? delta, delta), -height + 50);
        height += deltaY;
      }
      if (a === 'w') {
        const delta = resizeState.originalX - resizeState.currentX;
        deltaX = Math.max(Math.min(maxWidth ?? delta, delta), -width + 50); // random number, decide what is best and put it in a const
        width += deltaX;
        left -= deltaX / zoom;
      }
      if (b === 'e') {
        if (resizeState.keepAspectRatio) {
          const ratio = height / rect.height;
          const delta = rect.width * ratio - rect.width;
          deltaX = Math.min(maxWidth ?? delta, delta);
        } else {
          const delta = resizeState.currentX - resizeState.originalX;
          deltaX = Math.max(Math.min(maxWidth ?? delta, delta), -width + 50);
        }
        width += deltaX;
      }
      if (b === 'w') {
        if (resizeState.keepAspectRatio) {
          const ratio = height / rect.height;
          const delta = rect.width * ratio - rect.width;
          deltaX = Math.min(maxWidth ?? delta, delta);
        } else {
          const delta = resizeState.originalX - resizeState.currentX;
          deltaX = Math.max(Math.min(maxWidth ?? delta, delta), -width + 50); // random number, decide what is best and put it in a const
        }
        width += deltaX;
        left -= deltaX / zoom;
      }
    }
    return { top, left, width: width / zoom, height: height / zoom, deltaX, deltaY };
  }, [resizeState, zoom, rect]);

  const onMouseDown = (e: ReactMouseEvent<HTMLDivElement>, anchor: Corner | Side) => {
    setResizeState({
      anchor,
      originalX: e.pageX,
      originalY: e.pageY,
      currentX: e.pageX,
      currentY: e.pageY,
      keepAspectRatio: e.shiftKey ? !keepAspectRatioByDefault : keepAspectRatioByDefault,
    });
  };

  const onMouseMove = (e: MouseEvent) => {
    if (resizeState) {
      setResizeState({
        ...resizeState,
        keepAspectRatio: e.shiftKey ? !keepAspectRatioByDefault : keepAspectRatioByDefault,
        currentX: e.pageX,
        currentY: e.pageY,
      });
    }
  };

  const onMouseUp = (e: MouseEvent) => {
    if (resizeState) {
      resizeElement();
      setResizeState(null);
    }
  };

  const resizeElement = () => {
    resize(style.width, style.height);
  };

  return (
    <div className={styles.root} style={style} ref={ref}>
      {(!sides || sides.n) && (
        <div
          className={cx(styles.anchor, styles.n)}
          onMouseDown={(e) => onMouseDown(e, 'n')}
          data-testid="side-n-resizable"
        />
      )}
      {(!sides || sides.e) && (
        <div
          className={cx(styles.anchor, styles.e)}
          onMouseDown={(e) => onMouseDown(e, 'e')}
          data-testid="side-e-resizable"
        />
      )}
      {(!sides || sides.s) && (
        <div
          className={cx(styles.anchor, styles.s)}
          onMouseDown={(e) => onMouseDown(e, 's')}
          data-testid="side-s-resizable"
        />
      )}
      {(!sides || sides.w) && (
        <div
          className={cx(styles.anchor, styles.w)}
          onMouseDown={(e) => onMouseDown(e, 'w')}
          data-testid="side-w-resizable"
        />
      )}
      {(!corners || corners.nw) && (
        <div
          className={cx(styles.anchor, styles.nw)}
          onMouseDown={(e) => onMouseDown(e, 'nw')}
          data-testid="corner-nw-resizable"
        />
      )}
      {(!corners || corners.ne) && (
        <div
          className={cx(styles.anchor, styles.ne)}
          onMouseDown={(e) => onMouseDown(e, 'ne')}
          data-testid="corner-ne-resizable"
        />
      )}
      {(!corners || corners.se) && (
        <div
          className={cx(styles.anchor, styles.se)}
          onMouseDown={(e) => onMouseDown(e, 'se')}
          data-testid="corner-se-resizable"
        />
      )}
      {(!corners || corners.sw) && (
        <div
          className={cx(styles.anchor, styles.sw)}
          onMouseDown={(e) => onMouseDown(e, 'sw')}
          data-testid="corner-sw-resizable"
        />
      )}
    </div>
  );
};

export default ResizableWidgetView;

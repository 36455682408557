import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';

import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { NodeUtils } from 'Editor/services/DataManager';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { ImageElement } from './ImageElement/ImageElement';

export class ImageViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<ImageElement, Editor.Data.Node.ImageData>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<
    ImageElement,
    Editor.Data.Node.ImageData
  > = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.ImageElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // source (legacy)
    source: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        const source = node.getAttribute('source');
        if (source != null) {
          const data = source.split('/');
          builder.addProperty('es', data[5]);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.source != null) {
          // node.setAttribute('source', json.properties.source);
          const data = json.properties.source.split('/');
          node.dataset.es = data[5];
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('source');
      },
    },
    // style (legacy)
    style: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('style')) {
          if (node.style.width != null) {
            builder.addProperty('w', EditorDOMUtils.convertUnitTo(node.style.width, 'px', 'pt', 3));
          }
          if (node.style.height != null) {
            builder.addProperty(
              'h',
              EditorDOMUtils.convertUnitTo(node.style.height, 'px', 'pt', 3),
            );
          }
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.style != null) {
          // node.setAttribute('style', json.properties['style']);

          const styles = json.properties.style.split(';');
          for (let s = 0; s < styles.length; s++) {
            if (styles[s].includes('width')) {
              const width = styles[s].slice(styles[s].indexOf(':') + 1, styles[s].length).trim();
              node.dataset.w = `${EditorDOMUtils.convertUnitTo(width, 'px', 'pt', 3)}`;
            }
            if (styles[s].includes('height')) {
              const height = styles[s].slice(styles[s].indexOf(':') + 1, styles[s].length).trim();
              node.dataset.h = `${EditorDOMUtils.convertUnitTo(height, 'px', 'pt', 3)}`;
            }
          }
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('style');
      },
    },
    // width
    w: {
      parse: (node, builder) => {
        if (node.dataset.w != null) {
          builder.addProperty('w', node.dataset.w);
        }
      },
      render: (json, node) => {
        if (json.properties?.w != null) {
          node.dataset.w = `${json.properties.w}`;
        }
      },
      remove: (node) => {
        delete node.dataset.w;
      },
    },
    // height
    h: {
      parse: (node, builder) => {
        if (node.dataset.h != null) {
          builder.addProperty('h', node.dataset.h);
        }
      },
      render: (json, node) => {
        if (json.properties?.h != null) {
          node.dataset.h = `${json.properties.h}`;
        }
      },
      remove: (node) => {
        delete node.dataset.h;
      },
    },
    // behind_doc
    bd: {
      parse: (node, builder) => {
        if (node.dataset.bd != null) {
          builder.addProperty('bd', node.dataset.bd);
        }
      },
      render: (json, node) => {
        if (json.properties?.bd != null) {
          node.dataset.bd = `${json.properties.bd}`;
        }
      },
      remove: (node) => {
        delete node.dataset.bd;
      },
    },
    // editor source
    es: {
      parse: (node, builder) => {
        if (node.dataset.es != null) {
          builder.addProperty('es', node.dataset.es);
        }
      },
      render: (json, node) => {
        if (json.properties?.es != null) {
          node.dataset.es = json.properties.es;
        }
      },
      remove: (node) => {
        delete node.dataset.es;
      },
    },
    // floating
    f: {
      parse: (node, builder) => {
        if (node.dataset.f != null) {
          builder.addProperty('f', node.dataset.f);
        }
      },
      render: (json, node) => {
        if (json.properties?.f != null) {
          node.dataset.f = `${json.properties.f}`;
        }
      },
      remove: (node) => {
        delete node.dataset.f;
      },
    },
    // layout in cell
    lc: {
      parse: (node, builder) => {
        if (node.dataset.lc != null) {
          builder.addProperty('lc', node.dataset.lc);
        }
      },
      render: (json, node) => {
        if (json.properties?.lc != null) {
          node.dataset.lc = `${json.properties.lc}`;
        }
      },
      remove: (node) => {
        delete node.dataset.lc;
      },
    },
    // locked
    l: {
      parse: (node, builder) => {
        if (node.dataset.l != null) {
          builder.addProperty('l', node.dataset.l);
        }
      },
      render: (json, node) => {
        if (json.properties?.l != null) {
          node.dataset.l = `${json.properties.l}`;
        }
      },
      remove: (node) => {
        delete node.dataset.l;
      },
    },
    // original source
    os: {
      parse: (node, builder) => {
        if (node.dataset.os != null) {
          builder.addProperty('os', node.dataset.os);
        }
      },
      render: (json, node) => {
        if (json.properties?.os != null) {
          node.dataset.os = json.properties.os;
        }
      },
      remove: (node) => {
        delete node.dataset.os;
      },
    },
    // src rect
    sr: {
      parse: (node, builder) => {
        if (node.dataset.sr != null) {
          builder.addProperty('sr', JSON.parse(node.dataset.sr));
        }
      },
      render: (json, node) => {
        if (json.properties?.sr != null) {
          node.dataset.sr = JSON.stringify(json.properties.sr);
        }
      },
      remove: (node) => {
        delete node.dataset.sr;
      },
    },
    // allow overlap
    ao: {
      parse: (node, builder) => {
        if (node.dataset.ao != null) {
          builder.addProperty('ao', node.dataset.ao);
        }
      },
      render: (json, node) => {
        if (json.properties?.ao != null) {
          node.dataset.ao = `${json.properties.ao}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ao;
      },
    },
    // X align,
    ax: {
      parse: (node, builder) => {
        if (node.dataset.ax != null) {
          builder.addProperty('ax', node.dataset.ax);
        }
      },
      render: (json, node) => {
        if (json.properties?.ax != null) {
          node.dataset.ax = `${json.properties.ax}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ax;
      },
    },
    // Y align,
    ay: {
      parse: (node, builder) => {
        if (node.dataset.ay != null) {
          builder.addProperty('ay', node.dataset.ay);
        }
      },
      render: (json, node) => {
        if (json.properties?.ay != null) {
          node.dataset.ay = `${json.properties.ay}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ay;
      },
    },
    // X offset,
    ox: {
      parse: (node, builder) => {
        if (node.dataset.ox != null) {
          builder.addProperty('ox', node.dataset.ox);
        }
      },
      render: (json, node) => {
        if (json.properties?.ox != null) {
          node.dataset.ox = `${json.properties.ox}`;
        }
      },
      remove: (node) => {
        delete node.dataset.ox;
      },
    },
    // Y offset,
    oy: {
      parse: (node, builder) => {
        if (node.dataset.oy != null) {
          builder.addProperty('oy', node.dataset.oy);
        }
      },
      render: (json, node) => {
        if (json.properties?.oy != null) {
          node.dataset.oy = `${json.properties.oy}`;
        }
      },
      remove: (node) => {
        delete node.dataset.oy;
      },
    },
    // X relative from
    rfx: {
      parse: (node, builder) => {
        if (node.dataset.rfx != null) {
          builder.addProperty('rfx', node.dataset.rfx);
        }
      },
      render: (json, node) => {
        if (json.properties?.rfx != null) {
          node.dataset.rfx = `${json.properties.rfx}`;
        }
      },
      remove: (node) => {
        delete node.dataset.rfx;
      },
    },
    // Y relative from
    rfy: {
      parse: (node, builder) => {
        if (node.dataset.rfy != null) {
          builder.addProperty('rfy', node.dataset.rfy);
        }
      },
      render: (json, node) => {
        if (json.properties?.rfy != null) {
          node.dataset.rfy = `${json.properties.rfy}`;
        }
      },
      remove: (node) => {
        delete node.dataset.rfy;
      },
    },
    // wrapping
    wr: {
      parse: (node, builder) => {
        if (node.dataset.wr != null) {
          builder.addProperty('wr', JSON.parse(node.dataset.wr));
        }
      },
      render: (json, node) => {
        if (json.properties?.wr != null) {
          node.dataset.wr = JSON.stringify(json.properties.wr);
        }
      },
      remove: (node) => {
        delete node.dataset.wr;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  build(json: Editor.Data.Node.ImageData, model?: Editor.Data.Node.Model) {
    const node = new ImageElement(this.Visualizer, this.Data);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    let pageWidth;
    let isAtPageStart: boolean = false;
    if (model?.id) {
      pageWidth = this.Data?.sections.getPageWidthForBlockId(model.id);
      const data = model.selectedData();
      if (json.id && data) {
        isAtPageStart = NodeUtils.isPathAtContentStart(data, model.findPathToChild(json.id));
      }
    }

    node.preRender(pageWidth, isAtPageStart);

    return node;
  }

  shouldRenderChildren(json?: Editor.Data.Node.ImageData | undefined) {
    return false;
  }
}

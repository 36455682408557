import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Checkbox } from 'dodoc-design-system';

import { notify } from '_common/components/ToastSystem';
import downloadFileFromBlob from '_common/utils/downloadFileFromBlob';
import { useJobs } from '_common/components/JobsContext/JobsContext';
import { useSelector, useDispatch, useObject } from '_common/hooks';
import { setAppLoading } from 'App/redux/appSlice';
import { exportPDF, getExportPDF } from 'PDF/redux/PDFGeneralSlice';
import { closeAndResetModal } from '../ModalsSlice';

const ExportPDFModal = () => {
  const dispatch = useDispatch();
  const { addJob } = useJobs();
  const isOpen = useSelector((state) => state.modals.open.ExportPDFModal);
  const id = useSelector((state) => state.modals.ExportPDFModal.id);
  const { data } = useObject({ object_id: id, object_type: 'dopdf' });

  const [includeTasks, setIncludeTasks] = useState(true);

  const close = () => {
    dispatch(closeAndResetModal('ExportPDFModal'));
  };

  const handleExport = async () => {
    dispatch(
      setAppLoading({
        isOpen: true,
        message: 'EXPORTING_DOCUMENT',
      }),
    );
    dispatch(exportPDF({ id, options: { keep_tasks: includeTasks } }))
      .unwrap()
      .then((result) => {
        addJob(result.id, () => {
          dispatch(getExportPDF({ id }))
            .unwrap()
            .then((blob) => {
              downloadFileFromBlob(blob, `${data?.name}`);
              notify({
                type: 'success',
                title: 'DOPDF_SUCCESSFULLY_EXPORTED',
                message: 'DOPDF_SUCCESSFULLY_EXPORTED_MESSAGE',
                messageValues: {
                  name: data?.name ?? '',
                },
                persist: true,
                footerContent: (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      onClick={() => {
                        downloadFileFromBlob(blob, `${data?.name}`);
                      }}
                      testId="download-manually-button"
                    >
                      <FormattedMessage id="DOWNLOAD_MANUALLY" />
                    </Button>
                  </div>
                ),
              });
            });
        });
        dispatch(setAppLoading({ isOpen: false }));
      });
    close();
  };

  return (
    <Modal width="62rem" open={!!isOpen} onClose={close} testId="export-pdf">
      <Modal.Header onClose={close}>
        <FormattedMessage id={'EXPORT'} values={{ name: data?.name }} />
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormattedMessage id="DO_YOU_WANT_TO_INCLUDE_TASKS" values={{ name: data?.name }} />
          <Checkbox
            checked={includeTasks ? 'checked' : 'unchecked'}
            onChange={() => setIncludeTasks(!includeTasks)}
            margin="2rem 0 1rem 0"
            testId="export-pdf-include-tasks"
          >
            <FormattedMessage id="YES_INCLUDE_TASKS_AS_COMMENTS" />
          </Checkbox>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="export-pdf-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleExport}
          testId="export-pdf-submit-button"
        >
          <FormattedMessage id="global.export" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportPDFModal;

 
 
import { uniq, remove } from 'lodash-es';
import { BaseController } from '../BaseController';
import { DataObject } from '_common/services/Realtime';

export class UsersDataModel extends DataObject<
  Presentation.Data.Users.Data,
  {
    READY: () => void;
    LOADED: (data: Presentation.Data.Users.Data | null) => void;
    CREATED: () => void;
    UPDATED: (data: Presentation.Data.Users.Data | null) => void;
  }
> {
  protected data: Presentation.Data.Users.Data;

  constructor(id: string) {
    super(id);
    this.data = [];
  }

  set(data: Presentation.Data.Users.Data) {
    this.data = data;
    this.emit('UPDATED', data);
  }

  join(user: string) {
    this.set(uniq([...this.data, user]));
  }

  left(user: string) {
    const users = uniq(this.data);
    remove(users, (n: string) => n === user);
    this.set(users);
  }

  get() {
    return this.data;
  }
}

export class UsersController extends BaseController {
  user?: Realtime.Core.User;
  users: UsersDataModel = new UsersDataModel('users');

  start(documentId: string, document: Realtime.Core.Document.Data, user: Realtime.Core.User): void {
    this.user = user;
    this.registerTransportEvents({
      'DOCUMENT:USERS:STATE': this._handleEventDocumentUsersState.bind(this),
      'USER:JOINED': this._handleEventUsersJoined.bind(this),
      'USER:LEFT': this._handleEventUsersLeft.bind(this),
    });

    this.Data.transport.dispatchEvent('DOCUMENT:USERS:STATE', {
      document: documentId,
    });
  }

  stop(): void {}

  destroy(): void {
    this.unregisterAllTransportEvents();
  }

  _handleEventDocumentUsersState(event: { success: boolean; users: string[] }) {
    const users = uniq(event.users);
    remove(users, (n: string) => n === this.user?.id);
    this.users.set(users);
    // this.Data.events?.emit?.('LOAD_USERS_ONLINE', users);
  }

  _handleEventUsersJoined(event: { success: boolean; user: string }) {
    if (event.user !== this.user?.id) {
      this.users.join(event.user);
      this.Data.events?.emit?.('USER_JOINED', event.user);
    }
  }

  _handleEventUsersLeft(event: { success: boolean; user: string }) {
    if (event.user !== this.user?.id) {
      this.users.left(event.user);
      this.Data.events?.emit?.('USER_LEFT', event.user);
    }
  }

  get loggedUserId() {
    return this.user?.id;
  }

  isLoggedUser(userId: string) {
    return this.user?.id === userId;
  }
}

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Checkbox } from 'dodoc-design-system';
import Intl from 'Intl/Intl';

import { store } from '_common/redux';
import EventsManager from 'Editor/services/EventsManager';
import { ELEMENTS } from 'Editor/services/consts';
import EditorManager from 'Editor/services/EditorManager';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { SuiteAvatar } from '_common/suite/components';

import styles from './ApproveViewElement.module.scss';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { BaseBlockElement } from '../../../ViewBuilders';

export class ApproveViewElement extends BaseBlockElement {
  static SELECTION = {};
  static SELECTING = false;

  static getElementsSelection() {
    const elements = Array.prototype.slice.call(
      document.querySelectorAll(`${ELEMENTS.ApproveViewElement.TAG}[selected="true"]`),
    );
    return elements;
  }

  static deselectAllElements() {
    const elements = ApproveViewElement.getElementsSelection();

    if (elements) {
      ApproveViewElement.SELECTION = {};

      for (let i = 0; i < elements.length; i += 1) {
        elements[i].selected = false;
      }
    }
  }

  static selectElements(startElement, endElement) {
    if (!startElement && !endElement) {
      return;
    }

    const elements = Array.from(startElement.parentNode.childNodes);
    let startIndex = elements.indexOf(startElement);
    let endIndex = elements.indexOf(endElement);

    if (endIndex < startIndex) {
      const aux = endIndex;
      endIndex = startIndex;
      startIndex = aux;
    }

    if (startIndex !== endIndex) {
      ApproveViewElement.SELECTING = true;
      for (let i = 0; i < elements.length; i += 1) {
        if (i < startIndex || i > endIndex) {
          if (elements[i].getAttribute('selected')) {
            // deselect
            elements[i].selected = false;
          }
        } else if (!elements[i].getAttribute('selected')) {
          // select
          elements[i].selected = true;
        }
      }
    }
  }

  static handleMouseDown() {
    ApproveViewElement.SELECTING = false;
    EventsManager.getInstance().on(
      'DOCUMENT_SELECTION_CHANGE',
      ApproveViewElement.handleSelectionChange,
    );
    EventsManager.getInstance().on('DOCUMENT_MOUSE_UP', ApproveViewElement.handleMouseUp);
  }

  static handleMouseUp(e) {
    let element = e.target;

    if (element.tagName !== ELEMENTS.ApproveViewElement.TAG) {
      element = EditorDOMUtils.closest(element, ELEMENTS.ApproveViewElement.TAG);
    }

    if (!ApproveViewElement.SELECTING && element) {
      if (
        document
          .getElementById(`ApprovalIconWrapper-${element.getAttribute('enclosed_element')}`)
          .contains(e.target)
      ) {
        element.toggleSelected();
      } else {
        ApproveViewElement.deselectAllElements();
        element.selected = true;
      }
    }

    // publish select bulk action
    const keys = Object.keys(ApproveViewElement.SELECTION);

    if (keys.length > 0) {
      const selectedElements = [];
      for (let i = 0; i < keys.length; i += 1) {
        const id = keys[i];
        if (ApproveViewElement.SELECTION[id]) {
          selectedElements.push(id);
        }
      }
      EditorManager.getInstance().selectNodesForApproval(selectedElements);
    }

    ApproveViewElement.SELECTING = false;
    EventsManager.getInstance().removeListener(
      'DOCUMENT_SELECTION_CHANGE',
      ApproveViewElement.handleSelectionChange,
    );
    EventsManager.getInstance().removeListener(
      'DOCUMENT_MOUSE_UP',
      ApproveViewElement.handleMouseUp,
    );
  }

  static handleSelectionChange() {
    const selection = window.getSelection();
    const anchorNode = selection.anchorNode;
    const focusNode = selection.focusNode;
    const editorRoot = document.getElementById('EditorRoot');

    if (!editorRoot.contains(focusNode)) {
      return;
    }

    const startElement = EditorDOMUtils.closest(anchorNode, ELEMENTS.ApproveViewElement.TAG);
    const endElement = EditorDOMUtils.closest(focusNode, ELEMENTS.ApproveViewElement.TAG);

    ApproveViewElement.selectElements(startElement, endElement);
  }

  static get observedAttributes() {
    return ['selected'];
  }

  constructor() {
    super();
    this.mousedown = null;
    this.nodeContent = null;

    this.handleSelectedAttributeChange = this.handleSelectedAttributeChange.bind(this);
    this.setIcon = this.setIcon.bind(this);

    this.addEventListener('mousedown', ApproveViewElement.handleMouseDown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(val) {
    if (val) {
      this.setAttribute('selected', true);
    } else {
      this.removeAttribute('selected');
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      if (name === 'selected' && oldValue !== newValue) {
        this.handleSelectedAttributeChange(newValue);
      } else {
        super.attributeChangedCallback(name, oldValue, newValue);
      }
    }
  }

  deselect() {
    this.removeAttribute('selected');
  }

  handleSelectedAttributeChange(newValue) {
    if (newValue) {
      this.setIcon('CHECKED');
      this.setSelectedState();
      ApproveViewElement.SELECTION[this.getAttribute('enclosed_element')] = true;
    } else {
      this.setIcon('UNCHECKED');
      this.setDefaultState();
      ApproveViewElement.SELECTION[this.getAttribute('enclosed_element')] = false;
    }
  }

  setSelectedState() {
    if (this.approvedUsers && this.approvedUsers.length) {
      return;
    }
    this.content.style.backgroundColor = '#f2f7fc';
    this.users.style.backgroundColor = '#f2f7fc';
    this.contentWrapper.style.boxShadow = '0 0 0 1px #a5d4fa';
    this.users.style.boxShadow = ' 0px -1px 0px 0px #a5d4fa';
  }

  setDefaultState() {
    if (this.approvedUsers && this.approvedUsers.length) {
      return;
    }
    this.content.style.backgroundColor = 'transparent';
    this.users.style.backgroundColor = 'transparent';
    this.contentWrapper.style.boxShadow = '0 0 0 1px #d5d5db';
    this.users.style.boxShadow = ' 0px -1px 0px 0px #d5d5db';
  }

  setApprovedState() {
    this.content.style.backgroundColor = '#e7f9eb';
    this.users.style.backgroundColor = '#e7f9eb';
    this.contentWrapper.style.boxShadow = '0 0 0 1px #b9ecc4';
    this.users.style.boxShadow = ' 0px -1px 0px 0px #b9ecc4';
  }

  toggleSelected() {
    this.selected = !this.selected;
  }

  setIcon(status) {
    ReactDOM.unmountComponentAtNode(this.iconWrapper);
    ReactDOM.render(
      status === 'CHECKED' ? (
        <Checkbox checked="checked" size="small" testId="checkbox-column" />
      ) : (
        <Checkbox checked="unchecked" size="small" testId="checkbox-column" />
      ),
      this.iconWrapper,
    );
  }

  setContent(child) {
    this.nodeContent = child;
  }

  setApprovalUsers(users) {
    this.approvedUsers = users;
  }

  addUserElement(user) {
    const userElement = DOMElementFactory.buildElement('span');
    userElement.setAttribute('class', styles.userElement);
    ReactDOM.render(
      <Provider store={store}>
        <Intl>
          <SuiteAvatar size="small" userId={user} showTooltip />
        </Intl>
      </Provider>,
      userElement,
    );
    this.users.appendChild(userElement);
  }

  addApprovedByText() {
    const approvedByTextElement = DOMElementFactory.buildElement('span');
    approvedByTextElement.setAttribute('class', styles.approvedByTextElement);
    approvedByTextElement.appendChild(document.createTextNode('Approved by:'));
    this.users.appendChild(approvedByTextElement);
  }

  connectedCallback() {
    super.connectedCallback();

    // clean childreen
    while (this.firstChild) {
      this.removeChild(this.firstChild);
    }

    this.setAttribute('contentEditable', false);
    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);
    this.container.setAttribute(
      'data-testid',
      `approve-view-element-${this.getAttribute('enclosed_element')}`,
    );

    this.iconWrapper = DOMElementFactory.buildElement('div');
    this.iconWrapper.id = `ApprovalIconWrapper-${this.getAttribute('enclosed_element')}`;
    this.iconWrapper.setAttribute('class', styles.iconWrapper);

    this.contentWrapper = DOMElementFactory.buildElement('div');
    this.contentWrapper.setAttribute('class', styles.contentWrapper);

    this.content = DOMElementFactory.buildElement('span');
    this.content.setAttribute('class', styles.content);

    this.users = DOMElementFactory.buildElement('span');
    this.users.setAttribute('class', styles.users);

    this.appendChild(this.container);
    this.container.appendChild(this.iconWrapper);
    this.container.appendChild(this.contentWrapper);
    this.contentWrapper.appendChild(this.content);
    this.contentWrapper.appendChild(this.users);

    if (this.nodeContent) {
      this.content.appendChild(this.nodeContent);
    }
    if (this.approvedUsers) {
      if (this.approvedUsers.length) {
        this.setApprovedState();
        this.users.style.display = 'flex';
        this.addApprovedByText();
        this.approvedUsers.forEach((approver) => {
          this.addUserElement(approver.user);
        });
      } else {
        this.users.style.display = 'none';
      }
    }
    if (this.selected) {
      this.setIcon('CHECKED');
      this.setSelectedState();
    } else {
      this.setIcon('UNCHECKED');
      this.setDefaultState();
    }
  }
}

if (!window.customElements.get(ELEMENTS.ApproveViewElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.ApproveViewElement.IDENTIFIER, ApproveViewElement);
}

/**
 * Type guard to check if string is key of object, will narrow down type from string to keyof object
 * @see {@link https://github.com/microsoft/TypeScript/issues/43284}
 * @param {string} key
 * @param {object} object
 * @returns if key is a key of object
 */
export default function in_<K extends string | undefined, O extends object>(
  key: K,
  object: O,
): key is K & keyof O {
  return key && key in object;
}

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import appEvents from 'App/appEvents';

type ToastList = {
  [key in string]: ToastProps;
};

type ToastSystemContextProviderProps = {
  children: ReactNode;
};

type ToastSystemContextType = {
  toastList: ToastList;
  removeToast: (toastId: ToastProps['id']) => void;
};

const ToastSystemContext = createContext<ToastSystemContextType>({
  toastList: {},
  removeToast: () => {},
});

export const useToastSystem = () => {
  return useContext(ToastSystemContext);
};

const ToastSystemContextProvider = ({ children }: ToastSystemContextProviderProps) => {
  const [toastList, setToastList] = useState<ToastList>({});
  const isComponentMounted = useRef<boolean>();

  const handleNotify = useCallback((newToast: ToastElement) => {
    if (!isComponentMounted.current) {
      return;
    }

    const toastId = uuid();

    setToastList((prev) => {
      const singletonToast = newToast.id ? prev[newToast.id] : null;
      if (singletonToast) {
        appEvents.emit('NOTIFY_RESET', newToast.id);

        return prev;
      }

      return {
        ...prev,
        [newToast.id ?? toastId]: {
          ...newToast,
          id: newToast.id ?? toastId,
          singleton: !!newToast.id,
        },
      };
    });
  }, []);

  useEffect(() => {
    appEvents.on('NOTIFY', handleNotify);
    appEvents.on('NOTIFY_CLEAR_ALL', handleClearAll);

    isComponentMounted.current = true;

    return () => {
      appEvents.off('NOTIFY', handleNotify);
      appEvents.off('NOTIFY_CLEAR_ALL', handleClearAll);

      isComponentMounted.current = false;
    };
  }, []);

  const removeToast = (toastId: ToastProps['id']) => {
    if (!isComponentMounted.current) {
      return;
    }

    if (toastList[toastId]) {
      setToastList((prev) => {
        const copyOfPrev = { ...prev };
        delete copyOfPrev[toastId];
        return copyOfPrev;
      });
    }
  };

  const handleClearAll = () => {
    if (!isComponentMounted.current) {
      return;
    }

    setToastList({});
  };

  return (
    <ToastSystemContext.Provider
      value={{
        toastList,
        removeToast,
      }}
    >
      {children}
    </ToastSystemContext.Provider>
  );
};

export const useAuditLog = () => {
  return useContext(ToastSystemContext);
};

export default ToastSystemContextProvider;

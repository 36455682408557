import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '../BaseViewBuilder';
import { HyperlinkElement } from './HyperlinkElement/HyperlinkElement';

export class HyperlinkViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<HyperlinkElement>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<HyperlinkElement> = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.HyperlinkElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // href
    href: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.href != null) {
          builder.addProperty('href', node.dataset.href);
        } else if (node.hasAttribute('href')) {
          builder.addProperty('href', node.getAttribute('href'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.href != null) {
          node.dataset.href = json.properties.href;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.href;
      },
    },
    anchor: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.href != null) {
          builder.addProperty('anchor', node.dataset.anchor);
        } else if (node.hasAttribute('anchor')) {
          builder.addProperty('anchor', node.getAttribute('anchor'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.anchor != null) {
          node.dataset.anchor = json.properties.anchor;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.anchor;
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    const node = DOMElementFactory.buildElement(ELEMENTS.HyperlinkElement.TAG) as HyperlinkElement;

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}

import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { skipToken } from '@reduxjs/toolkit/query';
import type { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import { usePrimitiveUpdate, useSelector } from '_common/hooks';
import { useGetObjectQuery } from 'App/redux/objectApi';

import styles from './Subtitle.module.scss';

export type SubtitleProps = {
  notificationId: string;
  targetObject?: ObjectParams;
} & Pick<CellMeasurerChildProps, 'measure'>;

const Subtitle = ({ notificationId, targetObject, measure }: SubtitleProps) => {
  const notification = useSelector((state) => state.notifications.dict[notificationId]);
  const appData = useSelector((state) => state.app.data);
  const unknownObjects = useSelector((state) => state.app.unknownObjects);

  const { data, isLoading } = useGetObjectQuery(targetObject ?? skipToken, {
    skip:
      !targetObject ||
      !!unknownObjects[targetObject.objectId] ||
      !!(notification.action.extra as ApiSchemas['ActionExtra'])?.target_name,
    selectFromResult: (result) => ({
      ...result,
      data:
        result.data ||
        (notification.action.target ? appData[notification.action.target] : undefined),
    }),
  });

  //#region Card measure handling
  usePrimitiveUpdate(() => {
    measure();
  }, isLoading);
  //#endregion

  const targetName = useMemo(() => {
    return (notification.action.extra as ApiSchemas['ActionExtra'])?.target_name || data?.name;
  }, [notification, data]);

  const renderUnknownElement = () => {
    let type = '';

    if (notification.action.action === 'imported') {
      type = 'DOCUMENT';
    } else {
      switch (notification.event) {
        case 'deadline_reminder':
        case 'deadline_24_reminder':
        case 'deadline_expired':
        case 'status_changed':
        case 'shared':
        case 'permissions_updated':
        case 'added_to_container':
        case 'removed_from_container': {
          type = (
            notification.action.extra as ApiSchemas['CreateActionExtra']
          )?.type?.toLocaleUpperCase();
          break;
        }

        case 'user_mentioned':
        case 'node_status_updated':
        case 'comment_created':
        case 'comment_status_updated':
        case 'comment_reply':
        case 'comment_liked':
        case 'suggestion_status_updated':
        case 'suggestion_replied':
        case 'suggestion_liked':
        case 'task_deadline_reminder':
        case 'task_deadline':
        case 'task_assignee_updated':
        case 'task_add_watcher':
        case 'task_remove_watcher':
        case 'task_status_changed':
        case 'task_reply_liked':
        case 'task_replied': {
          type = 'DOCUMENT';
          break;
        }
      }
    }

    return (
      <div className={styles.unknown}>
        <FormattedMessage id={`DELETED_${type}`} />
      </div>
    );
  };

  const renderElementName = () => {
    return targetName ?? renderUnknownElement();
  };

  return (
    <div className={styles.subtitle} title={targetName}>
      {renderElementName()}
    </div>
  );
};

export default Subtitle;

import { TypedEmitter } from 'tiny-typed-emitter';

import type { Modal, ModalData } from './ModalContext/ModalContextTypes';

type ModalDataParam<M extends Modal, D extends ModalData[M], O extends boolean = true> = {
  modal: M;
  overwrite?: O;
} & (D extends undefined ? { data?: never } : { data: O extends true ? D : Partial<D> });

export interface AppEvents {
  NOTIFY: (toast: ToastElement) => void;
  NOTIFY_RESET: (toastId: ToastElement['id']) => void;
  NOTIFY_CLEAR_ALL: () => void;
  //#region Modal Context
  MODAL_OPEN: <M extends Modal, D extends ModalData[M]>(params: ModalDataParam<M, D>) => void;
  MODAL_UPDATE: <M extends Modal, D extends ModalData[M], O extends boolean = true>(
    params: ModalDataParam<M, D, O>,
  ) => void;
  MODAL_CLOSE: (modal: Modal) => void;
  MODAL_CLOSE_ALL: () => void;
  //#endregion
}

const appEvents = new TypedEmitter<AppEvents>();

export default appEvents;

import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Banner } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { notify } from '_common/components/ToastSystem';
import { updateVersionWarning, setVersionValidation } from 'App/redux/appSlice';
import PackageJson from '_common/../../package.json';

const BannerContent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const versionWarning = useSelector((state) => state.app.versionWarning);
  const versionValidation = useSelector((state) => state.app.versionValidation);
  const pjsonVersion = PackageJson.version;

  const fetchData = async () => {
    await fetch(`/__version?t=${Date.now()}`)
      .then((response) => response.text())
      .then((latestVersion) => {
        const currentVersion = import.meta.env.VITE_APP_UI_VERSION || pjsonVersion;
        if (currentVersion === latestVersion && versionValidation) {
          notify({
            type: 'success',
            title: 'NEW_VERSION_UPDATE',
            message: 'NEW_VERSION_UPDATE_MESSAGE',
          });
        }
        dispatch(
          updateVersionWarning({
            isOpen: currentVersion !== latestVersion,
          }),
        );
        dispatch(setVersionValidation(false));
      });
  };

  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      fetchData();
    }
  }, []);

  const hardReloadPage = () => {
    window.location.reload();
    dispatch(setVersionValidation(true));
  };

  return (
    <Banner
      isOpen={versionWarning.isOpen}
      buttonProps={{
        disabled: false,
        loading: false,
        onClick: hardReloadPage,
        children: intl.formatMessage({ id: 'REFRESH' }),
      }}
      testId="new-version-available-banner"
    >
      <FormattedMessage id="NEW_VERSION_AVAILABLE_WARNING" />
    </Banner>
  );
};

export default BannerContent;

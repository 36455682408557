import cx from 'classnames';
import { MouseEvent } from 'react';
import styles from './TableOptions.module.scss';

type ResizeAnchorProps = {
  size: string;
  offset: string;
  type: Editor.Elements.Table.Selection;
  onMouseDown: (e: MouseEvent<HTMLDivElement>) => void;
  ghostDelta?: number;
  extensor?: boolean;
};

const ResizeAnchor = ({
  size,
  offset,
  type,
  onMouseDown,
  ghostDelta,
  extensor = false,
}: ResizeAnchorProps) => {
  const offsetProperty = type === 'column' ? 'left' : 'top';
  const sizeProperty = type === 'column' ? 'height' : 'width';

  return (
    <div
      className={cx(styles.anchor, styles[type], {
        [styles.ghost]: ghostDelta,
        [styles.extensor]: extensor,
      })}
      style={{
        [offsetProperty]: `calc(${offset} + ${ghostDelta ?? 0}px)`,
        [sizeProperty]: size,
      }}
      onMouseDown={onMouseDown}
      data-testid={`${type}-resize-anchor`}
    />
  );
};

export default ResizeAnchor;

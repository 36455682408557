import * as Sentry from '@sentry/react';
import { CaptureContext, Primitive } from '@sentry/types';

const LOG_LEVELS = ['OFF', 'ERROR', 'WARNING', 'INFO', 'DEBUG'] as const;

const loggerOptions: LoggerClass.Options = {
  level: 'ERROR',
};

const isLogAllowed = (logLevelNeeded: LoggerClass.Level) => {
  return (
    loggerOptions?.level != null &&
    LOG_LEVELS.indexOf(loggerOptions.level) >= LOG_LEVELS.indexOf(logLevelNeeded)
  );
};

export class LoggerClass {
  get LOG_LEVELS() {
    return LOG_LEVELS;
  }

  getLogLevel() {
    return loggerOptions.level;
  }

  setLogLevel(logLevel: LoggerClass.Level) {
    if (LOG_LEVELS.includes(logLevel)) {
      loggerOptions.level = logLevel;
    }
  }

  // ############################################################
  //                      Sentry functions
  // ############################################################
  captureException(exception: any, context?: CaptureContext) {
    this.error(exception, context);
    Sentry.withScope((scope: any) => {
      scope.setExtra('exception', exception);
      if (exception instanceof Error) {
        Sentry.captureException(exception, context);
      } else {
        Sentry.captureMessage(exception, context);
      }
    });
  }

  captureMessage(message: string, context?: CaptureContext) {
    this.warn(message, context);
    Sentry.captureMessage(message, context);
  }

  setUser(user: Sentry.User) {
    Sentry.setUser(user);
  }

  setTag(key: string, value: Primitive) {
    Sentry.setTag(key, value);
  }

  configureScope(callback: (scope: Sentry.Scope) => void) {
    callback(Sentry.getCurrentScope());
  }

  reportToSentry = (report: string) => {
    Sentry.withScope((scope: any) => {
      scope.setTag('logger', 'Developer');
      Sentry.captureMessage(report, {
        level: 'info',
      });
    });
  };

  // ############################################################
  //                      Message Logging
  // ############################################################
  error(...args: any[]) {
    if (isLogAllowed('ERROR')) {
      console.error(`[${new Date().toLocaleString()}][ERROR]`, ...args);
    }
  }

  warning(...args: any[]) {
    this.warn(args);
  }

  warn(...args: any[]) {
    if (isLogAllowed('WARNING')) {
      console.warn(`[${new Date().toLocaleString()}][WARNING]`, ...args);
    }
  }

  info(message: any, ...args: any[]) {
    if (isLogAllowed('INFO')) {
      console.groupCollapsed(`%c[${new Date().toLocaleString()}][INFO]`, 'color: #20AD00', message);
      for (let i = 0; i < args.length; i++) {
        console.info(args[i]);
      }
      console.groupEnd();
    }
  }

  static info(message: any, ...args: any[]) {
    if (isLogAllowed('INFO')) {
      console.groupCollapsed(`%c[${new Date().toLocaleString()}][INFO]`, 'color: #20AD00', message);
      for (let i = 0; i < args.length; i++) {
        console.info(args[i]);
      }
      console.groupEnd();
    }
  }

  trace(message: any, ...args: any[]) {
    if (isLogAllowed('DEBUG')) {
      console.groupCollapsed(
        `%c[${new Date().toLocaleString()}][TRACE]`,
        'color: #1E69F6',
        message,
      );
      for (let i = 0; i < args.length; i++) {
        console.log(args[i]);
      }
      console.trace();
      console.groupEnd();
    }
  }

  debug(message: any, ...args: any[]) {
    if (isLogAllowed('DEBUG')) {
      console.groupCollapsed(`[${new Date().toLocaleString()}][DEBUG]`, message);
      for (let i = 0; i < args.length; i++) {
        console.log(args[i]);
      }
      console.groupEnd();
    }
  }

  deprecated(message: any, ...args: any[]) {
    if (isLogAllowed('DEBUG')) {
      console.warn(`[${new Date().toLocaleString()}][DEPRECATED] ${message}`, ...args);
      // console.groupCollapsed(
      //   `%c[${new Date().toLocaleString()}][DEPRECATED] ${message}`,
      //   'color: #f28e08;background-color: #332b00; padding: 2px 0 2px 0',
      // );
      // for (let i = 0; i < args.length; i++) {
      //   console.warn(args[i]);
      // }
      // console.groupEnd();
    }
  }
}

const Logger = new LoggerClass();

export default Logger;

import { ELEMENTS } from 'Editor/services/consts';
import DoDOCRange from '_common/DoDOCSelection/DoDOCRange/DoDOCRange';
import { EditorDOMElements } from '../../DOM/EditorDOMElements';
import { EditorDOMUtils } from '../../DOM/EditorDOMUtils';
import { EditorSelectionUtils } from '../Utils/EditorSelectionUtils';
import { Logger } from '_common/services';

export class EditorRange extends DoDOCRange {
  private static WRAPING_ANCESTORS = [
    'COMMENT-ELEMENT',
    'TEMP-COMMENT-ELEMENT',
    ELEMENTS.HyperlinkElement.TAG,
    ELEMENTS.ParagraphElement.TAG,
    'SPAN',
    'FORMAT-ELEMENT',
    ELEMENTS.FigureElement.TAG,
    // 'CITATION-ELEMENT',
    // 'CITATIONS-GROUP-ELEMENT',
    'CROSS-REFERENCE-ELEMENT',
    'NOTE-ELEMENT',
    'SYMBOL-ELEMENT',
    'EQUATION-ELEMENT',
    // ELEMENTS.TableCellElement.TAG,
  ];

  getBaseNode() {
    return EditorDOMUtils.findFirstLevelChildNode(
      EditorDOMUtils.getContentContainer(this.commonAncestorContainer),
      this.commonAncestorContainer,
    );
  }

  accept(visitor: DoDOCSelection.DoDOCRange.IRangeVisitor) {
    visitor.visitDoDOCRange(this);
  }

  cloneRange(): EditorRange {
    const newRange = new EditorRange();
    newRange.setStart(this.startContainer, this.startOffset);
    newRange.setEnd(this.endContainer, this.endOffset);
    return newRange;
  }

  static fromNativeRange(nativeRange: Range) {
    let range = new EditorRange();
    range.setStart(nativeRange.startContainer, nativeRange.startOffset);
    range.setEnd(nativeRange.endContainer, nativeRange.endOffset);
    return range;
  }

  getClientRects(): DOMRectList {
    return super.getClientRects();
  }

  updateFromJsonRange(jsonRange: Editor.Selection.JsonRange) {
    let start = jsonRange.serializeStartToNodeOffset();
    let end = jsonRange.serializeEndToNodeOffset();

    if (start.node != null && start.offset != null && end.node != null && end.offset != null) {
      this.setStart(start.node, start.offset);
      this.setEnd(start.node, start.offset);

      if (this.comparePoint(end.node, end.offset) >= 0) {
        this.setEnd(end.node, end.offset);
      } else {
        this.setStart(end.node, end.offset);
      }
    } else {
      Logger.warn('Node not found!');
      // throw new Error('Node not found!');
    }
  }

  isAtNodeStart(node: Node, container: 'start' | 'end' = 'start'): boolean {
    if (node) {
      let anchorNode;
      let anchorOffset;
      if (container === 'start') {
        anchorNode = this.startContainer;
        anchorOffset = this.startOffset;
      } else {
        anchorNode = this.endContainer;
        anchorOffset = this.endOffset;
      }

      return EditorDOMUtils.isAtStartOfNode(node, anchorNode, anchorOffset);
    }
    return false;
  }

  isAtNodeEnd(node: Node, container: 'start' | 'end' = 'start'): boolean {
    if (node) {
      let focusNode;
      let focusOffset;
      if (container === 'end') {
        focusNode = this.endContainer;
        focusOffset = this.endOffset;
      } else {
        focusNode = this.startContainer;
        focusOffset = this.startOffset;
      }

      return EditorDOMUtils.isAtEndOfNode(node, focusNode, focusOffset);
    }
    return false;
  }

  setRangeEnd(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    if (!node) {
      return false;
    }

    let workingNode = node;
    switch (position) {
      case 'PRE': {
        this.setEndBefore(workingNode);
        break;
      }
      case 'START': {
        this.setEnd(node, 0);
        break;
      }
      case 'INDEX': {
        this.setEnd(node, offset);
        break;
      }
      case 'INSIDE_START': {
        workingNode = node;
        while (
          workingNode.firstChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.firstChild)
        ) {
          workingNode = workingNode.firstChild;
        }
        this.setEnd(workingNode, 0);
        break;
      }
      case 'END': {
        let length = 0;
        if (node.nodeType === Node.TEXT_NODE) {
          length = (node as Text).length;
        }
        if (node.nodeType === Node.ELEMENT_NODE) {
          length = node.childNodes.length;
        }
        this.setEnd(node, length);
        break;
      }
      case 'INSIDE_END': {
        workingNode = node;
        while (
          workingNode.lastChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.lastChild)
        ) {
          workingNode = workingNode.lastChild;
        }
        let length = 0;
        if (workingNode.nodeType === Node.TEXT_NODE) {
          length = (workingNode as Text).length;
        }
        if (workingNode.nodeType === Node.ELEMENT_NODE) {
          length = workingNode.childNodes.length;
        }
        this.setEnd(workingNode, length);
        break;
      }
      case 'POST': {
        this.setEndAfter(workingNode);
        break;
      }
      default:
        return false;
    }
    return true;
  }

  setRangeStart(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    if (!node) {
      return false;
    }

    let workingNode = node;
    switch (position) {
      case 'PRE': {
        this.setStartBefore(workingNode);
        break;
      }
      case 'START': {
        this.setStart(node, 0);
        break;
      }
      case 'INDEX': {
        this.setStart(node, offset);
        break;
      }
      case 'INSIDE_START': {
        workingNode = node;
        while (
          workingNode.firstChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.firstChild)
        ) {
          workingNode = workingNode.firstChild;
        }
        this.setStart(workingNode, 0);
        break;
      }
      case 'END': {
        let length = 0;
        if (node.nodeType === Node.TEXT_NODE) {
          length = (node as Text).length;
        }
        if (node.nodeType === Node.ELEMENT_NODE) {
          length = node.childNodes.length;
        }
        this.setStart(node, length);
        break;
      }
      case 'INSIDE_END': {
        workingNode = node;
        while (
          workingNode.lastChild &&
          !EditorDOMUtils.isNodeNonSelectable(workingNode.lastChild)
        ) {
          workingNode = workingNode.lastChild;
        }
        let length = 0;
        if (workingNode.nodeType === Node.TEXT_NODE) {
          length = (workingNode as Text).length;
        }
        if (workingNode.nodeType === Node.ELEMENT_NODE) {
          length = workingNode.childNodes.length;
        }
        this.setStart(workingNode, length);
        break;
      }
      case 'POST': {
        this.setStartAfter(workingNode);
        break;
      }
      default:
        return false;
    }
    return true;
  }

  setCaret(node: Node, position: DoDOCSelection.CaretPosition = 'START', offset: number = 0) {
    return this.setRangeStart(node, position, offset) && this.setRangeEnd(node, position, offset);
  }

  private createMarker(): Element {
    const marker = document.createElement('span');
    marker.id = `DoDOCBoundaryMarker_${EditorDOMUtils.generateUUID()}`;
    marker.style.display = 'none';
    marker.style.lineHeight = '0';
    return marker;
  }

  saveRange(): DoDOCSelection.DoDOCRange.BoundaryMarkers {
    const markers: DoDOCSelection.DoDOCRange.BoundaryMarkers = {
      collapsed: this.collapsed,
    };

    if (this.collapsed) {
      const marker = this.createMarker();
      markers.markerId = marker.id;
      this.insertNode(marker);

      this.setStartAfter(marker);
      this.setEndAfter(marker);
    } else {
      const range = this.cloneRange();
      range.collapse(); // collapse to end;

      const startMarker = this.createMarker();
      markers.startMarkerId = startMarker.id;
      this.insertNode(startMarker);

      const endMarker = this.createMarker();
      markers.endMarkerId = endMarker.id;

      range.insertNode(endMarker);

      this.setStartAfter(startMarker);
      this.setEndBefore(endMarker);
    }

    return markers;
  }

  restoreRange(savedMarkers: DoDOCSelection.DoDOCRange.BoundaryMarkers) {
    if (savedMarkers.markerId) {
      const marker = document.getElementById(savedMarkers.markerId);

      if (marker) {
        let parentNode = marker.parentNode;
        if (parentNode) {
          parentNode.normalize();
        }

        if (marker.previousSibling instanceof Text && marker.nextSibling instanceof Text) {
          const node = marker.previousSibling;
          const offset = marker.previousSibling.length;

          if (parentNode) {
            parentNode.removeChild(marker);
            parentNode.normalize();
          }

          this.setStart(node, offset);
          this.setEnd(node, offset);
        }
        // else if (marker.previousSibling instanceof Element) {
        //   this.setCaret(marker.previousSibling, 'INSIDE_END');
        //   parentNode?.removeChild(marker);
        // }
        else {
          this.setStartBefore(marker);
          this.setEndBefore(marker);
          if (parentNode) {
            parentNode.removeChild(marker);
            parentNode.normalize();
          }
        }
      } else {
        throw new Error('Invalid marker to restore!');
      }
    } else if (savedMarkers.startMarkerId && savedMarkers.endMarkerId) {
      const startMarker = document.getElementById(savedMarkers.startMarkerId);
      const endMarker = document.getElementById(savedMarkers.endMarkerId);

      if (startMarker && endMarker) {
        // startMarker and endMarker are siblings, it means a collapsed position
        if (startMarker.nextSibling === endMarker) {
          let parentNode = startMarker.parentNode;
          if (parentNode) {
            parentNode.normalize();
          }

          if (startMarker.previousSibling instanceof Text) {
            const node = startMarker.previousSibling;
            const offset = startMarker.previousSibling.length;

            this.setStart(node, offset);
            this.setEnd(node, offset);
          } else if (endMarker.nextSibling instanceof Text) {
            const node = endMarker.nextSibling;
            const offset = 0;

            this.setStart(node, offset);
            this.setEnd(node, offset);
          }
          // else if (
          //   startMarker.previousSibling instanceof Element &&
          //   startMarker.previousSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setCaret(startMarker.previousSibling, 'INSIDE_END');
          // } else if (
          //   endMarker.nextSibling instanceof Element &&
          //   endMarker.nextSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setCaret(endMarker.nextSibling, 'INSIDE_START');
          // }
          else {
            this.setStartBefore(startMarker);
            this.setEndBefore(startMarker);
          }

          if (parentNode) {
            parentNode.removeChild(startMarker);
            parentNode.removeChild(endMarker);
            parentNode.normalize();
          }
        } else {
          // startMarker and endMarker are not siblings, it means a non-collapsed position
          let startParentNode = startMarker.parentNode;
          if (startParentNode) {
            startParentNode.normalize();
          }

          if (
            startMarker.previousSibling instanceof Text &&
            startMarker.nextSibling instanceof Text
          ) {
            const node = startMarker.previousSibling;
            const offset = startMarker.previousSibling.length;

            if (startParentNode) {
              startParentNode.removeChild(startMarker);
              startParentNode.normalize();
            }

            this.setStart(node, offset);
          }
          // else if (
          //   startMarker.nextSibling instanceof Element &&
          //   startMarker.nextSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setRangeStart(startMarker.nextSibling, 'INSIDE_START');
          //   startParentNode?.removeChild(startMarker);
          // }
          else {
            this.setStartBefore(startMarker);
            startParentNode?.removeChild(startMarker);
          }

          let endParentNode = endMarker.parentNode;
          if (endParentNode) {
            endParentNode.normalize();
          }

          if (endMarker.previousSibling instanceof Text && endMarker.nextSibling instanceof Text) {
            const node = endMarker.previousSibling;
            const offset = endMarker.previousSibling.length;

            if (endParentNode) {
              endParentNode.removeChild(endMarker);
              endParentNode.normalize();
            }

            this.setEnd(node, offset);
          }
          // else if (
          //   endMarker.previousSibling instanceof Element &&
          //   endMarker.previousSibling.nodeName !== 'SPAN'
          // ) {
          //   this.setRangeEnd(endMarker.previousSibling, 'INSIDE_END');
          //   endParentNode?.removeChild(endMarker);
          // }
          else {
            this.setEndBefore(endMarker);
            endParentNode?.removeChild(endMarker);
          }
        }
      } else {
        throw new Error('Invalid marker to restore!');
      }
    }
  }

  static splitRangeByBlocks(
    originalRange = EditorSelectionUtils.getRange(),
    blocksToFilter = EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    options: { allowNonDeletable?: boolean; includeCellSelection?: boolean } = {},
  ) {
    if (!originalRange) {
      return [];
    }
    let opts = {
      allowNonDeletable: false,
      includeCellSelection: true,
      ...options,
    };

    let rangesToSplit = [originalRange];
    let splitedRangesElements: {
      block: Node;
      range: EditorRange;
    }[] = [];

    if (opts.includeCellSelection) {
      // check if is inside a table
      const closestTable = EditorDOMUtils.closest(originalRange.commonAncestorContainer, [
        ELEMENTS.TableElement.TAG,
      ]);
      if (EditorDOMElements.isTableElement(closestTable)) {
        const selectedCells = closestTable.getSelectedCells();
        if (selectedCells && selectedCells.length > 1) {
          rangesToSplit = Array.from(selectedCells).reduce(
            (acc: EditorRange[], cell: Editor.Elements.TableCellElement) => {
              const cellRange = EditorSelectionUtils.createNewRange();
              cellRange.setStart(cell, 0);
              cellRange.setEnd(cell, cell.childNodes.length);
              acc.push(cellRange);
              return acc;
            },
            [],
          );
        }
      }
    }

    rangesToSplit.forEach((range) => {
      const rangeElements = EditorRange.filterBlocksFromRange(
        range,
        true,
        blocksToFilter,
        opts.allowNonDeletable,
      ).map((block) => {
        const blockRange = EditorSelectionUtils.createNewRange();

        if (block.contains(range.startContainer)) {
          blockRange.setStart(range.startContainer, range.startOffset);
        } else {
          blockRange.setStart(block, 0);
        }

        if (block.contains(range.endContainer)) {
          blockRange.setEnd(range.endContainer, range.endOffset);
        } else {
          blockRange.setEnd(block, block.childNodes.length);
        }

        return {
          block,
          range: blockRange,
        };
      });

      splitedRangesElements = splitedRangesElements.concat(rangeElements);
    });

    return splitedRangesElements;
  }

  static filterBlocksFromRange(
    range = EditorSelectionUtils.getRange(),
    forceOwnBlock = false,
    blocksToFilter = EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    allowNonDeletable = false,
  ) {
    if (!range) {
      return [];
    }

    const nodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
      const pageNode = EditorDOMUtils.getContentContainer(node);
      return (
        (node.parentNode === pageNode ||
          (node.parentNode instanceof HTMLElement &&
            EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(node.parentNode.tagName) &&
            range.commonAncestorContainer !== pageNode)) &&
        (EditorDOMUtils.isBlockNodeDeletable(node) || allowNonDeletable) &&
        node instanceof HTMLElement &&
        blocksToFilter.includes(node.tagName)
      );
    });

    if (nodes.length === 0 && forceOwnBlock) {
      const closest = EditorDOMUtils.closestMultiBlockContainerElement(
        range.commonAncestorContainer,
      );
      if (closest) {
        const level0 = EditorDOMUtils.findFirstLevelChildNode(
          closest,
          range.commonAncestorContainer,
        );
        if (
          level0 instanceof HTMLElement &&
          (EditorDOMUtils.isBlockNodeDeletable(level0) || allowNonDeletable) &&
          blocksToFilter.includes(level0.tagName)
        ) {
          nodes.push(level0);
        }
      } else {
        const textBlock = EditorDOMUtils.closest(range.commonAncestorContainer, blocksToFilter);
        let level0: Node | null;

        if (textBlock) {
          const pageNode = EditorDOMUtils.getContentContainer(textBlock);
          if (textBlock.parentNode === pageNode) {
            level0 = textBlock;
          } else {
            level0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, textBlock);
          }

          if (level0 && (EditorDOMUtils.isBlockNodeDeletable(level0) || allowNonDeletable)) {
            nodes.push(textBlock);
          }
        }
      }
    }

    return (nodes && nodes.filter((node) => node)) || [];
  }

  static splitRangeByTableCells(originalRange = EditorSelectionUtils.getRange()) {
    if (!originalRange) {
      return [];
    }

    const nodes = originalRange.getNodes([Node.ELEMENT_NODE], (node) => {
      return EditorDOMElements.isTableCellElement(node);
    }) as Editor.Elements.TableCellElement[];

    const newRanges = [];

    let range: EditorRange | undefined;
    let previousCell: Editor.Elements.TableCellElement | undefined;

    for (let i = 0; i < nodes.length; i++) {
      if (EditorDOMElements.isTableCellElement(nodes[i])) {
        if (range && previousCell) {
          range.setEnd(previousCell, previousCell.childNodes.length);
          newRanges.push(range);
        }

        range = EditorSelectionUtils.createNewRange();
        previousCell = nodes[i];
        if (nodes[i].contains(originalRange.startContainer)) {
          range.setStart(originalRange.startContainer, originalRange.startOffset);
        } else {
          range.setStart(nodes[i], 0);
        }
      }
    }

    if (range && previousCell && previousCell.contains(originalRange.endContainer)) {
      range.setEnd(originalRange.endContainer, originalRange.endOffset);
      newRanges.push(range);
    }

    if (newRanges.length === 0) {
      newRanges.push(originalRange);
    }

    return newRanges;
  }

  static splitRangeByInlineElements(
    block: Editor.Visualizer.BaseView,
    range = EditorSelectionUtils.getRange(),
    inlineElements: string[] = [],
  ) {
    if (!range) {
      return [];
    }
    const rangeList = [];

    if (block && block.contains(range.commonAncestorContainer)) {
      const nodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
        if (!(node instanceof HTMLElement)) {
          return false;
        }
        return inlineElements.includes(node.tagName);
      });

      let newRange: EditorRange | null = EditorSelectionUtils.createNewRange();
      newRange.setStart(range.startContainer, range.startOffset);

      let lastRange;

      for (let i = 0; i < nodes.length; i++) {
        if (lastRange && lastRange.containsNode(nodes[i])) {
          continue;
        } else if (newRange) {
          if (nodes[i].contains(range.startContainer) && nodes[i].contains(range.endContainer)) {
            newRange.setEnd(range.endContainer, range.endOffset);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }
            newRange = null;
          } else if (nodes[i].contains(range.endContainer)) {
            newRange.setEndBefore(nodes[i]);

            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();
            newRange.setStart(nodes[i], 0);
            newRange.setEnd(range.endContainer, range.endOffset);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }

            newRange = null;
          } else if (nodes[i].contains(range.startContainer)) {
            newRange.setEnd(nodes[i], nodes[i].childNodes.length);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodes[i],
              });
            }
            newRange = EditorSelectionUtils.createNewRange();

            newRange.setStartAfter(nodes[i]);
          } else {
            let nodeToSelect = nodes[i];

            // check for parents with this element as single child
            while (
              nodeToSelect.parentNode &&
              nodeToSelect.parentNode !== block &&
              nodeToSelect.parentNode.childNodes.length === 1
            ) {
              nodeToSelect = nodeToSelect.parentNode;
            }

            newRange.setEndBefore(nodeToSelect);

            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();
            newRange.setStartBefore(nodeToSelect);
            newRange.setEndAfter(nodeToSelect);
            if (!newRange.collapsed) {
              lastRange = newRange;
              rangeList.push({
                range: newRange,
                element: nodeToSelect,
              });
            }

            newRange = EditorSelectionUtils.createNewRange();

            newRange.setStartAfter(nodeToSelect);
          }
        }
      }

      if (newRange) {
        const closestElement = EditorDOMUtils.closest(range.endContainer, inlineElements);

        newRange.setEnd(range.endContainer, range.endOffset);
        if (!newRange.collapsed) {
          rangeList.push({
            range: newRange,
            element: closestElement,
          });
        }
      }

      if (rangeList.length === 0) {
        rangeList.push({
          range,
        });
      }
    }

    return rangeList;
  }

  static getTrackInsertOnRangeV2(originalRange = EditorSelectionUtils.getRange()) {
    // WARN: has issue with tracked ancestors
    if (!originalRange) {
      return [];
    }

    const insertSuggestions = originalRange.getNodes([Node.ELEMENT_NODE], (node) => {
      if (
        node instanceof HTMLElement &&
        node.tagName === 'TRACK-INS-ELEMENT' &&
        !node.hasAttribute('replacewith') &&
        !node.hasAttribute('replacewithsibling')
      ) {
        return true;
      }
      return false;
    });

    const insertRanges = insertSuggestions.map((node) => {
      const range = EditorSelectionUtils.createNewRange();
      const track = node;

      if (node.contains(originalRange.startContainer)) {
        range.setStart(originalRange.startContainer, originalRange.startOffset);
      } else {
        range.setStart(node, 0);
      }

      if (node.contains(originalRange.endContainer)) {
        range.setEnd(originalRange.endContainer, originalRange.endOffset);
      } else {
        range.setEnd(node, node.childNodes.length);
      }

      return {
        track,
        range,
      };
    });

    return insertRanges;
  }

  static getTrackInsertOnRange(
    originalRange: EditorRange | undefined = EditorSelectionUtils.getRange(),
  ) {
    if (!originalRange) {
      return [];
    }

    const insertNodes = originalRange.getNodes([Node.TEXT_NODE], (node: Node) => {
      let parentNode = node.parentNode;
      if (!parentNode) {
        return false;
      }

      const pageNode = EditorDOMUtils.getContentContainer(parentNode);

      // if direct parent is a citation group
      if (EditorDOMElements.isCitationsGroupElement(parentNode)) {
        return false;
      }

      while (parentNode !== pageNode) {
        if (
          EditorDOMElements.isTrackInsertElement(parentNode) &&
          !parentNode.hasAttribute('replacewith') &&
          !parentNode.hasAttribute('replacewithsibling')
        ) {
          return true;
        }
        parentNode = parentNode?.parentNode || null;
      }
      return false;
    });

    const breaklines = originalRange.getNodes([Node.ELEMENT_NODE], (node: Node | null) => {
      if (node && (node.nodeName === 'BREAKLINE-ELEMENT' || node.nodeName === 'BR')) {
        const pageNode = EditorDOMUtils.getContentContainer(node);
        while (node !== pageNode) {
          if (EditorDOMElements.isTrackInsertElement(node)) {
            return true;
          }

          node = node?.parentNode || null;
        }
      }

      return false;
    });

    const nodes = [...insertNodes, ...breaklines];

    const insertRanges = [];

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];

      const range = EditorSelectionUtils.createNewRange();

      const track = EditorDOMUtils.closest(node, 'TRACK-INS-ELEMENT');
      range.selectNodeContents(node);

      if (node === originalRange.startContainer) {
        range.setStart(node, originalRange.startOffset);
      }
      if (node === originalRange.endContainer) {
        range.setEnd(node, originalRange.endOffset);
      }

      const closestNonEditable = EditorDOMUtils.closest(
        node,
        EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS,
      );
      if (closestNonEditable && EditorDOMUtils.parentContainsNode(track, closestNonEditable)) {
        range.setStartBefore(closestNonEditable);
        range.setEndAfter(closestNonEditable);
      } else {
        while (
          node instanceof Text &&
          node?.parentNode?.firstChild === node &&
          node.parentNode.lastChild === node &&
          (node !== originalRange.startContainer || originalRange.startOffset === 0) &&
          (node !== originalRange.endContainer || originalRange.endOffset === node.length) &&
          node.parentNode instanceof HTMLElement &&
          EditorRange.WRAPING_ANCESTORS.includes(node.parentNode.tagName)
        ) {
          // range.selectNodeContents(node.parentNode);
          range.setStartBefore(node.parentNode);
          range.setEndAfter(node.parentNode);
          node = node.parentNode;
        }
      }

      insertRanges.push({
        track,
        range,
      });
    }

    return insertRanges;
  }

  static removeLostMarkers(range: DoDOCSelection.DoDOCRange.BoundaryMarkers) {
    if (range.startMarkerId) {
      const startMarker = document.getElementById(range.startMarkerId);

      if (startMarker?.parentNode) {
        startMarker.remove();
      }
    }

    if (range.endMarkerId) {
      const endMarker = document.getElementById(range.endMarkerId);

      if (endMarker?.parentNode) {
        endMarker.remove();
      }
    }

    if (range.markerId) {
      const collapsedMarker = document.getElementById(range.markerId);

      if (collapsedMarker?.parentNode) {
        collapsedMarker.remove();
      }
    }
  }
}

import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { useIntl } from 'react-intl';

import { ReduxInterface } from 'Editor/services';

import styles from './ColumnBreakElement.module.scss';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { BaseBlockElement } from '../../BaseViewBuilder';

type ColumnBreakContentArgs = { onlyChild: boolean };

const ColumnBreakContent = ({ onlyChild }: ColumnBreakContentArgs) => {
  const intl = useIntl();

  if (onlyChild) {
    return (
      <>
        {`-------------------------------------------------------  ${intl.formatMessage({
          id: 'EDITOR_COLUMN_BREAK',
        })}  -------------------------------------------------------`}
      </>
    );
  }

  return <>{` --  ${intl.formatMessage({ id: 'EDITOR_COLUMN_BREAK' })}  -- `}</>;
};
export class ColumnBreakElement extends BaseBlockElement {
  constructor() {
    super();

    this.validateOnlyChildState = this.validateOnlyChildState.bind(this);
    this.removeOnlyChildState = this.removeOnlyChildState.bind(this);
    this.buildElement = this.buildElement.bind(this);
  }

  get displayType() {
    // return INLINE / BLOCK
    const parentNode = this.parentNode as HTMLElement;
    return parentNode === EditorDOMUtils.getContentContainer(this) ||
      EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(parentNode?.tagName)
      ? DISPLAY_TYPES.BLOCK
      : DISPLAY_TYPES.INLINE;
  }

  get selectableContent() {
    return this;
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return [];
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return EditorDOMElements.INLINE_NON_EDITABLE_ELEMENTS.includes(this.tagName);
  }

  removeOnlyChildState() {
    this.removeAttribute('onlychild');
    this.buildElement();
  }

  validateOnlyChildState() {
    const paragraph = EditorDOMUtils.closest(
      this,
      EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    )?.cloneNode(true) as HTMLElement;

    if (paragraph) {
      const queryElements = [...EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS, this.tagName];
      const elements = paragraph.querySelectorAll(queryElements.join(','));

      for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
      }

      if (paragraph.textContent === '') {
        this.setAttribute('onlychild', 'true');
      } else {
        this.removeAttribute('onlychild');
      }
    }

    this.buildElement();
  }

  buildElement() {
    ReactDOM.unmountComponentAtNode(this);
    ReactDOM.render(
      <IntlProvider locale={ReduxInterface.getLocale()}>
        <ColumnBreakContent
          onlyChild={this.isBlock || this.hasAttribute('onlychild')}
        ></ColumnBreakContent>
      </IntlProvider>,
      this,
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('spellcheck', 'false');

    if (this.isBlock) {
      this.setAttribute('class', styles.block);
    } else {
      this.setAttribute('class', styles.inline);
    }

    setTimeout(this.validateOnlyChildState, 0);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

if (!window.customElements.get(ELEMENTS.ColumnBreakElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.ColumnBreakElement.IDENTIFIER, ColumnBreakElement);
}

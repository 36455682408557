import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { getBulletList, getNumberedList, getOutlineList } from 'Editor/redux/listStylesSlice';

const SLICE_NAME = 'EDITOR_TOOLBAR';

type ToolbarSliceState = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  superscript?: boolean;
  subscript?: boolean;
  vanish?: boolean;
  autoSpaceBefore?: boolean;
  autoSpaceAfter?: boolean;
  widowControl?: boolean;
  keepNext?: boolean;
  keepLines?: boolean;
  contextualSpacing?: boolean;
  pageBreakBefore?: boolean;
  lineRule?: string;
  lineHeight?: number;
  spaceBefore?: number;
  spaceAfter?: number;
  backgroundColor?: string;
  highlightColor?: string;
  paragraphColor?: string;
  fontFamily: string;
  fontSize: number;
  color: string;
  selectedList: string;
  alignment?: string;
  indentation?: {
    leftIndentation: number | null;
    rightIndentation: number | null;
    specialIndent: string;
    specialIndentValue: number | null;
  } | null;
};

const initialState: ToolbarSliceState = {
  fontFamily: 'arial',
  fontSize: 12,
  color: 'rgb(0,0,0)',
  selectedList: '',
};

const ToolbarSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateToolbarValues: (state, action: PayloadAction<Partial<ToolbarSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

const getSelectedList = (state: RootState) => state.editor.toolbar.selectedList;

export const selectedBulletList = createSelector(
  [getSelectedList, getBulletList],
  (selectedList, bulletLists) => {
    if (Object.keys(bulletLists).includes(selectedList)) {
      return true;
    }
    return false;
  },
);

export const selectedNumberedList = createSelector(
  [getSelectedList, getNumberedList],
  (selectedList, numberedLists) => {
    if (Object.keys(numberedLists).includes(selectedList)) {
      return true;
    }
    return false;
  },
);

export const selectedMultilevelList = createSelector(
  [getSelectedList, getOutlineList],
  (selectedList, getOutlineList) => {
    if (Object.keys(getOutlineList).includes(selectedList)) {
      return true;
    }
    return false;
  },
);

export const { updateToolbarValues } = ToolbarSlice.actions;

export default ToolbarSlice.reducer;

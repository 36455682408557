 
 
import { Mixin } from 'mixwith';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class extends superclass {
      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} anchorNode
       * @param {*} anchorOffset
       * @param {*} nonEditable
       */
      removeCitations(actionContext, anchorNode, anchorOffset, nonEditable) {
        if (actionContext.action === ActionContext.ACTION.DELETE) {
          this._deleteCitations(actionContext, anchorNode, anchorOffset, nonEditable);
        } else {
          this._backspaceCitations(actionContext, anchorNode, anchorOffset, nonEditable);
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} anchorNode
       * @param {*} anchorOffset
       * @param {*} nonEditable
       */
      _backspaceCitations(actionContext, anchorNode, anchorOffset, nonEditable) {
        if (nonEditable.tagName === ELEMENTS.CitationsGroupElement.TAG) {
          if (anchorNode.tagName === ELEMENTS.CitationsGroupElement.TAG) {
            if (anchorOffset === 0) {
              // this.selectionManager.selectNode(anchorNode);
              // !
              // DO NOTHING?
            } else if (anchorOffset === 1) {
              EditorSelectionUtils.selectNode(anchorNode);
            } else if (anchorOffset === anchorNode.childNodes.length) {
              EditorSelectionUtils.selectNode(anchorNode);
            } else {
              const selectdChild = anchorNode.childNodes[anchorOffset - 1];
              EditorSelectionUtils.setCaret(selectdChild, 'INSIDE_END');
              anchorNode = EditorSelectionUtils.getSelection().anchorNode;
              anchorOffset = EditorSelectionUtils.getSelection().anchorOffset;
              this._handleBackspaceOnCitationElement(
                actionContext,
                anchorNode,
                anchorOffset,
                selectdChild,
              );
            }
          }
          if (anchorNode.nodeType === Node.TEXT_NODE && nonEditable.contains(anchorNode)) {
            const bracketIndex = Array.from(nonEditable.childNodes).indexOf(anchorNode);
            if (bracketIndex === 0) {
              // first bracket
              if (anchorOffset === 0) {
                // ? DO NOTHING
              } else {
                EditorSelectionUtils.selectNode(nonEditable);
              }
            } else {
              // last bracket
              if (anchorOffset === 0) {
                if (
                  anchorNode.previousSibling &&
                  anchorNode.previousSibling.tagName === ELEMENTS.CitationElement.TAG
                ) {
                  EditorSelectionUtils.setCaret(anchorNode.previousSibling, 'INSIDE_END');
                  anchorNode = EditorSelectionUtils.getSelection().anchorNode;
                  anchorOffset = EditorSelectionUtils.getSelection().anchorOffset;
                  this._handleBackspaceOnCitationElement(
                    actionContext,
                    anchorNode,
                    anchorOffset,
                    EditorDOMUtils.closest(anchorNode, ELEMENTS.CitationElement.TAG),
                  );
                }
              } else {
                EditorSelectionUtils.selectNode(nonEditable);
              }
            }
          }
        } else if (nonEditable.tagName === ELEMENTS.CitationElement.TAG) {
          this._handleBackspaceOnCitationElement(
            actionContext,
            anchorNode,
            anchorOffset,
            nonEditable,
          );
        }
      }

      /**
       *
       * @param {ActionContext} actionContext
       * @param {*} anchorNode
       * @param {*} anchorOffset
       * @param {*} citElement
       */
      _handleBackspaceOnCitationElement(actionContext, anchorNode, anchorOffset, citElement) {
        // bettr when anchorNode is a text node
        const citationGroup = EditorDOMUtils.closest(
          citElement,
          ELEMENTS.CitationsGroupElement.TAG,
        );
        const citationIndex = Array.from(citationGroup.childNodes).indexOf(citElement);
        if (citationIndex === 1) {
          // first of group
          if (citElement.contains(anchorNode) && anchorOffset === 0) {
            // select group
            EditorSelectionUtils.selectNode(citationGroup);
            return;
          }
        } else {
          // middle/end of group
          if (
            citElement.contains(anchorNode) &&
            anchorOffset === 0 &&
            citElement.previousSibling &&
            citElement.previousSibling.tagName === ELEMENTS.CitationElement.TAG
          ) {
            // select previous citation
            citElement = citElement.previousSibling;
          }
        }
        if (
          citationGroup &&
          citationGroup.getCitationsCount &&
          citationGroup.getCitationsCount() <= 1
        ) {
          EditorSelectionUtils.selectNode(citationGroup);
          this.removeSelectionContent(actionContext);
          // this.setCursorPosition(
          //   this.addDeleteSuggestionOnRange(actionContext, range).ref,
          //   actionContext.collapseDirection,
          // );
        } else if (
          citationGroup &&
          citationGroup.getNonDeletedCitationsCount &&
          citationGroup.getNonDeletedCitationsCount() <= 1
        ) {
          // TODO : REVIEW THIS!!
          const trackDeletes = Array.from(
            citationGroup.querySelectorAll(ELEMENTS.TrackDeleteElement.TAG),
          );
          for (let index = 0; index < trackDeletes.length; index++) {
            const element = trackDeletes[index];
            EditorDOMUtils.replaceNode(element.parentNode, element.firstChild, element);
          }
          EditorSelectionUtils.selectNode(citationGroup);
          this.removeSelectionContent(actionContext);
          // this.setCursorPosition(
          //   this.addDeleteSuggestionOnRange(actionContext, range).ref,
          //   actionContext.collapseDirection,
          // );
          actionContext.addChangeUpdatedNode(citationGroup);
        } else {
          let selectionDestination;
          if (citElement.previousSibling) {
            selectionDestination = citElement.previousSibling;
          }
          // TODO : remove element
          EditorSelectionUtils.selectNode(citElement);
          if (this.removeSelectionContent(actionContext)) {
            // const range = EditorSelectionUtils.getSelection().getRangeAt(0);
            // this.addDeleteSuggestionOnRange(actionContext, range);
            if (selectionDestination) {
              EditorSelectionUtils.setCaret(selectionDestination, 'INSIDE_END');
            }
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} anchorNode
       * @param {*} anchorOffset
       * @param {*} nonEditable
       */
      _deleteCitations(actionContext, anchorNode, anchorOffset, nonEditable) {
        if (nonEditable.tagName === ELEMENTS.CitationsGroupElement.TAG) {
          if (anchorNode.tagName === ELEMENTS.CitationsGroupElement.TAG) {
            if (anchorOffset === 0) {
              EditorSelectionUtils.selectNode(anchorNode);
            } else if (anchorOffset === anchorNode.childNodes.length) {
              // this.selectionManager.selectNode(anchorNode);
              // !
              // DO NOTHING?
            } else if (anchorOffset === anchorNode.childNodes.length - 1) {
              EditorSelectionUtils.selectNode(anchorNode);
            } else {
              const selectdChild = anchorNode.childNodes[anchorOffset];
              EditorSelectionUtils.setCaret(selectdChild, 'INSIDE_START');
              anchorNode = EditorSelectionUtils.getSelection().anchorNode;
              anchorOffset = EditorSelectionUtils.getSelection().anchorOffset;
              this._handleDeleteOnCitationElement(
                actionContext,
                anchorNode,
                anchorOffset,
                selectdChild,
              );
            }
          }
          if (anchorNode.nodeType === Node.TEXT_NODE && nonEditable.contains(anchorNode)) {
            const bracketIndex = Array.from(nonEditable.childNodes).indexOf(anchorNode);
            if (bracketIndex === 0) {
              // first bracket
              if (anchorOffset === 0) {
                EditorSelectionUtils.selectNode(nonEditable);
              } else {
                if (
                  anchorNode.nextSibling &&
                  anchorNode.nextSibling.tagName === ELEMENTS.CitationElement.TAG
                ) {
                  EditorSelectionUtils.setCaret(anchorNode.nextSibling, 'INSIDE_START');
                  anchorNode = EditorSelectionUtils.getSelection().anchorNode;
                  anchorOffset = EditorSelectionUtils.getSelection().anchorOffset;
                  this._handleDeleteOnCitationElement(
                    actionContext,
                    anchorNode,
                    anchorOffset,
                    EditorDOMUtils.closest(anchorNode, ELEMENTS.CitationElement.TAG),
                  );
                }
              }
            } else {
              // last bracket
              if (anchorOffset === 0) {
                EditorSelectionUtils.selectNode(nonEditable);
              } else {
                // ? DO NOTHING
              }
            }
          }
        } else if (nonEditable.tagName === ELEMENTS.CitationElement.TAG) {
          this._handleDeleteOnCitationElement(actionContext, anchorNode, anchorOffset, nonEditable);
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} anchorNode
       * @param {*} anchorOffset
       * @param {*} citElement
       */
      _handleDeleteOnCitationElement(actionContext, anchorNode, anchorOffset, citElement) {
        // bettr when anchorNode is a text node
        const citationGroup = EditorDOMUtils.closest(
          citElement,
          ELEMENTS.CitationsGroupElement.TAG,
        );
        const citationIndex = Array.from(citationGroup.childNodes).indexOf(citElement);
        if (citationIndex === citationGroup.childNodes.length - 2) {
          // last of group
          if (EditorSelectionUtils.isSelectionAtEnd(citElement)) {
            // select group
            EditorSelectionUtils.selectNode(citationGroup);
            return;
          }
        } else {
          // start/middle of group
          if (
            EditorSelectionUtils.isSelectionAtEnd(citElement) &&
            citElement.nextSibling &&
            citElement.nextSibling.tagName === ELEMENTS.CitationElement.TAG
          ) {
            // select previous citation
            citElement = citElement.previousSibling;
          }
        }
        if (
          citationGroup &&
          citationGroup.getCitationsCount &&
          citationGroup.getCitationsCount() <= 1
        ) {
          EditorSelectionUtils.selectNode(citationGroup);
          this.removeSelectionContent(actionContext);
          // this.setCursorPosition(
          //   this.addDeleteSuggestionOnRange(actionContext, range).ref,
          //   actionContext.collapseDirection,
          // );
        } else if (
          citationGroup &&
          citationGroup.getNonDeletedCitationsCount &&
          citationGroup.getNonDeletedCitationsCount() <= 1
        ) {
          // TODO : REVIEW THIS!!
          const trackDeletes = Array.from(
            citationGroup.querySelectorAll(ELEMENTS.TrackDeleteElement.TAG),
          );
          for (let index = 0; index < trackDeletes.length; index++) {
            const element = trackDeletes[index];
            EditorDOMUtils.replaceNode(element.parentNode, element.firstChild, element);
          }
          EditorSelectionUtils.selectNode(citationGroup);
          if (this.removeSelectionContent(actionContext)) {
            // this.setCursorPosition(
            //   this.addDeleteSuggestionOnRange(actionContext, range).ref,
            //   actionContext.collapseDirection,
            // );
            actionContext.addChangeUpdatedNode(citationGroup);
          }
        } else {
          let selectionDestination;
          if (citElement.nextSibling) {
            selectionDestination = citElement.nextSibling;
          }
          // TODO : remove element
          EditorSelectionUtils.selectNode(citElement);
          if (this.removeSelectionContent(actionContext)) {
            // this.addDeleteSuggestionOnRange(actionContext, range);
            if (selectionDestination) {
              EditorSelectionUtils.setCaret(selectionDestination, 'INSIDE_START');
            }
          }
        }
      }

      /**
       * @param {ActionContext} actionContext
       * @param {*} range
       */
      removeRangeFromCitations(actionContext, range) {
        let citationGroup;

        const closest = EditorDOMUtils.closest(range.commonAncestorContainer, [
          ELEMENTS.CitationElement.TAG,
          ELEMENTS.CitationsGroupElement.TAG,
        ]);

        if (closest) {
          if (closest.tagName === ELEMENTS.CitationElement.TAG) {
            citationGroup = EditorDOMUtils.closest(
              range.commonAncestorContainer,
              ELEMENTS.CitationsGroupElement.TAG,
            );

            if (
              citationGroup &&
              citationGroup.getCitationsCount &&
              citationGroup.getCitationsCount() <= 1
            ) {
              const groupRange = EditorSelectionUtils.createNewRange();
              groupRange.selectNode(citationGroup);
              this.addDeleteSuggestionOnRange(actionContext, groupRange);
            } else if (
              citationGroup &&
              citationGroup.getNonDeletedCitationsCount &&
              citationGroup.getNonDeletedCitationsCount() <= 1
            ) {
              // TODO : REVIEW THIS!!
              const trackDeletes = Array.from(
                citationGroup.querySelectorAll(ELEMENTS.TrackDeleteElement.TAG),
              );
              for (let index = 0; index < trackDeletes.length; index++) {
                const element = trackDeletes[index];
                EditorDOMUtils.replaceNode(element.parentNode, element.firstChild, element);
              }
              const groupRange = EditorSelectionUtils.createNewRange();
              groupRange.selectNode(citationGroup);
              this.addDeleteSuggestionOnRange(actionContext, groupRange);
            } else {
              const range = EditorSelectionUtils.createNewRange();
              range.selectNode(closest);
              this.addDeleteSuggestionOnRange(actionContext, range);
            }
          } else {
            const range = EditorSelectionUtils.createNewRange();
            range.selectNode(closest);
            this.addDeleteSuggestionOnRange(actionContext, range);
          }
          actionContext.addChangeUpdatedNode(closest);
        }
      }
    },
);

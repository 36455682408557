//@ts-expect-error needs mixins refactor
import { Mixin } from 'mixwith';
import { notify } from '_common/components/ToastSystem';
import { Logger } from '_common/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import { ErrorCannotMergeCells } from 'Editor/services/_CustomErrors';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM/EditorDOMUtils';
import { ELEMENTS } from 'Editor/services/consts';
import {
  TableCellElement,
  TableElement,
} from 'Editor/services/VisualizerManager/Views/ViewBuilders';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';

const TABLE_OPERATIONS = {
  DELETE_CELLS: 'DELETE_CELLS',
  DELETE_ROWS: 'DELETE_ROWS',
  DELETE_ROWS_INDEX: 'DELETE_ROWS_INDEX',
  DELETE_COLUMNS: 'DELETE_COLUMNS',
  DELETE_COLUMNS_INDEX: 'DELETE_COLUMNS_INDEX',
  INSERT_ROW: 'INSERT_ROW',
  INSERT_ROW_INDEX: 'INSERT_ROW_INDEX',
  INSERT_COLUMN: 'INSERT_COLUMN',
  INSERT_COLUMN_INDEX: 'INSERT_COLUMN_INDEX',
  MERGE_CELLS: 'MERGE_CELLS',
  SPLIT_CELLS: 'SPLIT_CELLS',
  SORT_ROWS: 'SORT_ROWS',
} as const;

type TableOperationsType = (typeof TABLE_OPERATIONS)[keyof typeof TABLE_OPERATIONS];

type TableOperationArgsType = {
  operation: TableOperationsType;
  before?: boolean;
  shiftCells?: 'SHIFT_LEFT' | 'SHIFT_UP';
  splitColumns?: number;
  splitRows?: number;
  mergeCells?: boolean;
  index?: number[];
};

export default Mixin(
  (superclass: any) =>
    class TablesEditionHandler extends superclass {
      destroy() {
        super.destroy();
      }

      get TABLE_OPERATIONS() {
        return TABLE_OPERATIONS;
      }

      handleInsertTable(rows: number = 3, columns: number = 3) {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            let tableWidth: number = 0;

            // check if is inside another table
            let range = EditorSelectionUtils.getRange();

            if (range) {
              if (range.startContainer === EditorDOMUtils.getContentContainer()) {
                EditorSelectionUtils.fixSelection(range);
              }

              const block = EditorDOMUtils.findFirstLevelChildNode(
                EditorDOMUtils.getContentContainer(),
                range.startContainer,
              );

              const closestCell = EditorDOMUtils.closest(range.startContainer, [
                ELEMENTS.TableCellElement.TAG,
              ]) as TableCellElement;

              if (block instanceof HTMLElement) {
                if (closestCell) {
                  tableWidth =
                    EditorDOMUtils.convertUnitTo(getComputedStyle(closestCell).width, 'px', 'pt') ||
                    0;
                  tableWidth -= 5;
                } else {
                  tableWidth = this.dataManager?.sections.getPageWidthForBlockId(block.id);
                }

                if (tableWidth != null) {
                  const headerRow = !!this.dataManager.templates.getHeaderRow();

                  let table = DOMElementFactory.buildTable(rows, columns, tableWidth, headerRow);

                  this.handleInsertBlockNode(table);

                  table = EditorDOMUtils.getNode(table.id) as TableElement;
                  // TODO: temp fix
                  table.Visualizer = this.visualizerManager.Visualizer;

                  EditorSelectionUtils.setCaret(table.tBodies[0], 'INSIDE_START');
                  this.visualizerManager?.selection.debounceSelectionChanged();
                }
              }
            }
          } catch (error) {
            Logger.captureException(error);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      /**
       *
       * @param table
       * @param columnWidths in points (pt)
       */
      handleUpdateColumnWidths(
        table: TableElement,
        changedColumnWidths: {
          [index: number]: { current: Editor.Data.Node.TableWidth; delta: number };
        },
      ) {
        if (!this.askUserAboutThis()) {
          return false;
        }
        let actionContext = new ActionContext();

        if (table && EditorDOMUtils.isClosestBlockNodeEditable(table)) {
          let level0Node;
          if (table.parentNode === this.page) {
            level0Node = table;
          } else {
            level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, table);
          }

          this.updateColumnWidthsOperation(level0Node, table, changedColumnWidths);
          actionContext.jsonChanges = true;

          this.changeTracker.saveActionChanges(actionContext);
        }
      }

      /**
       *
       * @param table
       * @param rowHeights in points (pt)
       */
      handleUpdateRowHeigths(table: TableElement, rowHeights: number[]) {
        if (!this.askUserAboutThis()) {
          return false;
        }
        let actionContext = new ActionContext();
        if (table && EditorDOMUtils.isClosestBlockNodeEditable(table)) {
          let level0Node;
          if (table.parentNode === this.page) {
            level0Node = table;
          } else {
            level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, table);
          }

          this.updateRowHeightsOperation(level0Node, table, rowHeights);
          actionContext.jsonChanges = true;

          this.changeTracker.saveActionChanges(actionContext);
        }
      }

      /**
       *
       * @param table
       * @param height in points (pt)
       * @param width Editor.Data.Node.TableWidth
       */
      handleUpdateTableSize(
        table: TableElement,
        height: number,
        width: Editor.Data.Node.TableWidth,
      ) {
        if (!this.askUserAboutThis()) {
          return false;
        }
        let actionContext = new ActionContext();
        if (table && EditorDOMUtils.isClosestBlockNodeEditable(table)) {
          let level0Node;
          if (table.parentNode === this.page) {
            level0Node = table;
          } else {
            level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, table);
          }

          this.updateTableSizeOperation(level0Node, table, height, width);
          actionContext.jsonChanges = true;

          this.changeTracker.saveActionChanges(actionContext);
        }
      }

      handleUpdateTableProperties(propertiesData: Editor.Styles.TablePropertiesData) {
        if (!this.askUserAboutThis()) {
          return false;
        }

        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          try {
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            const elements = EditorSelectionUtils.getSelectedTableElements();

            if (elements && elements.selectedTable instanceof TableElement) {
              let actionContext = new ActionContext();
              if (
                elements.selectedTable &&
                EditorDOMUtils.isClosestBlockNodeEditable(elements.selectedTable)
              ) {
                let level0Node;
                if (elements.selectedTable.parentNode === this.page) {
                  level0Node = elements.selectedTable;
                } else {
                  level0Node = EditorDOMUtils.findFirstLevelChildNode(
                    this.page,
                    elements.selectedTable,
                  );
                }

                this.updateTablePropertiesOperation(
                  level0Node,
                  elements.selectedTable,
                  propertiesData,
                  elements.selectedCellsIds,
                  elements.rowsIndex,
                  elements.columnsIndex,
                );

                actionContext.jsonChanges = true;

                this.changeTracker.saveActionChanges(actionContext);
              }
            }
            this.visualizerManager?.selection.debounceSelectionChanged();
          } catch (error) {
            Logger.captureException(error);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }

      handleTableOperation(
        {
          operation,
          before = false,
          shiftCells = 'SHIFT_LEFT',
          splitColumns = 2,
          splitRows = 1,
          mergeCells = false,
          index,
        }: TableOperationArgsType,
        actionContext?: ActionContext,
      ) {
        let result = false;

        if (!this.askUserAboutThis()) {
          return result;
        }

        if (operation != null && this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let saveChanges = false;
          if (!actionContext) {
            actionContext = new ActionContext();
            saveChanges = true;
          }

          try {
            // restore and scroll into selection if needed
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            const range = EditorSelectionUtils.getRange();

            if (range) {
              const tableNode = EditorDOMUtils.closest(
                range.commonAncestorContainer,
                ELEMENTS.TableElement.TAG,
              ) as TableElement;

              if (tableNode && EditorDOMUtils.isClosestBlockNodeEditable(tableNode)) {
                let level0Node;
                if (tableNode.parentNode === this.page) {
                  level0Node = tableNode;
                } else {
                  level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, tableNode);
                }

                const selectedCells: TableCellElement[] = tableNode.getSelectedCells();

                if (selectedCells.length === 0) {
                  selectedCells.push(
                    EditorDOMUtils.closest(
                      range.commonAncestorContainer,
                      ELEMENTS.TableCellElement.TAG,
                    ) as TableCellElement,
                  );
                }

                tableNode.deselectAllCells();

                switch (operation) {
                  case this.TABLE_OPERATIONS.DELETE_CELLS:
                    this.deleteCellsOperation(actionContext, level0Node, tableNode, selectedCells, {
                      shiftCells,
                    });
                    break;
                  case this.TABLE_OPERATIONS.DELETE_ROWS:
                    this.deleteRowsOperation(actionContext, level0Node, tableNode, selectedCells);
                    break;
                  case this.TABLE_OPERATIONS.DELETE_ROWS_INDEX: {
                    if (index != null) {
                      const cells = [];
                      for (let i = 0; i < index.length; i++) {
                        const idx = index[i];
                        if (tableNode.tBodies[0].rows[idx]) {
                          cells.push(tableNode.tBodies[0].rows[idx].cells[0]);
                        }
                      }
                      this.deleteRowsOperation(actionContext, level0Node, tableNode, cells);
                    }
                    break;
                  }
                  case this.TABLE_OPERATIONS.DELETE_COLUMNS:
                    this.deleteColumnsOperation(
                      actionContext,
                      level0Node,
                      tableNode,
                      selectedCells,
                    );
                    break;
                  case this.TABLE_OPERATIONS.DELETE_COLUMNS_INDEX:
                    if (index != null) {
                      const cells = [];
                      for (let i = 0; i < index.length; i++) {
                        const idx = index[i];
                        // check cells for irregular tables
                        const length = tableNode.tBodies[0].rows.length;
                        for (let r = 0; r < length; r++) {
                          const cell = tableNode.tBodies[0].rows[r].cells[idx];
                          if (cell) {
                            cells.push(cell);
                            break;
                          }
                        }
                      }
                      this.deleteColumnsOperation(actionContext, level0Node, tableNode, cells);
                    }
                    break;
                  case this.TABLE_OPERATIONS.INSERT_ROW:
                    this.insertRowOperation(actionContext, level0Node, tableNode, selectedCells, {
                      before,
                    });
                    break;
                  case this.TABLE_OPERATIONS.INSERT_ROW_INDEX: {
                    const idx = index?.[0];
                    if (idx != null) {
                      if (idx === 0 && tableNode.tBodies[0].rows[idx]) {
                        const cells = [tableNode.tBodies[0].rows[0].cells[0]];
                        this.insertRowOperation(actionContext, level0Node, tableNode, cells, {
                          before: true,
                        });
                      } else if (tableNode.tBodies[0].rows[idx - 1]) {
                        const cells = [tableNode.tBodies[0].rows[idx - 1].cells[0]];
                        this.insertRowOperation(actionContext, level0Node, tableNode, cells, {
                          before: false,
                        });
                      }
                    }
                    break;
                  }
                  case this.TABLE_OPERATIONS.INSERT_COLUMN:
                    this.insertColumnOperation(
                      actionContext,
                      level0Node,
                      tableNode,
                      selectedCells,
                      {
                        before,
                      },
                    );
                    break;
                  case this.TABLE_OPERATIONS.INSERT_COLUMN_INDEX: {
                    const idx = index?.[0];
                    if (idx != null) {
                      const cells = [];
                      const length = tableNode.tBodies[0].rows.length;
                      if (idx === 0) {
                        for (let r = 0; r < length; r++) {
                          const cell = tableNode.tBodies[0].rows[r].cells[idx];
                          if (cell) {
                            cells.push(cell);
                            break;
                          }
                        }
                        this.insertColumnOperation(actionContext, level0Node, tableNode, cells, {
                          before: true,
                        });
                      } else {
                        for (let r = 0; r < length; r++) {
                          const cell = tableNode.tBodies[0].rows[r].cells[idx - 1];
                          if (cell) {
                            cells.push(cell);
                            break;
                          }
                        }
                        this.insertColumnOperation(actionContext, level0Node, tableNode, cells, {
                          before: false,
                        });
                      }
                    }
                    break;
                  }
                  case this.TABLE_OPERATIONS.MERGE_CELLS:
                    this.mergeCellsOperation(actionContext, level0Node, tableNode, selectedCells);
                    break;
                  case this.TABLE_OPERATIONS.SPLIT_CELLS:
                    this.splitCellsOperation(actionContext, level0Node, tableNode, selectedCells, {
                      splitColumns,
                      splitRows,
                      mergeCells,
                    });
                    break;
                  default:
                    break;
                }

                if (tableNode.parentNode) {
                  tableNode.buildTableOptions();
                }
              }

              this.visualizerManager.selection.triggerSelectionChanged();

              if (saveChanges) {
                this.changeTracker.saveActionChanges(actionContext);
              }
              result = true;
            }
          } catch (error) {
            if (error instanceof ErrorCannotMergeCells) {
              notify({
                type: 'error',
                title: 'global.error',
                message: 'editor.errors.cannotMergeCells',
              });
              Logger.warn(error);
            } else {
              Logger.captureException(error);
              this.restoreChanges(actionContext);
            }
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }

        return result;
      }

      handleDeleteTable() {
        if (this.navigationManager.isMarkerRendered()) {
          this.visualizerManager?.selection.stopSelectionTracker();

          let actionContext;

          try {
            // restore and scroll into selection if needed
            this.navigationManager.scrollIntoSelection();

            this.clipboard.removePasteOptions();

            actionContext = new ActionContext(ActionContext.ACTION.DELETE);

            const range = EditorSelectionUtils.getRange();

            if (range) {
              const tableNode = EditorDOMUtils.closest(
                range.commonAncestorContainer,
                ELEMENTS.TableElement.TAG,
              ) as TableElement;

              if (tableNode && EditorDOMUtils.isClosestBlockNodeEditable(tableNode)) {
                this.visualizerManager?.getWidgetsManager()?.removeAllWidgetsForView(tableNode.id);

                // TODO: create remove block operations for normal mode and suggestions mode
                // this.removeBlockNodeOperation(actionContext, tableNode.id);
                EditorSelectionUtils.selectNode(tableNode);
                this.handleDeleteOnMultiSelection(actionContext, tableNode);
              }

              this.visualizerManager.selection.triggerSelectionChanged();

              this.changeTracker.saveActionChanges(actionContext);
            }
          } catch (error) {
            Logger.captureException(error);
            this.restoreChanges(actionContext);
          } finally {
            this.visualizerManager.selection.debounceStartSelectionTracker();
          }
        }
      }
    },
);

import { createContext, useContext, useState } from 'react';

type ITableOptionsContext = {
  deleting: boolean;
  deletingTable: boolean;
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setDeletingTable: React.Dispatch<React.SetStateAction<boolean>>;
};
const TableOptionsContext = createContext<ITableOptionsContext>({
  deleting: false,
  deletingTable: false,
  setDeleting: () => {},
  setDeletingTable: () => {},
});

type TableOptionsProps = {
  children: JSX.Element;
};

const TableOptionsProvider = ({ children }: TableOptionsProps) => {
  const [deleting, setDeleting] = useState(false);
  const [deletingTable, setDeletingTable] = useState(false);

  return (
    <TableOptionsContext.Provider
      value={{ deleting, setDeleting, deletingTable, setDeletingTable }}
    >
      {children}
    </TableOptionsContext.Provider>
  );
};

export default TableOptionsProvider;

export const useTableOptionsContext = () => useContext(TableOptionsContext);

 

 
export abstract class BaseController {
  Data: Presentation.Data.State;
  private transportEvents: any;

  constructor(Data: Presentation.Data.State) {
    this.Data = Data;
    this.transportEvents = {};
  }

  protected registerTransportEvents(events: any) {
    const eventKeys = Object.keys(events) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event: Realtime.Transport.ServerEventName = eventKeys[i];
      if (!this.transportEvents[event]) {
        this.Data.transport.handleEvent(event, events[event]);
      }
    }
    this.transportEvents = {
      ...events,
      ...this.transportEvents,
    };
  }

  protected unregisterAllTransportEvents() {
    const eventKeys = Object.keys(this.transportEvents) as Realtime.Transport.ServerEventName[];
    for (let i = 0; i < eventKeys.length; i++) {
      const event = eventKeys[i];
      this.Data.transport.removeEvent(event, this.transportEvents[event]);
    }
  }

  abstract start(documentId?: string, data?: Realtime.Core.Document.Data, user?: any): void;
  abstract stop(): void;
  abstract destroy(): void;
}

import { useTasks } from 'Presentation/SyncStore';

const useAllTasks = (slideId?: Presentation.Data.Slide['_id']) => {
  const data = useTasks()?.data;
  const tasks = data ? Object.values(data) : [];

  const filterTask = (task: Presentation.Data.Task) => {
    if (slideId) {
      return task.anchor[0].id === slideId;
    }

    return true;
  };

  const filteredTasks: (Presentation.Data.Task & { state: 'Cancelled' | undefined })[] = tasks
    .filter(filterTask)
    .map((task) => {
      return {
        ...task,
        state: task.deletionDate !== '' ? 'Cancelled' : undefined,
      };
    });

  return filteredTasks;
};

export default useAllTasks;

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, InputField, Input, TextArea } from 'dodoc-design-system';

import { useCurrentUser, useSelector, useDispatch } from '_common/hooks';
import { AuthService, Logger } from '_common/services';
import { notify } from '_common/components/ToastSystem';

import { closeAndResetModal } from '_common/modals/ModalsSlice';

const MODAL = 'SupportFormModal';

type SupportFormValidations = { name?: string; email?: string; message?: string };

const SupportFormModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const { data: user, isLoading } = useCurrentUser();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validations, setValidations] = useState<SupportFormValidations>({});

  const nameInputRef = useRef<HTMLInputElement>();
  const messageInputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!isLoading) {
      setName(user?.profile.name ?? '');
      setEmail(user?.profile.email ?? '');
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (isOpen && user) {
      if (!user.profile.name && nameInputRef.current) {
        nameInputRef.current.focus();
      } else if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  }, [isOpen, user]);

  if (isLoading) {
    return null;
  }

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    setValidations((prevState) => ({ ...prevState, name: '' }));
  };

  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setValidations((prevState) => ({ ...prevState, email: '' }));
  };

  const handleOnChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);
    setValidations((prevState) => ({ ...prevState, message: '' }));
  };

  const handleOnClickSendMessage = () => {
    const validations: SupportFormValidations = {};

    if (!name || name.trim().length === 0) {
      validations.name = intl.formatMessage({
        id: 'validation.name.insertName',
      });
    }

    if (!email || email.trim().length === 0) {
      validations.email = intl.formatMessage({
        id: 'validation.email.insertEmail',
      });
    } else if (
       
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        email,
      )
    ) {
      // Regex from most accepted answer
      // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      validations.email = intl.formatMessage({
        id: 'auth.errors.emailValid',
      });
    }

    if (!message || message.trim().length === 0) {
      validations.message = intl.formatMessage({
        id: 'validation.support.emptyMessage',
      });
    }

    if (Object.keys(validations).length > 0) {
      setValidations(validations);
    } else {
      sendRequest();
      handleCloseSupportModal();
    }
  };

  const sendRequest = () => {
    const data = {
      user_name: name,
      email,
      message,
    };

    // create axios request
    new AuthService()
      .sendHelpTicket(data)
      .then(() => {
        notify({
          type: 'success',
          title: 'notifications.support.title',
          message: 'notifications.support.messageSuccess',
        });
      })
      .catch((error) => {
        notify({
          type: 'error',
          title: 'notifications.support.title',
          message: 'notifications.support.messageError',
        });

        Logger.captureException(error, {
          fingerprint: ['{{ default }}', error.config.url],
        });
      });
  };

  const handleCloseSupportModal = () => {
    setMessage('');
    setValidations({});
    dispatch(closeAndResetModal(MODAL));
  };

  return (
    <Modal
      open={!!isOpen}
      onClose={handleCloseSupportModal}
      width="46rem"
      backDropOpacity="0"
      position={{ left: 'auto', top: 'auto', right: '10rem', bottom: '5rem' }}
      testId="support-form"
    >
      <Modal.Header onClose={handleCloseSupportModal}>
        <FormattedMessage id="support.supportTitle" />
      </Modal.Header>

      <Modal.Body>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'global.name' })}
          feedback={validations.name ? validations.name : undefined}
          testId="support-form-name"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'global.name' })}
            size="large"
            value={name}
            onChange={handleOnChangeName}
            error={!!validations.name}
            testId="support-form-name"
          />
        </InputField>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'auth.login.email' })}
          feedback={validations.email ? validations.email : undefined}
          testId="support-form-email"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'settings.user.email' })}
            size="large"
            value={email}
            onChange={handleOnChangeEmail}
            error={!!validations.email}
            testId="support-form-email"
          />
        </InputField>
        <InputField
          size="large"
          label={intl.formatMessage({ id: 'support.howCanWeHelp' })}
          feedback={validations.message}
          testId="support-form-message"
        >
          <TextArea
            placeholder={intl.formatMessage({
              id: 'storage.modals.usersUpload.headers.message',
            })}
            size="large"
            value={message}
            onChange={handleOnChangeMessage}
            error={!!validations.message}
            testId="support-form-message-textarea"
          />
        </InputField>
      </Modal.Body>

      <Modal.Footer>
        <Button size="medium" onClick={handleCloseSupportModal} testId="support-form-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleOnClickSendMessage}
          testId="support-form-submit-button"
        >
          <FormattedMessage id="support.sendMessage" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupportFormModal;

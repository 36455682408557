import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks/redux';
import {
  Button,
  Modal,
  Input,
  SectionHeader,
  InputField,
  Checkbox,
  Select,
} from 'dodoc-design-system';

import { closeModal } from '_common/modals/ModalsSlice';

import EditorManager from 'Editor/services/EditorManager';

import styles from './CustomSpacingModal.module.scss';
import { useMemo, useState } from 'react';
import type {
  SelectOption,
  SelectProps,
} from 'dodoc-design-system/build/types/Components/Selects/Select';
import { MeasureInput } from '_common/components';

type LineRule = 'auto' | 'atLeast' | 'exact';

type SpacingTypeOption = SelectOption & {
  value: '1' | '1.15' | '1.5' | '2' | LineRule;
  lineRule: LineRule;
  saveLastValue?: boolean;
  defaultValue?: string;
  measure?: 'pt';
};

const CustomSpacingModal = () => {
  const intl = useIntl();

  const isOpen = useSelector((state) => state.modals.open.CustomSpacingModal);
  const spacing = useSelector((state) => state.modals.CustomSpacingModal.spacing);

  const lineSpacingTypeOptions: SpacingTypeOption[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'editor.menu.format.lineSpacing.single' }),
        value: '1',
        lineRule: 'auto',
        defaultValue: undefined,
      },
      {
        label: '1.15',
        value: '1.15',
        lineRule: 'auto',
        defaultValue: undefined,
      },
      {
        label: '1.5',
        value: '1.5',
        lineRule: 'auto',
        defaultValue: undefined,
      },
      {
        label: intl.formatMessage({ id: 'editor.menu.format.lineSpacing.double' }),
        value: '2',
        lineRule: 'auto',
        defaultValue: undefined,
      },
      {
        label: intl.formatMessage({ id: 'AT_LEAST' }),
        value: 'atLeast',
        lineRule: 'atLeast',
        measure: 'pt',
        defaultValue: '12',
        saveLastValue: true,
      },
      {
        label: intl.formatMessage({ id: 'storage.actionBar.search.rules.conditions.exactly' }),
        value: 'exact',
        lineRule: 'exact',
        measure: 'pt',
        defaultValue: '12',
        saveLastValue: true,
      },
      {
        label: intl.formatMessage({ id: 'MULTIPLE' }),
        value: 'auto',
        lineRule: 'auto',
        defaultValue: '1.33',
        saveLastValue: true,
      },
    ];
  }, []);

  const initialValues = useMemo(() => {
    let lineRule: SpacingTypeOption['value'] = spacing.lineRule ?? 'auto';
    let lineHeight: string | undefined =
      typeof spacing.lineHeight === 'number' ? `${spacing.lineHeight}` : spacing.lineHeight;

    switch (lineRule) {
      case 'auto': {
        switch (lineHeight) {
          case '1':
          case '1.15':
          case '1.5':
          case '2':
            lineRule = lineHeight;
            lineHeight = undefined;
            break;
        }
        break;
      }
    }
    return {
      lineRule,
      lineHeight,
    };
  }, [spacing]);

  const [state, setState] = useState<Editor.Elements.SpacingProperties>({
    ...spacing,
    lineHeight: initialValues.lineHeight,
  });
  const [selectedSpacingType, setSelectedSpacingType] = useState<SpacingTypeOption | undefined>(
    lineSpacingTypeOptions.find((option) => option.value === initialValues.lineRule),
  );
  const [lastValue, setLastValue] = useState<{ [x in SpacingTypeOption['value']]?: string }>({
    [initialValues.lineRule]: initialValues.lineHeight,
  });
  const dispatch = useDispatch();

  const handleBeforeAS = () => {
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      asb: !state.asb,
    }));
  };
  const handleAfterAS = () => {
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      asa: !state.asa,
    }));
  };

  const handleSelectedSpacingType: SelectProps<SpacingTypeOption>['onChange'] = (e) => {
    /**
     * When the selected spacing type changes:
     * Set the value of the line spacing input to its last value or default value or option value
     */
    setState((prevState) => ({
      ...prevState,
      lineHeight: lastValue?.[e.value] ?? e.defaultValue,
      lineRule: e.lineRule,
    }));
    setSelectedSpacingType(e);
  };

  const handleSpacingValueChange = (value: string) => {
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      lineHeight: value,
    }));

    /**
     * If a value is set and the selected spacing type is a auto preset option
     * set the selected option to the auto (multiple) option
     */
    if (value) {
      switch (selectedSpacingType?.lineRule) {
        case 'auto':
          setSelectedSpacingType(lineSpacingTypeOptions.find((option) => option.value === 'auto'));
      }

      /**
       * Save the last value if the selected spacing type option has this setting on
       */
      if (selectedSpacingType?.saveLastValue) {
        setLastValue((prevState) => ({
          ...prevState,
          [selectedSpacingType.value]: value,
        }));
      }
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState((prevState: Editor.Elements.SpacingProperties) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleApply = () => {
    let lineHeight = state.lineHeight;

    if (selectedSpacingType) {
      /**
       * When one of these spacing type are selected,
       * the lineHeight will be undefined in order to have the input empty
       * so we need to set the lineHeight to the value of the selected spacing type before submitting
       */
      switch (selectedSpacingType.value) {
        case '1':
        case '1.15':
        case '1.5':
        case '2': {
          lineHeight = selectedSpacingType?.value;
          break;
        }
      }

      /**
       * If the lineHeight its not set, set it to the last value or default value of the selected spacing type
       * Needs this validation because the last value its only set when onBlur is made on the MeasureInput
       * and it can happen that the user deletes the value and clicks directly on the submit button
       * without making the onBlur event
       */
      if (lineHeight == null) {
        lineHeight = lastValue?.[selectedSpacingType.value] ?? selectedSpacingType?.defaultValue;
      }
    }

    EditorManager.getInstance().lineSpaceCurrentSelection({
      ...state,
      lineHeight,
    });
    close();
  };

  const close = () => {
    dispatch(closeModal('CustomSpacingModal'));
  };

  return (
    <Modal open={!!isOpen} onClose={close} width="65rem" testId="custom-spacing">
      <Modal.Header onClose={close}>
        <FormattedMessage id="SPACING_OPTIONS" />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.section}>
          <SectionHeader>
            <FormattedMessage id="editor.modals.customSpacing.lineSpacing" />
          </SectionHeader>
        </div>
        <div className={styles.content}>
          <div className={styles.row}>
            <Select
              options={lineSpacingTypeOptions}
              value={lineSpacingTypeOptions.find(
                (option) => option.value === selectedSpacingType?.value,
              )}
              onChange={handleSelectedSpacingType}
              width="100%"
              menuPosition="fixed"
              menuWidth="30rem"
              clearable={false}
              testId="line-spacing-type"
            />
            <MeasureInput
              size="large"
              name="lineHeight"
              onChange={handleSpacingValueChange}
              value={state.lineHeight ? `${state.lineHeight}` : undefined}
              step="0.1"
              valueSuffix={selectedSpacingType?.measure}
              min={0}
              allowDecimal
              avoidEmpty
              clearable={false}
              placeholder={''}
              testId="custom-spacing-line-spacing"
            />
          </div>
        </div>
        <div className={styles.section}>
          <SectionHeader>
            <FormattedMessage id="PARAGRAPH_SPACING" />
          </SectionHeader>
        </div>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.column}>
              <InputField
                label={intl.formatMessage({ id: 'editor.modals.customSpacing.spacingBefore' })}
                testId="space-before-field"
              >
                <Input
                  size="large"
                  name="spaceBefore"
                  onChange={onInputChange}
                  value={`${state.spaceBefore}`}
                  type="number"
                  step="1"
                  min="0"
                  disabled={!!state.asb}
                  placeholder=""
                  testId="custom-spacing-space-before"
                />
              </InputField>
              <Checkbox
                onChange={handleBeforeAS}
                checked={state.asb ? 'checked' : 'unchecked'}
                size="small"
                testId="custom-spacing-auto-spacing-checkbox"
              >
                <FormattedMessage id="AUTO_SPACING" />
              </Checkbox>
            </div>
            <div className={styles.column}>
              <InputField
                label={intl.formatMessage({ id: 'editor.modals.customSpacing.spacingAfter' })}
                testId="space-after-field"
              >
                <Input
                  size="large"
                  name="spaceAfter"
                  onChange={onInputChange}
                  value={`${state.spaceAfter}`}
                  type="number"
                  step="1"
                  min="0"
                  placeholder=""
                  disabled={!!state.asa}
                  testId="custom-spacing-space-after"
                />
              </InputField>
              <Checkbox
                onChange={handleAfterAS}
                checked={state.asa ? 'checked' : 'unchecked'}
                size="small"
                testId="custom-spacing-auto-spacing-checkbox"
              >
                <FormattedMessage id="AUTO_SPACING" />
              </Checkbox>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="custom-spacing-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleApply}
          testId="custom-spacing-submit-button"
        >
          <FormattedMessage id="APPLY_CHANGES" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomSpacingModal;

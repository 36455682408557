import { useCallback } from 'react';
import Extensors from './Extensors';
import ResizeAnchors from './ResizeAnchors';
import SelectionOverlay from './SelectionOverlay';
import InsertCaptionShortcut from './TableShortcuts';
import { useTableOptionsContext } from './TableOptionsContext';

type TableOptionsProps = {
  widths: number[];
  heights: number[];
  columnWidths: number[];
  rowHeights: number[];
  selection: {
    polygon: [
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
      Editor.Elements.Table.Point,
    ][];
    rows: number[];
    columns: number[];
    fullySelected: {
      row: number[];
      column: number[];
    };
  };
  offsets: Rect | null;
  insert: (index: number, selection: Editor.Elements.Table.Selection) => void;
  select: (index: number, selection: Editor.Elements.Table.Selection) => void;
  remove: (index: number[], selection: Editor.Elements.Table.Selection) => void;
  resize: (
    selection: Editor.Elements.Table.Selection,
    params: { [index: number | string]: { current: number; delta: number } },
  ) => void;
};

const TableOptionsView = ({
  widths,
  heights,
  columnWidths,
  rowHeights,
  selection,
  offsets,
  insert,
  select,
  remove,
  resize,
}: TableOptionsProps) => {
  const { setDeleting } = useTableOptionsContext();

  const width = widths.reduce((previous, current) => previous + current, 0);
  const height = heights.reduce((previous, current) => previous + current, 0);

  const onRemove = useCallback(
    (index: number[], selection: Editor.Elements.Table.Selection) => {
      setDeleting(false);
      remove(index, selection);
    },

    [remove],
  );

  return (
    <>
      <Extensors
        sizes={widths}
        insert={insert}
        select={select}
        remove={onRemove}
        selected={selection.columns}
        fullySelected={selection.fullySelected}
        type="column"
      />
      <Extensors
        sizes={heights}
        insert={insert}
        select={select}
        remove={onRemove}
        selected={selection.rows}
        fullySelected={selection.fullySelected}
        type="row"
      />
      <SelectionOverlay selection={selection.polygon} width={width} height={height} />
      <ResizeAnchors rows={rowHeights} columns={columnWidths} resize={resize} />
      <InsertCaptionShortcut offsets={offsets} />
    </>
  );
};

export default TableOptionsView;

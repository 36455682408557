import { v4 as uuidv4 } from 'uuid';
import Hash from 'object-hash';
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { AlreadyExistsError, NotFoundError } from '../../common';
import { CitationData, CitationsLibraryData } from './Citations.types';
import { CITATION_PRIORITY } from 'Editor/services/consts';

export class CitationsLibrary extends RealtimeObject<CitationsLibraryData> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'citations_libraries');
  }

  static TYPE_NAME() {
    return 'CITATIONS_LIBRARY';
  }

  static TYPE_COLLECTION() {
    return 'citations_libraries';
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = this.get();
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  private is_valid_doi(citation: Pick<CitationData, 'doi' | 'id'>) {
    const data = this.get();
    if (data) {
      const citations_ids = Object.keys(data.citations);
      let cit;
      for (let index = 0; index < citations_ids.length; index++) {
        cit = data.citations[citations_ids[index]];
        if (
          cit.doi &&
          citation.doi &&
          cit.doi === citation.doi &&
          (!citation.id || cit.id !== citation.id)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  private setMonthAndYear(citation: Pick<CitationData, 'year' | 'month' | 'publication_date'>) {
    if (citation.publication_date) {
      if (!citation.month) {
        citation.month = new Date(citation.publication_date).toLocaleString('default', {
          month: 'long',
        });
      }
      if (!citation.year) {
        citation.year = new Date(citation.publication_date).getFullYear().toString();
      }
    }
  }

  citation(citationId: string) {
    return this.get()?.citations[citationId] || null;
  }

  getCitations() {
    return this.get()?.citations || [];
  }

  async addCitations(citations: CitationData[], source?: string) {
    let result: { inserted: CitationData[]; rejected: CitationData[] } = {
      inserted: [],
      rejected: [],
    };
    for (let index = 0; index < citations.length; index++) {
      try {
        await this.addCitation(citations[index], source);
        result.inserted.push(citations[index]);
      } catch (error: MyAny) {
        result.rejected.push({ ...citations[index], error: error.type });
      }
    }
    return result;
  }

  addCitation(citation: CitationData, source?: string) {
    if (!this.is_valid_doi(citation)) {
      return Promise.reject(new AlreadyExistsError('Invalid citation: Repeated DOI'));
    }

    if (!citation.id) {
      citation.id = uuidv4();
    }
    if (!citation.priority) {
      citation.priority = CITATION_PRIORITY.MEDIUM;
    }

    citation.inserted = false;

    this.setMonthAndYear(citation);
    const time = Date.now();
    citation.time = {
      creation: time,
      modification: time,
      access: time,
    };

    citation.hash = this._generateCitationHash(citation);

    return this.apply([{ p: ['citations', citation.id], oi: citation }]);
  }

  _generateCitationHash(citation: CitationData): string {
    // calculate hash
    let citationClone = JSON.parse(JSON.stringify(citation));

    // remove specific keys
    delete citationClone.insert;
    delete citationClone.hash;
    delete citationClone.time;
    delete citationClone.docx;
    delete citationClone.id;

    // use calculate hash using md5 and ordered object
    return Hash(citationClone, { algorithm: 'md5', unorderedObjects: true });
  }

  updateCitation(citation: Partial<CitationData>) {
    const data = this.get();
    if (citation.id && data?.citations[citation.id]) {
      if (citation.doi && !this.is_valid_doi(citation as CitationData)) {
        return Promise.reject(new AlreadyExistsError('Invalid citation: Repeated DOI'));
      }
      const updated = JSON.parse(JSON.stringify(data.citations[citation.id]));
      const updateKeys = Object.keys(citation);
      for (let index = 0; index < updateKeys.length; index++) {
        const key = updateKeys[index];
        /* @ts-expect-error */
        if (citation[key]) {
          /* @ts-expect-error */
          updated[key] = citation[key];
        } else {
          delete updated[key];
        }
      }
      updated.time.modification = Date.now();
      this.setMonthAndYear(updated);
      return this.apply([
        { p: ['citations', citation.id], od: data?.citations[citation.id], oi: updated },
      ]);
    }
    return Promise.reject(new NotFoundError('Citation not found in library'));
  }

  removeCitation(citationId: string) {
    const data = this.get();
    if (data?.citations[citationId]) {
      return this.apply([{ p: ['citations', citationId], od: data?.citations[citationId] }]);
    }
    return Promise.reject(new NotFoundError('Citation not found in library'));
  }

  defineCitationPriority(citationId: string, priority: string) {
    const data = this.get();
    if (data && data.citations[citationId]) {
      // set or update
      return this.updateCitation({
        id: citationId,
        priority,
      });
    }
    return Promise.reject(new NotFoundError('Citation not found in library'));
  }
}

import ReactDOM from 'react-dom';
import { IntlProvider } from 'Intl/Intl';
import { useIntl } from 'react-intl';
import { ReduxInterface } from 'Editor/services';
import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { BaseBlockElement } from '../../BaseViewBuilder';

import styles from './PageBreakElement.module.scss';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

type PageBreakContentArgs = { onlyChild: boolean };

const PageBreakContent = ({ onlyChild }: PageBreakContentArgs) => {
  const intl = useIntl();

  if (onlyChild) {
    return (
      <>
        {`--------------------------------------------------------  ${intl.formatMessage({
          id: 'editor.menu.insert.pageBreak',
        })}  --------------------------------------------------------`}
      </>
    );
  }

  return <>{` --  ${intl.formatMessage({ id: 'editor.menu.insert.pageBreak' })}  -- `}</>;
};
export class PageBreakElement extends BaseBlockElement {
  constructor() {
    super();

    this.validateOnlyChildState = this.validateOnlyChildState.bind(this);
    this.removeOnlyChildState = this.removeOnlyChildState.bind(this);
    this.buildElement = this.buildElement.bind(this);
  }

  get displayType() {
    // return INLINE / BLOCK
    const parentNode = this.parentNode as HTMLElement;
    return parentNode === EditorDOMUtils.getContentContainer(this) ||
      EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS.includes(parentNode?.tagName)
      ? DISPLAY_TYPES.BLOCK
      : DISPLAY_TYPES.INLINE;
  }

  get selectableContent() {
    return this;
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || [];
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  removeOnlyChildState() {
    this.removeAttribute('onlychild');
    this.buildElement();
  }

  validateOnlyChildState() {
    const paragraph = EditorDOMUtils.closest(
      this,
      EditorDOMElements.BLOCK_TEXT_ELEMENTS,
    )?.cloneNode(true) as HTMLElement;

    if (paragraph) {
      const queryElements = [...EditorDOMElements.INLINE_FRONTEND_ONLY_ELEMENTS, this.tagName];
      const elements = paragraph.querySelectorAll(queryElements.join(','));

      for (let i = 0; i < elements.length; i++) {
        elements[i].remove();
      }

      if (paragraph.textContent === '') {
        this.setAttribute('onlychild', 'true');
      } else {
        this.removeAttribute('onlychild');
      }
    }

    this.buildElement();
  }

  buildElement() {
    ReactDOM.unmountComponentAtNode(this);
    ReactDOM.render(
      <IntlProvider locale={ReduxInterface.getLocale()}>
        <PageBreakContent onlyChild={this.isBlock || this.hasAttribute('onlychild')} />
      </IntlProvider>,
      this,
    );
  }

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('spellcheck', 'false');

    if (this.isBlock) {
      this.setAttribute('class', styles.block);
    } else {
      this.setAttribute('class', styles.inline);
    }

    setTimeout(this.validateOnlyChildState, 0);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

if (!window.customElements.get(ELEMENTS.PageBreakElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.PageBreakElement.IDENTIFIER, PageBreakElement);
}

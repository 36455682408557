import { ExtensionHelper, ReduxInterface as EditorReduxInterface } from 'Editor/services';
import { ReduxInterface as PresentationReduxInterface } from 'Presentation/services/ReduxInterface';

const _testFontFamilyOnBrowser = (font: string) => {
  let result;
  if (!FontFamilyHelper.PROVIDED_FONTS.includes(font)) {
    const testContainer = document.createElement('div');
    testContainer.setAttribute('id', 'container-test-font');
    testContainer.style.position = 'fixed';
    testContainer.style.top = '100%';
    testContainer.style.visibility = 'collapse';
    document.body.appendChild(testContainer);

    const spanDefault = document.createElement('span');
    spanDefault.style.position = 'absolute';
    spanDefault.style.fontSize = '50px';
    spanDefault.style.width = 'auto';
    spanDefault.style.fontFamily = '"Inter"';
    spanDefault.style.whiteSpace = 'nowrap';
    spanDefault.appendChild(document.createTextNode('The quick brown fox jumps over the lazy dog'));

    testContainer.appendChild(spanDefault);

    const spanTest = spanDefault.cloneNode(true) as HTMLElement;
    spanTest.style.fontFamily = `"${font}", "Inter"`;

    testContainer.appendChild(spanTest);

    result = spanDefault.offsetWidth !== spanTest.offsetWidth;

    testContainer.remove();
  } else {
    result = true;
  }
  return result;
};

const FONTS_TO_LOAD: { name: string; src: string; props: FontFaceDescriptors }[] = [
  /* Inter Regular */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-Regular.woff'), url('/fonts/Inter/Inter-Regular.woff2')",
    props: {
      style: 'normal',
      weight: '400',
      display: 'block',
    },
  },
  /* Inter Regular Italic */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-Italic.woff'), url('/fonts/Inter/Inter-Italic.woff2')",
    props: {
      style: 'italic',
      weight: '400',
      display: 'block',
    },
  },
  /* Inter Medium */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-Medium.woff'), url('/fonts/Inter/Inter-Medium.woff2')",
    props: {
      style: 'normal',
      weight: '500',
      display: 'block',
    },
  },
  /* Inter Medium Italic */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-MediumItalic.woff'), url('/fonts/Inter/Inter-MediumItalic.woff2')",
    props: {
      style: 'italic',
      weight: '500',
      display: 'block',
    },
  },
  /* Inter SemiBold */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-SemiBold.woff'), url('/fonts/Inter/Inter-SemiBold.woff2')",
    props: {
      style: 'normal',
      weight: '600',
      display: 'block',
    },
  },
  /* Inter SemiBold Italic */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-SemiBoldItalic.woff'), url('/fonts/Inter/Inter-SemiBoldItalic.woff2')",
    props: {
      style: 'italic',
      weight: '600',
      display: 'block',
    },
  },
  /* Inter Bold */
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-Bold.woff'), url('/fonts/Inter/Inter-Bold.woff2')",
    props: {
      style: 'normal',
      weight: '700',
      display: 'block',
    },
  },
  /* Inter Bold Italic*/
  {
    name: 'Inter',
    src: "url('/fonts/Inter/Inter-BoldItalic.woff'), url('/fonts/Inter/Inter-BoldItalic.woff2')",
    props: {
      style: 'italic',
      weight: '700',
      display: 'block',
    },
  },
  /* open-sans-regular - latin */
  {
    name: 'Open Sans',
    src: "url('/fonts/OpenSans-Regular.woff2') format('woff2'), url('/fonts/OpenSans-Regular.woff') format('woff')",
    props: {
      style: 'normal',
      weight: '400',
      display: 'block',
    },
  },
  /* open-sans-regular-italic - latin */
  {
    name: 'Open Sans',
    src: "url('/fonts/OpenSans-Italic.woff2') format('woff2'), url('/fonts/OpenSans-Italic.woff') format('woff')",
    props: {
      style: 'italic',
      weight: '400',
      display: 'block',
    },
  },
  /* open-sans-600 - latin */
  {
    name: 'Open Sans',
    src: "url('/fonts/OpenSans-SemiBold.woff2') format('woff2'), url('/fonts/OpenSans-SemiBold.woff') format('woff')",
    props: {
      style: 'normal',
      weight: '600',
      display: 'block',
    },
  },
  /* open-sans-700 - latin */
  {
    name: 'Open Sans',
    src: "url('/fonts/OpenSans-Bold.woff2') format('woff2'), url('/fonts/OpenSans-Bold.woff') format('woff')",
    props: {
      style: 'normal',
      weight: '700',
      display: 'block',
    },
  },
  /* Carlito-regular - latin */
  {
    name: 'Calibri',
    src: "url('/fonts/Carlito-Italic.woff2') format('woff2')",
    props: {
      style: 'italic',
      weight: '400',
      display: 'block',
    },
  },
  /* Carlito-regular-italic - latin */
  {
    name: 'Calibri',
    src: "url('/fonts/Carlito-Regular.woff2') format('woff2')",
    props: {
      style: 'normal',
      weight: '400',
      display: 'block',
    },
  },
  /* Carlito-600 - latin */
  {
    name: 'Calibri',
    src: "url('/fonts/Carlito-Bold.woff2') format('woff2')",
    props: {
      style: 'normal',
      weight: '600',
      display: 'block',
    },
  },
  /* Carlito-600-italic - latin */
  {
    name: 'Calibri',
    src: "url('/fonts/Carlito-BoldItalic.woff2') format('woff2')",
    props: {
      style: 'italic',
      weight: '600',
      display: 'block',
    },
  },
  /* Symbola */
  {
    name: 'Symbola',
    src: "url('/fonts/Symbola.ttf') format('truetype')",
    props: {
      display: 'block',
    },
  },
];

export class FontFamilyHelper {
  private _reduxTimeout: NodeJS.Timeout | null = null;

  private validatedFonts: string[] = [];

  private externalFonts: Common.FontFamily.FontSupported[] = [];
  private missingFonts: Common.FontFamily.FontSupported[] = [];
  private defaultFonts: Common.FontFamily.FontSupported[] = [];
  private fontFamilyList: Common.FontFamily.FontList[] = [];

  private fontFamilyListPromise: any;

  private canvas: HTMLCanvasElement;
  private product: Common.Product;
  private reduxInterface:
    | typeof PresentationReduxInterface
    | typeof EditorReduxInterface
    | undefined;

  // fonts supported by the platform
  static FONTS: { label: string; value: string }[] = [];

  static PROVIDED_FONTS = ['Inter', 'Open Sans', 'Calibri', 'Symbola'];

  constructor(platform: Platform, product: Common.Product) {
    this._validateFontFamily = this._validateFontFamily.bind(this);
    this.scheduleUpdateReduxData = this.scheduleUpdateReduxData.bind(this);
    this.isFontFamilySupportedByBrowser = this.isFontFamilySupportedByBrowser.bind(this);
    this.isFontFamilySupportedByPlatform = this.isFontFamilySupportedByPlatform.bind(this);
    this.validateFontFamily = this.validateFontFamily.bind(this);
    this.loadFonts = this.loadFonts.bind(this);

    this.product = product;
    this.canvas = document.createElement('canvas');

    this.fontFamilyListPromise = ExtensionHelper.getFontFamilyList(platform)
      .then((data) => {
        this.fontFamilyListPromise.promiseStatus = 'resolved';
        this.fontFamilyList = Array.from(data.fonts);
        this.validateDefaultFonts();
      })
      .catch(() => {
        this.fontFamilyListPromise.promiseStatus = 'rejected';
        this.validateDefaultFonts();
        // console.warn(e);
      });

    switch (product) {
      case 'editor': {
        FontFamilyHelper.FONTS = [
          { label: 'Arial', value: 'arial' },
          { label: 'Arial Black', value: 'arial black' },
          { label: 'Calibri', value: 'calibri' },
          { label: 'Comic Sans MS', value: 'comic sans ms' },
          { label: 'Courier New', value: 'courier new' },
          { label: 'Georgia', value: 'georgia' },
          { label: 'Impact', value: 'impact' },
          { label: 'Palatino', value: 'palatino' },
          { label: 'Tahoma', value: 'tahoma' },
          { label: 'Times New Roman', value: 'times new roman' },
          { label: 'Verdana', value: 'verdana' },
        ];
        this.reduxInterface = EditorReduxInterface;
        break;
      }
      case 'presentation': {
        /**
         * TODO:PRESENTATION:EDITION
         * Define here the fonts that should appear in the font family select
         */
        FontFamilyHelper.FONTS = [];
        this.reduxInterface = PresentationReduxInterface;
        break;
      }
    }
  }

  async start() {
    await this.loadFonts();
  }

  destroy() {}

  async loadFonts() {
    const fontAPI = document.fonts;

    if (fontAPI) {
      for (let i = 0; i < FONTS_TO_LOAD.length; i++) {
        const fontInfo = FONTS_TO_LOAD[i];

        if (
          !fontAPI.check(
            `${fontInfo.props.style || ''} ${fontInfo.props.weight || ''} 12pt ${fontInfo.name}`,
          )
        ) {
          try {
            const font = new FontFace(fontInfo.name, fontInfo.src, fontInfo.props);
            fontAPI.add(font);
            font.load();
          } catch (e) {
            logger.warn(e);
          }
        }
      }

      await fontAPI.ready;
    }
  }

  isFontFamilySupportedByBrowser(font: string) {
    if (font) {
      let i = 0;
      const length = this.fontFamilyList.length;
      const f: string = font.toLowerCase();

      const browserTestResult = _testFontFamilyOnBrowser(font);

      if (length) {
        let fontObject;

        for (i = 0; i < length; i++) {
          if (f.includes(this.fontFamilyList[i].fontId.toLowerCase())) {
            fontObject = this.fontFamilyList[i];
            break;
          }
        }
        if (fontObject && browserTestResult) {
          return !!fontObject;
        }
      }
      return browserTestResult;
    }
    return false;
  }

   
  isFontFamilySupportedByPlatform(font: string) {
    if (font) {
      const length = FontFamilyHelper.FONTS.length;
      const f = font.toLowerCase();

      if (
        FontFamilyHelper.PROVIDED_FONTS.includes(font) ||
        FontFamilyHelper.PROVIDED_FONTS.includes(f)
      ) {
        return true;
      }

      let i = 0;
      for (i = 0; i < length; i++) {
        if (f === FontFamilyHelper.FONTS[i].value) {
          return FontFamilyHelper.FONTS[i];
        }
      }
    }

    return false;
  }

  private scheduleUpdateReduxData() {
    if (this._reduxTimeout) {
      clearTimeout(this._reduxTimeout);
    }

    this._reduxTimeout = setTimeout(() => {
      // send data to redux
      this.reduxInterface?.setFontValidationData({
        external: JSON.parse(JSON.stringify(this.externalFonts)),
        missing: JSON.parse(JSON.stringify(this.missingFonts)),
        default: JSON.parse(JSON.stringify(this.defaultFonts)),
      });
    }, 50);
  }

  private _validateFontFamily(font: string) {
    if (font) {
      if (!this.validatedFonts.includes(font.toLowerCase())) {
        this.validatedFonts.push(font.toLowerCase());

        const isDefault = this.isFontFamilySupportedByPlatform(font);
        const isBrowserSuported = this.isFontFamilySupportedByBrowser(font);

        if (isDefault) {
          this.defaultFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        } else {
          this.externalFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        }

        if (!isBrowserSuported) {
          this.missingFonts.push({
            label: font,
            value: font.toLowerCase(),
            supported: isBrowserSuported,
          });
        }

        this.scheduleUpdateReduxData();
      }
    }
  }

  private validateDefaultFonts() {
    FontFamilyHelper.FONTS.forEach((fontObject) => {
      this.validateFontFamily(fontObject.label);
    });
  }

  public validateFontFamily(font: string) {
    if (font) {
      if (
        this.fontFamilyListPromise.promiseStatus !== 'resolved' &&
        this.fontFamilyListPromise.promiseStatus !== 'rejected'
      ) {
        this.fontFamilyListPromise
          .then(() => {
            this._validateFontFamily(font);
          })
          .catch(() => {
            this._validateFontFamily(font);
          });
      } else {
        this._validateFontFamily(font);
      }
    }
  }

  getTextMetrics(font: string, size: string, text: string = 'AbcdefghijklmnopqrstuvxyZ') {
    const ctx = this.canvas.getContext('2d');

    if (ctx) {
      ctx.font = `${size} ${font}`;
      const metrics = ctx.measureText(text);

      if (metrics) {
        if (metrics.fontBoundingBoxAscent == null) {
          //@ts-expect-error firefox shit
          metrics.fontBoundingBoxAscent = metrics.actualBoundingBoxAscent * 1.2;
        }

        if (metrics.fontBoundingBoxDescent == null) {
          //@ts-expect-error firefox shit
          metrics.fontBoundingBoxDescent = metrics.actualBoundingBoxDescent * 1.2;
        }
      }

      return metrics;
    }

    return null;
  }
}

import { useIntl } from 'react-intl';
import PrivacyPolicy from 'assets/pdf/doDOC_Privacy_Policy.pdf';
import SecurityOverview from 'assets/pdf/doDOC_Security_Overview.pdf';
import TermsOfService from 'assets/pdf/doDOC_Terms_of_Service.pdf';
import styles from './FooterLinks.module.scss';

const LINKS = [
  { key: 'securityDocs', url: SecurityOverview },
  { key: 'privacyPolicy', url: PrivacyPolicy },
  { key: 'termsOfService', url: TermsOfService },
];

const FooterLinks = ({ hero = false, white = false }) => {
  const intl = useIntl();
  return (
    <div
      className={`${styles.root} ${hero ? styles.hero : undefined} ${
        white ? styles.white : undefined
      }`}
    >
      {LINKS.map((link, index) => (
        <div key={link.key} className={styles.link}>
          <a
            href={link.url}
            target="_blank"
            rel="noreferrer noopener"
            data-testid={`${link.key}_login`}
          >
            {intl.formatMessage({ id: `footerLinks.${link.key}` })}
          </a>
          {index !== LINKS.length - 1 && <span style={{ margin: '1rem' }}>•</span>}
        </div>
      ))}
    </div>
  );
};
export default FooterLinks;

 
import { Mixin } from 'mixwith';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import KeydownEventValidator from 'Editor/services/EditionManager/KeydownEventValidator';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class DefaultHandler extends superclass {
      destroy() {
        super.destroy();
      }

      /**
       * selects a function to handle default key events based on baseNode
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnCollapsedSelection(actionContext, e, baseNode, anchorNode, anchorOffset) {
        if (!baseNode || EditorDOMElements.isNodeContainerElement(anchorNode)) {
          // if baseNode is undefined try to fix selection
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            baseNode = EditorDOMUtils.findFirstLevelChildNode(this.page, selection.anchorNode);
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }
        if (baseNode) {
          if (anchorNode) {
            if (EditorDOMUtils.isClosestTextElementEditable(baseNode)) {
              // SELECTION IS A DEFAULT TEXT ELEMENT
              this.handleDefaultOnTextElement(actionContext, e, baseNode, anchorNode, anchorOffset);
            } else if (!EditorDOMUtils.isClosestBlockNodeEditable(baseNode)) {
              // SELECTION IS A NON-EDITABLE ELEMENT
              this.handleNonEditableDefault(actionContext, e);
            } else if (baseNode.tagName === ELEMENTS.FigureElement.TAG) {
              // SELECTION IS A FIGURE
              this.handleDefaultOnFigureElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (baseNode.tagName === ELEMENTS.TableElement.TAG) {
              // SELECTION IS A TABLE
              this.handleDefaultOnTableElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            } else if (EditorDOMElements.isNodeContainerElement(baseNode)) {
              this.handleDefaultOnContainerElement(
                actionContext,
                e,
                baseNode,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * handle default key events on multi selection
       * @param {ActionContext} actionContext
       * @param {Event} e
       */
      handleDefaultOnMultiSelection(actionContext, e) {
        if (this.joinSelectionContent(actionContext)) {
          const selection = EditorSelectionUtils.getSelection();
          const anchorNode = selection.anchorNode;
          const anchorOffset = selection.anchorOffset;
          const level0Node = EditorDOMUtils.findFirstLevelChildNode(this.page, anchorNode);
          this.handleDefaultOnCollapsedSelection(
            actionContext,
            e,
            level0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle default key event on a container element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} baseNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnContainerElement(actionContext, e, baseNode, anchorNode, anchorOffset) {
        if (EditorDOMElements.BLOCK_CONTAINER_ELEMENTS.includes(anchorNode.tagName)) {
          if (EditorSelectionUtils.fixSelection()) {
            const selection = EditorSelectionUtils.getSelection();
            anchorNode = selection.anchorNode;
            anchorOffset = selection.anchorOffset;
          }
        }

        const subLevel0Node = EditorDOMUtils.findFirstLevelChildNode(baseNode, anchorNode);
        if (subLevel0Node) {
          this.handleDefaultOnCollapsedSelection(
            actionContext,
            e,
            subLevel0Node,
            anchorNode,
            anchorOffset,
          );
        }
      }

      /**
       * handle default key event on a default text element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} level0Node
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnTextElement(actionContext, e) {
        if (KeydownEventValidator.isKeyPrintable(e)) {
          // check non_editable_inline_elements

          EditorSelectionUtils.fixCollapsedTextSelection();

          // insert normal text element
          this.insertTextInline(actionContext, e.key);
        }
      }

      /**
       * handle default key event on a figure element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} figureNode
       * @param {Node} anchorNode
       */
      handleDefaultOnFigureElement() {
        // TODO:
        // const closest = DOMUtils.closest(anchorNode, ['FIGCAPTION']);
        // if (closest && !closest.hasAttribute('lock')) {
        //   this.handleDefaultOnTextElement(actionContext, e, figureNode, anchorNode, anchorOffset);
        // }
      }

      /**
       * handle default key event on table element
       * @param {ActionContext} actionContext
       * @param {Event} e
       * @param {Node} tableNode
       * @param {Node} anchorNode
       * @param {*} anchorOffset
       */
      handleDefaultOnTableElement(actionContext, e, tableNode, anchorNode, anchorOffset) {
        const closest = EditorDOMUtils.closest(anchorNode, [ELEMENTS.TableCellElement.TAG]);
        if (closest && !closest.hasAttribute('lock')) {
          if (closest.tagName === ELEMENTS.TableCellElement.TAG) {
            let tdLevel0Node = EditorDOMUtils.findFirstLevelChildNode(closest, anchorNode);

            if (!tdLevel0Node && closest.childNodes.length === 0) {
              tdLevel0Node = DOMElementFactory.createNewParagraphElement();
              EditorDOMUtils.appendNode(closest, tdLevel0Node);
              actionContext.addChangeUpdatedNode(tableNode);
              EditorSelectionUtils.setCaret(tdLevel0Node, 'INSIDE_START');
            }

            if (tdLevel0Node && tdLevel0Node.tagName !== ELEMENTS.TableElement.TAG) {
              this.handleDefaultOnCollapsedSelection(
                actionContext,
                e,
                tdLevel0Node,
                anchorNode,
                anchorOffset,
              );
            }
          }
        }
      }

      /**
       * handle default key events on a non-editable element
       * @param {ActionContext} actionContext
       * @param {Event} e
       */
      handleNonEditableDefault() {
        // e.stopPropagation();
        // e.preventDefault();
      }
    },
);

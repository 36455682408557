import { Descendant } from 'slate';

export const stringToRichText = (string?: string) => {
  try {
    return JSON.parse(string ?? '') as Descendant[];
  } catch (_) {
    return [
      {
        type: 'paragraph',
        children: [
          {
            text: string ?? '',
          },
        ],
      },
    ] as Descendant[];
  }
};

export const richTextToString = (richText?: Descendant[]) => {
  if (richText && Array.isArray(richText)) {
    if (richText.length === 0) {
      return '';
    }

    return JSON.stringify(richText);
  }

  return '';
};

export const newRTEObject = ({
  text,
  styles,
  mention,
}: {
  text: string;
  styles?: Partial<RichTextEditor.RichFormat>;
  mention?: { userId: UserId; placement: 'start' | 'end' };
}): Descendant[] => {
  const { align, ...inlineStyles } = styles ?? {};

  const children: Descendant[] = [
    {
      text: text ?? '',
      ...inlineStyles,
    },
  ];

  if (mention) {
    switch (mention.placement) {
      case 'start':
        children.unshift({ type: 'mention', userId: mention.userId, children: [{ text: ' ' }] });
        break;
      case 'end':
        children.push({ type: 'mention', userId: mention.userId, children: [{ text: ' ' }] });
        break;
    }
  }

  return [
    {
      type: 'paragraph',
      children,
      align,
    },
  ];
};

export const isContentEmpty = (content: PDF.Annotation.Base['content']): content is undefined => {
  if (content && content.content.length > 0) {
    //@ts-expect-error Descendant type issue
    if (content.content[0]?.children?.[0].text === '' && content.content[0].children.length <= 1) {
      return true;
    }
    return false;
  }

  return true;
};

export const containsMention = (content: Descendant[]): boolean =>
  content.findIndex((descendant) => {
    const typedDescendant = descendant as RichTextEditor.CustomElement;
    switch (typedDescendant.type) {
      case 'mention':
        return true;
      case 'paragraph':
        return containsMention(typedDescendant.children);
      default:
        return false;
    }
  }) >= 0;

import { ELEMENTS } from 'Editor/services/consts';
import { TableOfContentsElementLegacy } from '..';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { TableOfContentsElement } from './TableOfContentsElement/TableOfContentsElement';

export class TableOfContentsViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.TableOfContentsElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
    rl: {
      parse(node, builder) {
        if (node.dataset.rl != null) {
          builder.addProperty('rl', JSON.parse(node.dataset.rl));
        }
      },
      render(json, node) {
        if (json.properties?.rl != null) {
          node.dataset.rl = JSON.stringify(json.properties.rl);
        }
      },
      remove(node) {
        delete node.dataset.tabs;
      },
    },
    tabs: {
      parse(node, builder) {
        if (node.dataset.tabs != null) {
          builder.addProperty('tabs', JSON.parse(node.dataset.tabs));
        }
      },
      render(json, node) {
        if (json.properties?.tabs != null) {
          node.dataset.tabs = JSON.stringify(json.properties.tabs);
        }
      },
      remove(node) {
        delete node.dataset.tabs;
      },
    },
    spn: {
      parse: (node, builder) => {
        if (node.hasAttribute('spn')) {
          builder.addProperty('spn', node.getAttribute('spn'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.spn != null) {
          node.setAttribute('spn', json.properties.spn);
        }
      },
      remove: (node: HTMLElement, attributeName?: string | null) => {
        node.removeAttribute('spn');
      },
    },
    anr: {
      parse: (node, builder) => {
        if (node.hasAttribute('anr')) {
          builder.addProperty('anr', node.getAttribute('anr'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.anr != null) {
          node.setAttribute('anr', json.properties.anr);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('anr');
      },
    },
    l: {
      parse: (node, builder) => {
        if (node.hasAttribute('l')) {
          builder.addProperty('l', node.getAttribute('l'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.l != null) {
          node.setAttribute('l', json.properties.l);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('l');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    // const node = DOMElementFactory.buildElement(ELEMENTS.TableOfContentsElement.TAG);
    let node: Editor.Elements.BaseBlockElement;
    let childNodes = json.childNodes || [];
    let properties = Object.keys(json.properties || {});
    if (childNodes.length === 0 && properties.length === 0) {
      node = new TableOfContentsElementLegacy(this.Visualizer, this.Data);
    } else {
      node = new TableOfContentsElement(this.Visualizer, this.Data);
    }

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data, prop);
    });

    return node;
  }
}

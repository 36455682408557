import { ELEMENTS } from 'Editor/services/consts';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BaseDecorator } from '../BaseDecorator';
import { PermissionViewElement } from './PermissionViewElement/PermissionViewElement';

export class PermissionModeDecorator extends BaseDecorator {
  decorator(data: Editor.Data.Node.Data, node: HTMLElement) {
    if (data.id) {
      if (this.isNodeApproved(data)) {
        return this.approveDecorator(data, node);
      }

      const decoration = DOMElementFactory.buildElement(
        ELEMENTS.PermissionViewElement.TAG,
      ) as PermissionViewElement;
      decoration.setAttribute('contentEditable', 'false');
      decoration.setAttribute('enclosed_element', data.id);
      decoration.setContent(node);
      if (this.isSelectedForPermissions(data.id)) {
        decoration.setAttribute('selected', 'true');
      }
      this.usersWithPermissions(data.id).then((users) => {
        decoration.setUsers(users);
      });

      return decoration;
    } else {
      return node;
    }
  }
}

import { BaseViewBuilder } from 'Editor/services/VisualizerManager/Views/ViewBuilders';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { RealtimeOpsBuilder } from '_common/services/Realtime';
import { NodeUtils } from 'Editor/services/DataManager/models';
import { BaseOperation } from '../BaseOperation';
import { TableUtils } from '../../Utils/TableUtils';

const MIN_DOUBLE_BORDER = 2.75;

export class TableStylesOperations extends BaseOperation<Editor.Data.Node.Model> {
  tableId: string;
  propertiesData: Editor.Styles.TablePropertiesData;
  selectedCellsIds: string[];
  rowsIndex: number[];
  columnsIndex: number[];

  constructor(
    baseModel: Editor.Data.Node.Model,
    tableId: string,
    propertiesData: Editor.Styles.TablePropertiesData,
    selectedCellsIds: string[],
    rowsIndex: number[],
    columnsIndex: number[],
  ) {
    super(baseModel);

    this.tableId = tableId;
    this.propertiesData = propertiesData;
    this.selectedCellsIds = selectedCellsIds;
    this.rowsIndex = rowsIndex;
    this.columnsIndex = columnsIndex;
    this.build();
  }

  private getOpsUpdateTableWidth(width: Editor.Data.Node.TableWidth) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'w'];

    let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;
    switch (width.t) {
      case 'abs':
      case 'pct': {
        newWidth = {
          t: width.t,
          v: width.v,
        };
        break;
      }
      case 'nil':
      case 'auto': {
        newWidth = {
          t: 'auto',
          v: 0,
        };
        break;
      }
    }

    const op = this.getObjectOperationforPathValue(tableData.properties?.w, newWidth, propPath);
    if (op) {
      this.ops.push(op);
    }
  }

  private getOpsUpdateCellsWidth(cellIds: string[], width: Editor.Data.Node.TableWidth) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    let newWidth: Editor.Data.Node.TableWidth | undefined = undefined;

    switch (width.t) {
      case 'abs':
      case 'pct': {
        newWidth = {
          t: width.t,
          v: width.v,
        };
        break;
      }
      case 'nil':
      case 'auto': {
        newWidth = {
          t: 'auto',
          v: 0,
        };
        break;
      }
    }

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'w'];

              const op = this.getObjectOperationforPathValue(
                cell.properties?.w,
                newWidth,
                propPath,
              );
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTableHeight(height: Editor.Data.Node.RowHeight) {
    if (height != null) {
      const tableData = this.model.getChildDataById(this.tableId);
      const tablePath = this.model.findPathToChild(this.tableId);

      const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
      const rowsData = tableData?.childNodes?.[0].childNodes;

      if (rowsData) {
        let oldHeight: number = 0;

        for (let r = 0; r < rowsData.length; r++) {
          oldHeight += +(rowsData[r].properties?.rh.v || TableUtils.DEFAULT_ROW_HEIGHT);
        }

        const diffRatio = height.v / oldHeight;

        for (let r = 0; r < rowsData.length; r++) {
          const propPath = [...rowsPath, r, this.model.KEYS.PROPERTIES, 'a'];

          const newHeight = +(
            +(rowsData[r].properties?.rh.v || TableUtils.DEFAULT_ROW_HEIGHT) * diffRatio
          ).toFixed(2);

          const op = this.getObjectOperationforPathValue(
            rowsData[r].properties?.rh,
            newHeight,
            propPath,
          );
          if (op) {
            this.ops.push(op);
          }
        }
      }
    }
  }

  private getOpsUpdateRowsHeight(rowIds: string[], height: Editor.Data.Node.RowHeight) {
    for (let i = 0; i < rowIds.length; i++) {
      const rowData = this.model.getChildDataById(rowIds[i]);
      const rowPath = this.model.findPathToChild(rowIds[i]);

      const propPath = [...rowPath, this.model.KEYS.PROPERTIES, 'rh'];

      let newRowHeight: Editor.Data.Node.RowHeight | undefined = undefined;

      switch (height.t) {
        case 'abs': {
          newRowHeight = {
            t: height.t,
            v: height.v,
          };
          break;
        }
        case 'nil':
        case 'auto': {
          newRowHeight = {
            t: 'auto',
            v: 0,
          };
          break;
        }
      }

      const op = this.getObjectOperationforPathValue(
        rowData.properties?.rh,
        newRowHeight,
        propPath,
      );
      if (op) {
        this.ops.push(op);
      }
    }
  }

  private getOpsUpdateTableIndentation(indentation: number | null) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const indPath = [...tablePath, this.model.KEYS.PROPERTIES, 'ind'];
    const leftIndPath = [...tablePath, this.model.KEYS.PROPERTIES, 'ind', 'l'];

    if (indentation != null) {
      if (tableData.properties?.ind !== undefined) {
        if (tableData.properties.ind.l !== undefined) {
          this.ops.push(
            RealtimeOpsBuilder.objectReplace(tableData.properties.ind.l, indentation, leftIndPath),
          );
        } else {
          this.ops.push(RealtimeOpsBuilder.objectInsert(indentation, leftIndPath));
        }
      } else {
        this.ops.push(RealtimeOpsBuilder.objectInsert({ l: indentation }, indPath));
      }
    } else if (tableData.properties?.ind?.l !== undefined) {
      this.ops.push(RealtimeOpsBuilder.objectDelete(tableData.properties.ind.l, leftIndPath));
    }
  }

  private getOpsUpdateTableAlignment(alignment: Editor.Data.Node.Alignment | null) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'a'];

    const op = this.getObjectOperationforPathValue(tableData.properties?.a, alignment, propPath);
    if (op) {
      this.ops.push(op);
    }
  }

  private getOpsUpdateTableTextAlignment(textAlignment: Editor.Data.Node.Alignment | null) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'ca'];

    const op = this.getObjectOperationforPathValue(
      tableData.properties?.ca,
      textAlignment,
      propPath,
    );
    if (op) {
      this.ops.push(op);
    }

    // remove all cell borders
    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell && cell.properties?.a !== undefined) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'a'];

              const op = this.getObjectOperationforPathValue(cell.properties?.a, null, propPath);
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTextAlignment(
    cellIds: string[],
    alignment: Editor.Data.Node.Alignment | null,
  ) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell.id && cellIds.includes(cell.id)) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'a'];

              const op = this.getObjectOperationforPathValue(
                cell.properties?.a,
                alignment,
                propPath,
              );
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTableCellVerticalAlignment(
    verticalAlignment: Editor.Data.Node.VerticalAlignment | null,
  ) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'cva'];

    const op = this.getObjectOperationforPathValue(
      tableData?.properties?.cva,
      verticalAlignment,
      propPath,
    );
    if (op) {
      this.ops.push(op);
    }

    // remove all cell borders
    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell && cell.properties?.va !== undefined) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'va'];

              const op = this.getObjectOperationforPathValue(cell.properties?.va, null, propPath);
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateCellVerticalAlignment(
    verticalAlignment: Editor.Data.Node.VerticalAlignment | null,
    cellIds: string[],
  ) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'va'];

              const op = this.getObjectOperationforPathValue(
                cell.properties?.va,
                verticalAlignment,
                propPath,
              );
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTableCellBackground(background: string | null) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'bg'];

    const op = this.getObjectOperationforPathValue(tableData?.properties?.bg, background, propPath);
    if (op) {
      this.ops.push(op);
    }

    // remove all cells background
    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell && cell.properties?.bg !== undefined) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'bg'];

              const op = this.getObjectOperationforPathValue(cell.properties?.bg, null, propPath);
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateCellBackground(cellIds: string[], background: string | null) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell.childNodes && cell.id && cellIds.includes(cell.id)) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'bg'];

              const op = this.getObjectOperationforPathValue(
                cell.properties?.bg,
                background,
                propPath,
              );
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTableCellPading(padding: Editor.Styles.PaddingProp) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    let propPath = [...tablePath, this.model.KEYS.PROPERTIES, 'cp'];
    let oldValue = undefined;

    let newValue: Editor.Data.Node.Padding = {};
    if (tableData.properties?.cp) {
      newValue = { ...tableData.properties?.cp };
    }

    if (padding.top != null) {
      newValue.t = padding.top.value;
    }

    if (padding.bottom != null) {
      newValue.b = padding.bottom.value;
    }

    if (padding.left != null) {
      newValue.l = padding.left.value;
    }

    if (padding.right != null) {
      newValue.r = padding.right.value;
    }

    const op = this.getObjectOperationforPathValue(oldValue, newValue, propPath);
    if (op) {
      this.ops.push(op);
    }

    // remove all cell borders
    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell && cell.properties?.p !== undefined) {
              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'p'];

              const op = this.getObjectOperationforPathValue(cell.properties?.p, null, propPath);
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateCellPading(cellIds: string[], padding: Editor.Styles.PaddingProp) {
    const tableData = this.model.getChildDataById(this.tableId);
    const tablePath = this.model.findPathToChild(this.tableId);

    const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (cell.id && cellIds.includes(cell.id)) {
              let propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'p'];
              let oldValue = undefined;

              let newValue: Editor.Data.Node.Padding = {};
              if (cell.properties?.p) {
                newValue = { ...cell.properties?.p };
              }

              if (padding.top != null) {
                newValue.t = padding.top.value;
              }

              if (padding.bottom != null) {
                newValue.b = padding.bottom.value;
              }

              if (padding.left != null) {
                newValue.l = padding.left.value;
              }

              if (padding.right != null) {
                newValue.r = padding.right.value;
              }

              const op = this.getObjectOperationforPathValue(oldValue, newValue, propPath);
              if (op) {
                this.ops.push(op);
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateTableCellBorder(border: Editor.Styles.BorderProp) {
    if (border.color !== undefined || border.style !== undefined || border.width !== undefined) {
      const tableData = this.model.getChildDataById(this.tableId);
      const tablePath = this.model.findPathToChild(this.tableId);

      // update table default borders
      const borderPropPath = [...tablePath, this.model.KEYS.PROPERTIES, 'cb'];

      let newBorderValue: Editor.Data.Node.Borders | null = null;
      if (tableData.properties?.cb) {
        newBorderValue = JSON.parse(JSON.stringify(tableData.properties.cb));
      }

      if (!newBorderValue) {
        newBorderValue = {};
      }

      const chosen: Editor.Styles.BorderChosen =
        border.chosen?.value === 'none' ? border.chosen?.value : 'all';

      this.updateBorderObject(newBorderValue, border, chosen);

      const op = this.getObjectOperationforPathValue(
        tableData.properties?.cb,
        newBorderValue,
        borderPropPath,
      );
      if (op) {
        this.ops.push(op);
      }

      // remove all cell borders
      const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
      const rowsData = tableData?.childNodes?.[0].childNodes;

      if (rowsData) {
        for (let r = 0; r < rowsData.length; r++) {
          const row = rowsData[r];

          if (row.childNodes) {
            for (let c = 0; c < row.childNodes.length; c++) {
              const cell = row.childNodes[c];

              if (cell && cell.properties?.b !== undefined) {
                const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'b'];

                const op = this.getObjectOperationforPathValue(cell.properties?.b, null, propPath);
                if (op) {
                  this.ops.push(op);
                }
              }
            }
          }
        }
      }
    }
  }

  private updateBorderObject(
    newBorder: Editor.Data.Node.Borders,
    borderProp: Editor.Styles.BorderProp,
    borderChosen: Extract<
      Editor.Styles.BorderChosen,
      'all' | 'bottom' | 'top' | 'left' | 'right' | 'none'
    >,
  ) {
    let width =
      borderProp.width?.value !== undefined
        ? EditorDOMUtils.convertUnitTo(borderProp.width.value, undefined, 'pt', 3)
        : undefined;

    switch (borderChosen) {
      case 'all': {
        newBorder.t = {};
        newBorder.b = {};
        newBorder.l = {};
        newBorder.r = {};

        const color =
          borderProp.color !== undefined
            ? BaseViewBuilder.colorMapper.parse(borderProp.color.value)
            : null;

        const style =
          borderProp.style !== undefined
            ? BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value)
            : null;

        if (style === 'd' && width != null && width !== 0 && width < MIN_DOUBLE_BORDER) {
          width = MIN_DOUBLE_BORDER;
        }

        const widthValue = width !== undefined && !isNaN(width) ? width : null;

        if (color) {
          newBorder.t.c = color;
          newBorder.b.c = color;
          newBorder.l.c = color;
          newBorder.r.c = color;
        }

        if (style) {
          newBorder.t.s = style;
          newBorder.b.s = style;
          newBorder.l.s = style;
          newBorder.r.s = style;
        }

        if (widthValue) {
          newBorder.t.w = widthValue;
          newBorder.b.w = widthValue;
          newBorder.l.w = widthValue;
          newBorder.r.w = widthValue;
        }

        return newBorder;
      }
      case 'bottom': {
        newBorder.b = {};

        const color =
          borderProp.color !== undefined
            ? BaseViewBuilder.colorMapper.parse(borderProp.color.value)
            : null;

        const style =
          borderProp.style !== undefined
            ? BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value)
            : null;

        const widthValue = width !== undefined && !isNaN(width) ? width : null;

        if (color) {
          newBorder.b.c = color;
        }

        if (style) {
          newBorder.b.s = style;
        }

        if (widthValue) {
          newBorder.b.w = widthValue;
        }

        return newBorder;
      }
      case 'left': {
        newBorder.l = {};

        const color =
          borderProp.color !== undefined
            ? BaseViewBuilder.colorMapper.parse(borderProp.color.value)
            : null;

        const style =
          borderProp.style !== undefined
            ? BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value)
            : null;

        const widthValue = width !== undefined && !isNaN(width) ? width : null;

        if (color) {
          newBorder.l.c = color;
        }

        if (style) {
          newBorder.l.s = style;
        }

        if (widthValue) {
          newBorder.l.w = widthValue;
        }

        return newBorder;
      }
      case 'right': {
        newBorder.r = {};

        const color =
          borderProp.color !== undefined
            ? BaseViewBuilder.colorMapper.parse(borderProp.color.value)
            : null;

        const style =
          borderProp.style !== undefined
            ? BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value)
            : null;

        const widthValue = width !== undefined && !isNaN(width) ? width : null;

        if (color) {
          newBorder.r.c = color;
        }

        if (style) {
          newBorder.r.s = style;
        }

        if (widthValue) {
          newBorder.r.w = widthValue;
        }

        return newBorder;
      }
      case 'top': {
        newBorder.t = {};

        const color =
          borderProp.color !== undefined
            ? BaseViewBuilder.colorMapper.parse(borderProp.color.value)
            : null;

        const style =
          borderProp.style !== undefined
            ? BaseViewBuilder.borderStyleMapper.parse(borderProp.style.value)
            : null;

        const widthValue = width !== undefined && !isNaN(width) ? width : null;

        if (color) {
          newBorder.t.c = color;
        }

        if (style) {
          newBorder.t.s = style;
        }

        if (widthValue) {
          newBorder.t.w = widthValue;
        }

        return newBorder;
      }
      case 'none': {
        newBorder.t = {};
        newBorder.b = {};
        newBorder.l = {};
        newBorder.r = {};

        const style = BaseViewBuilder.borderStyleMapper.parse('nil');

        newBorder.t.s = style;
        newBorder.b.s = style;
        newBorder.l.s = style;
        newBorder.r.s = style;

        //width
        newBorder.t.w = 0;
        newBorder.b.w = 0;
        newBorder.l.w = 0;
        newBorder.r.w = 0;

        return newBorder;
      }
    }
  }

  private getOpsUpdateCellBorder(
    cellIds: string[],
    rowsIndex: number[],
    columnsIndex: number[],
    border: Editor.Styles.BorderProp,
    fullTable: boolean = false,
  ) {
    if (border.color !== undefined || border.style !== undefined || border.width !== undefined) {
      const tableData = this.model.getChildDataById(this.tableId);
      const tablePath = this.model.findPathToChild(this.tableId);

      const rowsPath = [...tablePath, 'childNodes', 0, 'childNodes'];
      const rowsData = tableData?.childNodes?.[0].childNodes;

      const borderChosen = border.chosen?.value || 'all';

      if (borderChosen === 'none') {
        if (border.width && border.width.value !== 0) {
          border.width.value = 0;
        }

        if (border.style && border.style.value !== 'nil') {
          border.style.value = 'nil';
        }
      }

      if (rowsData) {
        for (let r = 0; r < rowsData.length; r++) {
          const row = rowsData[r];
          if (row.childNodes) {
            const cellsData = row.childNodes;
            for (let c = 0; c < cellsData.length; c++) {
              const cell = cellsData[c];

              const propPath = [...rowsPath, r, 'childNodes', c, this.model.KEYS.PROPERTIES, 'b'];
              let newBorderValue: Editor.Data.Node.Borders | null = null;
              if (
                cell.properties?.b?.t ||
                cell.properties?.b?.b ||
                cell.properties?.b?.l ||
                cell.properties?.b?.r
              ) {
                newBorderValue = JSON.parse(JSON.stringify(cell.properties.b));
              }

              if (!newBorderValue) {
                newBorderValue = {};
              }

              if (cell && cell.id && (cellIds.includes(cell.id) || fullTable)) {
                // cells within selection
                switch (borderChosen) {
                  case 'all':
                    this.updateBorderObject(newBorderValue, border, 'all');
                    break;
                  case 'none':
                    this.updateBorderObject(newBorderValue, border, 'none');
                    break;
                  case 'top':
                    if (r === rowsIndex[0] || (fullTable && r === 0)) {
                      this.updateBorderObject(newBorderValue, border, 'top');
                    }
                    break;
                  case 'bottom':
                    if (
                      r === rowsIndex[rowsIndex.length - 1] ||
                      (fullTable && r === rowsData.length - 1)
                    ) {
                      this.updateBorderObject(newBorderValue, border, 'bottom');
                    }
                    break;

                  case 'left':
                    if (c === columnsIndex[0] || (fullTable && c === 0)) {
                      this.updateBorderObject(newBorderValue, border, 'left');
                    }
                    break;
                  case 'right':
                    if (
                      c === columnsIndex[columnsIndex.length - 1] ||
                      (fullTable && c === cellsData.length - 1)
                    ) {
                      this.updateBorderObject(newBorderValue, border, 'right');
                    }
                    break;

                  case 'outside':
                    if (r === rowsIndex[0] || (fullTable && r === 0)) {
                      this.updateBorderObject(newBorderValue, border, 'top');
                    }
                    if (
                      r === rowsIndex[rowsIndex.length - 1] ||
                      (fullTable && r === rowsData.length - 1)
                    ) {
                      this.updateBorderObject(newBorderValue, border, 'bottom');
                    }
                    if (c === columnsIndex[0] || (fullTable && c === 0)) {
                      this.updateBorderObject(newBorderValue, border, 'left');
                    }
                    if (
                      c === columnsIndex[columnsIndex.length - 1] ||
                      (fullTable && c === cellsData.length - 1)
                    ) {
                      this.updateBorderObject(newBorderValue, border, 'right');
                    }
                    break;
                  case 'inside':
                    if (fullTable) {
                      if (r !== 0) {
                        this.updateBorderObject(newBorderValue, border, 'top');
                      }
                      if (r !== rowsData.length - 1) {
                        this.updateBorderObject(newBorderValue, border, 'bottom');
                      }
                    } else if (rowsIndex.length > 1) {
                      if (r !== rowsIndex[0]) {
                        this.updateBorderObject(newBorderValue, border, 'top');
                      }
                      if (r !== rowsIndex[rowsIndex.length - 1]) {
                        this.updateBorderObject(newBorderValue, border, 'bottom');
                      }
                    }

                    if (fullTable) {
                      if (c !== 0) {
                        this.updateBorderObject(newBorderValue, border, 'left');
                      }
                      if (c !== cellsData.length - 1) {
                        this.updateBorderObject(newBorderValue, border, 'right');
                      }
                    } else if (columnsIndex.length > 1) {
                      if (c !== columnsIndex[0]) {
                        this.updateBorderObject(newBorderValue, border, 'left');
                      }
                      if (c !== columnsIndex[columnsIndex.length - 1]) {
                        this.updateBorderObject(newBorderValue, border, 'right');
                      }
                    }
                    break;
                }

                const op = this.getObjectOperationforPathValue(
                  cell.properties?.b,
                  newBorderValue,
                  propPath,
                );
                if (op) {
                  this.ops.push(op);
                }
              } else if (
                r === rowsIndex[0] - 1 &&
                columnsIndex.includes(c) &&
                (borderChosen === 'all' ||
                  borderChosen === 'top' ||
                  borderChosen === 'outside' ||
                  borderChosen === 'none')
              ) {
                // ROW ABOVE
                this.updateBorderObject(newBorderValue, border, 'bottom');
                const op = this.getObjectOperationforPathValue(
                  cell.properties?.b,
                  newBorderValue,
                  propPath,
                );
                if (op) {
                  this.ops.push(op);
                }
              } else if (
                r === rowsIndex[rowsIndex.length - 1] + 1 &&
                columnsIndex.includes(c) &&
                (borderChosen === 'all' ||
                  borderChosen === 'bottom' ||
                  borderChosen === 'outside' ||
                  borderChosen === 'none')
              ) {
                // ROW BELOW
                this.updateBorderObject(newBorderValue, border, 'top');
                const op = this.getObjectOperationforPathValue(
                  cell.properties?.b,
                  newBorderValue,
                  propPath,
                );
                if (op) {
                  this.ops.push(op);
                }
              } else if (
                c === columnsIndex[0] - 1 &&
                rowsIndex.includes(r) &&
                (borderChosen === 'all' ||
                  borderChosen === 'left' ||
                  borderChosen === 'outside' ||
                  borderChosen === 'none')
              ) {
                // COLUMN BEFORE
                this.updateBorderObject(newBorderValue, border, 'right');
                const op = this.getObjectOperationforPathValue(
                  cell.properties?.b,
                  newBorderValue,
                  propPath,
                );
                if (op) {
                  this.ops.push(op);
                }
              } else if (
                c === columnsIndex[columnsIndex.length - 1] + 1 &&
                rowsIndex.includes(r) &&
                (borderChosen === 'all' ||
                  borderChosen === 'right' ||
                  borderChosen === 'outside' ||
                  borderChosen === 'none')
              ) {
                // COLUMN AFTER
                this.updateBorderObject(newBorderValue, border, 'left');
                const op = this.getObjectOperationforPathValue(
                  cell.properties?.b,
                  newBorderValue,
                  propPath,
                );
                if (op) {
                  this.ops.push(op);
                }
              }
            }
          }
        }
      }
    }
  }

  private getOpsUpdateHeaderRows(rowIds: string[], headerRow: boolean | null) {
    for (let i = 0; i < rowIds.length; i++) {
      const rowData = this.model.getChildDataById(rowIds[i]);
      const rowPath = this.model.findPathToChild(rowIds[i]);

      const propPath = [...rowPath, this.model.KEYS.PROPERTIES, 'hr'];

      const op = this.getObjectOperationforPathValue(rowData.properties?.hr, headerRow, propPath);
      if (op) {
        this.ops.push(op);
      }
    }
  }

  private getOpsUpdateTableAutoResize(autoResize: boolean) {
    const { data, path } = this.model.getChildInfoById(this.tableId);

    const propPath = [...path, this.model.KEYS.PROPERTIES, 'ar'];

    if (NodeUtils.isTableData(data)) {
      const op = this.getObjectOperationforPathValue(data.properties?.ar, autoResize, propPath);
      if (op) {
        this.ops.push(op);
      }
    }
  }

  private getCellIdsPerRowColumn(rowsIndex: number[], columnsIndex: number[]) {
    const cellsPerColumn: string[] = [];
    const cellsPerRow: string[] = [];
    const rowIds: string[] = [];
    const allRowIndex: number[] = [];
    const allColumnIndex: number[] = [];

    const tableData = this.model.getChildDataById(this.tableId);

    const rowsData = tableData?.childNodes?.[0].childNodes;

    if (rowsData) {
      for (let r = 0; r < rowsData.length; r++) {
        const row = rowsData[r];

        if (!allRowIndex.includes(r)) {
          allRowIndex.push(r);
        }

        if (row.id && row.childNodes) {
          for (let c = 0; c < row.childNodes.length; c++) {
            const cell = row.childNodes[c];

            if (!allColumnIndex.includes(c)) {
              allColumnIndex.push(c);
            }

            if (cell.id) {
              if (rowsIndex.includes(r)) {
                cellsPerRow.push(cell.id);
                if (!rowIds.includes(row.id)) {
                  rowIds.push(row.id);
                }
              }

              if (columnsIndex.includes(c)) {
                cellsPerColumn.push(cell.id);
              }
            }
          }
        }
      }
    }

    return { cellsPerRow, cellsPerColumn, rowIds, allRowIndex, allColumnIndex };
  }

  protected build(): Editor.Edition.IOperationBuilder {
    const { cellsPerRow, cellsPerColumn, rowIds, allRowIndex, allColumnIndex } =
      this.getCellIdsPerRowColumn(this.rowsIndex, this.columnsIndex);

    const elementKeys = Object.keys(this.propertiesData);
    for (let e = 0; e < elementKeys.length; e++) {
      const elementType = elementKeys[e] as Editor.Styles.TablePropertiesElementKey;

      if (elementType !== 'PAGE') {
        const elementData = this.propertiesData[elementType];
        if (elementData) {
          const propKeys = Object.keys(elementData);
          for (let k = 0; k < propKeys.length; k++) {
            const property = propKeys[k] as keyof Editor.Styles.TableProperties;

            switch (property) {
              case 'width': {
                const value = elementData[property]?.value;
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableWidth(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateTableWidth(value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateCellsWidth(cellsPerColumn, value);
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateCellsWidth(cellsPerColumn, value);
                  }
                }
                break;
              }
              case 'height': {
                const value = elementData[property]?.value;
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableHeight(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateRowsHeight(rowIds, value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateTableHeight(value);
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateRowsHeight(rowIds, value);
                  }
                }
                break;
              }
              case 'leftIndentation': {
                const value = elementData[property];
                if (value !== undefined) {
                  this.getOpsUpdateTableIndentation(value);
                }
                break;
              }
              case 'alignment': {
                const value = elementData[property];
                if (elementType === 'TABLE' && value !== undefined) {
                  this.getOpsUpdateTableAlignment(BaseViewBuilder.alignmentMapper.parse(value));
                }
                break;
              }
              case 'textAlignment': {
                const value = elementData[property];
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableTextAlignment(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateTextAlignment(cellsPerRow, value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateTextAlignment(cellsPerColumn, value);
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateTextAlignment(this.selectedCellsIds, value);
                  }
                }
                break;
              }
              case 'verticalAlignment': {
                const value = elementData[property];
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableCellVerticalAlignment(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateCellVerticalAlignment(
                      value,

                      cellsPerRow,
                    );
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateCellVerticalAlignment(
                      value,

                      cellsPerColumn,
                    );
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateCellVerticalAlignment(
                      value,

                      this.selectedCellsIds,
                    );
                  }
                }
                break;
              }
              case 'background': {
                const value = elementData[property];
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableCellBackground(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateCellBackground(cellsPerRow, value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateCellBackground(cellsPerColumn, value);
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateCellBackground(this.selectedCellsIds, value);
                  }
                }
                break;
              }
              case 'padding': {
                const value = elementData[property];
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    this.getOpsUpdateTableCellPading(value);
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateCellPading(cellsPerRow, value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateCellPading(cellsPerColumn, value);
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateCellPading(this.selectedCellsIds, value);
                  }
                }
                break;
              }
              case 'border': {
                const value = elementData[property];
                if (value !== undefined) {
                  if (elementType === 'TABLE') {
                    if (
                      value.chosen?.value != null &&
                      value.chosen?.value !== 'all' &&
                      value.chosen?.value !== 'none'
                    ) {
                      this.getOpsUpdateCellBorder([], [], [], value, true);
                    } else {
                      this.getOpsUpdateTableCellBorder(value);
                    }
                  } else if (elementType === 'ROW') {
                    this.getOpsUpdateCellBorder(cellsPerRow, this.rowsIndex, allColumnIndex, value);
                  } else if (elementType === 'COLUMN') {
                    this.getOpsUpdateCellBorder(
                      cellsPerColumn,
                      allRowIndex,
                      this.columnsIndex,
                      value,
                    );
                  } else if (elementType === 'CELL') {
                    this.getOpsUpdateCellBorder(
                      this.selectedCellsIds,
                      this.rowsIndex,
                      this.columnsIndex,
                      value,
                    );
                  }
                }
                break;
              }
              case 'headerRow': {
                const value = elementData[property]?.value;
                if (elementType === 'ROW' && value !== undefined) {
                  this.getOpsUpdateHeaderRows(rowIds, value);
                }
                break;
              }
              case 'autoResize': {
                const value = elementData[property];
                if (elementType === 'TABLE' && value !== undefined) {
                  this.getOpsUpdateTableAutoResize(value);
                }
              }
            }
          }
        }
      }
    }

    return this;
  }
}

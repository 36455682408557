import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '../BaseViewBuilder';
// import { BaseViewBuilder, BaseViewElement } from '..';
// import { BaseViewBuilder } from 'Editor/services/VisualizerManager/Views/ViewBuilders/BaseViewBuilder/';
// import { BaseViewElement } from 'Editor/services/VisualizerManager/Views/ViewBuilders/BaseViewBuilder/BaseElements/BaseViewElement';

export class AuthorsViewBuilder extends BaseViewBuilder implements Editor.Visualizer.IViewbuilder {
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.AuthorsElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // vanish
    v: this.GENERIC_ATTRIBUTE_MAPPER.v,
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.ParagraphData, model?: Editor.Data.Node.Model) {
    const node = DOMElementFactory.buildElement(ELEMENTS.AuthorsElement.TAG);

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}

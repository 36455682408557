export class Hook<
  T extends Editor.Visualizer.AvailableHooks,
  V extends (...args: any) => any = Editor.Visualizer.HookTypes[T],
> {
  protected hooks: V[];
  constructor() {
    this.hooks = [];
  }

  /**
   * Adds a function to be run before a hook completes
   * @example this.content.register(function(){...});
   * @return {undefined} void
   */
  register(...fncs: V[] | V[][]) {
    // eslint-disable-next-line no-var
    for (var i = 0; i < fncs.length; ++i) {
      const arg = fncs[i];
      if (typeof arg === 'function') {
        this.hooks.push(arg);
      } else {
        // unpack array
        // eslint-disable-next-line no-var
        for (var j = 0; j < arg.length; ++j) {
          this.hooks.push(arg[j]);
        }
      }
    }
  }

  /**
   * removes a function from hook list
   * @param fncs
   */
  unregister(...fncs: V[] | V[][]) {
    // eslint-disable-next-line no-var
    for (var i = 0; i < fncs.length; ++i) {
      const arg = fncs[i];
      if (typeof arg === 'function') {
        const index = this.hooks.indexOf(arg);
        if (index >= 0) {
          this.hooks.splice(index, 1);
        }
      } else {
        // unpack array
        // eslint-disable-next-line no-var
        for (var j = 0; j < arg.length; ++j) {
          const index = this.hooks.indexOf(arg[j]);
          if (index >= 0) {
            this.hooks.splice(index, 1);
          }
        }
      }
    }
  }

  /**
   * Triggers a hook to run all functions
   * @example this.content.trigger(args).then(function(){...});
   * @return {Promise} results
   */
  trigger(...args: Parameters<V>): Promise<ReturnType<V>[]> {
    let promises: Promise<ReturnType<V>>[] = [];
    this.hooks.forEach(function (task) {
      // eslint-disable-next-line no-var
      var executing = task(...args);

      if (executing && typeof executing['then'] === 'function') {
        // Task is a function that returns a promise
        promises.push(executing);
      }
      // Otherwise Task resolves immediately, add resolved promise with result
      promises.push(Promise.resolve(executing));
    });

    return Promise.all(promises);
  }

  // Adds a function to be run before a hook completes
  list() {
    return this.hooks;
  }

  clear() {
    this.hooks = [];
    return;
  }
}

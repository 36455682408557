import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';

export default Mixin(
  (superclass) =>
    class extends superclass {
       
      parsePlaceholder(representation) {
        const builder = new ElementNodeBuilder();

        builder.addElementType(ELEMENTS.PlaceholderElement.ELEMENT_TYPE);

        // parse properties
        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.PlaceholderElement.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        return builder.getNode();
      }
    },
);

import { FormattedMessage } from 'react-intl';
import { Dropdown, usePopper, Toggle } from 'dodoc-design-system';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { useDispatch, useSelector } from '_common/hooks';
import { InteractionController } from '_common/components';
import { useGetTenantSettingsQuery } from '_common/services/api/authority';

type NewElementDropdownProps = {
  disabled: boolean;
  openNewFolderModal: () => void;
  openNewDocumentModal: () => void;
  handleImportContentFromFile: ChangeEventHandler<HTMLInputElement>;
  handleFileUploadChange: ChangeEventHandler<HTMLInputElement>;
  handleImportFile: (e: ChangeEvent<HTMLInputElement>, type: 'dopdf' | 'presentation') => void;
};

const NewElementDropdown = ({
  disabled,
  openNewFolderModal,
  openNewDocumentModal,
  handleImportContentFromFile,
  handleFileUploadChange,
  handleImportFile,
}: NewElementDropdownProps) => {
  const dispatch = useDispatch();
  const { isOpen, popperProps, referenceProps } = usePopper({
    disabled,
    placement: 'bottom-start',
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const importDocumentOptionRef = useRef<HTMLDivElement>(null);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const active = useSelector((state) => state.onboarding.active.explorer);
  const started = useSelector((state) => state.onboarding.started.explorer);
  const interaction = useSelector((state) => state.onboarding.interactions);
  const [mouseOn, setMouseOn] = useState(false);
  const { data: tenantSettings } = useGetTenantSettingsQuery();
  useEffect(() => {
    if (isOpen && (active || started)) {
      dispatch(completeAction('explorer_myFiles_new_open'));
    }
  }, [isOpen, active, started]);

  useEffect(() => {
    if (!mouseOn && actionsCompleted.explorer_myFiles_new_open) {
      if (dropdownRef?.current) {
        dispatch(
          setPulseData({
            documentOptionRect: {
              top: dropdownRef.current.offsetTop,
              left: dropdownRef.current.offsetLeft,
              height: dropdownRef.current.offsetHeight,
              width: dropdownRef.current.offsetWidth,
            },
            importDocumentOptionRect: undefined,
          }),
        );
      } else {
        dispatch(
          setPulseData({
            documentOptionRect: undefined,
            importDocumentOptionRect: undefined,
          }),
        );
      }
    } else {
      if (
        importDocumentOptionRef?.current &&
        Object.prototype.hasOwnProperty.call(interaction, 'explorer_document_option')
      ) {
        dispatch(
          setPulseData({
            importDocumentOptionRect: {
              top: importDocumentOptionRef.current.offsetTop,
              left: importDocumentOptionRef.current.offsetLeft,
              height: importDocumentOptionRef.current.offsetHeight,
              width: importDocumentOptionRef.current.offsetWidth,
            },
            documentOptionRect: undefined,
          }),
        );
      } else {
        dispatch(
          setPulseData({
            documentOptionRect: undefined,
            importDocumentOptionRect: undefined,
          }),
        );
      }
    }

    return () => {
      dispatch(
        setPulseData({
          documentOptionRect: undefined,
        }),
      );
    };
  }, [actionsCompleted, mouseOn]);
  return (
    <>
      <InteractionController
        environment="explorer"
        rules={[
          {
            interaction: 'explorer_my_files_action_bar_new',
            actions: ['explorer_my_files_document_option_click'],
          },
        ]}
      >
        <Toggle
          size="medium"
          variant="standard"
          icon="New"
          isToggled={isOpen}
          testId="new-dropdown-toggle"
          disabled={disabled}
          {...referenceProps}
        >
          <FormattedMessage id="storage.actionBar.new.new" />
        </Toggle>
      </InteractionController>
      <div ref={dropdownRef}>
        <Dropdown
          {...popperProps}
          testId="new-dropdown"
          isOpen={
            (active || started) &&
            actionsCompleted?.explorer_myFiles_new_open &&
            !actionsCompleted?.explorer_my_files_document_option_click
              ? true
              : popperProps.isOpen
          }
        >
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            <Dropdown.Item
              size="large"
              prefixIcon="Folder"
              onClick={openNewFolderModal}
              disabled={disabled}
              testId="new-folder-dropdown-item"
            >
              <FormattedMessage id="storage.actionBar.new.newFolder" />
            </Dropdown.Item>
          </InteractionController>
          <div
            ref={importDocumentOptionRef}
            onMouseOver={() => {
              if (
                (active || started) &&
                Object.prototype.hasOwnProperty.call(interaction, 'explorer_document_option')
              ) {
                setMouseOn(true);
              }
            }}
            onMouseLeave={() => {
              if (active || started) {
                setMouseOn(false);
              }
            }}
          >
            <Dropdown.SubMenu
              size="large"
              prefixIcon="Document"
              itemContent={<FormattedMessage id="storage.actionBar.new.newDocument" />}
              testId="new-document-dropdown-submenu"
            >
              <InteractionController
                environment="explorer"
                style={{ width: '100%', flexDirection: 'column' }}
                rules={[{ interaction: 'explorer_document_option' }]}
              >
                <Dropdown.Item
                  size="large"
                  onClick={() => {
                    openNewDocumentModal();
                    dispatch(completeAction('explorer_my_files_document_option_click'));
                  }}
                  testId="blank-document-dropdown-item"
                >
                  <FormattedMessage id="storage.actionBar.new.blankDocument" />
                </Dropdown.Item>
              </InteractionController>
              <InteractionController
                environment="explorer"
                style={{ width: '100%', flexDirection: 'column' }}
              >
                <Dropdown.Item
                  size="large"
                  upload
                  onChange={handleImportContentFromFile}
                  accept=".docx, .html, .htm"
                  testId="import-document-dropdown-item"
                >
                  <FormattedMessage id="storage.actionBar.new.importDocument" />
                </Dropdown.Item>
              </InteractionController>
            </Dropdown.SubMenu>
          </div>
          <InteractionController
            environment="explorer"
            style={{ width: '100%', flexDirection: 'column' }}
          >
            {tenantSettings?.['product.pdf_reviewer'] && (
              <Dropdown.Item
                size="large"
                upload
                onChange={(e) => handleImportFile(e, 'dopdf')}
                accept=".pdf"
                prefixIcon="FilePDFRed"
                testId="import-pdf-document-dropdown-item"
              >
                <FormattedMessage id="PDF_IMPORT" />…
              </Dropdown.Item>
            )}
            {tenantSettings?.['product.presentation_reviewer'] && (
              <Dropdown.Item
                size="large"
                upload
                onChange={(e) => handleImportFile(e, 'presentation')}
                accept=".pptx"
                prefixIcon="FilePresentation"
                testId="import-pptx-document-dropdown-item"
              >
                <FormattedMessage id="PRESENTATION_IMPORT" />…
              </Dropdown.Item>
            )}
            <Dropdown.Item
              size="large"
              prefixIcon="File"
              onChange={handleFileUploadChange}
              upload
              multiple
              accept=".rtf, .html, .xhtml, .css, .pdf, .ppt, .pptx, .xls,
        .xlsx, .csv, .txt, .jpg, .jpeg, .png, .bmp, .tiff, .gif, .svg, .dwg,
        .dxf, .dgn, .stl, .mkv, .mov, .avi, .mp4, .m4v, .ogv, .webm, .qt, .mpg,
        .wmv, .asf, .flv, .aac, .flac, .wav, .mp3, .zip, .rar, .cls, .sty, .bst,
        .bib, .tex, .aux, .log, .docx, .dtd, .json, .kml, .mol, .cml, .xml, .xsl, 
        .xpt, .xport, .sdf, .xsd"
              testId="new-file-dropdown-item"
            >
              <FormattedMessage id="FILE_UPLOAD" />
            </Dropdown.Item>
          </InteractionController>
        </Dropdown>
      </div>
    </>
  );
};

export default NewElementDropdown;

import { ElementNodeBuilder } from 'Editor/services/Model';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder, BaseViewElement } from '../BaseViewBuilder';

export class SectionBreakViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.SectionBreakElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },
    // vanish
    v: this.GENERIC_ATTRIBUTE_MAPPER.v,
    // specific section propertie
    n_sct: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('n_sct')) {
          builder.addProperty('n_sct', node.getAttribute('n_sct'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.n_sct) {
          node.setAttribute('n_sct', json.properties?.n_sct);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('n_sct');
      },
    },
    sect: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.dataset.sect) {
          builder.addProperty('sect', node.dataset.sect);
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        let sectionType;

        if (json.properties?.n_sct) {
          const sectionModel = this.Data.sections.getSectionById(json.properties?.n_sct);
          if (sectionModel) {
            sectionType = sectionModel.properties?.t;
          }
        }

        if (sectionType) {
          node.dataset.sect = sectionType;
        } else {
          node.dataset.sect = ELEMENTS.SectionBreakElement.TYPES.CONTINUOUS;
        }
      },
      remove: (node: HTMLElement) => {
        delete node.dataset.sect;
      },
    },
    //section
    section: this.GENERIC_ATTRIBUTE_MAPPER.section,
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data, model?: Editor.Data.Node.Model) {
    const node = DOMElementFactory.buildElement(ELEMENTS.SectionBreakElement.TAG);

    const data = model?.get();

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node, data);
    });

    if (node instanceof BaseViewElement) {
      node.Visualizer = this.Visualizer;
    }

    return node;
  }
}

import { useMemo } from 'react';
import cx from 'classnames';
import AddShortcut from './AddShortcut';
import Remover from './Remover';
import { useTableOptionsContext } from './TableOptionsContext';
import styles from './TableOptions.module.scss';

// https://stackoverflow.com/a/60033403/13320604
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
function numberToLetter(number: number, result: string = ''): string {
  let charIndex = number % alphabet.length;
  let quotient = number / alphabet.length;
  if (charIndex - 1 === -1) {
    charIndex = alphabet.length;
    quotient--;
  }
  result = alphabet.charAt(charIndex - 1) + result;
  if (quotient >= 1) {
    return numberToLetter(parseInt(`${quotient}`, +result));
  } else {
    return result;
  }
}

type ExtensorsProps = {
  insert: (index: number, selection: Editor.Elements.Table.Selection) => void;
  select: (index: number, selection: Editor.Elements.Table.Selection) => void;
  remove: (index: number[], selection: Editor.Elements.Table.Selection) => void;
  sizes: number[];
  selected: number[];
  fullySelected: {
    row: number[];
    column: number[];
  };
  type: Editor.Elements.Table.Selection;
};

const Extensors = ({
  sizes,
  selected,
  type,
  fullySelected,
  insert,
  select,
  remove,
}: ExtensorsProps) => {
  const { deleting, deletingTable } = useTableOptionsContext();

  const getOffset = (index: number) => {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      offset += sizes[i];
    }
    return offset;
  };

  const remover = useMemo(() => {
    if (fullySelected[type].length > 0) {
      const sortedArray = [...fullySelected[type]].sort();
      const minIndex = sortedArray[0];
      const maxIndex = sortedArray[sortedArray.length - 1];
      const minOffset = getOffset(minIndex);
      const maxOffset = getOffset(maxIndex);
      const offset = minOffset;
      const size = maxOffset - minOffset + sizes[maxIndex];
      return { offset, size };
    }
    return null;
  }, [sizes, fullySelected, type]);

  const hasFullySelected = fullySelected.row.length > 0 || fullySelected.column.length > 0;

  return (
    <div className={cx(styles.extensors, styles[type])} data-testid={`table-${type}-extensors`}>
      {sizes.map((size, i) => {
        const partiallySelected = selected.includes(i);
        const fSelected = fullySelected[type].includes(i);
        return (
          <div
            key={`${i}-${size}`}
            style={{ [type === 'column' ? 'width' : 'height']: `${size}px` }}
            className={cx(styles.extensor, styles[type], {
              [styles['partially-selected']]: partiallySelected,
              [styles.selected]: fSelected,
              [styles.deleting]: (deleting && (partiallySelected || fSelected)) || deletingTable,
              [styles.last]: i === sizes.length - 1,
            })}
            onClick={() => {
              select(i, type);
            }}
            data-testid={`table-extensor-${
              type === 'column' ? numberToLetter(i + 1).toLowerCase() : i + 1
            }`}
          >
            {type === 'column' ? numberToLetter(i + 1) : i + 1}
            <AddShortcut
              type={type}
              first={i === 0}
              last={false}
              onClick={(e) => {
                e.stopPropagation();
                insert(i, type);
              }}
              enabled={!hasFullySelected}
              testId={`first-shortcut-add-${type}`}
            />
            <AddShortcut
              type={type}
              first={false}
              last={i === sizes.length - 1}
              onClick={(e) => {
                e.stopPropagation();
                insert(i + 1, type);
              }}
              enabled={!hasFullySelected}
              testId={`second-shortcut-add-${type}`}
            />
          </div>
        );
      })}
      {remover && (
        <Remover
          type={type}
          onClick={() => remove(fullySelected[type], type)}
          {...remover}
          multiple={fullySelected.row.length + fullySelected.column.length > 1}
          testId={`remove-${type}`}
        />
      )}
    </div>
  );
};

export default Extensors;

import { BlockViewModel } from '../../..';
import { SectionValuesUpdateType } from '../../LazyLoaderManager';
import { DocumentLayout } from '../BaseLayout';

export class WebLayout extends DocumentLayout {
  destroy() {}

  removeAllChildren() {
    while (this.vm.view?.firstChild) {
      this.vm.view?.removeChild(this.vm.view?.firstChild);
    }
  }

  handleChildChangedHeight(
    viewModel: BlockViewModel,
    view: Editor.Visualizer.BaseView | null,
    difference: number,
    scrollDiff: number | null,
  ) {
    if (this.vm.lazyLoader.isBehindAnchor(viewModel.id)) {
      this.vm.scrollManager.updateScrollDiff(scrollDiff);
    }
  }

  updateSectionsValues(delta: SectionValuesUpdateType) {
    const blocksToUpdate = Object.keys(delta);
    let blockId;
    let block: BlockViewModel;
    for (let index = 0; index < blocksToUpdate.length; index++) {
      blockId = blocksToUpdate[index];
      block = this.vm.children.getById(blockId) as BlockViewModel;
      if (block) {
        block.updateSectionValue(delta[blockId]);
      }
    }
  }

  updateSectionProperties(sectionId: string) {}

  updateParagraphStyle(styleId: string) {}

  appendChild(viewModel: BlockViewModel) {
    if (this.vm.view) {
      viewModel.render();
      this.vm.children.push(viewModel);
      const view = viewModel.getRootView();
      if (view) {
        const scrollDiffToBottom = this.vm.getScrollDiffToBottom();
        this.vm.view.appendChild(view);
        this.Visualizer.tabulator?.tabulate(viewModel);
        let height = view?.clientHeight;
        this.handleChildChangedHeight(viewModel, view, height, scrollDiffToBottom);
        viewModel.triggerPendingEvent('RENDERED');
      }
    }
    viewModel.parent = this.vm;
  }

  replaceChildAt(viewModel: BlockViewModel, index: number) {
    let old = this.vm.children.splice(index, 1, [viewModel]);
    viewModel.parent = this.vm;
    if (old[0]) {
      let oldHeight = old[0].viewHeight;
      viewModel.render();
      const view = viewModel.getRootView();
      const oldView = old[0]?.getRootView();
      if (view && oldView) {
        const scrollDiffToBottom = this.vm.getScrollDiffToBottom();
        this.vm.view?.replaceChild(view, oldView);
        this.Visualizer.tabulator?.tabulate(viewModel);
        let height = viewModel.viewHeight;
        this.handleChildChangedHeight(viewModel, view, height - oldHeight, scrollDiffToBottom);

        viewModel.triggerPendingEvent('RENDERED');
      }
      old[0].dispose(true, false);
    }
  }

  insertChildAt(viewModel: BlockViewModel, index: number) {
    if (this.vm.view?.children && this.vm.view.children?.length <= index) {
      this.appendChild(viewModel);
    } else {
      viewModel.parent = this.vm;
      this.vm.children.splice(index, 0, [viewModel]);
      viewModel.render();
      const modelView = viewModel.getRootView();
      if (modelView && this.vm.view) {
        const scrollDiffToBottom = this.vm.getScrollDiffToBottom();
        this.vm.view.insertBefore(modelView, this.vm.view.children[index]);
        this.Visualizer.tabulator?.tabulate(viewModel);
        this.handleChildChangedHeight(
          viewModel,
          modelView,
          viewModel.viewHeight,
          scrollDiffToBottom,
        );

        viewModel.triggerPendingEvent('RENDERED');
      }
    }
    // if (this.vm.lazyLoader.isBehindAnchor(viewModel.id)) {
    //   this.vm.scrollManager.applyOffsetToScroll(viewModel.viewHeight);
    // }
  }

  removeChildAt(index: number) {
    this.vm.removeChildAt(index);
  }
}

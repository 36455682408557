import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';
import { BaseViewBuilder } from '../BaseViewBuilder';
import { FormatElement } from './FormatElement/FormatElement';

export class FormatViewBuilder
  extends BaseViewBuilder
  implements Editor.Visualizer.IViewbuilder<Editor.Elements.FormatElement>
{
  ATTRIBUTE_MAPPER: Editor.Visualizer.ATTRIBUTE_MAPPER_TYPE<Editor.Elements.FormatElement> = {
    // id
    id: this.GENERIC_ATTRIBUTE_MAPPER.id,
    // parent id
    parent_id: this.GENERIC_ATTRIBUTE_MAPPER.parent_id,
    // element_type
    element_type: {
      parse: this.GENERIC_ATTRIBUTE_MAPPER.element_type.parse,
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        node.setAttribute('element_type', ELEMENTS.FormatElement.ELEMENT_TYPE);
      },
      remove: this.GENERIC_ATTRIBUTE_MAPPER.element_type.remove,
    },

    // 'bold',
    bold: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('bold')) {
          builder.addProperty('bold', node.getAttribute('bold'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.bold != null) {
          node.setAttribute('bold', json.properties.bold);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('bold');
      },
    },
    // 'italic',
    italic: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('italic')) {
          builder.addProperty('italic', node.getAttribute('italic'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.italic != null) {
          node.setAttribute('italic', json.properties.italic);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('italic');
      },
    },
    // 'underline',
    underline: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('underline')) {
          builder.addProperty('underline', node.getAttribute('underline'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.underline != null) {
          node.setAttribute('underline', json.properties.underline);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('underline');
      },
    },
    // 'strikethrough',
    strikethrough: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('strikethrough')) {
          builder.addProperty('strikethrough', node.getAttribute('strikethrough'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.strikethrough != null) {
          node.setAttribute('strikethrough', `${json.properties.strikethrough}`);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('strikethrough');
      },
    },
    // 'superscript',
    superscript: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('superscript')) {
          builder.addProperty('superscript', node.getAttribute('superscript'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.superscript != null) {
          node.setAttribute('superscript', json.properties.superscript);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('superscript');
      },
    },
    // 'subscript',
    subscript: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('subscript')) {
          builder.addProperty('subscript', node.getAttribute('subscript'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.subscript != null) {
          node.setAttribute('subscript', json.properties.subscript);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('subscript');
      },
    },
    // 'fontfamily',
    fontfamily: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('fontfamily')) {
          builder.addProperty('fontfamily', node.getAttribute('fontfamily'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.fontfamily != null) {
          node.setAttribute('fontfamily', json.properties.fontfamily);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('fontfamily');
      },
    },
    // 'fontsize',
    fontsize: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('fontsize')) {
          builder.addProperty('fontsize', node.getAttribute('fontsize'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.fontsize != null) {
          node.setAttribute('fontsize', json.properties.fontsize);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('fontsize');
      },
    },
    // 'color',
    color: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('color')) {
          builder.addProperty('color', node.getAttribute('color'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.color != null) {
          node.setAttribute('color', json.properties.color);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('color');
      },
    },
    // 'backgroundcolor',
    backgroundcolor: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('highlightcolor')) {
          builder.addProperty('backgroundcolor', node.getAttribute('highlightcolor'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.backgroundcolor != null) {
          node.setAttribute('highlightcolor', json.properties.backgroundcolor);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('highlightcolor');
      },
    },
    // 'vanish',
    v: {
      parse: (node: HTMLElement, builder: ElementNodeBuilder) => {
        if (node.hasAttribute('vanish')) {
          builder.addProperty('v', node.getAttribute('vanish'));
        }
      },
      render: (json: Editor.Data.Node.Data, node: HTMLElement) => {
        if (json.properties?.v != null) {
          node.setAttribute('vanish', `${json.properties.v}`);
        }
      },
      remove: (node: HTMLElement) => {
        node.removeAttribute('vanish');
      },
    },
  };

  get attributeMapper() {
    return this.ATTRIBUTE_MAPPER;
  }

  shouldRenderChildren(json?: Editor.Data.Node.Data | undefined) {
    return true;
  }

  build(json: Editor.Data.Node.Data) {
    const node = new FormatElement(this.Visualizer, this.Data);

    Object.keys(this.ATTRIBUTE_MAPPER).forEach((prop) => {
      this.ATTRIBUTE_MAPPER[prop].render(json, node);
    });

    node.preRender();

    return node;
  }
}

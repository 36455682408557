import { ELEMENTS, DISPLAY_TYPES } from 'Editor/services/consts';
import { BaseViewElement } from '../../BaseViewBuilder';

import './Tabelement.module.scss';
import EditorManager from 'Editor/services/EditorManager/EditorManager';

export class TabElement extends BaseViewElement {
  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get displayType() {
    return DISPLAY_TYPES.INLINE;
  }

  get isEditable() {
    return false;
  }

  get isDeletable() {
    return true;
  }

  get isSplitable() {
    return false;
  }

  get isContentWrapper() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  private repeatContent(text: string, width: number): string {
    let fontFamily = getComputedStyle(this).fontFamily;
    let fontSize = getComputedStyle(this).fontSize;

    let content = '';

    if (fontFamily != null && fontSize != null) {
      const metrics = EditorManager.getInstance().fontFamilyHelper?.getTextMetrics(
        fontFamily,
        fontSize,
        text,
      );

      if (metrics) {
        let w = 0;
        while (w + metrics.width - 1 <= width) {
          content += text;
          w += metrics.width;
        }
      }
    }

    return content;
  }

  buildContent() {
    while (this.firstChild != null) {
      this.removeChild(this.firstChild);
    }

    if (this.dataset.width != null) {
      this.style.width = `${this.dataset.width}px`;

      let width = +this.dataset.width;

      if (!isNaN(width) && width > 0) {
        let content = ' ';

        switch (this.dataset.leading) {
          case 'd':
            content = this.repeatContent('.', width);
            break;
          case 'h':
            content = this.repeatContent('-', width);
            break;
          case 'u':
            content = this.repeatContent('_', width);
            break;
          default:
            content = this.repeatContent('\u00A0', width);
            break;
        }
        this.appendChild(document.createTextNode(content));
      }
    }
  }

  resetWidth() {
    this.dataset.width = '0';
    this.style.width = '0px';

    while (this.firstChild != null) {
      this.removeChild(this.firstChild);
    }
  }
}

// register element
if (!window.customElements.get(ELEMENTS.TabElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.TabElement.IDENTIFIER, TabElement);
}

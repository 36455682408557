import { Mixin } from 'mixwith';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export default Mixin(
  (superclass) =>
    class RemoveSelectionHelper extends superclass {
      /**
       * remove contents from a selection range and register them in action context
       * @param {ActionContext} actionContext
       * @param {Editor.Selection.EditorRange} range
       */
       
      removeRangeContents(actionContext, range) {
        if (actionContext && range && range.getNodes) {
          if (!range.collapsed) {
            let savedMarkers = range.saveRange();

            const pageNode = EditorDOMUtils.getContentContainer();
            const commonAncestor = range.commonAncestorContainer;

            if (EditorDOMUtils.parentContainsNode(pageNode, commonAncestor)) {
              let startLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                pageNode,
                range.startContainer,
              );
              let endLevel0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.endContainer);

              // inside container elements
              if (
                startLevel0 === endLevel0 &&
                (startLevel0.tagName === ELEMENTS.TableElement.TAG ||
                  EditorDOMElements.isNodeContainerElement(startLevel0))
              ) {
                const closestStartContainer = EditorDOMUtils.closestMultiBlockContainerElement(
                  range.startContainer,
                );
                const closestEndContainer = EditorDOMUtils.closestMultiBlockContainerElement(
                  range.endContainer,
                );
                if (closestStartContainer === closestEndContainer) {
                  startLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                    closestStartContainer,
                    range.startContainer,
                  );

                  endLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                    closestEndContainer,
                    range.endContainer,
                  );

                  if (startLevel0 == null && endLevel0 == null) {
                    actionContext.addChangeUpdatedNode(closestStartContainer);
                  }
                }
              }

              // let blockIdsToRemove = [];

              if (startLevel0 !== endLevel0) {
                // mark start as updated
                actionContext.addChangeUpdatedNode(startLevel0);

                // mark nodes inbetween to removed
                let nextSibling;
                for (
                  nextSibling = startLevel0.nextSibling;
                  nextSibling && nextSibling !== endLevel0;
                  nextSibling = nextSibling.nextSibling
                ) {
                  // blockIdsToRemove.push(nextSibling.id);
                  this.removeBlockElementFromDOM(actionContext, nextSibling);
                }

                // mark last as updated
                actionContext.addChangeUpdatedNode(endLevel0);
              } else if (startLevel0 != null) {
                // mark start as updated
                actionContext.addChangeUpdatedNode(startLevel0);
              }

              range.deleteContents();

              // this.removeBlockNodeOperation(actionContext, blockIdsToRemove);

              // check if common ancestor is empty
              if (
                range.commonAncestorContainer.nodeType === Node.TEXT_NODE &&
                range.commonAncestorContainer.length === 0
              ) {
                range.commonAncestorContainer.parentNode.removeChild(range.commonAncestorContainer);
              }
            } else if (commonAncestor === pageNode) {
              // mark all nodes to be removed
              const level0Nodes = range.getNodes(
                [Node.ELEMENT_NODE],
                (node) => node.parentNode === pageNode,
              );

              let nodesToUpdate = false;
              let i;
              let blockIdsToRemove = [];
              for (i = 0; i < level0Nodes.length; i++) {
                if (
                  level0Nodes[i] &&
                  EditorDOMUtils.parentContainsNode(pageNode, level0Nodes[i]) &&
                  range.containsNode(level0Nodes[i])
                ) {
                  blockIdsToRemove.push(level0Nodes[i].id);
                  // this.removeBlockElementFromDOM(actionContext, level0Nodes[i]);
                } else {
                  nodesToUpdate = true;
                  actionContext.addChangeUpdatedNode(level0Nodes[i]);
                }
              }

              if (blockIdsToRemove.length) {
                this.removeBlockNodeOperation(actionContext, blockIdsToRemove);
              }

              if (nodesToUpdate) {
                range.deleteContents();
              }
            }

            if (savedMarkers) {
              range.restoreRange(savedMarkers);
              EditorSelectionUtils.applyRangeToSelection(range);
            }
          }
        } else {
          throw new Error('Invalid arguments!');
        }
      }

       
      extractRangeContents(actionContext, range) {
        let contents;
        if (actionContext && range && range.getNodes) {
          if (!range.collapsed) {
            let savedMarkers = range.saveRange();

            const pageNode = EditorDOMUtils.getContentContainer();
            const commonAncestor = range.commonAncestorContainer;
            if (EditorDOMUtils.parentContainsNode(pageNode, commonAncestor)) {
              // common ancestor is in the page

              let startLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                pageNode,
                range.startContainer,
              );
              let endLevel0 = EditorDOMUtils.findFirstLevelChildNode(pageNode, range.endContainer);

              // inside container elements
              if (
                startLevel0 === endLevel0 &&
                (startLevel0.tagName === ELEMENTS.TableElement.TAG ||
                  EditorDOMElements.isNodeContainerElement(startLevel0))
              ) {
                const closestStartContainer = EditorDOMUtils.closestMultiBlockContainerElement(
                  range.startContainer,
                );
                const closestEndContainer = EditorDOMUtils.closestMultiBlockContainerElement(
                  range.endContainer,
                );
                if (closestStartContainer === closestEndContainer) {
                  if (closestStartContainer === range.startContainer) {
                    startLevel0 = closestStartContainer.childNodes[range.startOffset];
                  } else {
                    startLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                      closestStartContainer,
                      range.startContainer,
                    );
                  }

                  if (closestEndContainer === range.endContainer) {
                    endLevel0 = closestEndContainer.childNodes[range.endOffset - 1];
                  } else {
                    endLevel0 = EditorDOMUtils.findFirstLevelChildNode(
                      closestEndContainer,
                      range.endContainer,
                    );
                  }
                }
              }

              // let blockIdsToRemove = [];

              if (startLevel0 !== endLevel0) {
                // mark start as updated
                actionContext.addChangeUpdatedNode(startLevel0);

                // mark nodes inbetween to removed
                let nextSibling;
                for (
                  nextSibling = startLevel0.nextSibling;
                  nextSibling && nextSibling !== endLevel0;
                  nextSibling = nextSibling.nextSibling
                ) {
                  this.removeBlockElementFromDOM(actionContext, nextSibling);
                  // blockIdsToRemove.push(nextSibling.id);
                }

                // mark last as updated
                actionContext.addChangeUpdatedNode(endLevel0);
              } else {
                // mark start as updated
                actionContext.addChangeUpdatedNode(startLevel0);
              }

              contents = range.extractContents();

              // this.removeBlockNodeOperation(actionContext, blockIdsToRemove);
            } else if (commonAncestor === pageNode) {
              // mark all nodes to be removed
              const level0Nodes = range.getNodes(
                [Node.ELEMENT_NODE],
                (node) => node.parentNode === pageNode,
              );

              contents = range.cloneContents();

              let nodesToUpdate = false;
              let i;
              let blockIdsToRemove = [];
              for (i = 0; i < level0Nodes.length; i++) {
                if (
                  level0Nodes[i] &&
                  EditorDOMUtils.parentContainsNode(pageNode, level0Nodes[i]) &&
                  range.containsNode(level0Nodes[i])
                ) {
                  blockIdsToRemove.push(level0Nodes[i].id);
                  // this.removeBlockElementFromDOM(actionContext, level0Nodes[i]);
                } else {
                  nodesToUpdate = true;
                  actionContext.addChangeUpdatedNode(level0Nodes[i]);
                }
              }
              if (blockIdsToRemove.length) {
                this.removeBlockNodeOperation(actionContext, blockIdsToRemove);
              }

              if (nodesToUpdate) {
                range.deleteContents();
              }
            }

            if (savedMarkers) {
              range.restoreRange(savedMarkers);
              EditorSelectionUtils.applyRangeToSelection(range);
            }
          }
        } else {
          throw new Error('Invalid arguments!');
        }

        return contents;
      }

       
      removeBlockElementFromDOM(actionContext, nodeToRemove) {
        if (actionContext && nodeToRemove) {
          let nodes = nodeToRemove;
          if (!Array.isArray(nodes)) {
            nodes = [nodeToRemove];
          }

          for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];

            // remove tracke elements with reference to this element
            if (
              (EditorDOMUtils.isClosestTextElementEditable(node) ||
                node.tagName === ELEMENTS.TrackInsertElement.TAG ||
                node.tagName === ELEMENTS.TrackDeleteElement.TAG) &&
              node.hasAttribute('id')
            ) {
              let i = 0;

              let elements = document.querySelectorAll(`[replacewith=${node.getAttribute('id')}]`);
              for (i = 0; i < elements.length; i++) {
                if (
                  elements[i].tagName === ELEMENTS.TrackInsertElement.TAG ||
                  elements[i].tagName === ELEMENTS.TrackDeleteElement.TAG
                ) {
                  actionContext.addReferenceToRefresh(
                    elements[i].getAttribute('element_reference'),
                  );
                  actionContext.addChangeUpdatedNode(elements[i]);
                  elements[i].remove();
                }
              }

              elements = document.querySelectorAll(
                `[replacewithsibling=${node.getAttribute('id')}]`,
              );
              for (i = 0; i < elements.length; i++) {
                if (
                  elements[i].tagName === ELEMENTS.TrackInsertElement.TAG ||
                  elements[i].tagName === ELEMENTS.TrackDeleteElement.TAG
                ) {
                  actionContext.addReferenceToRefresh(
                    elements[i].getAttribute('element_reference'),
                  );
                  actionContext.addChangeUpdatedNode(elements[i]);
                  elements[i].remove();
                }
              }
            }

            // TODO: add safeguard to remove block elements

            const closestContainer = EditorDOMUtils.closest(
              node,
              EditorDOMElements.MULTI_BLOCK_CONTAINER_ELEMENTS,
            );
            if (
              closestContainer &&
              nodeToRemove.parentNode !== EditorDOMUtils.getContentContainer(nodeToRemove)
            ) {
              actionContext.addChangeRemovedNode(node);
              if (node && node.parentNode) {
                node.remove();
              }
            } else {
              // remove element
              this.removeBlockNodeOperation(actionContext, node.id);
            }
          }
        }
      }
    },
);

 
import { v4 as uuid } from 'uuid';
import { RealtimeObject, RealtimeOpsBuilder } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';

export class Comments extends RealtimeObject<Presentation.Model.Comments.Data> {
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'presComments');
  }

  private static defaultValues(
    data: Common.OnlyRequire<Presentation.Data.Comment, 'content' | 'anchor' | 'authorId'>,
  ): Presentation.Data.Comment {
    return {
      id: data.id || uuid(),
      time: new Date().toISOString(),
      status: 'OPEN',
      votes: [],
      priority: 'MEDIUM',
      deleted_replies: [],
      replies: [],
      ...data,
    };
  }

  private static replyDefaultValues(
    userAuthor: string,
    replyContent: Presentation.Model.Comments.ContentsType['content'],
  ): Presentation.Model.Tasks.Reply {
    return {
      id: uuid(),
      creationDate: new Date().toISOString(),
      modificationDate: '',
      content: {
        content: replyContent,
        dir: 'ltr',
      },
      authorId: userAuthor,
      votes: [],
    };
  }

  get allCommentIds() {
    const data = this.selectedData();
    const slidesIds = Object.keys(data?.sld || {});
    const commentIds: string[] = [];
    for (let index = 0; index < slidesIds.length; index++) {
      const slideId = slidesIds[index];
      commentIds.push(...Object.keys(data?.sld?.[slideId] || {}));
    }
    return commentIds;
  }

  getCommentsData(): {
    list: string[];
    data: Record<string, Presentation.Data.Comment>;
  } {
    const data = this.selectedData();
    const slidesIds = Object.keys(data?.sld || {});
    let commentsData = {};
    const commentIds: string[] = [];
    for (let index = 0; index < slidesIds.length; index++) {
      const slideId = slidesIds[index];
      const comments = Object.keys(data?.sld?.[slideId] || {});
      commentIds.push(...comments);
      commentsData = {
        ...commentsData,
        ...(data?.sld?.[slideId] || {}),
      };
    }
    return {
      list: commentIds,
      data: commentsData,
    };
  }

  subscribe(): Promise<RealtimeObject<Presentation.Model.Comments.Data>> {
    return new Promise((resolve, reject) => {
      this.model.subscribe((error) => {
        if (error) {
          reject(error);
        } else {
          resolve(this);
        }
      });
    });
  }

  handleLoad(): void {
    //
  }

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    const data = super.get() as Presentation.Model.Comments.Data;
    this.emit('UPDATED', data, ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  getComments(slideId: string) {
    const data = this.selectedData();
    return data?.sld[slideId];
  }

  getComment(slideId: string, commentId: string) {
    return this.getComments(slideId)?.[commentId];
  }

  addComment(
    data: Common.OnlyRequire<Presentation.Data.Comment, 'content' | 'anchor' | 'authorId'>,
  ) {
    const properData = Comments.defaultValues(data);
    const ops: Realtime.Core.RealtimeOps = [];

    if (!this.get(['sld', properData.anchor[0].id])) {
      ops.push(RealtimeOpsBuilder.objectInsert({}, ['sld', properData.anchor[0].id]));
    }
    ops.push(
      RealtimeOpsBuilder.objectInsert(properData, ['sld', properData.anchor[0].id, properData.id]),
    );
    return this.apply(ops);
  }

  editComment(data: Presentation.Data.Comment) {
    if (!this.get(['sld', data.anchor[0].id, data.id])) {
      return Promise.resolve(this);
    }
    const oldData = this.get(['sld', data.anchor[0].id, data.id]);
    if (!oldData) {
      return this.apply([
        RealtimeOpsBuilder.objectInsert(data, ['sld', data.anchor[0].id, data.id]),
      ]);
    }
    return this.apply([
      RealtimeOpsBuilder.objectReplace(oldData, data, ['sld', data.anchor[0].id, data.id]),
    ]);
  }

  changeStatus(
    data: Common.OnlyRequire<Presentation.Data.Comment, 'id' | 'anchor'>,
    status: Presentation.Model.Comments.CommentStatus,
  ) {
    if (!this.get(['sld', data.anchor[0].id]) || !this.get(['sld', data.anchor[0].id, data.id])) {
      return Promise.resolve(this);
    }
    const oldStatus = this.get(['sld', data.anchor[0].id, data.id, 'status']);
    if (status === oldStatus) {
      return Promise.resolve(this);
    }
    return this.apply([
      RealtimeOpsBuilder.objectReplace(oldStatus, status, [
        'sld',
        data.anchor[0].id,
        data.id,
        'status',
      ]),
    ]);
  }

  changePriority(
    slideId: string,
    commentId: Presentation.Data.Comment['id'],
    priority: Presentation.Model.Comments.CommentPriority,
  ) {
    if (!this.get(['sld', slideId]) || !this.get(['sld', slideId, commentId])) {
      return Promise.resolve(this);
    }
    const oldPrpriority = this.get(['sld', slideId, commentId, 'priority']);
    if (priority === oldPrpriority) {
      return Promise.resolve(this);
    }
    return this.apply([
      RealtimeOpsBuilder.objectReplace(oldPrpriority, priority, [
        'sld',
        slideId,
        commentId,
        'priority',
      ]),
    ]);
  }

  resolveComment(slideId: string, commentId: Presentation.Data.Comment['id']) {
    if (!this.get(['sld', slideId]) || !this.get(['sld', slideId, commentId])) {
      return Promise.resolve(this);
    }
    const oldStatus = this.get(['sld', slideId, commentId, 'status']);
    if ('ACCEPTED' === oldStatus) {
      return Promise.resolve(this);
    }
    return this.apply([
      RealtimeOpsBuilder.objectReplace(oldStatus, 'ACCEPTED', [
        'sld',
        slideId,
        commentId,
        'status',
      ]),
    ]);
  }

  voteComment(
    slideId: string,
    commentId: Presentation.Data.Comment['id'],
    user: Presentation.Model.Comments.CommentVoteType['user'],
    value: Presentation.Model.Comments.CommentVoteType['value'],
  ) {
    const comment = this.getComment(slideId, commentId);

    if (!comment) {
      return Promise.resolve(this);
    }

    const ops = [];
    const vote: Presentation.Model.Comments.CommentVoteType = {
      user: user,
      value: value,
      time: new Date().toISOString(),
    };
    if (!comment.votes) {
      if (value !== 0) {
        ops.push(RealtimeOpsBuilder.objectInsert([vote], ['sld', slideId, commentId, 'votes']));
      }
    } else {
      const voteIndex = comment.votes.findIndex((vote) => vote.user === user);
      if (comment.votes[voteIndex]) {
        if (value === 0) {
          ops.push(
            RealtimeOpsBuilder.listDelete(comment.votes[voteIndex], [
              'sld',
              slideId,
              commentId,
              'votes',
              voteIndex,
            ]),
          );
        } else if (comment.votes[voteIndex].value !== value) {
          ops.push(
            RealtimeOpsBuilder.listReplace(comment.votes[voteIndex], vote, [
              'sld',
              slideId,
              commentId,
              'votes',
              voteIndex,
            ]),
          );
        }
      } else if (value !== 0) {
        ops.push(
          RealtimeOpsBuilder.listInsert(vote, [
            'sld',
            slideId,
            commentId,
            'votes',
            comment.votes.length,
          ]),
        );
      }

      if (ops.length) {
        return this.apply(ops);
      }
      return Promise.resolve(this);
    }
  }

  replyToComment(
    slideId: string,
    commentId: string,
    authorId: string,
    replyContent: Presentation.Model.Comments.ContentsType['content'],
  ) {
    const comment: Presentation.Data.Comment = this.get(['sld', slideId, commentId]);
    if (!comment) {
      return Promise.resolve(this);
    }
    const reply = Comments.replyDefaultValues(authorId, replyContent);
    return this.apply([
      RealtimeOpsBuilder.listInsert(reply, [
        'sld',
        slideId,
        commentId,
        'replies',
        comment.replies.length,
      ]),
    ]);
  }

  deleteReplyComment(slideId: string, commentId: string, replyId: string) {
    const replies: Presentation.Model.Comments.Reply[] = this.get([
      'sld',
      slideId,
      commentId,
      'replies',
    ]);

    if (!replies) {
      return Promise.resolve(this);
    }
    const replyIndex = replies.findIndex(
      (reply: Presentation.Model.Comments.Reply) => reply.id === replyId,
    );

    if (replyIndex === -1) {
      return Promise.resolve(this);
    }

    return this.apply([
      RealtimeOpsBuilder.listDelete(replies[replyIndex], [
        'sld',
        slideId,
        commentId,
        'replies',
        replyIndex,
      ]),
    ]);
  }

  editReplyComment(
    slideId: string,
    commentId: string,
    replyId: string,
    replyContent: Presentation.Model.Comments.ContentsType['content'],
  ) {
    const replies: Presentation.Model.Comments.Reply[] = this.get([
      'sld',
      slideId,
      commentId,
      'replies',
    ]);

    if (!replies) {
      return Promise.resolve(this);
    }
    const replyIndex = replies.findIndex(
      (reply: Presentation.Model.Comments.Reply) => reply.id === replyId,
    );

    if (replyIndex === -1) {
      return Promise.resolve(this);
    }

    return this.apply([
      RealtimeOpsBuilder.objectReplace(replies[replyIndex].content.content, replyContent, [
        'sld',
        slideId,
        commentId,
        'replies',
        replyIndex,
        'content',
        'content',
      ]),
    ]);
  }

  voteReply(
    slideId: string,
    commentId: string,
    authorId: string,
    replyId: string,
    value: Presentation.Model.Comments.CommentVoteType['value'],
  ) {
    const replies = this.get(['sld', slideId, commentId, 'replies']);

    if (!replies) {
      return Promise.resolve(this);
    }

    const replyIndex = replies.findIndex(
      (reply: Presentation.Model.Comments.Reply) => reply.id === replyId,
    );

    if (replyIndex === -1) {
      return Promise.resolve(this);
    }

    const reply: Presentation.Model.Comments.Reply = replies[replyIndex];

    const voteIndex = reply.votes.findIndex(
      (vote: Presentation.Model.Comments.CommentVoteType) => vote.user === authorId,
    );

    if (voteIndex === -1) {
      const vote: Presentation.Model.Comments.CommentVoteType = {
        user: authorId,
        value: value,
        time: new Date().toISOString(),
      };
      return this.apply([
        RealtimeOpsBuilder.listInsert(vote, [
          'sld',
          slideId,
          commentId,
          'replies',
          replyIndex,
          'votes',
          reply.votes.length,
        ]),
      ]);
    } else {
      const oldVote: Presentation.Model.Comments.CommentVoteType = reply.votes[voteIndex];
      const newVote: Presentation.Model.Comments.CommentVoteType = JSON.parse(
        JSON.stringify(reply.votes[voteIndex]),
      );
      newVote.value = value;
      return this.apply([
        RealtimeOpsBuilder.listReplace(oldVote, newVote, [
          'sld',
          slideId,
          commentId,
          'replies',
          replyIndex,
          'votes',
          voteIndex,
        ]),
      ]);
    }
  }
}

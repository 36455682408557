import './FigureElement.module.scss';

import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { DISPLAY_TYPES, ELEMENTS } from 'Editor/services/consts';
import { BaseBlockElement } from '../../BaseViewBuilder';

export class FigureElement extends BaseBlockElement {
  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  get ALLOWED_STYLE_ATTRIBUTES() {
    return StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || [];
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isEditable() {
    return !this.isLocked;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return true;
  }

  get imageNode() {
    return (this.childNodes[0] as Editor.Elements.ImageElement).imageNode;
  }

  awaitForImageLoad() {
    return (this.childNodes[0] as Editor.Elements.ImageElement).awaitForImageLoad();
  }
}

if (!window.customElements.get(ELEMENTS.FigureElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.FigureElement.IDENTIFIER, FigureElement);
}

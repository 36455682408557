import { v4 as uuid } from 'uuid';
import { EditorService, Logger } from '_common/services';
import { ReduxInterface } from 'Editor/services';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMElements, EditorDOMUtils } from 'Editor/services/_Common/DOM';

export abstract class Parser {
  static ORIGINAL_STYLES: Editor.Clipboard.PasteOptions = 'ORIGINAL_STYLES';
  static MATCH_DESTINATION: Editor.Clipboard.PasteOptions = 'MATCH_DESTINATION';
  static PLAIN_TEXT: Editor.Clipboard.PasteOptions = 'PLAIN_TEXT';

  static ATTRIBUTES_TO_REMOVE = ['id', 'parent_id', 'sct', 'task'];

  public openPasteOptions = true;
  public dataManager: Editor.Data.API;
  public stylesHandler: Editor.Styles.Handler;
  visualizerManager: Editor.Visualizer.API;

  protected debug = true;
  public isValid = false;
  public data: string | File[];
  protected documentId: string | undefined;
  public container: HTMLElement | null;
  public html: HTMLHtmlElement | null;

  // Helpers for keeping track of maps of ids for when the elements are actually inserted
  public newCitations: Editor.Clipboard.NewCitations = {};
  public newCrossRefs: Editor.Clipboard.NewFields = {};

  protected newDocumentStyles = {};
  public newNotes: Editor.Clipboard.NewNotes = {};
  public afterPasteMatchIdList: Editor.Clipboard.AfterPasteMatchIdList = {};
  protected existingCitations: Editor.Data.Citations.CitationData[] = [];

  constructor(
    data: string | File[],
    dataManager: Editor.Data.API,
    stylesHandler: Editor.Styles.Handler,
    visualizerManager: Editor.Visualizer.API,
  ) {
    this.dataManager = dataManager;
    this.stylesHandler = stylesHandler;
    this.visualizerManager = visualizerManager;
    this.data = data;
    this.documentId = this.dataManager.document.getDocumentId();
    this.container = null;
    this.html = null;
  }

  debugMessage(message: string, ...args: any[]) {
    if (this.debug) {
      Logger.debug(message, ...args);
    }
  }

  createFormatElement(styles: Editor.Clipboard.FormatStyleAttributes) {
    const attributes = Object.keys(styles);
    if (attributes.length > 0) {
      const viewFactory = this.visualizerManager.getViewFactory();
      if (viewFactory) {
        const element = viewFactory.getViewByType('format');

        if (EditorDOMElements.isFormatElement(element)) {
          for (let i = 0; i < attributes.length; i++) {
            const attr = attributes[i] as Editor.Elements.FormatElementAttributes;
            const value = styles[attr];
            if (value != null) {
              element.setAttribute(attributes[i], String(value));
            }
          }
          element.preRender?.();
          return element;
        }
      }
    }
    return null;
  }

  static prepareImageForUpload(image: HTMLElement) {
    const id = uuid();
    image.setAttribute('uploading', 'true');
    image.setAttribute('upload_id', id);
    return id;
  }

  async uploadImageFromBase64(
    image: HTMLElement,
    base64: string,
    imageAttributes: Editor.Clipboard.ImageAllowedAttributes | null = null,
  ) {
    // return a promise that resolves with a File instance
    const id = Parser.prepareImageForUpload(image);
    const res = await fetch(base64);
    const buf = await res.arrayBuffer();

    const file = new File([buf], 'attachment');
    await this.uploadPastedImage(id, file, imageAttributes);
  }

  async uploadImageFromSource(
    image: Editor.Elements.ImageElement | null,
    src: RequestInfo | URL,
  ): Promise<void> {
    if (image) {
      const id = Parser.prepareImageForUpload(image);

      return new Promise<void>((resolve, reject) => {
        fetch(src, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
          .then((response) => {
            const id = Parser.prepareImageForUpload(image);
            response.blob().then((blob) => this.uploadPastedImage(id, blob as File));
            resolve();
          })
          .catch((error) => {
            //Logger.captureException(error);
            const image = this.getWorkingImage(id);

            if (image) {
              image.removeAttribute('uploading');
              image.removeAttribute('upload_id');
            }
            reject(error);
          });
      });
    }
  }

  uploadPastedImage(
    id: string,
    file: File,
    imageAttributes: Editor.Clipboard.ImageAllowedAttributes | null = null,
  ): Promise<void> | null {
    const documentId = this.documentId;
    return documentId !== undefined
      ? new Promise<void>((resolve, reject) => {
          new EditorService()
            .saveImage({ id: documentId, image: file }, {})
            .then((response) => {
              const image = this.getWorkingImage(id);

              if (image) {
                image.setImageSource(response.data.reference);
                if (response.data.dimensions) {
                  image.setImageDimensions(
                    response.data.dimensions.width,
                    response.data.dimensions.height,
                  );
                }

                if (imageAttributes) {
                  Object.entries(imageAttributes).forEach(([key, value]) => {
                    image.setAttribute(key, value);
                  });
                }

                image.removeAttribute('uploading');
                image.removeAttribute('upload_id');
              }

              resolve();
            })
            .catch((error) => {
              Logger.captureException(error);
              if (this.container) {
                const image = this.container.querySelector(`[upload_id="${id}"]`);
                if (image) {
                  image.remove();
                  const figure = image.closest(ELEMENTS.FigureElement.TAG);
                  if (figure) {
                    figure.remove();
                  }
                }
              }

              reject(error);
            });
        })
      : null;
  }

  getWorkingImage(id: string): Editor.Elements.ImageElement | null {
    if (this.container) {
      let image = this.container.querySelector(
        `[upload_id="${id}"]`,
      ) as Editor.Elements.ImageElement;
      return image;
    }
    return null;
  }

  prepareForPaste(actionContext: ActionContext, node: Node) {
    if (EditorDOMElements.isParagraphElement(node)) {
      this.handleParagraphAfterPaste(actionContext, node);
    } else if (EditorDOMElements.isTableElement(node)) {
      node.prepareForPaste(actionContext);
      const paragraphs = node.querySelectorAll(
        `${ELEMENTS.ParagraphElement.TAG}, ${ELEMENTS.ParagraphElement.IDENTIFIER}`,
      );

      for (let i = 0; i < paragraphs.length; i++) {
        const paragraph = paragraphs[i];
        this.handleParagraphAfterPaste(actionContext, paragraph);
      }
    }
  }

  prepareForInlinePaste(actionContext: ActionContext, node: Node) {
    // Handle pending note elements
    this.handleNotesAfterPaste(node);
    // Handle pending citation elements
    this.handleCitationsAfterPaste(node);
    // Handle pending field elements
    this.handleFieldsAfterPaste(node);
  }

  handleParagraphAfterPaste(actionContext: ActionContext, node: Node) {
    if (node instanceof HTMLElement) {
      if (!node.id) {
        node.id = EditorDOMUtils.generateRandomNodeId();
      }

      if (node.dataset.tempCrossReferenceId) {
        const references = node.dataset.tempCrossReferenceId.split(',');
        // Node is the target and a reference was found and we are keeping the target to visit later
        for (let i = 0; i < references.length; i++) {
          const refId = references[i];
          this.newCrossRefs[refId].target = node;
        }
      }
      // Update pending document style
      if (node.hasAttribute('data-temp-style-id')) {
        const styleName = node.getAttribute('data-temp-style-id');
        if (styleName) {
          let documentStyle = this.stylesHandler.checkIfStyleNameExist(styleName);
          if (documentStyle) {
            node.removeAttribute('data-temp-style-id');
            node.setAttribute('data-style-id', documentStyle.id);
          } else {
            node.removeAttribute('data-temp-style-id');
            node.setAttribute('data-style-id', ELEMENTS.ParagraphElement.BASE_STYLES.PARAGRAPH);
          }
        }
      }

      // Update lists attributes
      let listId: keyof Editor.Clipboard.AfterPasteMatchIdList =
        node.getAttribute('cp_list_id') ?? '';
      const listLevel = node.getAttribute('cp_list_level');
      const listStyleId = node.getAttribute('cp_list_style');

      if (
        listId &&
        listStyleId &&
        !this.dataManager.styles.listStyles.style(listStyleId)?.isMultiLevelList()
      ) {
        if (this.afterPasteMatchIdList[listId]) {
          this.dataManager.numbering.addBlocksToList(
            [node.id],
            this.afterPasteMatchIdList[listId].id,
            listLevel || '0',
            this.afterPasteMatchIdList[listId].elements[
              this.afterPasteMatchIdList[listId].elements.length - 1
            ],
            actionContext,
          );
          this.afterPasteMatchIdList[listId].elements.push(node.id);
        } else {
          let newListId = this.dataManager.numbering.createNewList(listStyleId);

          this.afterPasteMatchIdList[listId] = {
            id: newListId,
            elements: [node.id],
          };
          this.dataManager.numbering.addBlocksToList(
            [node.id],
            newListId,
            listLevel || '0',
            undefined,
            actionContext,
          );
        }
      }

      node.removeAttribute('cp_list_id');
      node.removeAttribute('cp_list_level');
      node.removeAttribute('cp_list_style');

      // Handle pending note elements
      this.handleNotesAfterPaste(node);
      // Handle pending citation elements
      this.handleCitationsAfterPaste(node);
      // Handle pending field elements
      this.handleFieldsAfterPaste(node);
    }
  }

  async handleNotesAfterPaste(node: Node) {
    // Node can't be a text node
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Node can be a note element
      let notes: HTMLElement[] = [];
      if (EditorDOMElements.isNoteElement(node)) {
        notes = [node];
      } else if (node instanceof HTMLElement) {
        notes = Array.from(node.querySelectorAll('note-element[data-temp-id]'));
      }
      for (let i = 0; i < notes.length; i++) {
        const note = notes[i];
        const wordId: keyof Editor.Clipboard.NewNotes = note.dataset.tempId ?? '';
        if (wordId) {
          const newNote = this.newNotes[wordId];
          if (newNote.newId) {
            note.setAttribute('element_reference', newNote.newId);
          } else {
            const newId = await EditorManager.getInstance().createNote(
              null,
              this.newNotes[wordId].type,
              this.newNotes[wordId].text,
            );
            this.newNotes[wordId].newId = newId;
            if (newId) {
              note.setAttribute('element_reference', newId);
            }
          }
          delete note.dataset.tempId;
        }
      }
    }
  }

  handleCitationsAfterPaste(node: Node) {
    // Node can't be a text node
    if (node.nodeType === Node.ELEMENT_NODE && node instanceof HTMLElement) {
      // Node shouldn't ever be a citation-element, it will always be a citation-group-element at least
      const citations = node.querySelectorAll('citation-element[data-temp-citation-info]');
      for (let i = 0; i < citations.length; i++) {
        const citation = citations[i] as HTMLElement;
        const oldId = citation.getAttribute('element_reference');
        if (oldId) {
          if (!this.newCitations[oldId]) {
            // Get the current existing citations just in case we copying citations that already exist
            this.existingCitations = ReduxInterface.getCitationsObjects();
            // Check if citation already exists using the hash field and use that citation id
            if (citation.dataset.tempCitationInfo) {
              const info = JSON.parse(citation.dataset.tempCitationInfo);
              let newId = this.existingCitations.find(
                ({ hash, doi }) =>
                  (hash != null && hash === info.hash) || (doi != null && doi === info.doi),
              )?.id;
              // Add the citation to the document library if it doesn't exist already
              if (!newId) {
                const source = info.source;

                newId = uuid();
                delete info.hash;
                delete info.source;
                delete info.time;
                info.inserted = true;
                // WARN: carefull with  merge conflits
                // correct arguments, wrong function calling
                this.dataManager.citations.addCitationsToLibrary([{ ...info, id: newId }], source);
              }
              this.newCitations[oldId] = newId;
            }
          }

          citation.setAttribute('element_reference', this.newCitations[oldId]);
          this.dataManager.citations.addCitationToDocument(this.newCitations[oldId]);

          delete citation.dataset.tempCitationInfo;
        }
      }
    }
  }

  handleFieldsAfterPaste(node: Node) {
    // Handle pending cross references elements
    // - Node can be the target
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Node can be a field element

      let fields: HTMLElement[] = [];
      if (EditorDOMElements.isFieldElement(node)) {
        fields = [node];
      } else if (node instanceof HTMLElement) {
        fields = Array.from(
          node.querySelectorAll('field-element[data-temp-cross-reference-target]'),
        );
      }

      for (let i = 0; i < fields.length; i++) {
        // Cross reference target was found
        const field = fields[i];
        const refId = field.dataset.tempCrossReferenceTarget;
        if (refId && this.newCrossRefs[refId]?.hasTarget) {
          let references = this.newCrossRefs[refId].references;
          if (!references) {
            references = [];
          } else {
            references.push(field);
          }
        }
      }
    }
  }

  afterCompletePaste() {
    const refs = Object.keys(this.newCrossRefs);
    for (let i = 0; i < refs.length; i++) {
      const refId = refs[i];
      // Broken ref case
      const references = this.newCrossRefs[refId].references;
      if (this.newCrossRefs[refId].hasTarget && references) {
        const target = this.newCrossRefs[refId].target;
        if (target && target.parentNode) {
          for (let j = 0; j < references.length; j++) {
            const reference = references[j];

            let node = EditorDOMUtils.findFirstLevelChildNode(
              EditorDOMUtils.getContentContainer(target),
              target,
            ) as HTMLElement;
            reference.dataset.ref = `${node.id}:${target.id}`;
            delete reference.dataset.tempCrossReferenceTarget;
          }
          delete target.dataset.tempCrossReferenceId;
        }
      }
    }
  }

  async getContainer(type: Editor.Clipboard.PasteOptions): Promise<HTMLElement | undefined> {
    this.afterPasteMatchIdList = {};
    // TODO: Cache different paste options results
    let containerData: HTMLElement | undefined;
    if (this.container) {
      switch (type) {
        case Parser.ORIGINAL_STYLES:
          containerData = this.container.cloneNode(true) as HTMLElement;

          await this.handleContainerWithPasteOption?.(type, containerData);
          break;
        case Parser.MATCH_DESTINATION: {
          const container = this.container.cloneNode(true) as HTMLElement;

          await this.handleContainerWithPasteOption?.(type, container);
          containerData = container;
          break;
        }
        case Parser.PLAIN_TEXT: {
          const container = this.container.cloneNode(true) as HTMLElement;

          await this.handleContainerWithPasteOption?.(type, container);

          const nodes = container.querySelectorAll('*');
          nodes.forEach((node) => {
            switch (node.tagName) {
              case ELEMENTS.ParagraphElement.TAG: {
                const p = DOMElementFactory.createNewParagraphElement();
                p.textContent = node.textContent;
                if (node.parentNode) {
                  node.parentNode.replaceChild(p, node);
                }
                break;
              }
              case 'LI': {
                // Append content into a new P element and replace
                const p = DOMElementFactory.createNewParagraphElement();
                while (node.firstChild) {
                  p.appendChild(node.firstChild);
                }
                if (node.parentNode) {
                  node.parentNode.replaceChild(p, node);
                }
                break;
              }
              case 'OL':
              case 'UL':
              case ELEMENTS.FigureElement.TAG:
              case ELEMENTS.TableElement.TAG:
              case ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG:
              case ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG:
              case ELEMENTS.TableCellElement.TAG:
              case 'FORMAT-ELEMENT':
              case ELEMENTS.HyperlinkElement.TAG:
              case 'CROSS-REFERENCE-ELEMENT':
              case 'NOTE-ELEMENT':
              case 'CITATIONS-GROUP-ELEMENT':
              case 'CITATION-ELEMENT':
              case 'TRACK-INS-ELEMENT':
              case 'TRACK-DEL-ELEMENT':
                // Unwrap all these elements
                while (node.firstChild) {
                  if (node.parentNode) {
                    node.parentNode.insertBefore(node.firstChild, node);
                  }
                }
                node.remove();
                break;
              default:
                // Remove everything else
                node.remove();
                break;
            }
          });
          containerData = container;
          break;
        }
        default:
          return containerData;
      }
    }
    return containerData;
  }

  handleContainerWithPasteOption(
    type: string,
    containerData: HTMLElement | null,
  ): Promise<void> | void {}
}

import ReactDOM from 'react-dom';

import { IntlProvider } from 'Intl/Intl';
import { FormattedMessage } from 'react-intl';
import styles from './InvalidElement.module.scss';

import { Icon } from 'dodoc-design-system';

import { ReduxInterface } from 'Editor/services';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import EditorManager from 'Editor/services/EditorManager';
import { ELEMENTS } from 'Editor/services/consts';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import { BaseBlockElement } from '../../BaseViewBuilder';

export class InvalidElement extends BaseBlockElement {
  static TIMEOUTS: { [index: string]: NodeJS.Timeout } = {};
  private container?: HTMLElement;
  private iconWrapper?: HTMLElement;
  private wrapper?: HTMLElement;
  private info?: HTMLElement;
  private nodeId?: string;

  connectedCallback() {
    super.connectedCallback();
    this.innerHTML = '';

    if (this.parentNode === EditorDOMUtils.getContentContainer()) {
      this.buildBlockElement();
    } else {
      this.buildInlineElement();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.clearForceUpdateTimeout();
  }

  get selectableContent() {
    return this.wrapper?.childNodes[0] || null;
  }

  setElementIdToCheck(nodeId: string) {
    this.nodeId = nodeId;
    this.setAttribute('enclosed_element', nodeId);

    // this._setForceUpdateTimeout();
  }

  private clearForceUpdateTimeout() {
    if (this.nodeId) {
      if (InvalidElement.TIMEOUTS[this.nodeId]) {
        clearTimeout(InvalidElement.TIMEOUTS[this.nodeId]);
        delete InvalidElement.TIMEOUTS[this.nodeId];
      }
    }
  }

  private setForceUpdateTimeout() {
    this.clearForceUpdateTimeout();
    if (this.nodeId) {
      InvalidElement.TIMEOUTS[this.nodeId] = setTimeout(() => {
        if (this.nodeId) {
          EditorManager.getInstance().forceUpdate(this.nodeId);
        }
      }, 1000);
    }
  }

  private setIcon() {
    if (this.iconWrapper) {
      ReactDOM.unmountComponentAtNode(this.iconWrapper);
      ReactDOM.render(<Icon disabled icon="Warning" size={32} />, this.iconWrapper);
    }
  }

  private setTranslations() {
    const locale = ReduxInterface.getLocale();
    if (this.info) {
      ReactDOM.unmountComponentAtNode(this.info);
      ReactDOM.render(
        <IntlProvider locale={locale}>
          <FormattedMessage id="CUSTOM_ELEMENT_WARNING_CONTENT" />
        </IntlProvider>,
        this.info,
      );
    }
  }

  buildInlineElement() {
    this.setAttribute('spellcheck', 'false');
    this.setAttribute('class', styles.inline);

    this.appendChild(document.createTextNode('INVALID ELEMENT'));
  }

  buildBlockElement() {
    this.setAttribute('spellcheck', 'false');
    this.setAttribute('class', styles.block);

    this.info = DOMElementFactory.buildElement('span');
    this.info.setAttribute('class', styles.info);
    this.info.textContent = 'WARNING';

    this.wrapper = DOMElementFactory.buildElement('span');
    this.wrapper.setAttribute('class', styles.contentWrapper);

    this.wrapper.appendChild(this.info);

    this.iconWrapper = DOMElementFactory.buildElement('div');
    this.iconWrapper.setAttribute('class', styles.iconWrapper);

    this.container = DOMElementFactory.buildElement('div');
    this.container.setAttribute('class', styles.container);

    this.container.appendChild(this.iconWrapper);
    this.container.appendChild(this.wrapper);

    this.appendChild(this.container);

    this.setTranslations();
    this.setIcon();
  }
}

if (!window.customElements.get(ELEMENTS.InvalidElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.InvalidElement.IDENTIFIER, InvalidElement);
}

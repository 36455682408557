import { Ref, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { InputField } from 'dodoc-design-system';

import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';

import { RichTextEditor } from '_common/components';

type DescriptionFieldProps = {
  id?: string;
  initialValue?: string;
  collaborators: UserId[];
  onChange: (newValue: string) => void;
  testId: string;
};

const DescriptionField = forwardRef(
  (
    { id, initialValue, collaborators, onChange, testId }: DescriptionFieldProps,
    ref: Ref<RichTextEditor.RichTextEditorHandler>,
  ) => {
    const intl = useIntl();

    return (
      <InputField
        size="medium"
        label={intl.formatMessage({ id: 'TASK_DESCRIPTION' })}
        required={intl.formatMessage({ id: 'REQUIRED' })}
        feedback={false}
        testId={`${testId}-description`}
      >
        <RichTextEditor
          id={id}
          initialValue={initialValue}
          mentionableUsers={collaborators}
          placeholder={intl.formatMessage({
            id: 'INSERT_TASK_DESCRIPTION',
          })}
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          onChange={onChange}
          ref={ref}
          testId={`${testId}-description-rich-text-editor`}
        />
      </InputField>
    );
  },
);

export default DescriptionField;

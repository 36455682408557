import getConfig from 'dodoc.config';
import { BaseTypedEmitter } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { Logger } from '_common/services';
import { DataManager } from './DataManager';
import { NavigationService } from './NavigationService';
import { ReduxInterface } from './ReduxInterface';
import { SelectionManager } from './SelectionManager';
import { SelectionUtils } from '_common/DoDOCSelection';
import { PresentationSelectionUtils } from './SelectionManager/PresentationSelectionUtils';
import { ShortcutsManager } from './ShortcutsManager';
import { FontFamilyHelper } from '_common/utils/FontFamilyHelper';
import { openDisconnectedModal, openConnectionErrorModal } from 'Presentation/utils';

const Blockable = (
  target: PresentationManager,
  methodName: string,
  descriptor: PropertyDescriptor,
) => {
  const originalMethod = descriptor.value;
  descriptor.value = async function (...args: any[]) {
    // console.warn('-> this is processing...');
    target.setLoading.apply(this, [true]);
    const result = await originalMethod.apply(this, args);
    target.setLoading.apply(this, [false]);
    return result;
  };
  return descriptor;
};

const RequireState = (state: Presentation.Status) => {
  return (target: PresentationManager, methodName: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {
      if (target.getStatus.apply(this) !== state) {
        console.warn(`Presentation Manager need to be ${state} to execute ${methodName}!`);
        return;
      }
      return originalMethod.apply(this, args);
    };
    return descriptor;
  };
};

export class PresentationManager
  extends BaseTypedEmitter<Presentation.Events>
  implements Presentation.IPresentationManager
{
  private fontFamilyHelper: FontFamilyHelper | undefined;
  private context: Presentation.Context;
  protected _reloadTimeout: NodeJS.Timeout | null = null;
  static instance: PresentationManager | null = null;

  constructor() {
    super();
    this.context = {
      status: 'NOT_READY',
      loading: true,
    };

    /* develblock:start */
    //@ts-expect-error
    global.presentation = this;
    /* develblock:end */

    this.saveVersion = this.saveVersion.bind(this);
    this.restoreVersion = this.restoreVersion.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  get status(): Presentation.Status {
    return this.context.status;
  }

  get navigation() {
    return this.context.navigation;
  }

  get loading() {
    return this.context.loading;
  }

  getData(): Presentation.API | undefined {
    return this.context.data;
  }

  getStatus(): Presentation.Status {
    return this.context.status;
  }

  isInitialized(): boolean {
    return (
      this.context.status === 'INITIALIZING' ||
      this.context.status === 'INITIALIZED' ||
      this.context.status === 'READY'
    );
  }

  static getInstance(): PresentationManager {
    if (!PresentationManager.instance) {
      PresentationManager.instance = new PresentationManager();
    }
    return PresentationManager.instance;
  }

  //----------------------------------------------------------------------
  //                              Lifecycle
  //----------------------------------------------------------------------

  setStatus(status: Presentation.Status) {
    this.context.status = status;
    this.emit('STATUS_CHANGED', null, status);
    if (status === 'READY') {
      this.setLoading(false);
    }
  }

  setLoading(loading: boolean, reason?: string) {
    this.context.loading = loading;
    this.emit('LOADING', loading);
  }

  private async initializeCoreServices() {
    try {
      await this.initializeDataManager();
      this.initializeNavigation();
      this.initializeSelectionManager();
      await this.initializeFontFamilyHelper();
      this.setStatus('READY');
    } catch (error) {
      this.setStatus('DESTROYING');
    }
  }

  private async initializeFontFamilyHelper() {
    this.fontFamilyHelper = new FontFamilyHelper(ReduxInterface.getPlatform(), 'presentation');
    await this.fontFamilyHelper.start();
  }

  private async initializeDataManager() {
    if (this.context.transport && this.context.document?.id) {
      this.context.data = DataManager(this.context.transport);
      await this.context.data.start(this.context.document.id, this.context.user);

      this.context.data.on('FORCE_REMOTE_RELOAD', () => {
        this.reloadPresentationManager();
      });

      this.context.data.on('DOCUMENT_UPDATED', () => {
        ReduxInterface.invalidateApiTags([{ type: 'Object', id: this.context.document?.id }]);
      });
    }
  }

  private initializeNavigation() {
    if (this.context.transport && this.context.document?.id) {
      this.context.navigation = new NavigationService(this.context);
      this.context.navigation.start();
      this.context.navigation.on('BEFORE_CHANGING_CURRENT_SLIDE', (current) => {
        this.emit('BEFORE_CHANGING_CURRENT_SLIDE', current);
      });
      this.context.navigation.on('CHANGED_CURRENT_SLIDE', (current) => {
        // ReduxInterface.setCurrentPage(current);
        ReduxInterface.setCurrentSlide(current);
        this.emit('CHANGED_CURRENT_SLIDE', current);
      });
      this.context.navigation.on('AFTER_CHANGING_CURRENT_SLIDE', (current) => {
        this.emit('AFTER_CHANGING_CURRENT_SLIDE', current);
      });
      this.context.navigation.on('SCROLL_TO_SLIDE', (current) => {
        this.emit('SCROLL_TO_SLIDE', current);
      });
    }
  }

  private initializeSelectionManager() {
    this.context.selection = new SelectionManager();
    this.context.selection.start();
  }

  initializeShortcutsManager() {
    this.context.shortcuts = new ShortcutsManager(ReduxInterface.getPlatform());
    this.context.shortcuts.on('ZOOM_IN', ReduxInterface.zoomIn);
    this.context.shortcuts.on('ZOOM_OUT', ReduxInterface.zoomOut);
    this.context.shortcuts.start();
  }

  destroyShortcutsManager() {
    if (this.context.shortcuts) {
      this.context.shortcuts.destroy();
      delete this.context.shortcuts;
    }
  }

  connect(documentId: string, user: Realtime.Core.User) {
    if (this.context.status === 'INITIALIZING') {
      Logger.warning(`TRANSPORT CONNECTION already INITIALIZING!: docId: ${documentId}`, user);
      return;
    }

    if (this.context.status === 'READY') {
      Logger.warning(`MANAGER already INITIALIZED!: docId: ${documentId}`, user);
      this.destroy();
    }
    Logger.info(`CONNECTION INITIALIZING: docId: ${documentId}`, user);

    this.context.status = 'INITIALIZING';
    this.emit('STATUS_CHANGED', null, 'INITIALIZING');

    if (this.context.transport != null && this.context.transport.isConnected()) {
      // transport is already connected
      this.context.transport.checkConnectionStrength().then((result: number) => {
        this.setStatus('INITIALIZED');
        Logger.debug(`TRANSPORT ALREADY CONNECTED : ${result}`);
        this.initializeCoreServices();
      });
    } else {
      this.context.document = {
        id: documentId,
      };
      this.context.user = user;
      this.context.transport = new Transport(getConfig());
      this.context.transport
        .on('TS_CONNECTED', () => {
          this.context.transport?.checkConnectionStrength().then((result: number) => {
            this.setStatus('INITIALIZED');
            Logger.debug(`TRANSPORT CONNECTION : ${result}`);
            this.initializeCoreServices();
          });
        })
        .on('TS_DESTROYED', () => {
          this.destroy();
        })
        .on('TS_DISCONNECTED', () => {
          openDisconnectedModal();
          this.destroy(new Error('DISCONNECTED'));
        })
        .on('TS_ERROR', (event: any, error: any) => {
          openConnectionErrorModal();
          this.destroy(new Error(event));
        });
      if (this.context.document.id && this.context.user.token) {
        this.context.transport.connect(this.context.document.id, this.context.user.token);
      }
    }
  }

  reloadPresentationManager() {
    if (this._reloadTimeout) {
      clearTimeout(this._reloadTimeout);
    }

    this._reloadTimeout = setTimeout(() => {
      try {
        this.destroy();
        this.initializeCoreServices();
      } catch (error) {
        Logger.captureException(error);
        this.destroy();
        throw error;
      }
    }, 0);
  }

  destroyDataManager() {
    if (this.context.data) {
      try {
        this.context.data.destroy();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        delete this.context.data;
      }
    }
  }

  destroyNavigation() {
    if (this.context.navigation) {
      try {
        this.context.navigation.destroy();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        delete this.context.navigation;
      }
    }
  }

  destroySelectionManager() {
    if (this.context.selection) {
      try {
        this.context.selection.destroy();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        delete this.context.selection;
      }
    }
  }

  destroyFontFamilyHelper() {
    if (this.fontFamilyHelper) {
      try {
        this.fontFamilyHelper.destroy();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        delete this.fontFamilyHelper;
      }
    }
  }

  destroyTransport() {
    if (this.context.transport) {
      try {
        this.context.transport.destroy();
      } catch (error) {
        Logger.captureException(error);
      } finally {
        delete this.context.transport;
      }
    }
  }

  destroy(error?: Error): void {
    Logger.info('DESTROYING MANAGER', this.context.status);
    if (this.context.status !== 'DESTROYING' && this.context.status !== 'DESTROYED') {
      this.setStatus('DESTROYING');
      this.destroyFontFamilyHelper();
      this.destroySelectionManager();
      this.destroyNavigation();
      this.destroyDataManager();
      this.destroyTransport();
      super.destroy();
      this.setStatus('DESTROYED');
    }
  }

  //----------------------------------------------------------------------
  //                              Utils
  //----------------------------------------------------------------------

  getFontFamilyHelper() {
    return this.fontFamilyHelper;
  }

  //----------------------------------------------------------------------
  //                              Comments
  //----------------------------------------------------------------------

  @RequireState('READY')
  addComment(...args: Parameters<Presentation.Controller.Comments['addComment']>) {
    return this.context.data?.comments.addComment(...args);
  }

  @RequireState('READY')
  editComment(...args: Parameters<Presentation.Controller.Comments['editComment']>) {
    return this.context.data?.comments.editComment(...args);
  }

  @RequireState('READY')
  changePriority(...args: Parameters<Presentation.Controller.Comments['changePriority']>) {
    return this.context.data?.comments.changePriority(...args);
  }

  @RequireState('READY')
  resolveComment(...args: Parameters<Presentation.Controller.Comments['resolveComment']>) {
    return this.context.data?.comments.resolveComment(...args);
  }

  @RequireState('READY')
  focusComment(comment: Presentation.Data.Comment) {
    return this.context.navigation?.goToSlideId(comment.anchor[0].id);
  }

  @RequireState('READY')
  deleteComment(...args: Parameters<Presentation.Controller.Comments['deleteComment']>) {
    return this.context.data?.comments.deleteComment(...args);
  }

  @RequireState('READY')
  voteComment(...args: Parameters<Presentation.Controller.Comments['voteComment']>) {
    return this.context.data?.comments.voteComment(...args);
  }

  @RequireState('READY')
  replyToComment(...args: Parameters<Presentation.Controller.Comments['replyToComment']>) {
    return this.context.data?.comments.replyToComment(...args);
  }

  @RequireState('READY')
  deleteReplyComment(...args: Parameters<Presentation.Controller.Comments['deleteReplyComment']>) {
    return this.context.data?.comments.deleteReplyComment(...args);
  }
  @RequireState('READY')
  editReplyComment(...args: Parameters<Presentation.Controller.Comments['editReplyComment']>) {
    return this.context.data?.comments.editReplyComment(...args);
  }

  @RequireState('READY')
  voteReply(...args: Parameters<Presentation.Controller.Comments['voteReply']>) {
    return this.context.data?.comments.voteReply(...args);
  }

  //----------------------------------------------------------------------
  //                              TASKS
  //----------------------------------------------------------------------

  @RequireState('READY')
  addTask(...args: Parameters<Presentation.Controller.Tasks['addTask']>) {
    return this.context.data?.tasks.addTask(...args);
  }

  @RequireState('READY')
  editTask(...args: Parameters<Presentation.Controller.Tasks['editTask']>) {
    return this.context.data?.tasks.editTask(...args);
  }

  @RequireState('READY')
  deleteTask(...args: Parameters<Presentation.Controller.Tasks['deleteTask']>) {
    return this.context.data?.tasks.deleteTask(...args);
  }

  @RequireState('READY')
  changeTaskStatus(...args: Parameters<Presentation.Controller.Tasks['changeTaskStatus']>) {
    return this.context.data?.tasks.changeTaskStatus(...args);
  }

  @RequireState('READY')
  replyToTask(...args: Parameters<Presentation.Controller.Tasks['replyToTask']>) {
    return this.context.data?.tasks.replyToTask(...args);
  }

  @RequireState('READY')
  addWatcherToTask(...args: Parameters<Presentation.Controller.Tasks['addWatcherToTask']>) {
    return this.context.data?.tasks.addWatcherToTask(...args);
  }

  @RequireState('READY')
  removeWatcherFromTask(
    ...args: Parameters<Presentation.Controller.Tasks['removeWatcherFromTask']>
  ) {
    return this.context.data?.tasks.removeWatcherFromTask(...args);
  }

  @RequireState('READY')
  voteReplyToTask(...args: Parameters<Presentation.Controller.Tasks['voteReplyToTask']>) {
    return this.context.data?.tasks.voteReplyToTask(...args);
  }

  @RequireState('READY')
  deleteReplyToTask(...args: Parameters<Presentation.Controller.Tasks['deleteReplyToTask']>) {
    return this.context.data?.tasks.deleteReplyToTask(...args);
  }

  @RequireState('READY')
  editReplyToTask(...args: Parameters<Presentation.Controller.Tasks['editReplyToTask']>) {
    return this.context.data?.tasks.editReplyToTask(...args);
  }

  focusTask(task: Presentation.Data.Task) {
    this.context.navigation?.goToSlideId(task.anchor[0].id);
  }

  //----------------------------------------------------------------------
  //                              Versions
  //----------------------------------------------------------------------

  @RequireState('READY')
  async saveVersion(description: string) {
    return this.context.data?.versions.saveVersion(description);
  }

  @RequireState('READY')
  async loadVersion(index: number | null) {
    try {
      const res = await this.context.data?.versions.loadVersion(index);
      ReduxInterface.setLoadedVersion(index);
      return res;
    } catch (e) {
      throw e;
    }
  }

  @RequireState('READY')
  async restoreVersion(index: number) {
    try {
      const restore = this.context.data?.versions.restoreVersion(index);
      await this.loadVersion(null);
      return restore;
    } catch (error) {
      throw error;
    }
  }

  //----------------------------------------------------------------------
  //                              Find/Replace
  //----------------------------------------------------------------------

  @RequireState('READY')
  @Blockable
  find(...args: Parameters<Presentation.Controller.FindAndReplace['findInPresentation']>) {
    return this.context.data?.findAndReplace.findInPresentation(...args);
  }

  //----------------------------------------------------------------------
  //                              Selection
  //----------------------------------------------------------------------
  restoreSelection() {
    if (this.context.selection) {
      this.context.selection.restoreRange();
    }
    return null;
  }

  clearSelection() {
    if (this.context.selection) {
      this.context.selection.clearRange();
    }
    return null;
  }

  getRange(): Presentation.Selection.PresentationRange | undefined {
    if (this.context.selection) {
      return PresentationSelectionUtils.getRange();
    }
    return undefined;
  }

  getPresentationAnchor(): Presentation.Common.PresentationAnchor | null {
    if (this.context.selection) {
      return PresentationSelectionUtils.getRange()?.getPresentationAnchor() ?? null;
    }
    return null;
  }

  isSelectionCollapsed() {
    if (this.context.selection) {
      return SelectionUtils.isSelectionCollapsed();
    }
    return null;
  }
}

import { Mixin } from 'mixwith';
import { Logger } from '_common/services';
import { ElementNodeBuilder } from 'Editor/services/Model';
import { ELEMENTS } from 'Editor/services/consts';

export default Mixin(
  (superclass) =>
    class extends superclass {
       
      parseTable(representation) {
        const builder = new ElementNodeBuilder();

        builder.addElementType(ELEMENTS.TableElement.ELEMENT_TYPE);

        // parse properties
        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.TableElement.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);

        this.parseClassNames(representation, builder);

        const json = builder.getNode();
        return json;
      }

      parseTableBody(representation) {
        const builder = new ElementNodeBuilder();

        builder.addElementType(ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE);

        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);

        this.parseClassNames(representation, builder);

        return builder.getNode();
      }

      parseTableRow(representation) {
        const builder = new ElementNodeBuilder();

        builder.addElementType(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE);

        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);

        this.parseClassNames(representation, builder);

        return builder.getNode();
      }

      parseTableCell(representation) {
        const builder = new ElementNodeBuilder();

        builder.addElementType(ELEMENTS.TableCellElement.ELEMENT_TYPE);

        const mapper = this.editorContext.visualizerManager
          .getViewFactory()
          ?.getAttributeMapper(ELEMENTS.TableCellElement.ELEMENT_TYPE);
        Object.keys(mapper).forEach((prop) => {
          try {
            mapper[prop].parse(representation, builder);
          } catch (e) {
            Logger.captureException(e);
          }
        });

        this.parseChildren(representation, builder);

        this.parseClassNames(representation, builder);

        const json = builder.getNode();
        return json;
      }
    },
);

 
import { RealtimeObject } from '_common/services/Realtime';
import { Transport } from '_common/services/Realtime/Transport';
import { CmappsData } from './Cmapps.types';

export class Cmapps extends RealtimeObject<CmappsData> {
  private nodesKeys?: string[];
  constructor(transport: Transport, id: Realtime.Core.RealtimeObjectId) {
    super(transport, id, 'cmapps_info');
  }

  handleLoad(): void {}

  handlePreBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  handleBatchOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    this.emit('UPDATED', this.get(), ops, source);
  }

  handleOperations(ops: Realtime.Core.RealtimeOps, source: Realtime.Core.RealtimeSourceType): void {
    //
  }

  handlePreOperations(
    ops: Realtime.Core.RealtimeOps,
    source: Realtime.Core.RealtimeSourceType,
  ): void {
    //
  }

  nodes() {
    if (!this.nodesKeys) {
      this.nodesKeys = Object.keys(this.get(['n']));
    }
    return this.nodesKeys;
  }

  hasNode(nodeId: string) {
    return this.get(['n', nodeId]);
  }
}

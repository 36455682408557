import { useMemo } from 'react';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { notify } from '_common/components/ToastSystem';
import {
  APISYNC,
  DateRange,
  FilterIdentity,
  FilterName,
  Filters,
  FilterType,
  FilterValue,
  TYPES,
} from './FilterController';
import { modifyDateRangeFilterField, modifyFilterValue } from './filterUtils';

type FilterSliceState = {
  [identity in FilterIdentity]: Filters;
};

const SLICE_NAME = 'FILTER_POPOVER';
const initialState: FilterSliceState = {
  auditLog: {},
  storage: {},
  tenantSettings_users: {},
  shared: {},
  notifications: {},
  editorTaskPanel: {},
  editorCommentPanel: {},
  editorTrackPanel: {},
  editorDocCaption: {},
  editorNavPanel: {},
  reviewerCommentPanel: {},
  reviewerTaskPanel: {},
  presentationCommentPanel: {},
  presentationTaskPanel: {},
};

// #region AsyncThunks
export const clearFilterIdentity = createAsyncThunk<
  FilterIdentity,
  { identity: FilterIdentity; noFeedback?: boolean },
  {
    state: RootState;
  }
>(`${SLICE_NAME}/clearFilterIdentity`, async ({ identity, noFeedback }) => {
  if (!noFeedback) {
    notify({
      type: 'success',
      title: 'ALL_FILTERS_CLEARED',
      message: 'ALL_FILTERS_WERE_SUCCESSFULLY_CLEARED',
    });
  }

  return identity;
});
// #endregion

// #region Slice
const FilterPopoverSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setFilter<F extends FilterName>(
      state: FilterSliceState,
      action: PayloadAction<{
        identity: FilterIdentity;
        filter: F;
        value: Filters[F];
      }>,
    ) {
      const { identity, filter, value } = action.payload;

      if (!state[identity]) {
        state[identity] = {};
      }

      if (!value || (Array.isArray(value) && value.length === 0)) {
        delete state[identity][filter];
      } else {
        state[identity][filter] = value;
      }
    },
    clearFilterValue<F extends FilterName>(
      state: FilterSliceState,
      action: PayloadAction<{
        identity: FilterIdentity;
        filter: F;
        value?: FilterType[F];
      }>,
    ) {
      const { identity, filter, value } = action.payload;
      const foundFilter = state[identity][filter];

      if (foundFilter) {
        if (TYPES.dateRange.includes(filter) || !Array.isArray(foundFilter)) {
          //Single values can be fully removed
          delete state[identity][filter];
        } else {
          //Remove a single value of array
          const filteredValue = foundFilter.filter((filterValue) => filterValue.value !== value);

          if (filteredValue.length === 0) {
            //Empty array can be fully removed
            delete state[identity][filter];
          } else {
            //@ts-expect-error Since 'filter' can be any value of 'FilterName', TS expects more types other than arrays
            state[identity][filter] = filteredValue;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearFilterIdentity.fulfilled, (state, action) => {
      state[action.payload] = initialState[action.payload];
    });
  },
});

const receiveFilterIdentity = (_: RootState, identityFilters: Filters) => identityFilters;
const makeFilterParamsSelector = () => {
  return createSelector([receiveFilterIdentity], (identityFilters) => {
    return Object.entries(identityFilters ?? {})
      .filter(([_, value]) => value)
      .reduce<Request.FilterParams>(
        (filterParams, [filter, value]) => {
          const typedFilter = filter as FilterName; //Cast as filterName because TS doesn't type Object.entries properly

          if (TYPES.dateRange.includes(typedFilter)) {
            const dateRangeFilter = value as DateRange; //Since we checked it is indeed a dateRange, cast value as a DateRange
            filterParams.filter_fields.push(
              //Get dateRange keys which have values
              ...(
                Object.entries(dateRangeFilter ?? {})
                  .filter(([_, value]) => value)
                  .map(([key]) => key) as ('startISO' | 'endISO')[]
              ) //Cast because Object.entries doesn't type key properly
                .map((key) => modifyDateRangeFilterField(typedFilter, key)),
            );
            filterParams.filter_values.push(
              //Get dateRange non-empty values
              ...Object.entries(dateRangeFilter ?? {})
                .filter(([_, value]) => value)
                .map(([filter, value]) => {
                  const typedFilter = filter as FilterName; //Cast as filterName because TS
                  return modifyFilterValue(typedFilter, value);
                }),
            );
          } else if (TYPES.switch.includes(typedFilter) || TYPES.checkbox.includes(typedFilter)) {
            const controlValues = value as FilterValue<string>[]; //Since we checked it is indeed a switch/checkbox, cast as switch/checkbox value

            //Due to API inconsistencies some specific conditions are needed in order to achieve a global solution
            if (typedFilter === 'notificationCategory') {
              filterParams.filter_fields.push('type');
              filterParams.filter_values.push(
                controlValues.map((controlValue) => controlValue.value).join(','),
              );
            } else {
              filterParams.filter_fields.push(
                ...controlValues.map((controlValue) => controlValue.value),
              );
              filterParams.filter_values.push(...Array(controlValues.length).fill('true'));
            }
          } else {
            const typedValue = value as Exclude<typeof value, DateRange>;
            filterParams.filter_fields.push(APISYNC[typedFilter] ?? typedFilter);
            filterParams.filter_values.push(
              modifyFilterValue(
                typedFilter,
                Array.isArray(typedValue)
                  ? typedValue.map((element) => element.value)
                  : typedValue.value,
              ),
            );
          }

          return filterParams;
        },
        { filter_fields: [], filter_values: [] },
      );
  });
};

const makeAppliedFilterCountSelector = () => {
  return createSelector([receiveFilterIdentity], (identityFilters) => {
    return Object.entries(identityFilters ?? {})
      .filter(([_, value]) => value)
      .reduce<number>((appliedFilterCount, [_, value]) => {
        if (Array.isArray(value)) {
          return appliedFilterCount + value.length;
        } else {
          return appliedFilterCount + 1;
        }
      }, 0);
  });
};

export const selectHasFilters = createSelector([receiveFilterIdentity], (identityFilters) => {
  return Object.values(identityFilters ?? {}).some((value) =>
    Array.isArray(value) ? value.length > 0 : !!value,
  );
});

/** Source: https://redux.js.org/usage/deriving-data-selectors#selector-factories
 * 'createSelector' only has a default cache size of 1, and this is per each unique instance of a selector.
 * This creates problems when a single selector function needs to get reused in multiple places with differing inputs.
 */
export const useFilterSelector = () => ({
  selectFilterParams: useMemo(() => makeFilterParamsSelector(), []),
  selectAppliedFilterCount: useMemo(() => makeAppliedFilterCountSelector(), []),
});

export default FilterPopoverSlice.reducer;
// #endregion

// #region Actions
export const { setFilter, clearFilterValue } = FilterPopoverSlice.actions;
// #endregion

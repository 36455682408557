import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';

type NavigationSliceState = {
  searchValue: string;
};

const initialState: NavigationSliceState = {
  searchValue: '',
};

const NavigationSlice = createSlice({
  name: 'Navigation',
  initialState,
  reducers: {
    editNavigationSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
      return initialState;
    });
  },
});

export const selectNavigationElements = createSelector(
  [(state: RootState) => state.editor.toc.data],
  (data) => Object.values(data).filter((toc) => toc.type === 'p'),
);

export const selectFilteredNavigationElements = createSelector(
  [
    (state: RootState) => state.editor.toc.list,
    (state: RootState) => state.editor.toc.data,
    (state: RootState) => state.editor.navigation.searchValue,
    (state: RootState) => state.filters.editorNavPanel,
    (state: RootState) => state.editor.wordCount.partials,
  ],
  (list, data, searchValue, filters, partials) => {
    const filterValues = {
      elementType: filters.elementType?.map((filterValue) => filterValue.value),
    };

    let total = 0;
    const filteredData = cloneDeep(data);
    const filter = (elementId: TableOfContents.TOCElementType['id']) => {
      const element = filteredData[elementId];

      // add word count summary to element
      if (partials[elementId]) {
        element.wc = partials[elementId];
      }

      element.childNodes = element.childNodes.filter(filter);
      if (element.childNodes.length > 0) {
        total += element.childNodes.length;
        return true;
      }

      //Filter by element type (also removing equations)
      if (
        element.label &&
        element.label !== 'equation' &&
        filterValues.elementType &&
        !filterValues.elementType.includes(element.label)
      ) {
        return false;
      }

      //Filter by search value
      if (
        searchValue !== '' &&
        (!element.content || !element.content.toLowerCase().includes(searchValue.toLowerCase()))
      ) {
        return false;
      }
      return true;
    };
    const filteredList = list.filter(filter);
    total += filteredList.length;
    return { list: filteredList, data: filteredData, total };
  },
);

export const { editNavigationSearchValue } = NavigationSlice.actions;

export default NavigationSlice.reducer;
